import React, {useContext, useEffect} from "react";
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../ag-grid-custom-styles.scss';
import {ReportResults} from "./ReportResults";
import {AuthorizedItemsContext} from "../../context/AuthorizedItemsContext";
import {authHelper} from "../../util/authHelper";
import {useAgGrid} from "../../hooks/useAgGrid";
import {generateGridState} from "./generateGridState";
import useSimpleToast from "../../hooks/useSimpleToast";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import {ReportType} from "../../enums/ReportType";
import {generateChartOptions} from "./generateChartOptions";

interface ReportChartProps {
  onRefreshData: () => void;
  reportData: ReportResults;
  reportType: ReportType;
  isShowing: boolean;
}


// ----------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------
const ReportChart: React.FC<ReportChartProps> = ({ reportType, reportData, isShowing  }: ReportChartProps) => {
  const {user} = useContext(ApplicationUserContext);
  const {auth} = useContext(AuthorizedItemsContext);
  const agTop = useAgGrid();
  const agBottom = useAgGrid();
  const toast = useSimpleToast();
  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
  const [topGridOptions] = generateGridState(reportData, locSettings);

  // --------------------------------------------------------------------------------
  useEffect(() => {
    if (isShowing && reportData && agTop.columnApi !== null && agBottom.columnApi !== null) {
      agTop.columnApi.autoSizeAllColumns();
      agTop.gridApi!.sizeColumnsToFit();
      agBottom.columnApi.autoSizeAllColumns();
      agBottom.gridApi!.sizeColumnsToFit();
    }
  }, [reportData, agTop, agBottom, isShowing]);

  return (
    <>
      <div className="ag-theme-alpine" /* style={{display: 'flex', flexDirection: 'column', height: '100%'}} */ >
        <div className="data-display-container" /* style={{flex: '1 1 auto'}} */ >
          <AgChartsReact
            options={generateChartOptions(reportType, topGridOptions.rowData)}
          />
        </div>
      </div>

      {toast.simpleToastJsx}
    </>
  );
};

export default ReportChart;

