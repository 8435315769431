import React from "react";
import "../../global-styles.scss";

interface NoEventsToShowProps {
  message: string;
}

// --------------------------------------------------------------------------------------
const NoEventsToShow: React.FC<NoEventsToShowProps> = ({message}: NoEventsToShowProps) => {
  return (
    <div>
      <span className="no-messagesOrEvents-text">{message}</span>
    </div>
  );
};

export default NoEventsToShow;
