import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonModal,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
  IonFooter,
} from "@ionic/react";
import {notifications, mail, logOut, person, settings} from "ionicons/icons";
import React, { useState } from "react";
import "../global-styles.scss";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import env from "../appFunctions/environment";
import useDialogBox from "../hooks/useDialogBox";
import NotificationSettingsModal from "./NotificationSettingsModal";
import useSimpleToast from "../hooks/useSimpleToast";
import SubmitFeedbackModal from "./SubmitFeedbackModal";
import EmployeeDetailModal from "./EmployeeDetailModal";
import ApplicationSettingsModal from "./ApplicationSettingsModal";



// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const Menu: React.FC = () => {
  const [disableMenu] = useState(false);
  const { user } = React.useContext(ApplicationUserContext);
  const environment = env.settings.env.item(user.environment);
  const userName =
    environment.key !== "prod" ? user.username!.substr(4) : user.username!;
  const dlg = useDialogBox();
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [showSubmitFeedbackModal, setShowSubmitFeedbackModal] = useState(false);
  const [showEmployeeDetailModal, setShowEmployeeDetailModal] = useState(false);
  const [showApplicationSettingsModal, setShowApplicationSettingsModal] = useState(false);
  const toast = useSimpleToast();

  // --------------------------------------------------------------------------------------
  function onCloseSubmitFeedbackModal(submitted: boolean) {
    setShowSubmitFeedbackModal(false);
    if (submitted) {
      toast.showToast("Submitted, thank you!");
    }
  }

  /* tslint:disable:jsx-no-lambda */
  return (
    <IonMenu className="menu-button-background" type="overlay" disabled={disableMenu} contentId="main">
      <IonHeader className="ion-no-border">
        {user.isLoggedin && (
          <IonToolbar>
            <IonTitle>Menu</IonTitle>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="outer-content">
        {environment.key !== "prod" && (
          <div className="username-header">
            {userName.toUpperCase()} ({environment.name.toUpperCase()})
          </div>
        )}
        {environment.key === "prod" && user.isLoggedin && (
          <div className="username-header">User {user.isLoggedin ? userName : ""}</div>
        )}
        <IonList>
          {user.isLoggedin && (
            <>
              <IonMenuToggle auto-hide="false">
                <IonItem
                  button={true}
                  onClick={() => setShowEmployeeDetailModal(true)}
                >
                  <IonLabel>My Account</IonLabel>
                  <IonIcon slot="end" icon={person} />
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle auto-hide="false">
                <IonItem
                  button={true}
                  onClick={() => {
                    setShowNotificationsModal(true);
                  }}
                >
                  <IonLabel>Notifications</IonLabel>
                  <IonIcon slot="end" icon={notifications} />
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle auto-hide="false">
                <IonItem
                  button={true}
                  onClick={() => {setShowApplicationSettingsModal(true);}}
                >
                  <IonLabel>Application Settings</IonLabel>
                  <IonIcon slot="end" icon={settings} />
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle auto-hide="false">
                <IonItem button={true} onClick={() => {setShowSubmitFeedbackModal(true)}}>
                  <IonLabel>Give App Feedback</IonLabel>
                  <IonIcon slot="end" icon={mail} />
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle auto-hide="false">
                <IonItem button={true} routerLink="/login" routerDirection="back" >
                  <IonIcon slot="end" icon={logOut} />
                  <IonLabel>Logout</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </>
          )}
        </IonList>
        {dlg.alertBoxJsx}
        {toast.simpleToastJsx}
      </IonContent>
      <IonFooter>
        <div className="debug-info">
          {env.settings.appTypeSettings.name}
          <br />
          Push: {user.isPushEnabled ? "Yes" : "No"}
          <br />
          Build Date:{" "}
          {env.settings.buildDate === "_BUILD_DATE_"
            ? "N/A"
            : env.settings.buildDate}
          <br />
          UI: {env.settings.userInterface === "_REPLACE_USERINTERFACE_"
                  ? "(Development)"
                  : env.settings.userInterface}
          <br />
          Ver:{" "}
          {env.settings.revisionHash === "_REPLACE_COMMIT_HASH_"
            ? "N/A"
            : env.settings.appVersionString}
        </div>
      </IonFooter>

      <IonModal
        mode="md"
        isOpen={showApplicationSettingsModal}
        onDidDismiss={() => setShowApplicationSettingsModal(false)}
      >
        <ApplicationSettingsModal
          onDismissModal={() => setShowApplicationSettingsModal(false)}
          isOpen={showApplicationSettingsModal}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showNotificationsModal}
        onDidDismiss={() => { setShowNotificationsModal(false);}}
      >
        <NotificationSettingsModal
          onDismissModal={() => {
            setShowNotificationsModal(false);
          }}
          isOpen={showNotificationsModal}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showSubmitFeedbackModal}
        onDidDismiss={() => { onCloseSubmitFeedbackModal(false) }}
      >
        <SubmitFeedbackModal
          onDismissModal={onCloseSubmitFeedbackModal}
          isOpen={showSubmitFeedbackModal}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showEmployeeDetailModal}
        onDidDismiss={() => setShowEmployeeDetailModal(false)}
      >
        <EmployeeDetailModal
          onDismissModal={() => setShowEmployeeDetailModal(false)}
          isOpen={showEmployeeDetailModal}
          employeeId={null}
        />
      </IonModal>
    </IonMenu>
  );
};

export default Menu;
