import React, { useContext, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar, IonList, IonItem, IonTextarea,
} from "@ionic/react";
import "../global-styles.scss";
import { arrowBackOutline } from "ionicons/icons";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import {feedbackApi} from "../api/feedbackApi";
import {log} from "../util/logger";
import {useShowLoading} from "../hooks/useShowLoading";
import useCancelablePromise from "../hooks/useCancelablePromise";

interface SubmitFeedbackModalProps {
  onDismissModal: (submitted: boolean) => void;
  isOpen: boolean;
}

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
const SubmitFeedbackModal: React.FC<SubmitFeedbackModalProps> = ({
  onDismissModal,
}: SubmitFeedbackModalProps) => {

  const { user } = useContext(ApplicationUserContext);
  const [message, setMessage] = useState("" as string | null | undefined);
  const loading = useShowLoading("Submitting your message...");
  const makeCancelable = useCancelablePromise();

  // -------------------------------------------------------------------------------------------
  function onSubmitClick() {
    if (message !== null && message !== undefined && message.length > 0) {
      loading.setShowLoading(true);
      makeCancelable(feedbackApi.submitFeedback(user, message)).then(res => {
        log("submit_feedback", { succeeded: !res.error});
        loading.setShowLoading(false);
        onDismissModal(true);
      });
    }
  }

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => {onDismissModal(false)}}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Orbital Shift Support</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content menu-button-background">
        <IonList>
          <IonItem lines="none">
            <IonTextarea
              value={message}
              placeholder={"Enter your feedback to Orbital Shift"}
              autoGrow={true}
              spellcheck={true}
              wrap="hard"
              debounce={50}
              onIonChange={(e) => {
                setMessage(e.detail.value);
              }}

            />
          </IonItem>

        </IonList>
        {loading.showLoadingJsx}

      </IonContent>

      <IonFooter>
        <IonButton
          className="apply-button"
          expand="block"
          onClick={onSubmitClick}
          disabled={
            message === null || message === undefined || message.length === 0
          }
        >
          Submit
        </IonButton>
      </IonFooter>
    </>
  );
};

export default SubmitFeedbackModal;
