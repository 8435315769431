import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import ToggleListEx from "../components/ToggleListEx";
import {pushNotificationsApi} from "../api/pushNotificationsApi";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import { NotificationTypeModel } from "../orbital-interfaces/NotificationTypeModel";

interface NotificationSettingsModalProps {
  onDismissModal: () => void;
  isOpen: boolean;
}

const NotificationSettingsModal: React.FC<NotificationSettingsModalProps> = ({
  onDismissModal,
  isOpen,
}: NotificationSettingsModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const [notifications, setNotifications] = useState(
    null as NotificationTypeModel[] | null
  );
  const [selectedNotifications, setSelectedNotifications] = useState(
    null as NotificationTypeModel[] | null
  );

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    let isSubscribed = true;

    if (!isOpen) {
      setSelectedNotifications(null);
      setNotifications(null);
      return;
    }

    if (user.isLoggedin) {
      Promise.all([
        pushNotificationsApi.getTypesSelectedByUser(user),
        pushNotificationsApi.listTypes(user),
      ]).then((responses) => {
        if (!isSubscribed) {
          return;
        }

        setSelectedNotifications(responses[0]);
        setNotifications(responses[1]);
      });
    }


    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line
  }, [isOpen, user.isLoggedin]);

  // --------------------------------------------------------------------------------------
  const onCancel = () => {
    onDismissModal();
  };

  // --------------------------------------------------------------------------------------
  const onSelectionChanged = (newSelected: NotificationTypeModel[]) => {
    const ids = newSelected.map((n) => n.id);
    pushNotificationsApi.putTypes(user, ids).then(() => {
      setSelectedNotifications(newSelected);
    });
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content menu-button-background">
        <ToggleListEx
          list={notifications}
          selected={selectedNotifications}
          selectionChanged={onSelectionChanged}
        />
      </IonContent>


    </>
  );
};

export default NotificationSettingsModal;
