export enum SubscriptionType {
  None = -1,
  Standard = 0,
  Premium = 1,
  Enterprise = 2,
}

export function getSubscriptionTypeName(subscripionType: SubscriptionType): string {
  switch (subscripionType) {
    case SubscriptionType.None:
      return "None";
    case SubscriptionType.Standard:
      return "Standard";
    case SubscriptionType.Premium:
      return "Premium";
    case SubscriptionType.Enterprise:
      return "Enterprise";
    default:
      return "Unknown";
  }
}
