import React, {useContext, useEffect, useRef, useState} from "react";
import {IonCol, IonInput, IonLabel, IonRow,} from "@ionic/react";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {EventType, TimeFormat,} from "../../../enums";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {orbitalTimeHelper} from "../../../util/orbitalTimeHelper";
import {authHelper} from "../../../util/authHelper";
import {timeHelper, timeHelper as th} from "../../../util/timeHelper";
import {employeesApi} from "../../../api/employeesApi";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";

interface AbsentTimeEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const AbsentTimeEditor: React.FC<AbsentTimeEditorProps> = ({
 auth,
 editorValues,
 update,
}: AbsentTimeEditorProps) => {
  const {user} = useContext(ApplicationUserContext);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, editorValues.locationId);
  const isEditing = useRef(false);
  const inputRef = useRef<HTMLIonInputElement | null>(null);
  const [placeHolder, setPlaceHolder] = useState((locSettings.TimeFormat === TimeFormat.HoursMinutes)
    ? "Ex: 00:00"
    : "Ex: 0.0");


  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.absentId !== undefined) {
      const locId = authHelper.getLocationIdFromPositionid(auth, editorValues.position.position.id);
      const locSettings = authHelper.getLocSettingsForLocationId(auth, locId);

      employeesApi.getPayrollBanks(
        user,
        locId,
        editorValues.employee.id,
        editorValues.absentId
      ).then(res => {
        const timeEx = timeHelper.getDesktopDurationStringFromTimeSpan(res.value, locSettings);
        setPlaceHolder("Ex: " + timeEx);
      });
    }

    // eslint-disable-next-line
  }, [editorValues.absentId, editorValues.employee.id]);

  // ------------------------------------------------------------------------------------
  function onBlur() {
    if (inputRef.current === null ||
        inputRef.current.value === null ||
        inputRef.current.value === undefined) {
      return;
    }

    const newVal = inputRef.current.value.toString();
    update({
      type: "ABSENTTIME-UPDATED",
      absentTimeMinutes: orbitalTimeHelper.getMinutesFromTimeString(locSettings, newVal),
    });
  }

  return (
    <IonRow
      className={
        editorValues.eventType === EventType.Absent
          ? "visible edit-row"
          : "invisible"
      }
    >
      <IonCol size="4">
        <IonLabel className="label">Hours</IonLabel>
      </IonCol>
      <IonCol size="8">
        <IonInput
          ref={inputRef}
          className={((editorValues.absentTimeMinutes === null) && (editorValues.eventType === EventType.Absent))
            ? "input-textbox-error"
            : "input-textbox"}
          placeholder={placeHolder}
          disabled={editorValues.isReadonly || editorValues.isConfirmed}
          value={editorValues.absentTimeMinutes === null
            ? null
            : th.getTimeStringFromMinutes(locSettings, editorValues.absentTimeMinutes)}
          onIonFocus={() => {isEditing.current = true}}
          onIonBlur={onBlur}
        />
      </IonCol>
    </IonRow>
  );
};

export default AbsentTimeEditor;
