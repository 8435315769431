import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import {EventType, EventStatus, RecurrenceUpdateType} from "../enums";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";
import { EditorValues } from "../components/EditEvent/EditEventModalState";
import { fixIsoStringForMvc } from "./scheduleEventsApi";

export interface CoverListItem {
  value: number;
  text: string;
  disabled: boolean;
  style: string | null;
}

interface DeleteEventModel {
  LocationId: number;
  ScheduleEventId: number;
  EventTypeId: EventType;
  EventStatusId: EventStatus;
  RecUpdateType?: RecurrenceUpdateType;
}

interface CoverRequestModel {
  ScheduleEventId: number;
  LocationId: number;
  BusinessId: number;
  BroadcastCoverRequestMessage: boolean;
  CoverMessage: string;
}

// ----------------------------------------------------------------------------------------------
async function undoCoverRequest(
  user: ApplicationUser,
  scheduleEventId: number
) {
  // eslint-disable-next-line max-len
  log("api_requests_undoCoverRequest");
  const url = `api/v1/employee/schedule-events/cover-request/undo?scheduleEventId=${scheduleEventId}`;
  return await apiHelper.put(user, url);
}

// ----------------------------------------------------------------------------------------------
async function createCoverRequest(
  user: ApplicationUser,
  scheduleEventId: number,
  locationId: number,
  businessId: number,
  broadCastMessage: boolean,
  coverMessage: string,
  toEmployeeId: number | null
) {
  log("api_requests_createCoverRequest");
  const url = "api/v1/employee/schedule-events/cover-request/submit";
  const data = {
    ScheduleEventId: scheduleEventId,
    LocationId: locationId,
    BusinessId: businessId,
    BroadcastCoverRequestMessage: broadCastMessage,
    CoverMessage: coverMessage,
    DirectToEmployeeId: toEmployeeId,
  } as CoverRequestModel;

  return await apiHelper.put(user, url, data);
}

// ----------------------------------------------------------------------------------------------
async function makeShiftRequest(
  user: ApplicationUser,
  scheduleEventId: number,
  locationId: number,
  businessId: number,
  broadCastMessage: boolean,
  coverMessage: string
) {
  log("api_requests_makeShiftRequest");
  const url = "api/v2/requests/shift-request/submit";
  const data = {
    ScheduleEventId: scheduleEventId,
    LocationId: locationId,
    BusinessId: businessId,
    BroadcastCoverRequestMessage: broadCastMessage,
    CoverMessage: coverMessage,
  } as CoverRequestModel;

  return (await apiHelper.put(user, url, data)) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function removeShiftRequest(
  user: ApplicationUser,
  locationId: number,
  scheduleEventId: number,
  eventType: EventType,
  eventStatus: EventStatus,
  recUpdateType?: RecurrenceUpdateType
) {
  log("api_requests_removeShiftRequest");
  const url = "api/v1/employee/schedule-events/delete";
  const data = {
    LocationId: locationId,
    ScheduleEventId: scheduleEventId,
    EventTypeId: eventType,
    EventStatusId: eventStatus,
    RecUpdateType: recUpdateType,
  } as DeleteEventModel;

  return await apiHelper.post(user, url, data);
}

// ----------------------------------------------------------------------------------------------
async function approveShiftRequest(
  user: ApplicationUser,
  msgId: number
) {
  log("api_requests_approveShiftRequest");
  const url = "api/v2/requests/shift-request/approve";
  const data = { msgId };

  return await apiHelper.post(user, url, data) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function deleteEvent(
  user: ApplicationUser,
  locationId: number,
  scheduleEventId: number,
  eventType: EventType,
  eventStatus: EventStatus,
  recUpdateType?: RecurrenceUpdateType
) {
  log("api_requests_deleteEvent");
  const url = "api/v1/employee/schedule-events/delete";
  const data = {
    LocationId: locationId,
    ScheduleEventId: scheduleEventId,
    EventTypeId: eventType,
    EventStatusId: eventStatus,
    RecUpdateType: recUpdateType,
  } as DeleteEventModel;

  return await apiHelper.post(user, url, data);
}

// ----------------------------------------------------------------------------------------------
async function getCoverRequestListItems(
  user: ApplicationUser,
  scheduleEventId: number,
  isDirectCoverList: boolean
) {
  log("api_requests_getCoverRequestListItems");
  const url = "api/v2/requests/transfers/to-list";
  const data = {
    scheduleEventId,
    directCoverList: isDirectCoverList,
  };

  return (await apiHelper.post(user, url, data)) as CoverListItem[];
}

// ----------------------------------------------------------------------------------------------
async function transferCoverToEmployee(
  user: ApplicationUser,
  transferToEmployeeId: number,
  coverRequestEventId: number
) {
  log("api_requests_transferCoverToEmployee");
  const url = "api/v2/requests/transfers/transfer";
  const data = {
    transferToEmployeeId,
    coverRequestEventId
  };

  return await(apiHelper.post(user, url, data)) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function undoCoverApproved(user: ApplicationUser, scheduleEventId: number) {
  log("api_requests_undoCoverApproved");
  const url = "api/v2/requests/undo-cover";
  const data = { scheduleEventId };

  return await(apiHelper.post(user, url, data)) as ApiErrorResult;
}

interface CanMakeShiftRequestDto {
  canMakeShiftRequest: boolean;
}

// ----------------------------------------------------------------------------------------------
async function canMakeShiftRequest(user: ApplicationUser, ev: EditorValues) {
  log("api_requests_undoCoverApproved");
  const url = "api/v2/requests/can-make-shift-request";
  const data = {
    id: ev.scheduleEventId,
    start: fixIsoStringForMvc(ev.eventStart),
    end: fixIsoStringForMvc(ev.eventEnd),
    locationId: ev.locationId,
  };

  return await(apiHelper.post(user, url, data)) as CanMakeShiftRequestDto;
}

export const requestsApi = {
  approveShiftRequest,
  canMakeShiftRequest,
  createCoverRequest,
  deleteEvent,
  getCoverRequestListItems,
  makeShiftRequest,
  removeShiftRequest,
  transferCoverToEmployee,
  undoCoverApproved,
  undoCoverRequest,
};
