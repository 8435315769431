import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
  IonModal,
} from "@ionic/react";
import "../global-styles.scss";
import {arrowBackOutline, checkmarkOutline} from "ionicons/icons";
import {ApplicationUserContext} from "../context/ApplicationUserContext";
import { useShowLoading } from "../hooks/useShowLoading";
import { Employee } from "../orbital-interfaces/Employee";
import { employeesApi } from "../api/employeesApi";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import { authHelper } from "../util/authHelper";
import { parseISO } from "date-fns";
import { timeHelper } from "../util/timeHelper";
import UpdatePasswordModal from "./UpdatePasswordModal";
import EmployeePayrollBanks from "./EmployeePayrollBanks";
import {LocationSettings} from "../orbital-interfaces/LocationSettings";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";
import {ClockLocationRequirement, Role} from "../enums";
import useCancelablePromise from "../hooks/useCancelablePromise";

interface EmployeeDetailModalProps {
  onDismissModal: (submitted: boolean) => void;
  isOpen: boolean;
  employeeId: number | null;
}

// -------------------------------------------------------------------------------------------
function showEmployeeOverrideClockLocationRequirement(
  locSettings: LocationSettings,
  auth: AuthorizedItems) {
  if (auth.role === Role.Employee) {
    return false;
  }

  return ((locSettings.ClockLocationRequirement &
      ClockLocationRequirement.EmployeeOverrideClockLocationRequirement) ===
      ClockLocationRequirement.EmployeeOverrideClockLocationRequirement);
}

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
const EmployeeDetailModal: React.FC<EmployeeDetailModalProps> = ({
  onDismissModal,
  isOpen,
  employeeId,
}: EmployeeDetailModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const loading = useShowLoading("Getting your information...");
  const [employee, setEmployee] = useState(null as Employee | null);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const makeCancelable = useCancelablePromise();
  const locSettings =  authHelper.isAuthItemsValid(auth)
    ? authHelper.getLocSettingsForLocationId(auth,user.defaultLocationId)
    : null;

  // -------------------------------------------------------------------------------------------
  useEffect(() => {
    if (user.isLoggedin && isOpen) {
      getEmployee();
    }

    // eslint-disable-next-line
  }, [employeeId, isOpen, user.isLoggedin]);

  // -------------------------------------------------------------------------------------------
  function getEmployee() {
    loading.setShowLoading(true);
    makeCancelable(employeesApi.getEmployee(user, employeeId))
      .then((employeeResult) => {
        loading.setShowLoading(false);
        setEmployee(employeeResult);
      });
  }

  // -------------------------------------------------------------------------------------------
  function onSubmitClick() {}

  if (employee === null || locSettings === null) return <div />;

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                onDismissModal(false);
              }}
            >
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            {employeeId === null ? "My Account" : employee.FullName}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content">
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">First Name</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.FirstName}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Last Name</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.LastName}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">User Name</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.UserName}
              />
            </IonCol>
          </IonRow>

          {locSettings.GenericEmployeeIdName && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">
                  {locSettings.GenericEmployeeIdName}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  type="text"
                  className="input-textbox"
                  disabled={true}
                  value={employee.GenericEmployeeId}
                />
              </IonCol>
            </IonRow>
          )}

          {locSettings.GenericEmployeeId2Name && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">
                  {locSettings.GenericEmployeeId2Name}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  type="text"
                  className="input-textbox"
                  disabled={true}
                  value={employee.GenericEmployeeId2}
                />
              </IonCol>
            </IonRow>
          )}

          {locSettings.GenericEmployeeId3Name && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">
                  {locSettings.GenericEmployeeId3Name}
                </IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  type="text"
                  className="input-textbox"
                  disabled={true}
                  value={employee.GenericEmployeeId3}
                />
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Station Code</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.StationCode}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Employment Start</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={timeHelper.getDateString(
                  parseISO(employee.EmploymentStartDate)
                )}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Requested Hours</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.RequestedHours}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Role</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={authHelper.getRoleStringFromRole(employee.RoleName)}
              />
            </IonCol>
          </IonRow>

          {showEmployeeOverrideClockLocationRequirement(locSettings, auth) && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Time Clock Access</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  type="text"
                  className="input-textbox"
                  disabled={true}
                  value={employee.OverrideClockLocationRequirement
                    ? "No Restrictions"
                    : "Clock Locations"
                  }
                />
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Email</IonLabel>
            </IonCol>
            <IonCol size="7">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={employee.Email}
              />
            </IonCol>
            <IonCol size="1">
              {employee.EmailAddressVerified && (
                <IonIcon icon={checkmarkOutline} style={{ color: "green" }} />
              )}
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Cell</IonLabel>
            </IonCol>
            <IonCol size="7">
              <IonInput
                type="text"
                className="input-textbox"
                disabled={true}
                value={
                  (employee.CellPhoneNumber !== null && employee.EmailToTextExtension !== null)
                    ? employee.CellPhoneNumber + "@" + employee.EmailToTextExtension
                    : ""
                }
              />
            </IonCol>
            <IonCol size="1">
              {employee.SMSVerified && (
                <IonIcon icon={checkmarkOutline} style={{ color: "green" }} />
              )}
            </IonCol>
          </IonRow>

          <EmployeePayrollBanks employeeId={employee.EmployeeId} />

          <IonRow>
            <IonCol size="12"></IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <IonButton
                className="button-selectEmployees-messages"
                onClick={() => setShowUpdatePasswordModal(true)}
              >
                Update Password
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        {loading.showLoadingJsx}
      </IonContent>

      <IonModal
        mode="md"
        isOpen={showUpdatePasswordModal}
        cssClass="mini-modal add-break-modal"
        onDidDismiss={() => setShowUpdatePasswordModal(false)}
      >
        <UpdatePasswordModal
          onClose={() => setShowUpdatePasswordModal(false)}
          isOpen={showUpdatePasswordModal}
          employeeId={employeeId}
        />
      </IonModal>

      <IonFooter>
        <IonButton
          className="apply-button"
          expand="block"
          onClick={onSubmitClick}
          disabled={true}
        >
          Submit
        </IonButton>
      </IonFooter>
    </>
  );
};

export default EmployeeDetailModal;
