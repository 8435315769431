import React, {useState, useContext, useEffect} from "react";
import {AuthorizedItemsContext} from "./AuthorizedItemsContext";
import { timeHelper as th } from "../util/timeHelper";
import {addDays} from "date-fns";
import {authHelper} from "../util/authHelper";
import {ApplicationUser, ApplicationUserContext} from "./ApplicationUserContext";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";

export interface IDateRangeState {
    rangeStart: Date | null;
    rangeEnd: Date | null;
}

export interface IDateRangeContext extends IDateRangeState {
    setRangeStartDate: (newStart: Date) => Date;
}

const INITIAL_DATERANGE_CONTEXT: IDateRangeContext = {
    rangeStart: null,
    rangeEnd: null,
    setRangeStartDate: (newStart: Date) => { return newStart; }
};

export const DateRangeContext = React.createContext<IDateRangeContext>(
    INITIAL_DATERANGE_CONTEXT
);

// --------------------------------------------------------------------------------------
function getInitialStartDate(auth: AuthorizedItems, user: ApplicationUser) {
    if (!authHelper.isAuthItemsValid(auth)) {
        return null;
    }

    const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
    return th.getWeekStart(new Date(), locSettings.DayWeekStartsOn, locSettings.DayStartTime);
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export const DateRangeContextProvider = (props: any) => {
    const { user } = useContext(ApplicationUserContext);
    const { auth } = useContext(AuthorizedItemsContext);
    const initialStartDate = getInitialStartDate(auth, user);
    const [dateRangeState, internalSetDateRangeState] = useState<IDateRangeState>({
        rangeStart: initialStartDate,
        rangeEnd: initialStartDate === null ? null : addDays(initialStartDate!, 7)
    });

    useEffect(() => {
        if (!authHelper.isAuthItemsValid(auth)) {
            return;
        }

        const initialStartDate = getInitialStartDate(auth, user);
        internalSetDateRangeState({
            ...dateRangeState,
            rangeStart: initialStartDate,
            rangeEnd: addDays(initialStartDate!, 7)
        });

      // eslint-disable-next-line
    }, [auth.locations])

    // ------------------------------------------------------------------------------------
    function setRangeStartDate(newStart: Date): Date {
        // if it's the same, don't do any updates, just return current start
        if (dateRangeState.rangeStart === newStart) {
            return dateRangeState.rangeStart;
        }

        const weekStart = th.getWeekStart(
            newStart,
            auth.locations[0].settings.DayWeekStartsOn,
            auth.locations[0].settings.DayStartTime);


        internalSetDateRangeState({
            ...dateRangeState,
            rangeStart: weekStart,
            rangeEnd: addDays(weekStart, 7)
        });

        return weekStart;
    }

    return (
        <DateRangeContext.Provider
            value={{
                rangeStart: dateRangeState.rangeStart,
                rangeEnd: dateRangeState.rangeEnd,
                setRangeStartDate
            }}
        >
            {props.children}
        </DateRangeContext.Provider>
    );
};
