import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import { timeHelper as th } from "../util/timeHelper";
import { DailyNotes } from "../orbital-interfaces/DailyNotes";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";

// ----------------------------------------------------------------------------------------------
async function updateDailyNote(
  user: ApplicationUser,
  dailyNoteId: number,
  text: string,
  role: number,
  date: Date
) {
  log("api_dailyNotes_updateDailyNote");
  return (await apiHelper.post(user, "api/v2/daily-notes/update", {
    dailyNoteId,
    date: th.formatDateTimeAsMvcString(date),
    text,
    role,
  })) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function insertDailyNote(
  user: ApplicationUser,
  locId: number,
  depId: number,
  role: number,
  date: Date,
  text: string
) {
  log("api_dailyNotes_insertDailyNote");
  return (await apiHelper.post(user, "api/v2/daily-notes/insert", {
    locId,
    depId,
    role,
    date,
    text,
  })) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function deleteDailyNote(user: ApplicationUser, dailyNoteId: number) {
  log("api_dailyNotes_deleteDailyNote");
  return (await apiHelper.post(
    user,
    "api/v2/daily-notes/delete/" + dailyNoteId
  )) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function getDailyNote(
  user: ApplicationUser,
  locId: number,
  depId: number,
  role: number,
  date: Date
): Promise<ApiErrorResult | DailyNotes> {
  log("api_dailyNotes_getDailyNote");
  return (await apiHelper.post(user, "api/v2/daily-notes/get", {
    locId,
    depId,
    role,
    date,
  })) as ApiErrorResult | DailyNotes;
}

export const dailyNotesApi = {
  getDailyNote,
  updateDailyNote,
  insertDailyNote,
  deleteDailyNote,
};
