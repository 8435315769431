import React, { useEffect, useState } from "react";
import { IonLabel, IonCol, IonRow, IonInput } from "@ionic/react";
import "./EventStartEndEditor";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {LocationSettings} from "../../../orbital-interfaces/LocationSettings";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";
import {authHelper} from "../../../util/authHelper";
import {EventType} from "../../../enums";

interface EventClockoutValueEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventClockoutValueEditor: React.FC<EventClockoutValueEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventClockoutValueEditorProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [locSettings, setLocSettings] = useState(
    null as LocationSettings | null
  );

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      editorValues.locationId === 0 ||
      (locSettings && editorValues.locationId === locSettings.LocationId)
    ) {
      return;
    }

    if (!auth || !auth.locations || auth.locations.length === 0) {
      return;
    }

    if (!editorValues.locationId) {
      return;
    }

    const newLocSettings = authHelper.getLocSettingsForLocationId(
      auth,
      editorValues.locationId
    );
    setLocSettings(newLocSettings);
    // eslint-disable-next-line
  }, [editorValues.locationId, auth]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (!locSettings) {
      return;
    }

    setIsVisible(
      locSettings!.ClockOutValueLabel !== undefined &&
        locSettings!.ClockOutValueLabel !== null &&
        locSettings!.ClockOutValueLabel.length > 0 &&
        editorValues.eventType === EventType.Clock &&
        !editorValues.isClockedIn
    );
  }, [editorValues.eventType, editorValues.isClockedIn, locSettings]);

  function onClockoutValueUpdated(newValue?: string | null) {
    if (newValue === null) {
      newValue = undefined;
    }

    if (newValue === editorValues.clockoutValue) {
      return;
    }

    update({
      type: "CLOCKOUTVALUE-UPDATED",
      locSettings: locSettings!,
      clockoutValue: newValue,
    });
  }

  if (!isVisible || locSettings === null) {
    return <div />;
  }

  return (
    <IonRow>
      <IonCol size="4">
        <IonLabel className="label">{locSettings.ClockOutValueLabel}</IonLabel>
      </IonCol>
      <IonCol size="8">
        <IonInput
          className="input-textbox"
          placeholder="Enter value"
          disabled={editorValues.isReadonly || editorValues.isConfirmed}
          value={editorValues.clockoutValue}
          onIonChange={(e) => onClockoutValueUpdated(e.detail.value)}
        />
      </IonCol>
    </IonRow>
  );
};

export default EventClockoutValueEditor;
