import React, { useEffect, useState } from "react";
import { IonCheckbox } from "@ionic/react";
import "../../../global-styles.scss";
import {LocationSettings} from "../../../orbital-interfaces/LocationSettings";
import { timeHelper as th } from "../../../util/timeHelper";
import { addDays, format, getDay } from "date-fns";
import { EditorReducerAction, EditorValues } from "../EditEventModalState";

interface WeekdayListProps {
  locSettings: LocationSettings | null;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
const WeekdayList: React.FC<WeekdayListProps> = ({
  locSettings,
  editorValues,
  update,
}: WeekdayListProps) => {
  const [checkList, setCheckList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (!editorValues.repeatWeekdays) {
      setCheckList([false, false, false, false, false, false, false]);
    } else {
      const newState = [false, false, false, false, false, false, false];
      for (let i = 0; i < editorValues.repeatWeekdays.length; i++) {
        newState[parseInt(editorValues.repeatWeekdays[i])] = true;
      }

      setCheckList(newState);
    }
  }, [editorValues.repeatWeekdays]);

  if (locSettings === null) {
    return <div />;
  }

  const weekStart = th.getWeekStart(
    new Date(),
    locSettings.DayWeekStartsOn,
    locSettings.DayStartTime
  );

  // --------------------------------------------------------------------------------------
  function onCheckboxChecked(dayOfWeek: number) {
    const newCheckState = checkList.slice();
    newCheckState[dayOfWeek] = !checkList[dayOfWeek];
    setCheckList(newCheckState);

    let repeatWeekdays = "";
    for (let i = 0; i < newCheckState.length; i++) {
      if (newCheckState[i]) {
        repeatWeekdays += i;
      }
    }

    update({
      type: "REPEATING-UPDATED",
      repeatWeekDays: repeatWeekdays,
      repeatInterval: editorValues.repeatInterval,
      recurrenceType: editorValues.recurrenceType,
      recurrenceEnd: editorValues.recurrenceEnd,
      recurrenceStart: editorValues.recurrenceStart,
    });
  }

  return (
    <div className="weeklist-container">
      {[0, 1, 2, 3, 4, 5, 6].map((num: number) => {
        const thisDate = addDays(weekStart, num);
        const dayOfWeek = getDay(thisDate);

        return (
          <span key={num} className="day">
            <IonCheckbox
              mode="ios"
              className="day-checkbox"
              disabled={editorValues.isReadonly}
              onClick={() => onCheckboxChecked(dayOfWeek)}
              checked={checkList[dayOfWeek]}
            />
            <span className="day-abbr">
              {format(thisDate, "EE").substr(0, 2)}
            </span>
          </span>
        );
      })}
    </div>
  );
};

export default WeekdayList;
