import React, {ErrorInfo} from "react";
import {log} from "../util/logger";
import {ApplicationUser, ApplicationUserContext} from "../context/ApplicationUserContext";
import ErrorShownComponent from "./ErrorShownComponent";

// ----------------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------------
export class ErrorBoundary extends React.Component<{}, {}> {
  static contextType = ApplicationUserContext;
  state = {
    hasError: false,
    errMessage: "",
  }

  // ----------------------------------------------------------------------------------------------
  constructor(props :any) {
    super(props);
    this.state = {
      hasError: false,
      errMessage: "",
    };
  }

  // ----------------------------------------------------------------------------------------------
  componentDidCatch(error: Error, info: ErrorInfo) {
    const user = this.context as ApplicationUser;
    console.log(error);
    console.log(info);
    log("unhandled_error", {
      message: error.message,
      stack: info.componentStack,
      user: user.username
    });

    this.setState({ hasError: true, errMessage: error.message });
  }

  // ----------------------------------------------------------------------------------------------
  static getDerivedStateFromError(error: any) {
    console.log(error);
    return { hasError: true, errMessage: error.message };
  }

  // ----------------------------------------------------------------------------------------------
  render() {
    if (this.state.hasError) {
      return <ErrorShownComponent errMessage={this.state.errMessage} />;
    }

    return this.props.children;
  }
}
