import { createGesture, GestureDetail } from '@ionic/core';

const SWIPE_LENGTH = 35;

// ---------------------------------------------------------------------
export function enableGesture(
  elementRef: HTMLDivElement,
  onGoBack: () => void,
  onGoForward: () => void
) {
  const onMove = (detail: GestureDetail) => {
    if (detail.deltaX > SWIPE_LENGTH) {
      onGoBack();
      return;
    }

    if (detail.deltaX < (-1 * SWIPE_LENGTH)) {
      onGoForward();
      return;
    }
  };

  const gesture = createGesture({
    gestureName: "swipe",
    el: elementRef,
    onMove: (detail) => {onMove(detail)}
  });

  gesture.enable();
}

