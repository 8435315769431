// https://dev.to/rodw1995/cancel-your-promises-when-a-component-unmounts-gkl
import { useCallback, useEffect, useRef } from 'react';

const useMountedState = (): () => boolean => {
  const mountedRef = useRef<boolean>(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  return useCallback(() => mountedRef.current, []);
};

export default useMountedState;

//
// EXAMPLE USAGE:
//

/*
  import useDidMount from '@rooks/use-did-mount';
  import { useState } from 'react';
  import useMountedState from './useMountedState';

  export default () => {
    const isMounted = useMountedState();
    const [user, setUser] = useState();

    // Fetch the authenticated user on mount
    useDidMount(() => {
      fetchAuthenticatedUser().then((user) => {
        // Before calling "setUser", check if the component is still mounted
        if (isMounted()) {
          setUser(user);
        }
      });
    });

    // Rest of the component...
  };
*/
