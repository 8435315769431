import React, { useState, useContext, useEffect } from "react";
import {RouteComponentProps} from "react-router";
import {
  IonButton,
  IonCol,
  IonContent, IonFooter, IonGrid,
  IonHeader,
  IonPage, IonRow,
  IonModal,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { punchClockApi } from "../api/punchClockApi";
import {isApiError} from "../orbital-interfaces/ApiErrorResult";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import useDialogBox from "../hooks/useDialogBox";
import useSimpleToast from "../hooks/useSimpleToast";
import { ClockInResponseCode, PunchClockDeviceEnableResponse } from "../enums";
import PunchClockHeader from './PunchClockHeader';
import DeviceIdDisplay from "./DeviceIdDisplay";
import PunchClockSettingsModal from "./PunchClockSettingsModal";
import TimeClockModal from './../components/TimeClock/TimeClockModal';
import { INITIAL_AUTHORIZED_ITEMS } from "../context/AuthorizedItemsContext";
import { TimeClockSettingsData } from './../orbital-interfaces/TimeClockSettingsData';
import { useShowLoading } from './../hooks/useShowLoading';
import { initialPunchClockDeviceData } from './../orbital-interfaces/PunchClockDeviceData';
import useErrorToast from './../hooks/useErrorToast';


interface PunchClockEntryPageProps extends RouteComponentProps {}

// ------------------------------------------------------------------------------------
function codeToAsterisks(code: string) {
  if (!code) {
    return "Enter Code"
  }

  let asterisks = "";
  for (let i = 0 ; i < code.length; i++) {
    asterisks += "*";
  }

  return asterisks;
}

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const PunchClockEntryPage: React.FC<PunchClockEntryPageProps> = () => {
  const { user } = useContext(ApplicationUserContext);
  const { alertBoxJsx, showAlert } = useDialogBox();
  const { showToast, simpleToastJsx } = useSimpleToast(1500);
  const errorToast = useErrorToast(2000);
  const [ curCode, setCurCode ] = useState("");
  const [ showSettingsModal, setShowSettingsModal ] = useState(false);
  const [ showTimeClockModal, setShowTimeClockModal ] = useState(false);
  const [ punchClockDeviceData, setPunchClocDeviceData ] = useState(initialPunchClockDeviceData);
  const [ clockInAuth, setClockInAuth ] = useState(INITIAL_AUTHORIZED_ITEMS);
  const [ timeclockSettings, setTimeClockSettings] = useState(null as null | TimeClockSettingsData);
  const loading = useShowLoading("Getting punch clock info");

  // -------------------------------------------------------------------------------
  useEffect(() => {
    let mounted = true;

    if (punchClockDeviceData.employeeId === -2 || punchClockDeviceData.response !== PunchClockDeviceEnableResponse.DeviceRegistered) {
      loading.setShowLoading(true);
    }

    if (punchClockDeviceData.employeeId === -2) {
      punchClockApi.getPunchClockDeviceData(user).then(result => {
        if (isApiError(result)) {
          console.error("ERROR:" + result.error);
          loading.setShowLoading(false);
        } else {
          if (mounted) {
            if (result.response !== PunchClockDeviceEnableResponse.DeviceRegistered) {
              loading.setShowLoading(false);
              setShowSettingsModal(true);
            } else {
              loading.setShowLoading(false);
              setPunchClocDeviceData(result);  
            }            
          }
        }        
      });    
    }

    return () => {
      mounted = false;
    }

    // eslint-disable-next-line
  }, [user]);

  // -------------------------------------------------------------------------------
  const onNumPress = (num: string) => {
    setCurCode(curCode + num);
  }

  // -------------------------------------------------------------------------------
  const genButtonCol = (num: number) => {
    return (
      <IonCol size="4"  className="punchclock-numpad-col">
        <IonButton 
          className="punchclock-numpad-button"
          onClick={() => { onNumPress(num.toString())}}
        >
          {num}
        </IonButton>
      </IonCol>
    );
  }

  // -------------------------------------------------------------------------------
  const onSubmit = () => {
    punchClockApi.enterStationCode(user, curCode).then(resp => {
      if (isApiError(resp)) {
        showAlert("Error", resp.message, () => {
          setCurCode("");
        });        
      } else {
        if (resp.timeclockData.ClockInResponseCode === ClockInResponseCode.CouldNotFindClockStationInfo) {
          setCurCode("");
          errorToast.showToast("Station Code Not Recognized");
        } else {
          //showAlert("YES", "User may clock in: " + resp.UserMayClockIn + ", clock out: " + resp.UserMayClockOut, () => {});
          setClockInAuth(resp.auth);
          setTimeClockSettings(resp.timeclockData);
          setShowTimeClockModal(true);
        }
      }
    });
  }

  return (
    <IonPage 
      id="punchclock-entry-page"
    >
      <IonHeader className="ion-no-border">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <img
              className="logo"
              src="assets/icons/icon-72x72.png"
              alt="Orbital Shift Logo"
              width="48"
              style={{filter: "contrast(0%)"}}
            />            
          </IonButtons>
          <IonTitle
            style={{fontSize: "2em", color: "white", textAlign: "center", verticalAlign: "center", width: "100%" }}
          >
            <PunchClockHeader />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowSettingsModal(true)}>
              <IonIcon
                icon={ellipsisHorizontal}
                mode="ios"
                slot="icon-only"
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent 
        className="outer-content"
      >
        <IonGrid className="punchclock-numpad-container">
          <IonRow>
            <IonCol size="12">
              <div
                style={{fontSize: "2em", color: "gray", textAlign: "center", verticalAlign: "center", width: "100%" }}
              >
                {codeToAsterisks(curCode)}
              </div>
            </IonCol>
          </IonRow>
          <IonRow  className="punchclock-numpad-row">
            {genButtonCol(1)}
            {genButtonCol(2)}
            {genButtonCol(3)}
          </IonRow>
          <IonRow  className="punchclock-numpad-row">
            {genButtonCol(4)}
            {genButtonCol(5)}
            {genButtonCol(6)}
          </IonRow>
          <IonRow className="punchclock-numpad-row">
            {genButtonCol(7)}
            {genButtonCol(8)}
            {genButtonCol(9)}
          </IonRow>
          <IonRow class="punchclock-numpad-row">
            <IonCol size="4" className="punchclock-numpad-row">

            </IonCol>
            {genButtonCol(0)}
            <IonCol size="4" className="punchclock-numpad-col">
              <IonButton 
                className="punchclock-numpad-button"
                disabled={curCode.length < 1}
                onClick={() => {
                  setCurCode(curCode.slice(0, -1))                  
                }}
              >
                <img
                  id="punchclock-backbutton"
                  className="logo"
                  src="assets/icons/backspace.svg"
                  alt="Orbital Shift Logo"
                />            
              </IonButton>
              </IonCol>
          </IonRow>
          <IonRow>

          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonButton 
                color="secondary" 
                className="punchclock-numpad-submit-button"
                disabled={curCode.length < 1}
                onClick={onSubmit}
              >
                SUBMIT
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <div style={{color: "gray", fontSize: "small"}}>
          <DeviceIdDisplay punchClockDeviceData={punchClockDeviceData} />
        </div>
      </IonFooter>
      {alertBoxJsx}
      {simpleToastJsx}
      {errorToast.errorToastJsx}
      {loading.showLoadingJsx}

      <IonModal
        mode="md"
        isOpen={showSettingsModal}
        onDidDismiss={() => setShowSettingsModal(false)}
        backdropDismiss={false}
      >
        <PunchClockSettingsModal
          isOpen={showSettingsModal}
          onCancelModal={() => setShowSettingsModal(false)}
          onDismissModal={(msg, newPunchClockDeviceSettings) => {
            showToast(msg);
            setPunchClocDeviceData(newPunchClockDeviceSettings);
            setShowSettingsModal(false);
          }}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showTimeClockModal}
        onDidDismiss={() => { 
          setShowTimeClockModal(false);
          setCurCode("");
        }}
      >
        <TimeClockModal
          isOpen={showTimeClockModal}
          auth={clockInAuth}
          stationCode={curCode}
          timeclockSettings={timeclockSettings}
          onDismissModal={(msg?: string) => {
            if (msg) {
              showToast(msg);
            }
            setShowTimeClockModal(false);
            setCurCode("");  
          }}
        />
      </IonModal>

    </IonPage>
  );
};

export default PunchClockEntryPage;
