/* eslint-disable */
import React, {useContext, useEffect} from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Menu from "./components/Menu";
import { initialize, log } from "./util/logger";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Login from "./pages/Login";
import AuthGate from "./components/AuthGate";

import {
  ApplicationUser,
  ApplicationUserContext,
  ApplicationUserContextProvider
} from "./context/ApplicationUserContext";
import {AuthorizedItemsContext, AuthorizedItemsContextProvider} from "./context/AuthorizedItemsContext";
import env from "./appFunctions/environment";
import { MessagesContextProvider } from "./context/MessagesContext";
import {authHelper} from "./util/authHelper";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import {EventsContextProvider} from "./context/EventsContext";
import AuthPage from "./pages/AuthPage";
import {DateRangeContextProvider} from "./context/DateRangeContext";
import {AuthorizedItems} from "./orbital-interfaces/AuthorizedItems";
import {getSubscriptionTypeName} from "./enums";
import {ErrorBoundary} from "./components/ErrorBoundary";

// -------------------------------------------------------------------------------------------
function logInitialRender(user: ApplicationUser, auth: AuthorizedItems) {
  const environment = env.settings.env.item(user.environment);
  log("app_open", {
    userName: user.username,
    isPushEnabled: user.isPushEnabled,
    environment: environment.name,
    version: env.settings.appVersionString,
    platform: env.settings.platform,
    userRole: auth.role,
    subscriptionType: getSubscriptionTypeName(auth.subscriptionType),
    businessName: auth.businessName
  });

  console.log(`Application Opening for ${user.username} (${environment.name}) `);
}

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
const MyApp: React.FC = () => {
  const {user} = useContext(ApplicationUserContext);
  const {auth, refreshAuth, authLoading} = useContext(AuthorizedItemsContext);
  const onLoggedInUrl = env.settings.appType === "punchclock"
    ? "/tabs/punchclock"
    : "/tabs/events";


  // -------------------------------------------------------------------------------------------
  useEffect(() => {
    console.log("Application Starting ... ");

    const init = async () => {
      initialize(env.settings.appTypeSettings.firebaseConfig).then((v) => {
        console.log("App started, Firebase initialized", v);
      });
    }

    init();
    log("app_start");

  }, []);

  // -------------------------------------------------------------------------------------------
  useEffect(() => {
    if (user.isLoggedin && !user.loading) {
      if (!authHelper.isAuthItemsValid(auth) && !authLoading) {
        refreshAuth(false, user, () => {});
        logInitialRender(user, auth);
      }
    }
  }, [user.isLoggedin, auth.locations?.length, refreshAuth]);


  return (
    <IonApp>
      <IonReactRouter>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            {env.settings.appType !== "punchclock" && <Menu /> }
            <IonRouterOutlet id="main">
              <Route
                path="/login"
                render={(routeProps) => <Login {...routeProps} onLoggedInUrl={onLoggedInUrl} />}
              />

              <Route
                path="/auth"
                exact={false}
                render={(routeProps) => <AuthPage {...routeProps} />}
              />

              <Route
                path="/reset-password"
                render={(routeProps) => <ResetPasswordPage {...routeProps} />}
              />

              <Route
                path="/tabs"
                render={(routeProps) => <AuthGate {...routeProps} />}
              />

              <Route path="/" exact={true}>
                <Redirect to={onLoggedInUrl} />
              </Route>


            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ApplicationUserContextProvider>
        <ErrorBoundary>
            <AuthorizedItemsContextProvider>
              <EventsContextProvider>
                <MessagesContextProvider>
                  <DateRangeContextProvider>
                    <MyApp />
                  </DateRangeContextProvider>
                </MessagesContextProvider>
              </EventsContextProvider>
            </AuthorizedItemsContextProvider>
        </ErrorBoundary>
      </ApplicationUserContextProvider>
    </BrowserRouter>
  );
};

export default App;
