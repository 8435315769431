import { ApplicationUser } from "../context/ApplicationUserContext";
import {EmployeeSort} from "../enums";
import { timeHelper as th } from "../util/timeHelper";
import apiHelper from "./orbitalApiHelper";
import { IdName } from "../orbital-interfaces/IdName";
import env from "../appFunctions/environment";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {EmployeeCommunication} from "../orbital-interfaces/EmployeeCommunication";
import {Employee} from "../orbital-interfaces/Employee";
import {log} from "../util/logger";

export interface UpdatePasswordResults {
  error: boolean;
  messages: string[];
}

export interface GetPayrollBankResults {
  name: string;
  value: string; // TimeSpan
  minutes?: number;
}

export interface PayrollBanksForEmployee {
  payrollBankId: number;
  payrollBankName: string;
  bankedMinutes?: number;
  bankedTime: string; // TimeSpan
}

// -----------------------------------------------------------------------------
async function getEmployeesByLocationIdPositionIdAndActiveDateRange(
  user: ApplicationUser,
  locId: number,
  posId: number,
  start: Date,
  end: Date,
  sort: EmployeeSort
) {
  log("api_employees_getEmployeesByLocIdPosId");
  const data = {
    locId,
    posId,
    start: th.formatDateTimeAsMvcString(start),
    end: th.formatDateTimeAsMvcString(end),
    sort,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employees/at-position",
    data
  )) as IdName[];
}

// -----------------------------------------------------------------------------
async function getEmployeesByPositionIdsAndActiveDateRange(
  user: ApplicationUser,
  positionIds: number[],
  start: Date,
  end: Date,
  sort: EmployeeSort
) {
  log("api_employees_getEmployeesByPositionId");
  const data = {
    positionIds,
    start: th.formatDateTimeAsMvcString(start),
    end: th.formatDateTimeAsMvcString(end),
    sort,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employees/at-positions",
    data
  )) as IdName[];
}


// -----------------------------------------------------------------------------
async function getEmployeeListForCommunication(
  user: ApplicationUser,
  locId: number,
  depId: number,
  posId: number,
  channel: number,
  role: string
) {
  log("api_employees_getEmployeeListForComm");
  return (await apiHelper.post(
    user,
    "api/v2/employees/get-communication-list",
    {
      locId,
      depId,
      posId,
      channel,
      role,
    }
  )) as EmployeeCommunication[];
}

// -----------------------------------------------------------------------------
async function getEmployee(user: ApplicationUser, employeeId: number | null) {
  log("api_employees_getEmployee");
  const data = {
    employeeId,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employees/get-employee",
    data
  )) as Employee;
}

// -----------------------------------------------------------------------------
async function getEmployeesWhoSentMessagesToMe(user: ApplicationUser) {
  log("api_employees_getEmployeesWhoSentMsg");
  return (await apiHelper.get(user,"api/v2/employees/get-messaging-employees",
  )) as IdName[];
}

// -----------------------------------------------------------------------------
async function submitFeedback(user: ApplicationUser, msg: string) {
  log("api_employees_submitFeedback");
  const data = {
    version: env.settings.appVersionString,
    buildDate: env.settings.buildDate,
    environment: env.settings.env.item(user.environment).name,
    os: env.settings.platform,
    msg
  }

  return (await apiHelper.post(
    user,
    "/api/v2/employees/submit-feedback",
    data)) as ApiErrorResult;
}

// -----------------------------------------------------------------------------
async function updatePassword(
  user: ApplicationUser,
  employeeId: number | null,
  oldPassword: string,
  newPassword: string) {
  log("api_employees_updatePassword");
  const data = {
    employeeId,
    oldPassword,
    newPassword,
  };

  return (await apiHelper.post(
    user,
    "/api/v2/employees/update-password",
    data
  )) as UpdatePasswordResults;
}

// -----------------------------------------------------------------------------
async function getPayrollBanks(
  user: ApplicationUser,
  locationId: number,
  employeeId: number,
  payrollEventId: number
) {
  log("api_employees_getPayrollBanks");
  const data = {
    employeeId,
    locationId,
    eventType: payrollEventId
  };

  return (await apiHelper.post(
    user,
    "/api/v2/employees/get-payroll-bank",
    data
  )) as GetPayrollBankResults;
}

// -----------------------------------------------------------------------------
async function getPayrollBanksForEmployee(
  user: ApplicationUser,
  employeeId: number,
) {
  log("api_employees_getPayrollBanksForEmployee");
  const data = {
    employeeId,
  };

  return (await apiHelper.post(
    user,
    "/api/v2/employees/get-employee-payroll-banks",
    data
  )) as PayrollBanksForEmployee[];
}

export const employeesApi = {
  getEmployee,
  getEmployeesByLocationIdPositionIdAndActiveDateRange,
  getEmployeesByPositionIdsAndActiveDateRange,
  getEmployeeListForCommunication,
  getEmployeesWhoSentMessagesToMe,
  getPayrollBanks,
  getPayrollBanksForEmployee,
  submitFeedback,
  updatePassword,
};
