import { IonToast } from "@ionic/react";
import React, { useState } from "react";


function useSimpleToast(seconds?: number | null | undefined) {
  const [isOpen, setIsOpen] = useState(false);
  const [toastData, setToastData] = useState({ message: "" });

  const showToast = (message: string) => {
    setToastData({
      message,
    });

    setIsOpen(true);
  };

  const simpleToastJsx = (
    <IonToast
      cssClass="simple-toast"
      mode="md"
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      position="top"
      message={toastData.message}
      duration={seconds ? seconds : 2000}
    />
  );

  return {
    showToast,
    simpleToastJsx,
  };
}

export default useSimpleToast;
