import React, {forwardRef, MutableRefObject, Ref, useImperativeHandle, useRef} from "react";
import "../../global-styles.scss";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";
import {addDays, format, isBefore, parseISO} from "date-fns";
import { KeyedCollection } from "../../util/KeyedCollection";
import EventListSection, {EventListSectionRef} from "./EventListSection";
import { timeHelper as th} from "../../util/timeHelper";
import { EventInformation, HoursList } from "../../orbital-interfaces/TEC";
import { DailyNotes } from "../../orbital-interfaces/DailyNotes";
import {authHelper} from "../../util/authHelper";
import {ApplicationUser} from "../../context/ApplicationUserContext";

interface EventListProps {
  events: EventInformation[];
  notes: DailyNotes[];
  hoursPerDay: HoursList[];
  listType: "my" | "requests" | "all" | "today";
  user: ApplicationUser;
  auth: AuthorizedItems;
  isTimeClockPage: boolean;
  onEdit: (ev: EventInformation) => void;
  onForceRefresh: (toastMessage: string) => void;
  weekStart: Date;
}

interface SegmentItemList {
  events: EventInformation[];
  notes: DailyNotes[];
  date: Date | null;
  segmentRef?: MutableRefObject<EventListSectionRef | null>;
}

export interface EventListRef {
  scrollToDate: (d: Date) => void;
}

// --------------------------------------------------------------------------------------
const getSegmentsForToday = (
  events: EventInformation[],
  notes: DailyNotes[],
  locationSettings: LocationSettings,
  hoursPerDay: HoursList[],
) => {
  const todayStart = th.getTodayStartForLocation(new Date(), locationSettings);

  let totalDuration = 0.0;
  for(let i = 0; i < hoursPerDay.length; i++) {
    totalDuration += th.getDurationFromTimeSpan(hoursPerDay[i].time);
  }

  const durationString = (totalDuration > 0.0)
    ? th.getDurationString(totalDuration)
    : "";

  const header =
    format(todayStart, "h:mmaaa").replace("M", "") + " " +
    format(todayStart, "eeee, MMM d") + " " +
    durationString;

  const segmentDate = new Date(todayStart.valueOf());
  segmentDate.setHours(0,0,0,0);

  const segments = new KeyedCollection<SegmentItemList>();
  segments.add(header, { events, notes, date: segmentDate });
  return segments;
};

// --------------------------------------------------------------------------------------
const getSegmentsForMy = (
  events: EventInformation[],
  notes: DailyNotes[],
  locationSettings: LocationSettings,
  hoursPerDay: HoursList[]
) => {
  const segments = new KeyedCollection<SegmentItemList>();
  if (events.length === 0 && notes.length === 0) {
    return segments;
  }

  let weekStart = new Date(2199, 1, 1);
  if (events.length > 0) {
    weekStart = th.getWeekStart(
      parseISO(events[0].EventStart),
      locationSettings.DayWeekStartsOn,
      locationSettings.DayStartTime
    );
  }

  if (notes.length > 0) {
    const notesStart = th.getWeekStart(
      parseISO(notes[0].EventStart),
      locationSettings.DayWeekStartsOn,
      locationSettings.DayStartTime
    );
    if (isBefore(notesStart, weekStart)) {
      weekStart = notesStart;
    }
  }

  const weekEnd = addDays(weekStart, 7);
  const header =
    `${format(weekStart, "MMM d")} - ${format(
      weekEnd,
      "MMM d"
    )}` +
    " " +
    th.getDurationString(
      hoursPerDay
        .map((h) => th.getDurationFromTimeSpan(h.time))
        .reduce((prev, cur) => prev + cur, 0)
    );

  segments.add(header, { events, notes, date: weekStart });
  return segments;
};

// --------------------------------------------------------------------------------------
const getSegmentsForAll = (events: EventInformation[], notes: DailyNotes[]) => {
  const segments = new KeyedCollection<SegmentItemList>();
  events.map((ev) => {
    const thisDate = format(
      parseISO(ev.EventStart).setHours(0, 0, 0, 0),
      "eeee, MMM d"
    );
    if (!segments.containsKey(thisDate)) {
      const thisDatesNotes = notes.filter(
        (n) =>
          format(
            parseISO(n.EventStart).setHours(0, 0, 0, 0),
            "eeee, MMM d"
          ) === thisDate
      );
      segments.add(thisDate, {
        notes: thisDatesNotes,
        events: [],
        date: parseISO(ev.EventStart),
      });
    }

    segments.item(thisDate).events.push(ev);
    return thisDate;
  });

  return segments;
};

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const EventList = forwardRef((props: EventListProps, ref: Ref<EventListRef>) => {
  const {
    events,
    notes,
    hoursPerDay,
    listType,
    user,
    auth,
    isTimeClockPage,
    onEdit,
    onForceRefresh,
    weekStart
  } = props;

  let segments: KeyedCollection<SegmentItemList> = new KeyedCollection<
    SegmentItemList
    >();

  switch (listType) {
    case "my":
      segments = getSegmentsForMy(
        events,
        notes,
        authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId),
        hoursPerDay
      );
      break;
    case "today":
      segments = getSegmentsForToday(
        events,
        notes,
        authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId),
        hoursPerDay);
      break;
    default:
      segments = getSegmentsForAll(events, notes);
      break;
  }

  // ----------------------------------------------------------------------------
  useImperativeHandle(ref, () => ({

    scrollToDate(date: Date) {
      const dateName = format(date, "eeee, MMM d");
      const segkeys = segments.keys();
      for (let i = 0; i < segkeys.length; i++) {
        if (segkeys[i] === dateName) {
          const curSeg = segments.item(segkeys[i]);
          if (curSeg.segmentRef !== undefined &&
            curSeg.segmentRef.current !== null) {
            curSeg.segmentRef.current.scrollIntoView();

            break;
          }
        }
      }
    }

  }));

  // We create a bunch of references, more than we need, so that they can
  // be used if need be with the same number of useRef calls... which is
  // a React requirement
  const references = [-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10].map(dayOffset => {
    const segName = format(
      addDays(weekStart, dayOffset).setHours(0, 0, 0, 0),
      "eeee, MMM d"
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const segRef = useRef(null as EventListSectionRef | null);

    return { segName, segRef};
  });


  return (
    <>
      {segments.keys().map((seg) => {
        const refLookup = references.find(r => seg === r.segName)
        const thisRef = refLookup !== undefined
          ? refLookup.segRef
          : references[0].segRef;
        segments.item(seg).segmentRef = thisRef;
        return (
          <EventListSection
            ref={thisRef}
            onEdit={onEdit}
            key={seg}
            header={seg}
            locations={auth.locations}
            events={segments.item(seg).events}
            notes={segments.item(seg).notes}
            showEmployeeNames={true}
            showTimeColumn={listType !== "requests"}
            dailyNotesAddButtonDate={segments.item(seg).date}
            isTimeClockPage={isTimeClockPage}
            onForceRefresh={onForceRefresh}
          />
        );
      })}
    </>
  );



});

export default EventList;
