import {
  EventFilter,
  initialEventFilter,
  initialStoredEventFilter,
  StoredEventFilter
} from "../orbital-interfaces/EventFilter";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";
import {authHelper} from "../util/authHelper";
import {arrayHelper} from "../util/arrayHelper";
import {ApplicationUser} from "../context/ApplicationUserContext";
import {FilteredRangeType} from "../enums/FilteredRangeType";

const FILTER_STORAGE_KEY = "scheduleEventFilter";

// ------------------------------------------------------------------------------------
function getFilter(auth: AuthorizedItems, user: ApplicationUser): StoredEventFilter {
  let filter = {...initialStoredEventFilter};

  const str = localStorage.getItem(FILTER_STORAGE_KEY);
  if (str !== null) {
    filter = JSON.parse(str) as StoredEventFilter;
    if (filter.employee === undefined) {
      filter.employee = {id: -1, name: "All Employees"};
    }
  }

  if (authHelper.isAuthItemsValid(auth)) {
    if (filter.positionIds === undefined || filter.positionIds.length === 0) {
      filter.positionIds = auth.locations.flatMap((l) =>
        l.departments.flatMap((d) =>
          d.positions.map((p) => p.id)));
    }

    if (filter.dnLocIds === undefined || filter.dnLocIds.length === 0) {
      filter.dnLocIds = auth.locations.map(l => l.id);
    }

    if (filter.settingsLocId === undefined || filter.settingsLocId === 0) {
      filter.settingsLocId = (user.defaultLocationId === 0 || user.defaultLocationId === undefined)
        ? auth.locations[0].id
        : user.defaultLocationId;
    }
  }

  if (filter.filteredRangeType === null) {
    filter.filteredRangeType = FilteredRangeType.Day;
  }

  return filter;
}

// ------------------------------------------------------------------------------------
function getDaysInRange(auth: AuthorizedItems, user: ApplicationUser): number {
  const filter = getFilter(auth, user);
  switch (filter.filteredRangeType) {
    case FilteredRangeType.Day:
      return 1;
    case FilteredRangeType.Week:
      return 7;
    default:
      return 7;
  }
}

// ------------------------------------------------------------------------------------
function putFilter(filter: EventFilter) {
  const storedFilter: StoredEventFilter= {
    settingsLocId: filter.settingsLocId,
    dnLocIds: filter.dnLocIds,
    filteredRangeType: filter.filteredRangeType,
    positionIds: filter.positionIds,
    tagId: filter.tagId,
    eventStatusId: filter.eventStatusId,
    eventTypeId: filter.eventTypeId,
    employee: filter.employee,
  };

  localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(storedFilter));
}

// ------------------------------------------------------------------------------------
function clear() {
  localStorage.removeItem(FILTER_STORAGE_KEY);
}

// ------------------------------------------------------------------------------------
function getDefaultFilter(auth: AuthorizedItems, filter: EventFilter): EventFilter {
  return {
    ...initialEventFilter,
    start: filter.start,
    end: filter.end,
    tagId: -1,
    positionIds: auth.locations.flatMap((l) =>
      l.departments.flatMap((d) => d.positions.map((p) => p.id))
    ),
    dnLocIds: auth.locations.map((l) => l.id),
    settingsLocId: auth.locations[0].id,
    employee: { id: -1, name: "All Employees"},
  };
}

// ------------------------------------------------------------------------------------
function tagIsEqual(tagId1: number | undefined, tagId2: number | undefined) {
  return (
    (tagId1 === undefined && tagId2 === -1) ||
    (tagId1 === -1 && tagId2 === undefined) ||
    (tagId1 === tagId2)
  );
}

// ------------------------------------------------------------------------------------
function isFilterApplied(auth: AuthorizedItems, filter: EventFilter): boolean {
  const defaultFilter = getDefaultFilter(auth, filter);

  const filterApplied = !(
    defaultFilter.eventTypeId === filter.eventTypeId &&
    defaultFilter.eventStatusId === filter.eventStatusId &&
    tagIsEqual(defaultFilter.tagId, filter.tagId) &&
    arrayHelper.areEqual(
      defaultFilter.positionIds,
      filter.positionIds
    ) &&
    filter.employee.id === -1
  );

  return filterApplied;
}

export const filterStore = {
  getDaysInRange,
  getFilter,
  putFilter,
  isFilterApplied,
  getDefaultFilter,
  clear,
  tagIsEqual
}
