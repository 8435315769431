/* eslint-disable eqeqeq */
import {
  AuthorizedDepartment,
  AuthorizedItems,
  AuthorizedLocation,
  AuthorizedPosition,
} from "../orbital-interfaces/AuthorizedItems";
import {AdminAuthorization, Role} from "../enums";

export interface PositionEx {
  location: AuthorizedLocation;
  department: AuthorizedDepartment | null;
  position: AuthorizedPosition | null;
}

export interface SelectedPosition {
  location: AuthorizedLocation;
  position: AuthorizedPosition;
}

export interface SelectedDepartment {
  location: AuthorizedLocation;
  department: AuthorizedDepartment | null;
}

// --------------------------------------------------------------------------------------
function canAddNote(auth: AuthorizedItems) {
  if (auth.role === Role.Employee) {
    return false;
  }

  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return true;
  }

  for (let i = 0; i < auth.locations.length; i++) {
    const curLoc = auth.locations[i];
    if (curLoc.adminAllDepts) {
      return true;
    }

    for (let j = 0; j < curLoc.departments.length; j++) {
      if (curLoc.departments[j].isDeptAdmin) {
        return true;
      }
    }
  }

  return false;
}

// --------------------------------------------------------------------------------------
function canCreateMessage(auth: AuthorizedItems) {
  return auth.locations.find((l) =>
      l.adminAllDepts || l.departments.find((d) => authHelper.isDeptAdmin(auth, d.id))
  ) !== undefined;
}

// --------------------------------------------------------------------------------------
function getRoleStringFromRole(role: Role) {
  switch(role) {
    case Role.Employee:
      return "Employee";
    case Role.Admin:
      return "Admin";
    case Role.LocationAdmin:
      return "Location Admin";
    case Role.BusinessAdmin:
      return "Business Admin";
    default:
      return "Unknown";
  }
}

// --------------------------------------------------------------------------------------
function getLocSettingsForLocationId(auth: AuthorizedItems, locationId: number) {
  if (!auth) {
    throw new Error("getLocSettingsForLocationId: called with null/undefined auth");
  }

  if (!auth.locations) {
    throw new Error("getLocSettingsForLocationId: called with null/undefined auth.locations");
  }

  if (auth.locations.length === 0) {
    throw new Error("getLocSettingsForLocationId: called with auth having no locations");
  }

  if (locationId === 0 || locationId === undefined) {
    return auth.locations[0].settings;
  }

  let location = auth.locations.filter((loc) => loc.id === locationId)[0];
  if (location === undefined) {
    location = auth.locations[0];
  }

  return location.settings;
}

// --------------------------------------------------------------------------------------
const getLocationIdFromPositionid = (auth: AuthorizedItems, posId: number): number => {
  for (let i = 0; i < auth.locations.length; i++) {
    const curLoc = auth.locations[i];
    for (let j = 0; j < curLoc.departments.length; j++) {
      // NOTE THE CHECK BELOW is == and NOT ===
      // This is because we may be comparing a string to a number in the p.id and posId
      const pos = curLoc.departments[j].positions.find(p => p.id == posId);
      if (pos !== undefined) {
        return curLoc.id;
      }
    }
  }

  throw new Error("getLocationIdFromPositionId: Could not find a location that had positionid " + posId);
}

// --------------------------------------------------------------------------------------
function getLocationFromLocationId(auth: AuthorizedItems, locationId: number): AuthorizedLocation {
  if (!auth || !auth.locations || auth.locations.length === 0) {
    throw new Error("getLocationFromLocationId: Cannot get location when auth items has not been loaded");
  }

  if (locationId === 0 || locationId === undefined) {
    return auth.locations[0];
  }

  return auth.locations.filter((l) => l.id === locationId)[0];
}

// --------------------------------------------------------------------------------------
// Returns true if the user can edit their own events (they have a position within one of
// the admin's editable departments)
function canEditNonPositionEvents(auth: AuthorizedItems, departmentId: number): boolean {
  const dept = auth.locations.flatMap((l) =>
    l.departments.filter((d) => d.id === departmentId)
  )[0];
  if (
    (dept.adminAuth & AdminAuthorization.CanEditSchedules) !==
    AdminAuthorization.CanEditSchedules
  ) {
    return false;
  }

  return dept.positions.filter((p) => p.hasPosition).length > 0;
}

// --------------------------------------------------------------------------------------
function getPositionIdsForEmployee(auth: AuthorizedItems): number[] {
  return auth.locations.flatMap((l) =>
    l.departments.flatMap((d) =>
      d.positions.filter((p) => p.hasPosition).map((p) => p.id)
    )
  );
}

// --------------------------------------------------------------------------------------
function getDepartmentFromPositionId(auth: AuthorizedItems, positionId: number)  {
  if (!auth || !auth.locations || auth.locations.length === 0) {
    return null;
  }

  if (positionId <= 0) {
    return null;
  }

  let foundDepartment = null as AuthorizedDepartment | null;
  auth.locations.flatMap((l) =>
    l.departments.map((d) => {
      const foundPosition = d.positions.filter((p) => p.id === positionId);
      if (foundPosition.length === 1) {
        foundDepartment = d;
        return d;
      }

      return null;
    })
  );

  return foundDepartment;
}

// --------------------------------------------------------------------------------------
// Returns true if the Admin can edit schedules for the passed-in department
function canEditSchedules(auth: AuthorizedItems, locationId: number, departmentId: number) {
  return (
    (auth.locations
      .filter((l) => l.id === locationId)[0]
      .departments.filter((d) => d.id === departmentId)[0].adminAuth &
      AdminAuthorization.CanEditSchedules) ===
    AdminAuthorization.CanEditSchedules
  );
}

// --------------------------------------------------------------------------------------
// Returns true if 1) the user is an Admin and 2) they have a position in the passed-in department
function adminIsInDepartment(auth: AuthorizedItems, departmentId: number) {
  if (auth.role !== Role.Admin) {
    return false;
  }

  const dept = auth.locations.flatMap((l) =>
    l.departments.filter((d) => d.id === departmentId)
  )[0];
  return dept.positions.filter((p) => p.hasPosition).length > 0;
}

// --------------------------------------------------------------------------------------
function getAdminAuthorizationForDepartment(auth: AuthorizedItems, departmentId: number) {
  return auth.locations.flatMap((l) =>
    l.departments.filter((d) => d.id === departmentId)
  )[0].adminAuth;
}

// --------------------------------------------------------------------------------------
function hasPosition(auth: AuthorizedItems, positionId: number) {
  return (
    auth.locations.flatMap((l) =>
      l.departments.flatMap((d) =>
        d.positions.filter((p) => p.hasPosition && p.id === positionId)
      )
    ).length > 0
  );
}

// --------------------------------------------------------------------------------------
function getSelectedDepartment(auth: AuthorizedItems, locId: number, depId: number): SelectedDepartment {
  const location = getLocationFromLocationId(auth, locId);
  if (location === null) {
    throw new Error(
      `getSelectedDepartment: Could not find locationId "${locId}"`
    );
  }

  if (depId === -1) {
    return {
      location,
      department: null,
    } as SelectedDepartment;
  }

  const department = location.departments.filter((d) => d.id === depId)[0];
  return { location, department } as SelectedDepartment;
}

// --------------------------------------------------------------------------------------
function getSelectedPositionWithLocId(loc: AuthorizedLocation[], locId: number, posId: number) {
  const location = loc.filter((l) => l.id === locId)[0];
  const position = location.departments.flatMap((d) =>
    d.positions.filter((p) => p.id === posId)
  )[0];
  return { location, position };
}

// --------------------------------------------------------------------------------------
function getSelectedPosition(loc: AuthorizedLocation[], posId: number) {
  for (let locPosId = 0; locPosId < loc.length; locPosId++) {
    for (
      let depPosId = 0;
      depPosId < loc[locPosId].departments.length;
      depPosId++
    ) {
      for (
        let posPosId = 0;
        posPosId < loc[locPosId].departments[depPosId].positions.length;
        posPosId++
      ) {
        if (
          loc[locPosId].departments[depPosId].positions[posPosId].id === posId
        ) {
          return {
            location: loc[locPosId],
            position: loc[locPosId].departments[depPosId].positions[posPosId],
          };
        }
      }
    }
  }

  return {
    location: {} as AuthorizedLocation,
    position: {} as AuthorizedPosition,
  };
}

// --------------------------------------------------------------------------------------
function hasAnyPosition(auth: AuthorizedItems) {
  if (!auth || !auth.locations || auth.locations.length === 0) {
    return false;
  }

  let hasPosition = false;
  auth.locations
      .flatMap(l => l.departments
          .flatMap(d => d.positions
              .map(p => {
                if (p.hasPosition) {
                  hasPosition = true;
                }

                return 0;
              })));

  return hasPosition;
}

// --------------------------------------------------------------------------------------
function requiresGeolocation(locations: AuthorizedLocation[]): boolean {
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].settings.RequireGeoLocation) {
      return true;
    }
  }
  return false;
}

// --------------------------------------------------------------------------------------
function isAdminForAllDepts(auth: AuthorizedItems, locationId: number) {
  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return true;
  }

  if (auth.role === Role.Employee) {
    return false;
  }

  const location = getLocationFromLocationId(auth, locationId);
  return location?.adminAllDepts === true;
}

// --------------------------------------------------------------------------------------
function isDeptAdmin(auth: AuthorizedItems, departmentId: number): boolean {
  if (!auth || !auth.locations || auth.locations.length === 0) {
    return false;
  }

  if (auth.role === Role.Employee) {
    return false;
  }

  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return true;
  }

  const dept = auth.locations.flatMap((l) =>
    l.departments.filter((d) => d.id === departmentId)
  )[0];

  return dept.isDeptAdmin;
}

// --------------------------------------------------------------------------------------
function adminCan(adminAuth: AdminAuthorization, value: AdminAuthorization) {
  return (adminAuth & value) === value;
}

// --------------------------------------------------------------------------------------
function isAdminAuthorizedFor(
  auth: AuthorizedItems,
  locationId: number,
  departmentId: number | null | undefined,
  adminAuth: AdminAuthorization
): boolean {
  if (!isAuthItemsValid(auth))  {
    return false;
  }

  if (auth.role === Role.Employee) {
    return false;
  }

  if (auth.role === Role.LocationAdmin || auth.role === Role.BusinessAdmin) {
    return true;
  }

  if (departmentId === -1 || departmentId === null) {
    const location = getLocationFromLocationId(auth, locationId);
    return location?.adminAllDepts === true;
  }

  const dept = auth.locations.flatMap((l) =>
    l.departments.filter((d) => d.id === departmentId)
  )[0];

  return (dept.adminAuth & adminAuth) === adminAuth;
}

// --------------------------------------------------------------------------------------
function getAdminDetpartments(auth: AuthorizedItems): AuthorizedDepartment[] {
  if (!auth || !auth.locations || auth.locations.length > 0) {
    return [];
  }

  if (auth.role === Role.Employee) {
    return [];
  }

  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return auth.locations.flatMap((l) => l.departments);
  }

  if (auth.role === Role.Admin) {
    return auth.locations
      .flatMap((l) => l.departments)
      .filter((d) => d.isDeptAdmin);
  }

  return [];
}

// --------------------------------------------------------------------------------------
function isAuthItemsValid(auth: AuthorizedItems | null | undefined): boolean {
  return auth !== null &&
    auth !== undefined &&
    auth!.locations !== undefined &&
    auth!.locations !== null &&
    auth!.locations.length > 0;
}

export const authHelper = {
  adminCan,
  canAddNote,
  canCreateMessage,
  getLocSettingsForLocationId,
  getLocationFromLocationId,
  getLocationIdFromPositionid,
  getDepartmentFromPositionId,
  getPositionIdsForEmployee,
  getRoleStringFromRole,
  canEditSchedules,
  getAdminAuthorizationForDepartment,
  adminIsInDepartment,
  canEditNonPositionEvents,
  hasAnyPosition,
  hasPosition,
  isAuthItemsValid,
  isDeptAdmin,
  isAdminForAllDepts,
  isAdminAuthorizedFor,
  getSelectedDepartment,
  getSelectedPositionWithLocId,
  getSelectedPosition,
  getAdminDetpartments,
  requiresGeolocation,
};
