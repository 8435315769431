import React from "react";
import { IonCol, IonLabel, IonRow, IonTextarea } from "@ionic/react";
import "../../../global-styles.scss";
import "./EventStartEndEditor";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { EditorReducerAction, EditorValues } from "../EditEventModalState";
import { EventStatus } from "../../../enums/EventStatus";
import { EventType, Role } from "../../../enums";

interface EventNotesEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
const EventNotesEditor: React.FC<EventNotesEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventNotesEditorProps) => {
  const onNotesUpdate = (newNote?: string | null) => {
    if (!newNote) {
      newNote = "";
    }

    if (newNote !== editorValues.notes) {
      update({ type: "NOTES-UPDATED", notes: newNote });
    }
  };

  if (
    editorValues.eventStatus === EventStatus.Request &&
    (editorValues.eventType === EventType.Cover ||
      editorValues.eventType === EventType.Shift) &&
    editorValues.employee.id !== auth.employeeId &&
    auth.role === Role.Employee
  ) {
    return <></>;
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">Event Note</IonLabel>
        </IonCol>
        <IonCol size="8"></IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12">
          <IonTextarea
            mode="md"
            className="textarea"
            // placeholder="Enter note"
            maxlength={120}
            autoGrow={true}
            spellcheck={true}
            rows={4}
            value={editorValues.notes}
            disabled={editorValues.isReadonly || editorValues.isConfirmed}
            onIonChange={(e) => onNotesUpdate(e.detail.value)}
          />

          <div className="character-counter">
            {editorValues.notes?.length ?? 0}/120
          </div>
        </IonCol>
      </IonRow>
    </>
  );
};

export default EventNotesEditor;
