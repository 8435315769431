import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import { timeHelper as th } from "../util/timeHelper";
import {ReportResults} from "../components/DataPageComponents/ReportResults";
import {IdName} from "../orbital-interfaces/IdName";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {EventFilter} from "../orbital-interfaces/EventFilter";
import {log} from "../util/logger";

export interface PayrollPeriodDataResults {
  start: string;
  end: string;
  results: ReportResults;
}

// ----------------------------------------------------------------------------------------------
async function runReport(
  user: ApplicationUser,
  filter: EventFilter,
  reportTypeId: number
) {
  log("api_reports_runReport");
  const filterToSend = {
    ...filter,
    start: th.formatDateTimeAsMvcString(filter.start),
    end: th.formatDateTimeAsMvcString(filter.end),
  };

  return (await apiHelper.post(
    user,
    "api/v2/reports/run-report/" + reportTypeId,
    filterToSend
  )) as PayrollPeriodDataResults | ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function getReportList(user: ApplicationUser) {
  log("api_reports_getReportList");
  return (await apiHelper.get(user, "api/v2/reports/list")) as IdName[]
}

export const reportsApi = {
  getReportList,
  runReport
};
