import React, {useContext, useRef} from "react";
import "../../global-styles.scss";
import { IonIcon, IonDatetime } from "@ionic/react";
import {addDays, format, isEqual, isSameDay, isToday, parseISO} from "date-fns";
import {DateRangeContext} from "../../context/DateRangeContext";
import {enableGesture} from "./WeekChooserGesture";

interface WeekChooserProps {
  onStartDateUpdated: (date: Date) => void;
  onWeekdayClick: (date: Date) => void;
  selectedDate: Date | null;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const WeekChooser: React.FC<WeekChooserProps> = ({
  onStartDateUpdated,
  onWeekdayClick,
  selectedDate
}: WeekChooserProps) => {
  const {rangeStart, setRangeStartDate} = useContext(DateRangeContext);
  const dateRef = useRef(rangeStart);
  const chooserRef = useRef(null as null | HTMLDivElement);

  // -------------------------------------------------------------------------------------
  const onDateChanged = (newDate: Date | null) => {
    if (newDate === null) {
      return;
    }

    if (!isSameDay(newDate!, dateRef!.current!)) {
      dateRef.current = newDate;
      onStartDateUpdated(setRangeStartDate(newDate));
    }
  };

  if (rangeStart === null) {
    return <></>;
  }

  if (chooserRef.current !== null) {
    enableGesture(
      chooserRef.current,
      () => { onDateChanged(addDays(rangeStart!, -7))},
      () => {onDateChanged(addDays(rangeStart!, 7))
      });
  }

  return (
    <div className="chooser" ref={chooserRef}>
      <div className="top">
        <button
          onClick={() => onDateChanged(addDays(rangeStart, -7))}
          className="arrow"
          key={-1}
        >
          <IonIcon src="assets/prev-triangle-solid.svg" />
        </button>

        <div className="month-year-container">
          <div className="datetime-picker-container select-icon-inner">
            <IonDatetime
              mode="ios"
              className="datetime-picker"
              displayFormat="MMMM YYYY"
              pickerFormat="MMM D YYYY"
              min="2005-01-01"
              max="2040-01-01"
              value={rangeStart.toISOString()}
              onIonChange={(e) => {
                onDateChanged(parseISO(e.detail.value!));
              }}
            />
            {/* <IonIcon src="assets/caret-down-solid.svg" className="select-icon-inner" /> */}
          </div>
        </div>

        <button
          onClick={() => onDateChanged(addDays(rangeStart, 7))}
          className="arrow"
          key={7}
        >
          <IonIcon src="assets/next-triangle-solid.svg" />
        </button>
      </div>
      <div className="weekday-list">
        {[0, 1, 2, 3, 4, 5, 6].map((num: number) => {
          const thisDate = addDays(rangeStart, num);
          const isTodayClass = isToday(thisDate) ? "today" : "not-today";
          const isSelectedDateClass = selectedDate !== null && isEqual(thisDate, selectedDate)
            ? "selected-date"
            : "";
          return (
            <button
              key={num}
              className="weekday-button"
              style={{ outline: "none" }}
              onClick={() => {
                onWeekdayClick(thisDate);
              }}
            >
              <div>
                <div className={"weekday-abbr " + isSelectedDateClass}>
                  {format(thisDate, "EEE")}
                </div>
                <div className={isTodayClass}  />
                <div className={"weekday-num " + isSelectedDateClass}>
                  {format(thisDate, "d")}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default WeekChooser;
