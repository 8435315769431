import React, {useState, MouseEvent} from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonList,
  IonText,
  useIonViewWillEnter,
  IonFooter,
} from "@ionic/react";
import "../global-styles.scss";
import { userApi } from "../api/userApi";
import {RouteComponentProps, useHistory} from "react-router";
import useDialogBox from "../hooks/useDialogBox";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import env from "../appFunctions/environment";
import { log, clearAnalytics } from "../util/logger";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import "public/assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg";
import { useShowLoading } from "../hooks/useShowLoading";
import { filterStore } from "../data/filterStore";
import { Role } from "../enums";
import { timeHelper as th } from '../util/timeHelper';

interface LoginProps extends RouteComponentProps {
  onLoggedInUrl: string;
}

// ----------------------------------------------------------------------------------------------
function validate(username: string, password: string): boolean {
  return username.length > 0 && password.length > 5;
}

// ----------------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------------
const Login: React.FC<LoginProps> = ({
  onLoggedInUrl,
}: LoginProps) => {
  const { alertBoxJsx, showAlert } = useDialogBox();
  const { user, userLoggedIn, logout } = React.useContext(
    ApplicationUserContext
  );
  const { clearAuth, refreshAuth } = React.useContext(AuthorizedItemsContext);
  const loading = useShowLoading("Logging in...");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameErrors] = useState("");
  const [passwordErrors] = useState("");
  const [loginEnabled, setLoginEnabled] = useState(false);
  const history = useHistory();

  // ----------------------------------------------------------------------------------------------
  useIonViewWillEnter(() => {

    log("logout", { user: user.username });

    try {
      userApi.logout(user);
    } catch(e) {
      console.error("Error calling logout API: " + e);
    }

    // userApi.logout(user).then(() => {
      logout().then(() => {
        clearAuth().then(() => {
          filterStore.clear();
          localStorage.clear();
          clearAnalytics().then(() => {
            console.log(`logged out`);
          });
        });
      });
    // });

    setUsername("");
    setPassword("");
  }, [user.isLoggedin]);
/*

  // ----------------------------------------------------------------------------------------------
  useIonViewWillEnter(() => {
    if (user.isLoggedin) {
      history.push(onLoggedInUrl, { direction: "forward" });
      history.goForward();
    } else {
      log("app_login", { version: env.settings.appVersionString });
    }
  });

*/

  // ----------------------------------------------------------------------------------------------
  const onUsernameChange = (newUsername: string) => {
    setUsername(newUsername);
    setLoginEnabled(validate(newUsername, password));
  };

  // ----------------------------------------------------------------------------------------------
  const onPasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    setLoginEnabled(validate(username, newPassword));
  };

  // ----------------------------------------------------------------------------------------------
  const onForgotPasswordClick = (e: MouseEvent) => {
    e.preventDefault();
    history.push("/reset-password", { direction: "forward" });
    history.goForward();
  };

  // ----------------------------------------------------------------------------------------------
  const login = async (e: React.FormEvent | null) => {
    if (e) {
      e.preventDefault();
    }

    if (!username || !password) {
      return;
    }

    const envUser = env.getUserAndEnvironmentFromInput(username);
    loading.setShowLoading(true);
    userApi
      .authenticateUser(envUser.username, password, envUser.env.key)
      .then((response) => {
        if (!response.loggedIn) {
          loading.setShowLoading(false);
          log("login_failure", {
            username: envUser.username,
            appVersion: env.settings.appVersionString,
            environment: envUser.env.name,
          });
          const msg = response.error ? response.error : "Could not log in";
          showAlert("Failed to log in", msg, () => {
            setPassword("");
            setLoginEnabled(false);
          });

          return;
        }

        // user is logged in, get auth-item data;
        userLoggedIn(username, response.access_token, envUser.env.key)
          .then((newUser) => {
            refreshAuth(true,  newUser, logout)
            .then((newAuth) => {
              if (!newAuth) {

              } else {
                if (env.settings.appType === "punchclock" && (newAuth.role === Role.Admin || newAuth.role === Role.Employee)) {
                  loading.setShowLoading(false);
                  showAlert("Unauthorized", "Only Business Admins or Location Admins may log in", () => {
                    setUsername("");
                    setPassword("");
                    setLoginEnabled(false);
                    logout();
                  });
                } else {
                  loading.setShowLoading(false);
                  // history.replace(onLoggedInUrl);
                  //history.push(onLoggedInUrl, { direction: 'forward'});

                  history.push({ pathname: onLoggedInUrl});
                  window.location.reload();  //  HACKHACK, Ionic sucks
                }
              }
            });
          })
          .catch(() => {
            loading.setShowLoading(false);
            showAlert("Failed to log in", "Could not set access token", () => {
              logout();
            });
          });
      });
  };

  return (
    <IonPage id="login-page">
      <IonContent className="login-content">
        <form
          noValidate={true}
          className="login-content-container"
          onSubmit={login}
        >
          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          {alertBoxJsx}
          {loading.showLoadingJsx}

          <IonList className="form-list">
            <div className="item">
              <div className="label">
                {env.settings.appType === "punchclock" ? (
                  <span>Location Admin / Business Admin</span>
                ) : (
                  <span>User</span>
                )}
              </div>
              <div className="field-container">
                <input
                  className="input"
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                  tabIndex={1}
                  onChange={(e) => {
                    onUsernameChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {usernameErrors.length > 0 && (
              <IonText color="light">
                <p className="ion-padding-start">{usernameErrors}</p>
              </IonText>
            )}

            <div className="item">
              <div className="label">
                Password
                <a
                  className="forgot-password-link"
                  href="/reset-password"
                  tabIndex={4}
                  onClick={(e) => onForgotPasswordClick(e)}
                >
                  Forgot password?
                </a>
              </div>
              <div className="field-container">
                <input
                  className="input"
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  tabIndex={2}
                  onChange={(e) => {
                    onPasswordChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {passwordErrors.length > 0 && (
              <IonText color="light">
                <p className="ion-padding-start">{passwordErrors}</p>
              </IonText>
            )}

            <IonButton
              className="login-button"
              type="submit"
              expand="block"
              disabled={!loginEnabled}
              onClick={login}
              tabIndex={3}
              onKeyDown={(e) => {
                // WORKAROUND for when enter key is hit when button has focus,
                // otherwise will not accept as submit, see explanation below
                if (e.key === 'Enter') {
                  login(null);
                }
              }}
            >
              LOG IN
            </IonButton>

            {/*
                12/27/2021: WORKAROUND for Ionic Bug https://github.com/ionic-team/ionic-framework/issues/19368
                  where the form cannot be submitted with the
                  'submit' button above using the enter/return key.
                  Buttons in the Shadow DOM are not picked up by the parent form.
                  In this case, clicking the button and pressing "Enter"
                  would normally not submit the form.
            */}
            <input
              type="submit"
              tabIndex={-1}
              style={{position: "absolute", visibility: "hidden", left: "-1000px"}}
            />

            <div className="item"></div>
          </IonList>
        </form>
      </IonContent>
      <IonFooter className="login-footer">
        <div className="divider-line"></div>
        <div className="legal-statement">
          By continuing, you agree to{" "}
          <a className="terms-privacy-link" href="https://app.orbitalshift.com/Terms">
            Terms of Service
          </a>{" "}
          and{" "}
          <a className="terms-privacy-link" href="https://app.orbitalshift.com/Privacy">
            Privacy Policy.
          </a>
        </div>
        <div className="copyright">
          © 2009-{th.getCurrentYear()} Orbital Shift, Inc. All rights reserved.
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Login;
