import React, {useEffect, useRef, useState} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage, IonRefresher, IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "../global-styles.scss";
import {funnel} from "ionicons/icons";
import {timeHelper as th} from "../util/timeHelper";
import {RouteComponentProps} from "react-router";
import {ApplicationUserContext} from "../context/ApplicationUserContext";
import {AuthorizedItemsContext} from "../context/AuthorizedItemsContext";
import {ReportResults} from "../components/DataPageComponents/ReportResults";
import {IdName} from "../orbital-interfaces/IdName";
import useDialogBox from "../hooks/useDialogBox";
import {reportsApi} from "../api/reportsApi";
import {addDays, parseISO} from "date-fns";
import PayrollPeriodChooser from "../components/TimeClock/PayrollPeriodChooser";
import ReportGrid from "../components/DataPageComponents/ReportGrid";
import ReportChooser from "../components/DataPageComponents/ReportChooser";
import {isApiError} from "../orbital-interfaces/ApiErrorResult";
import {useShowLoading} from "../hooks/useShowLoading";
import {filterStore} from "../data/filterStore";
import {EventFilter, EventFilterView,} from "../orbital-interfaces/EventFilter";
import useSimpleToast from "../hooks/useSimpleToast";
import ScheduleFilterModal from "../components/EventsPageComponents/ScheduleFilterModal";
import NoEventsToShow from "../components/EventsPageComponents/NoEventsToShow";
import FabButton from "../components/FabButton";
import {authHelper} from "../util/authHelper";
import ReportChart from "../components/DataPageComponents/ReportChart";
import {EventStatus, ReportType} from "../enums";
import {FilteredRangeType} from "../enums/FilteredRangeType";

interface DataPageProps
  extends RouteComponentProps<{
    messageId?: string;
  }> {}

  // initial/dummy filter that is set in state before the data comes back
  const initialFilter = {
    tagId: undefined,
    positionIds: [] as number[],
    dnLocIds: [] as number[],
    settingsLocId: -1,
    eventTypeId: EventStatus.Approved,
    eventStatusId: EventStatus.Approved,
    start: new Date(),
    end: new Date(),
    filterByEmployee: false,
    view: EventFilterView.schedule,
    employee: { id: -1, name: ""},
    filteredRangeType: FilteredRangeType.Payroll
  } as EventFilter;

// ------------------------------------------------------------------------------------
function showChartForReportType(selectedReport :ReportType): boolean {
  switch(selectedReport) {
/*
    case ReportType.IndividualPayroll:
      return true;
*/
    default:
      return false;
  }
}

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const DataPage: React.FC<DataPageProps> = () => {
  const { user } = React.useContext(ApplicationUserContext);
  const { auth } = React.useContext(AuthorizedItemsContext);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null as Date | null);
  const [data, setData] = useState(null as ReportResults | null);
  const [reports, setReports] = useState([
    { id: ReportType.IndividualPayroll, name: "My Hours" },
  ] as IdName[]);
  const fabButtonRef = useRef(null as any | null);  // Should be HTMLIonFabElement but  ¯\_(ツ)_/¯
  const [selectedReport, setSelectedReport] = useState(ReportType.IndividualPayroll);
  const dlg = useDialogBox();
  const loading = useShowLoading("Retrieving data...");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const toast = useSimpleToast();
  const [isShowing, setIsShowing] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

  // ------------------------------------------------------------------------------------
  useIonViewDidEnter(() => {
    setIsShowing(true);
  });

  // ------------------------------------------------------------------------------------
  useIonViewWillLeave(() => {
    fabButtonRef.current!.close();
    setIsShowing(false);
  });

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    getReportList();

    // eslint-disable-next-line
  }, []);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (!authHelper.isAuthItemsValid(auth) || !user.isLoggedin) {
      return;
    }

    const storedFilter = filterStore.getFilter(auth, user);
    const newFilter = {
      tagId: storedFilter.tagId,
      positionIds: storedFilter.positionIds,
      dnLocIds: storedFilter.dnLocIds,
      settingsLocId: storedFilter.settingsLocId,
      eventTypeId: storedFilter.eventTypeId,
      eventStatusId: storedFilter.eventStatusId,
      start: startDate,
      end: addDays(startDate, 7),
      filterByEmployee: false,
      view: EventFilterView.schedule,
      employee: storedFilter.employee
    } as EventFilter;

    setFilter(newFilter);
    getData(newFilter);

    // eslint-disable-next-line
  }, [currentDate, selectedReport, auth.locations.length, user.defaultLocationId]);

  // ------------------------------------------------------------------------------------
  function getReportList() {
    reportsApi.getReportList(user).then((res) => {
      setReports(res);
    });
  }

  // ------------------------------------------------------------------------------------
  function getData(reportFilter: EventFilter) {
    loading.setShowLoading(true);

    if (!authHelper.isAuthItemsValid(auth) || !user.isLoggedin) {
      return;
    }

    const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
    const filterToSend = {
      ...reportFilter,
      start: th.getTodayStartForLocation(currentDate!, locSettings),
      end: th.getTodayStartForLocation(currentDate!, locSettings),
    } as EventFilter;

    reportsApi.runReport(user, filterToSend, selectedReport).then((res) => {
      loading.setShowLoading(false);


      ionRefresherRef.current!.complete();

      if (isApiError(res)) {
        dlg.showAlert("Error", res.message, () => {});
      } else {
        setData(res.results);
        setStartDate(parseISO(res.start));
        setEndDate(parseISO(res.end));
      }
    });
  }

  // ------------------------------------------------------------------------------------
  const onDismissFilterModal = (f: EventFilter, filterApplied: boolean) => {
    setFilter(f);
    filterStore.putFilter(f);
    if (filterApplied) {
      toast.showToast("Filter applied");
    }

    getData(f);
    setShowFilterModal(false);
  };

  // ------------------------------------------------------------------------------------
  function doRefresh() {
    getData(filter);
  }

  // ------------------------------------------------------------------------------------
  function onNextPayPeriod() {
    setCurrentDate(addDays(endDate!, 1));
  }

  // ------------------------------------------------------------------------------------
  function onPrevPayPeriod() {
    setCurrentDate(addDays(startDate!, -1));
  }

  // ------------------------------------------------------------------------------------
  const onFabModalClosed = (
    updateMessages: boolean,
    updateEventsOrNotes: boolean,
    toastMessage?: string
  ) => {
    if (toastMessage) {
      toast.showToast(toastMessage);
    }

    if (updateMessages) {
    }

    if (updateEventsOrNotes) {
    }
  };

  return (
    <IonPage id="schedule-page">
      <IonHeader className="ion-no-border">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonMenuButton className="ion-menu-button" />
          </IonButtons>
          <IonTitle slot="start">
            <ReportChooser
              reports={reports}
              selectedReportId={selectedReport}
              onReportChanged={(id) => {
                setSelectedReport(id as ReportType);
              }}
            />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowFilterModal(true)}>
              <IonIcon
                icon={funnel}
                mode="ios"
                slot="icon-only"
                className={
                  authHelper.isAuthItemsValid(auth) && filterStore.isFilterApplied(auth, filter)
                    ? "filter-applied"
                    : "filter-not-applied"
                }
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <PayrollPeriodChooser
        startDate={startDate}
        endDate={endDate}
        onNextPayPeriod={onNextPayPeriod}
        onPrevPayPeriod={onPrevPayPeriod}
      />

      <IonContent className="outer-content">
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          ref={ionRefresherRef}
        >
          <IonRefresherContent
            pullingIcon="arrow-dropdown"
            pullingText="Pull down to refresh"
            refreshingText="Refreshing..."
          />
        </IonRefresher>


        {data !== null && data.records.length > 0 && !showChartForReportType(selectedReport) && (
          <ReportGrid
            isShowing={isShowing}
            onRefreshData={() => {
              getData(filter);
            }}
            reportData={data}
          />
        )}

        {data !== null && data.records.length > 0 && showChartForReportType(selectedReport) && (
          <ReportChart
            isShowing={isShowing}
            onRefreshData={() => {
              getData(filter);
            }}
            reportData={data}
            reportType={selectedReport}
          />
        )}

        {(data === null || data.records.length === 0) &&
          !loading.showLoading && (
            <NoEventsToShow message="No records match criteria" />
          )}

        {dlg.alertBoxJsx}
        {loading.showLoadingJsx}
        {toast.simpleToastJsx}
      </IonContent>

      <FabButton
        fabRef={fabButtonRef}
        onFabModalClosed={onFabModalClosed}
      />

      <IonModal
        mode="md"
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
      >
        <ScheduleFilterModal
          filter={filter}
          auth={auth}
          showRangeFilter={false}
          onDismissModal={onDismissFilterModal}
          isOpen={showFilterModal}
          onCancelModal={() => setShowFilterModal(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default DataPage;
