import React, {useContext} from "react";
import {IonRow, IonCol, IonLabel, IonSelectOption, IonSelect, IonGrid} from "@ionic/react";
import "../../../global-styles.scss";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";
import {authHelper} from "../../../util/authHelper";
import {timeHelper as th} from "../../../util/timeHelper";
import {Role} from "../../../enums/Role";
import {
  addMinutes,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  subHours,
  subMinutes
} from "date-fns";
import {EventType} from "../../../enums/EventType";
import {scheduleEventHelper} from "../../../util/scheduleEventHelper";

interface LegacyBreakEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

const breakTimeOptions = ["None","0:15","0:30","0:45","1:00","1:15","1:30","1:45","2:00",
                          "2:15","2:30","2:45","3:00","3:15","3:30","3:45","4:00","4:15",
                          "4:30","4:45","5:00","5:15","5:30","5:45","6:00"];

// --------------------------------------------------------------------------------------
// TimeSpans come in as 00:00:00, as hours:minutes:seconds.  Need to change it to
// the value that we show in the dropdown.
function getBreakTimeFromTimeSpan(ts: string | null) {
  if (ts === null) {
    return "None";
  }

  if (ts === "00:00:00") {
    return "None";
  }

  const breakTime = ts.substring(1,5);
  return breakTime;
}

// --------------------------------------------------------------------------------------
function getTimeSpanFromBreakTime(bt: string | null): string | null {
  if (bt === null) {
    return null;
  }

  if (bt === "None") {
    return "00:00:00";
  }

  return "0" + bt + ":00";
}

// --------------------------------------------------------------------------------------
function getBreakStartOptions(start: string, end: string): Date[] {
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  let xDate = subHours(subMinutes(startDate, getMinutes(startDate)), getHours(startDate));
  const startOptions = [] as Date[];

  for(let i = 0; i < 48 ; i++) {
    xDate = addMinutes(xDate, 30);
    if (isEqual(xDate, startDate) ||
        isEqual(xDate, endDate) ||
        (isAfter(xDate, startDate) && isBefore(xDate, endDate))) {
      startOptions.push(xDate);
    }

  }

  return startOptions;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const LegacyBreakEditor: React.FC<LegacyBreakEditorProps> = ({
  auth,
  editorValues,
  update
}: LegacyBreakEditorProps) => {
  const {user} = useContext(ApplicationUserContext);
  const locSettings = authHelper.getLocSettingsForLocationId(auth,
    editorValues.locationId > 0
      ? editorValues.locationId
      : user.defaultLocationId);

  if (!locSettings.ShowBreak || (
    editorValues.eventType !== EventType.Clock &&
    !scheduleEventHelper.isShiftTypeEvent(
      editorValues.eventType,
      editorValues.eventStatus) &&
    editorValues.transferToEmployeeId === null
  )) {
    return <></>;
  }

  const breakStartOptions = getBreakStartOptions(editorValues.eventStart, editorValues.eventEnd);

  // --------------------------------------------------------------------------------------
  function onLunchHourTimeChange(newValue: string | null | undefined) {
    if (newValue === undefined) {
      return;
    }

    const newLunchHourTime = getTimeSpanFromBreakTime(newValue);
    if (newLunchHourTime !== editorValues.lunchHourTime) {
      update({
        type: "LUNCHHOURTIME-UPDATED",
        lunchHourTime: newLunchHourTime
      });
    }
  }

  // --------------------------------------------------------------------------------------
  function onBreakStartTimeChange(newValue: string | null | undefined) {
    if (newValue === undefined) {
      return;
    }

    if (newValue !== null) {
      newValue = th.convertTimeWithoutTimeZoneToLocalTime(newValue);
    }

    if (newValue !== editorValues.breakStartTime) {
      update({
        type: "BREAKSTARTTIME-UPDATED",
        breakStartTime: newValue
      })
    }
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">(B)reak</IonLabel>
        </IonCol>
        <IonCol size="8">

          <IonGrid>
            {locSettings.EnableBreakStartTime && (
              <IonRow>
                <IonCol size="3">
                  <IonLabel className="label">Start</IonLabel>
                </IonCol>
                <IonCol size="9">
                  <IonSelect
                    mode="ios"
                    className="select"
                    interface="action-sheet"
                    disabled={
                      editorValues.isReadonly ||
                      editorValues.isConfirmed ||
                      editorValues.transferToEmployeeId !== null ||
                      (auth.role === Role.Employee && editorValues.scheduleEventId <= 0)
                    }
                    value={editorValues.breakStartTime === null ? null : parseISO(editorValues.breakStartTime).toISOString()}
                    onIonChange={(e) => onBreakStartTimeChange(e.detail.value)}
                  >
                    <IonSelectOption value={null}>
                      None
                    </IonSelectOption>
                    {breakStartOptions.map(opt => (
                      <IonSelectOption key={opt.toISOString()} value={opt.toISOString()}>
                        {format(opt, "h:mm a")}
                      </IonSelectOption>
                    ))}
                  </IonSelect>

                </IonCol>
              </IonRow>
            )}

            <IonRow>
              <IonCol size="3">
                <IonLabel className="label">Duration</IonLabel>
              </IonCol>
              <IonCol size="9">
                <IonSelect
                  mode="ios"
                  className="select"
                  interface="action-sheet"
                  disabled={
                    editorValues.isReadonly ||
                    editorValues.isConfirmed ||
                    editorValues.transferToEmployeeId !== null ||
                    (auth.role === Role.Employee && editorValues.scheduleEventId <= 0)
                  }
                  value={getBreakTimeFromTimeSpan(editorValues.lunchHourTime)}
                  onIonChange={(e) => onLunchHourTimeChange(e.detail.value)}
                >
                  {breakTimeOptions.map(opt => (
                    <IonSelectOption key={opt} value={opt}>
                      {opt}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonCol>
      </IonRow>
    </>
  );
};

export default LegacyBreakEditor;
