// https://dev.to/rodw1995/cancel-your-promises-when-a-component-unmounts-gkl
import useMountedState from "./useMountedState";
import { useCallback } from 'react';

const useCancelablePromise = () => {
  const isMounted = useMountedState();

  return useCallback(
    <T>(promise: Promise<T>, onCancel?: () => void) => new Promise<T>((resolve, reject) => {
      promise
        .then((result) => {
          if (isMounted()) {
            resolve(result);
          }
        })
        .catch((error) => {
          if (isMounted()) {
            reject(error);
          }
        })
        .finally(() => {
          if (!isMounted() && onCancel) {
            onCancel();
          }
        });
    }),
    [isMounted],
  );
};

export default useCancelablePromise;


//
// EXAMPLE USAGE
//

/*
  import useDidMount from './useMountedState';
  import { useState } from 'react';
  import useCancelablePromise from './useCancelablePromise';

  export default () => {
    const makeCancelable = useCancelablePromise();
    const [user, setUser] = useState();

    // Fetch the authenticated user on mount
    useDidMount(() => {
      makeCancelable(fetchAuthenticatedUser()).then((user) => {
        setUser(user);
      });
    });

    // Rest of the component...
  };
*/
