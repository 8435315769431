import React from "react";
import { IonItem, IonToggle, IonList, IonLabel } from "@ionic/react";
import {IdName} from "../orbital-interfaces/IdName";

interface ToggleListExProps {
  list: IdName[] | null;
  selected: IdName[] | null;
  selectionChanged(newSelected: IdName[] | null): void;
  hideAllToggle?: boolean | null | undefined;
}

const ToggleListEx: React.FC<ToggleListExProps> = ({
  list,
  selected,
  selectionChanged,
  hideAllToggle
}: ToggleListExProps) => {
  // ----------------------------------------------------------------------------------------------
  const isItemChecked = (id: number) => {
    if (!selected || selected.length === 0) {
      return false;
    }

    for (let i = 0; i < selected.length; i++) {
      if (selected[i].id === id) {
        return true;
      }
    }

    return false;
  };

  // ----------------------------------------------------------------------------------------------
  const onAllChanged = () => {
    const checked = list?.length !== selected?.length;

    if (list?.length === selected?.length) {
      if (checked) {
        return;
      }

      const newSelected = [] as IdName[];
      selectionChanged(newSelected);
    } else {
      if (!checked) {
        return;
      }

      selectionChanged(list ? list.slice() : null);
    }
  };

  // ----------------------------------------------------------------------------------------------
  const itemToggle = (item: IdName) => {
    if (selected === null) {
      return;
    }

    const checked = !selected.map((n) => n.id).includes(item.id);

    if (checked) {
      const newList = selected.slice();
      newList.push(item);
      selectionChanged(newList);
    } else {
      const newlist = selected.filter((r) => r.id !== item.id);
      selectionChanged(newlist);
    }
  };

  if (!list) {
    return <div>Loading...</div>;
  }

  return (
    <IonItem lines="none">
      <IonList>
        {!hideAllToggle && (
          <IonItem lines="none" key={-1}>
            <IonToggle
              mode="md"
              key={-1}
              checked={list?.length === selected?.length}
              onClick={() => onAllChanged()}
            />
            <IonLabel>SELECT ALL</IonLabel>
          </IonItem>
        )}
        {list &&
          list.map((toggle) => (
            <IonItem lines="none" key={toggle.id}>
              <IonToggle
                mode="md"
                key={toggle.id}
                onClick={() => {
                  itemToggle(toggle);
                }}
                checked={isItemChecked(toggle.id)}
              />
              <IonLabel>{toggle.name}</IonLabel>
            </IonItem>
          ))}
      </IonList>
    </IonItem>
  );
};

export default ToggleListEx;
