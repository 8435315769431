import React, {useContext, useEffect, useState} from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
// import "./EventRequestModal.scss";
import "../../../global-styles.scss";
import {requestsApi, CoverListItem} from "../../../api/requestsApi";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";
import {EditorValues} from "../EditEventModalState";
import {EventRequestEnum} from "./EventRequestEditorEx";
import {authHelper} from "../../../util/authHelper";
import {AuthorizedItemsContext} from "../../../context/AuthorizedItemsContext";
import {close} from "ionicons/icons";

interface EventRequestModalProps {
  onCancel: () => void;
  onOk: (
    employeeId: number | null,
    notes: string,
    sendMessage: boolean
  ) => void;
  editorValues: EditorValues;
  eventRequestType: EventRequestEnum;
  buttonText: string;
}

// --------------------------------------------------------------------------------------
const EventRequestModalEx: React.FC<EventRequestModalProps> = ({
  onCancel,
  onOk,
  editorValues,
  eventRequestType,
  buttonText
}: EventRequestModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const [notes, setNotes] = useState("" as string | null | undefined);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    null as number | null
  );
  const [coverList, setCoverList] = useState(null as CoverListItem[] | null);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, editorValues.locationId)

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      eventRequestType === EventRequestEnum.CreateCoverRequest &&
      editorValues.scheduleEventId > 0
    ) {
      requestsApi
        .getCoverRequestListItems(user, editorValues.scheduleEventId, true)
        .then((results) => {
          setCoverList(results);
          if (results && results.length > 0) {
            setSelectedEmployeeId(results[0].value);
          }
        });
    }

    // eslint-disable-next-line
  }, [editorValues.scheduleEventId, eventRequestType]);

  // --------------------------------------------------------------------------------------
  const onOkClick = () => {
    const newNotes = notes ?? "";
    onOk(selectedEmployeeId, newNotes, false);
  };

  if (eventRequestType === EventRequestEnum.None) {
    return <></>;
  }

  return (
    <>
      <IonContent>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonTitle>{buttonText}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onCancel}>
                <IonIcon icon={close} mode="ios" slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow
            hidden={
              !locSettings.EnableDirectCoverRequest ||
              eventRequestType !== EventRequestEnum.CreateCoverRequest
            }
          >
            <IonCol className="label" size="3">
              To
            </IonCol>
            <IonCol size="9">
              <IonSelect
                className="select"
                value={selectedEmployeeId}
                onIonChange={(e) => {
                  setSelectedEmployeeId(parseInt(e.detail.value));
                }}
              >
                {coverList &&
                coverList.map((cover) => (
                  <IonSelectOption
                    key={cover.value}
                    value={cover.value}
                    disabled={cover.disabled}
                  >
                    {cover.text}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>

          <IonRow
            hidden={eventRequestType === EventRequestEnum.CreateCoverRequest}
          >
            <IonCol size="12">
              {eventRequestType === EventRequestEnum.UndoCoverRequest && (
                <div className="usage-message">
                  This will remove your Cover-Request, setting the event
                  to Shift-Approved.
                </div>
              )}
              {eventRequestType === EventRequestEnum.ApproveShiftRequest && (
                <div className="usage-message">
                  This will approve the Shift-Request from {editorValues.employee.name}
                </div>
              )}
              {eventRequestType === EventRequestEnum.CreateShiftRequest && (
                <div className="usage-message">
                  This will create a Shift-Request to be approved by an admin.
                </div>
              )}
              {eventRequestType === EventRequestEnum.DeleteRequest && (
                <div className="usage-message">
                  This will delete your Request.
                </div>
              )}
            </IonCol>
          </IonRow>

          <IonRow
            hidden={eventRequestType !== EventRequestEnum.CreateCoverRequest}
          >
            <IonCol className="label" size="3">
              Notes
            </IonCol>
            <IonCol size="9">
              <IonTextarea
                className="select"
                maxlength={200}
                rows={4}
                value={notes}
                onIonChange={(e) => {
                  setNotes(e.detail.value);
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="label" size="3" />
            <IonCol size="9" className="error-message">
              <div />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonButton expand="block" color="secondary" onClick={onOkClick}>
          OK
        </IonButton>
      </IonFooter>
    </>
  );
};

export default EventRequestModalEx;
