import { EndBreakResponse } from "../orbital-interfaces/EndBreakResponse";
import { StartBreakResponse } from "../orbital-interfaces/StartBreakResponse";
import { ScheduleEvent } from "../orbital-interfaces/ScheduleEvent";
import { ClockOutResponse } from "../orbital-interfaces/ClockOutResponse";
import { ClockInResponse } from "../orbital-interfaces/ClockInResponse";
import { SwitchPositionOrTagResponse } from "../orbital-interfaces/SwitchPositionOrTagResponse";
import { TimeClockSettingsData } from "../orbital-interfaces/TimeClockSettingsData";
import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import { ClockInGeoLocations } from "../orbital-interfaces/ClockInGeoLocations";
import {BreakType} from "../enums";
import {log} from "../util/logger";

// ----------------------------------------------------------------------------------------------
async function getTimeClockSettings(
  user: ApplicationUser,
  lat: number | undefined,
  lng: number | undefined
) {
  log("api_timeClock_getTimeClockSettings");
  return (await apiHelper.get(
    user,
    `api/v1/employee/timeclock/?lat=${lat}&lng=${lng}`
  )) as TimeClockSettingsData;
}

// ----------------------------------------------------------------------------------------------
async function clockIn(
  user: ApplicationUser,
  positionId: number,
  tagId: number | null,
  lat: number | undefined,
  lng: number | undefined
) {
  log("api_timeClock_clockIn");
  // eslint-disable-next-line max-len
  const baseUrl = `/api/v2/employee/timeclock/clockin/${positionId}/${tagId}?lat=${lat}&lng=${lng}`;
  return (await apiHelper.post(user, baseUrl, {})) as ClockInResponse;
}

// ----------------------------------------------------------------------------------------------
async function clockOut(
  user: ApplicationUser,
  lat: number | undefined,
  lng: number | undefined,
  clockOutValue?: number,
) {
  log("api_timeClock_clockOut");
  const clockoutDataModel = {
    ClockOutValue: clockOutValue,
    TimeClockBreak: null,
    BreakStart: null,
  };

  return (await apiHelper.post(
    user,
    `/api/v2/employee/timeclock/clockout?lat=${lat}&lng=${lng}`,
    clockoutDataModel
  )) as ClockOutResponse;
}

// ----------------------------------------------------------------------------------------------
async function switchPositionOrTag(
  user: ApplicationUser,
  positionId: number,
  tagId: number | null,
  lat: number | undefined,
  lng: number | undefined  ,
  clockOutValue?: number
) {
  log("api_timeClock_switchPositionOrTag");
  // eslint-disable-next-line max-len
  const baseUrl = `/api/v2/employee/timeclock/switch/${positionId}/${tagId}?lat=${lat}&lng=${lng}`;
  const clockoutDataModel = {
    ClockOutValue: clockOutValue,
    TimeClockBreak: null,
    BreakStart: null,
  };

  return (await apiHelper.post(
    user,
    baseUrl,
    clockoutDataModel
  )) as SwitchPositionOrTagResponse;
}

// ----------------------------------------------------------------------------------------------
async function startBreak(
  user: ApplicationUser,
  clockEvent: ScheduleEvent,
  shiftEvent: ScheduleEvent | null,
  breakType: BreakType,
  lat: number | undefined,
  lng: number | undefined,
  breakStart?: string
) {
  log("api_timeClock_startBreak");
  const baseUrl = `/api/v2/employee/timeclock/startbreak?lat=${lat}&lng=${lng}`;
  const breakStartOptions = {
    clockEventId: clockEvent.ScheduleEventId,
    shiftEventId: shiftEvent ? shiftEvent!.ScheduleEventId : null,
    shiftBreakStart: breakStart,
    breakType,
  };

  return (await apiHelper.post(
    user,
    baseUrl,
    breakStartOptions
  )) as StartBreakResponse;
}

// ----------------------------------------------------------------------------------------------
async function endBreak(
  user: ApplicationUser,
  clockEvent: ScheduleEvent,
  shiftEvent: ScheduleEvent | null,
  lat: number | undefined,
  lng: number | undefined
) {
  log("api_timeClock_endBreak");
  const shiftEventId = shiftEvent ? shiftEvent.ScheduleEventId : "";
  // eslint-disable-next-line max-len
  const baseUrl =
    shiftEvent !== null
      ? `/api/v2/employee/timeclock/endbreak/${clockEvent.ScheduleEventId}/${shiftEventId}?lat=${lat}&lng=${lng}`
      : `/api/v2/employee/timeclock/endbreak/${clockEvent.ScheduleEventId}/-1?lat=${lat}&lng=${lng}`;

  return (await apiHelper.post(user, baseUrl, {})) as EndBreakResponse;
}

// ----------------------------------------------------------------------------------------------
async function getGeoLocations(user: ApplicationUser) {
  log("api_timeClock_getGeoLocations");
  const baseUrl = "/api/v2/employee/timeclock/geolocations";
  return (await apiHelper.get(user, baseUrl)) as ClockInGeoLocations[];
}

export const timeClockApi = {
  getTimeClockSettings,
  clockIn,
  clockOut,
  switchPositionOrTag,
  startBreak,
  endBreak,
  getGeoLocations,
};
