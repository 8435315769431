import React, {MutableRefObject, useContext, useState} from "react";
import {
  IonButton,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonLabel,
  IonModal,
} from "@ionic/react";
import "../global-styles.scss";
import { add } from "ionicons/icons";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import { authHelper } from "../util/authHelper";
import EditEventModal from "./EditEvent/EditEventModal";
import TimeClockModal from "./TimeClock/TimeClockModal";
import EditDailyNoteModal from "./EditDailyNote/EditDailyNoteModal";
import CreateMessageModal from "./CreateMessage/CreateMessageModal";
import { INITIAL_EDITOR_VALUES } from "./EditEvent/EditEventModalState";
import { Role } from "../enums/Role";
import { ApplicationUserContext } from "../context/ApplicationUserContext";

export interface FabProps {
  fabRef: MutableRefObject<any> | null;
  onFabModalClosed: (
    updateMessages: boolean,
    updateEventsOrNotes: boolean,
    toastMessage?: string
  ) => void;
}

// ------------------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------------------
const FabButton: React.FC<FabProps> = ({ onFabModalClosed, fabRef }: FabProps) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showTimeClockModal, setShowTimeClockModal] = useState(false);
  const [showEditDailyNoteModal, setShowEditDailyNoteModal] = useState(false);
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);

  const fabStyle = (user.fabLocation === "left") ? { left: "24px"} : {};
  const fabListStyle = (user.fabLocation === "left") ? {left: "110px"} : {};

  // ------------------------------------------------------------------------------------------------
  function onEditEventModalDismissed(toastMessage?: string) {
    setShowEventModal(false);
    onFabModalClosed(false, toastMessage !== undefined, toastMessage);
  }

  // ------------------------------------------------------------------------------------------------
  function onTimeClockModalDismissed(toastMessage?: string) {
    setShowTimeClockModal(false);
    onFabModalClosed(false, toastMessage !== undefined, toastMessage);
  }

  // ------------------------------------------------------------------------------------------------
  function onEditDailyNoteModalDismissed(toastMessage: string) {
    setShowEditDailyNoteModal(false);
    onFabModalClosed(false, toastMessage.length > 0, toastMessage);
  }

  // ------------------------------------------------------------------------------------------------
  function onCreateMessageModalDismissed(toastMessage?: string) {
    setShowCreateMessageModal(false);
    onFabModalClosed(toastMessage !== undefined, false, toastMessage);
  }

  return (
    <>
      <IonFab
        id="fab"
        ref={fabRef}
        horizontal={user.fabLocation === "left" ? "start" : "end"}
        style={fabStyle}
      >
        <IonFabButton className="fab-button">
          <IonIcon icon={add} className="fab-icon" />
        </IonFabButton>
        <IonFabList
          side="top"
          className="fab-list"
          style={fabListStyle}
        >
          <div>
            {authHelper.canCreateMessage(auth) && (
              <IonButton
                mode="ios"
                onClick={() => setShowCreateMessageModal(true)}
              >
                <IonIcon src="assets/new-message-solid.svg" />
                <IonLabel>New Message</IonLabel>
              </IonButton>
            )}

            {authHelper.canAddNote(auth) && (
              <>
                <IonButton
                  mode="ios"
                  onClick={() => setShowEditDailyNoteModal(true)}>
                  <IonIcon src="assets/daily-note-solid.svg" />
                  <IonLabel>Daily Notes</IonLabel>
                </IonButton>
                <br />
              </>
            )}

            <IonButton
              mode="ios"
              onClick={() => setShowEventModal(true)}>
              <IonIcon src="assets/new-event-solid.svg" />
              <IonLabel>
                {auth.role === Role.Employee ? "New Request" : "New Event"}
              </IonLabel>
            </IonButton>
            <br />

            {auth.timeClock && authHelper.hasAnyPosition(auth) && (
              <IonButton
                mode="ios"
                onClick={() => setShowTimeClockModal(true)}>
                <IonIcon src="assets/timeclock-solid.svg" />
                <IonLabel>Time Clock</IonLabel>
              </IonButton>
            )}
          </div>
        </IonFabList>
      </IonFab>

      <IonModal
        mode="md"
        isOpen={showEventModal}
        onDidDismiss={() => setShowEventModal(false)}
      >
        <EditEventModal
          isOpen={showEventModal}
          auth={auth}
          onCancelModal={() => setShowEventModal(false)}
          onDismissModal={onEditEventModalDismissed}
          scheduleEventId={null}
          initialEvent={{ ...INITIAL_EDITOR_VALUES }}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showTimeClockModal}
        cssClass="mini-mdal add-break-modal"
        onDidDismiss={() => setShowTimeClockModal(false)}
      >
        <TimeClockModal
          onDismissModal={onTimeClockModalDismissed}
          isOpen={showTimeClockModal}
          auth={auth}
          timeclockSettings={null}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showEditDailyNoteModal}
        onDidDismiss={() => setShowEditDailyNoteModal(false)}
      >
        <EditDailyNoteModal
          isOpen={showEditDailyNoteModal}
          note={null}
          initialDate={new Date()}
          onCloseModal={onEditDailyNoteModalDismissed}
          newNoteClicked={true}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showCreateMessageModal}>
        <CreateMessageModal
          onCloseModal={onCreateMessageModalDismissed}
          isOpen={showCreateMessageModal}
        />
      </IonModal>
    </>
  );
};

export default FabButton;
