import React, { useState } from "react";
import {
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonContent,
  IonFooter,
  IonInput,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "../../global-styles.scss";
import { close } from "ionicons/icons";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";

interface ClockoutModalProps {
  onCancel: () => void;
  onOk: (clockoutValue?: number) => void;
  locSettings: LocationSettings;
}

// --------------------------------------------------------------------------------------
const ClockoutModal: React.FC<ClockoutModalProps> = ({
  onCancel,
  onOk,
  locSettings,
}: ClockoutModalProps) => {
  const [clockoutValue, setClockoutValue] = useState(
    undefined as number | undefined
  );

  // --------------------------------------------------------------------------------------
  const onOkClick = () => {
    onOk(clockoutValue);
  };

  // --------------------------------------------------------------------------------------
  function onClockoutValueUpdate(val: string | undefined | null) {
    if (!val) {
      if (clockoutValue !== undefined) {
        setClockoutValue(undefined);
      }

      return;
    }

    setClockoutValue(parseFloat(val));
  }

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Clock Out</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onCancel}>
              <IonIcon icon={close} mode="ios" slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="label" size="5">
              {locSettings.ClockOutValueLabel}
            </IonCol>
            <IonCol size="7">
              <IonInput
                placeholder="Enter value"
                type="number"
                className="input"
                autoCorrect="off"
                clearInput={true}
                value={clockoutValue?.toString()}
                onIonChange={(e) => {
                  onClockoutValueUpdate(e.detail.value);
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="block"
          onClick={onOkClick}
          className="clockout-button"
        >
          CLOCK OUT
        </IonButton>
      </IonFooter>
    </>
  );
};

export default ClockoutModal;
