import React from "react";
import "../../global-styles.scss";
import Skeleton from "react-loading-skeleton";

interface EventCardSkeletonProps {
  count: number;
}

interface MessageCardSkeletonProps {
  count: number;
}

export const EventCardSkeleton: React.FC<EventCardSkeletonProps> = ({
  count,
}: EventCardSkeletonProps) => {
  const items = [];

  items.push(
    <div className="event-list-header" key={-1} >
      <Skeleton height={18} width={"30%"} />
    </div>
  );

  for (let i = 0; i < count; i++) {
    items.push(
      <div className="card" key={i} >
        <div className="card-outer" style={{ paddingLeft: "0px" }}>
          <Skeleton height={28} width={"96%"} />
        </div>
      </div>
    );
  }

  return <> {items} </>;
};

export const MessageCardSkeleton: React.FC<MessageCardSkeletonProps> = ({
  count,
}: MessageCardSkeletonProps) => {
  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(
      <div className="message" key={i}>
        <Skeleton height={31} width={"100%"} />
      </div>
    );
  }

  return <> {items} </>;
};
