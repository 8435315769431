import {useState, useCallback} from "react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {ColumnApi, GridApi} from "ag-grid-community";

// --------------------------------------------------------------------------------------
export function useAgGrid() {
  const [gridApi, setGridApi] = useState(null as GridApi | null);
  const [columnApi, setColumnApi] = useState(null as ColumnApi | null);
  const onGridReady = useCallback(
    (params) => {
      const { api, columnApi } = params;
      setGridApi(api);
      setColumnApi(columnApi);
    },
    []
  );
  return {
    onGridReady,
    columnApi,
    gridApi
  };
}
