import React, { useState, useEffect, useContext } from "react";
import { ApplicationUserContext } from "./ApplicationUserContext";
import {messageApi} from "../api/messageApi";
import { useInterval } from "../hooks/useInterval";
import { getConstants } from "../appFunctions/environment";

export interface IMessagesState {
  unreadMessagesCount: number;
  unreadMessageCountWhenViewed: number;

}

export interface IMessagesContext extends IMessagesState {
  refreshCount: () => void;
  messagesViewed: () => void;
  hasNewMessages: boolean;
}

const INITIAL_MESSAGES_CONTEXT: IMessagesContext = {
  unreadMessagesCount: 0,
  unreadMessageCountWhenViewed: 0,
  refreshCount: () => {},
  messagesViewed: () => {},
  hasNewMessages: false
};

export const MessagesContext = React.createContext<IMessagesContext>(
  INITIAL_MESSAGES_CONTEXT
);

const COUNT_STORAGE_KEY = "unreadMessageCountWhenViewed";

// ------------------------------------------------------------------------------------
function getCountFromStorage(): number {
  const fromStorageCount = localStorage.getItem(COUNT_STORAGE_KEY);
  if (fromStorageCount !== null) {
    return parseInt(fromStorageCount);
  }

  return 0;
}

// ------------------------------------------------------------------------------------
function putCountIntoStorage(cnt: number) {
  localStorage.setItem(COUNT_STORAGE_KEY, cnt.toString());
}


// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export const MessagesContextProvider = (props: any) => {
  const { user } = useContext(ApplicationUserContext);
  const [messagesState, internalSetMessagesState] = useState<IMessagesState>({
    unreadMessagesCount: 0,
    unreadMessageCountWhenViewed: 0
  });

  // ------------------------------------------------------------------------------------
  function refreshCount() {
    getUnreadCount();
  }

  // ------------------------------------------------------------------------------------
  function messagesViewed() {
    const newViewCount = messagesState.unreadMessagesCount;

    if (newViewCount !== messagesState.unreadMessageCountWhenViewed) {
      internalSetMessagesState({
        ...messagesState,
        unreadMessageCountWhenViewed: newViewCount
      });

      putCountIntoStorage(newViewCount);
    }
  }

  // ------------------------------------------------------------------------------------
  function getUnreadCount() {
    if (user.isLoggedin) {
      messageApi.getUnreadCount(user).then((newUnreadCount) => {

        let newUnreadMessageCountWhenViewed = messagesState.unreadMessageCountWhenViewed;
        if (newUnreadMessageCountWhenViewed === 0) {
          newUnreadMessageCountWhenViewed = getCountFromStorage();
        }

        if (newUnreadCount < newUnreadMessageCountWhenViewed) {
          newUnreadMessageCountWhenViewed = newUnreadCount;
          putCountIntoStorage(newUnreadMessageCountWhenViewed);
        }

        if (newUnreadCount !== messagesState.unreadMessagesCount ||
            newUnreadMessageCountWhenViewed !== messagesState.unreadMessageCountWhenViewed) {
          const newState: IMessagesState = {
            unreadMessageCountWhenViewed: newUnreadMessageCountWhenViewed,
            unreadMessagesCount: newUnreadCount
          };

          internalSetMessagesState(newState);
        }
      });
    }
  }

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (user.isLoggedin) {
      getUnreadCount();
    }
    // eslint-disable-next-line
  }, [user.isLoggedin]);

  // ------------------------------------------------------------------------------------
  useInterval(
    () => {
      getUnreadCount();
    },
    true,
    getConstants(user).checkUnreadMessageCountTimer,
      []
  );

  return (
    <MessagesContext.Provider
      value={{
        refreshCount,
        messagesViewed,
        unreadMessagesCount: messagesState.unreadMessagesCount,
        unreadMessageCountWhenViewed: messagesState.unreadMessageCountWhenViewed,
        hasNewMessages:
          messagesState.unreadMessagesCount > messagesState.unreadMessageCountWhenViewed
      }}
    >
      {props.children}
    </MessagesContext.Provider>
  );
};
