import React, {useContext, useEffect, useState} from "react";
import {IonCheckbox, IonCol, IonDatetime, IonInput, IonLabel, IonRow, IonSelect, IonSelectOption,} from "@ionic/react";
import "../../../global-styles.scss";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {LocationSettings} from "../../../orbital-interfaces/LocationSettings";
import {EventType, RecurrenceType} from "../../../enums";
import {authHelper} from "../../../util/authHelper";
import WeekdayList from "./WeekdayList";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {timeHelper, timeHelper as th} from "../../../util/timeHelper";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";

interface RecurringEventSectionExProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const RecurringEventSectionEx: React.FC<RecurringEventSectionExProps> = ({
 auth,
 editorValues,
 update,
}: RecurringEventSectionExProps) => {
  const { user } = useContext(ApplicationUserContext);
  const [locSettings, setLocSettings] = useState(null as LocationSettings | null);
  const [isVisible, setIsVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    if (editorValues.locationId > 0) {
      setLocSettings(authHelper.getLocSettingsForLocationId(auth, editorValues.locationId));
    } else if (editorValues.locationId === 0) {
      setLocSettings(authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId));
    }
    // eslint-disable-next-line
  }, [editorValues.locationId, editorValues.scheduleEventId]);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    setIsVisible(
      editorValues.eventType === EventType.Shift ||
      editorValues.eventType === EventType.Unavailable
    );
  }, [editorValues.eventType, editorValues.scheduleEventId]);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (!isVisible || editorValues.scheduleEventId === -1) {
      return;
    }

    if (editorValues.recurrenceStart) {
      setIsChecked(true);
    } else {
      if (isChecked) {
      } else {
      }
    }
  }, [
    isChecked,
    isVisible,
    editorValues.recurrenceStart,
    editorValues.scheduleEventId,
  ]);

  // --------------------------------------------------------------------------------------
  function onRepeatingChecked() {
    if (!isChecked) {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: undefined,
        repeatInterval: 1,
        recurrenceType: RecurrenceType.Daily,
        recurrenceEnd: null,
        recurrenceStart: th.formatDateTimeAsMvcString(new Date()),
      });
    } else {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: undefined,
        repeatInterval: undefined,
        recurrenceType: undefined,
        recurrenceEnd: undefined,
        recurrenceStart: undefined,
      });
    }

    setIsChecked(!isChecked);
  }

  // --------------------------------------------------------------------------------------
  function onRecurrenceEndChanged(newValue: string | null | undefined) {
    if (!newValue) {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: undefined,
        recurrenceStart: editorValues.recurrenceStart,
      });
    } else {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: newValue,
        recurrenceStart: editorValues.recurrenceStart,
      });
    }
  }

  // --------------------------------------------------------------------------------------
/*
  function onRecurrenceEndChanged(newVal: boolean) {

    if (newVal) {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: null,
        recurrenceStart: editorValues.recurrenceStart,
      });
    } else {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: undefined,
        recurrenceStart: editorValues.recurrenceStart,
      });
    }
  }
*/


  // --------------------------------------------------------------------------------------
  function onRecurrenceTypeChanged(newValue: string | null | undefined) {
    if (newValue === undefined || newValue === null) {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: undefined,
        recurrenceEnd: editorValues.recurrenceEnd,
        recurrenceStart: editorValues.recurrenceStart,
      });
    } else {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: editorValues.repeatInterval,
        recurrenceType: parseInt(newValue),
        recurrenceEnd: editorValues.recurrenceEnd,
        recurrenceStart: editorValues.recurrenceStart,
      });
    }
  }

  // --------------------------------------------------------------------------------------
  function onRepeatIntervalChanged(newValue: string | null | undefined) {
    if (!newValue) {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: undefined,
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: editorValues.recurrenceEnd,
        recurrenceStart: editorValues.recurrenceStart,
      });
    } else {
      update({
        type: "REPEATING-UPDATED",
        repeatWeekDays: editorValues.repeatWeekdays,
        repeatInterval: parseInt(newValue),
        recurrenceType: editorValues.recurrenceType,
        recurrenceEnd: editorValues.recurrenceEnd,
        recurrenceStart: editorValues.recurrenceStart,
      });
    }

  }

  if (!isVisible) {
    return <span />;
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">(R)epeats</IonLabel>
        </IonCol>

        <IonCol size="4">
          <IonCheckbox
            mode="ios"
            checked={isChecked}
            onClick={onRepeatingChecked}
            disabled={editorValues.isReadonly || editorValues.isConfirmed}
          />
        </IonCol>
      </IonRow>

      {isChecked && (
        <>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Repeat Every</IonLabel>
            </IonCol>
            <IonCol size="3">
              <IonInput
                className="input-textbox"
                disabled={editorValues.isReadonly || editorValues.isConfirmed}
                value={
                  editorValues.repeatInterval
                    ? editorValues.repeatInterval.toString()
                    : undefined
                }
                onIonChange={(e) => onRepeatIntervalChanged(e.detail.value)}
              />
            </IonCol>
            <IonCol size="5">
              <IonSelect
                mode="ios"
                interface="action-sheet"
                className="select"
                value={editorValues.recurrenceType}
                disabled={editorValues.isReadonly || editorValues.isConfirmed}
                onIonChange={(e) => {
                  onRecurrenceTypeChanged(e.detail.value);
                }}
              >
                <IonSelectOption value={RecurrenceType.Daily}>
                  days
                </IonSelectOption>
                <IonSelectOption value={RecurrenceType.Weekly}>
                  weeks
                </IonSelectOption>
                <IonSelectOption value={RecurrenceType.MonthlyByDayOfMonth}>
                  monthly (day of month)
                </IonSelectOption>
                <IonSelectOption value={RecurrenceType.MonthlyByDayOfWeek}>
                  monthly (day of week)
                </IonSelectOption>
              </IonSelect>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Ends</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonDatetime
                mode="ios"
                value={editorValues.recurrenceEnd === null ? undefined : editorValues.recurrenceEnd}
                placeholder={editorValues.recurrenceEnd === null ? "(no end date)" : "(enter end)"}
                className="select"
                displayFormat="M/D/YY, DDD"
                pickerFormat="MMM D YYYY"
                disabled={editorValues.isReadonly || editorValues.isConfirmed}
                min="2005-01-01"
                max="2050-01-01"
                onIonChange={(e) => {
                  onRecurrenceEndChanged(e.detail.value);
                }}
                pickerOptions={{
                  buttons: [
                    {
                      text: "Cancel",
                      handler: () => {}
                    },
                    {
                      text: "No End Date",
                      handler: () => {
                        onRecurrenceEndChanged(null);
                      }
                    },
                    {
                      text: "DONE",
                      handler: (e) => {
                        const newTime = timeHelper.formatDateTimeAsMvcString(
                          new Date(e.year.value, e.month.value - 1, e.day.value))
                        onRecurrenceEndChanged(newTime);
                      }
                    }
                  ]
                }}
              />
            </IonCol>
          </IonRow>

          {editorValues.recurrenceType === RecurrenceType.Weekly && (
            <IonRow>
              <IonCol size="12">
                <WeekdayList
                  locSettings={locSettings}
                  editorValues={editorValues}
                  update={update}
                />
              </IonCol>
            </IonRow>
          )}
        </>
      )}
    </>
  );
};

export default RecurringEventSectionEx;
