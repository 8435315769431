import React, { useState } from "react";
import {
  IonCheckbox,
  IonDatetime,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonGrid,
} from "@ionic/react";
import "../../../global-styles.scss";
import {
  setHours,
  setMinutes,
  parseISO,
  isAfter,
  isBefore,
  addMinutes,
  isSameDay,
} from "date-fns";
import useDialogBox from "../../../hooks/useDialogBox";
import { EventBreak } from "../../../orbital-interfaces/EventBreak";
import { LocationSettings } from "../../../orbital-interfaces/LocationSettings";
import { timeHelper as th } from "../../../util/timeHelper";

interface AddBreakModalProps {
  onCancel: () => void;
  onAdd: (
    startDate: Date,
    endDate: Date,
    isRestricted: boolean,
    isPaid: boolean
  ) => void;
  currentBreaks: EventBreak[];
  eventStart: string;
  eventEnd: string;
  locSettings: LocationSettings;
}

// --------------------------------------------------------------------------------------
const hasOverlap = (start: Date, end: Date, breaks: EventBreak[]) => {
  const startA = start;
  const endA = end;
  let found = false;
  breaks.map((brk) => {
    const startB = addMinutes(parseISO(brk.BreakStart), 3);
    const endB = brk.BreakEnd
      ? addMinutes(parseISO(brk.BreakEnd!), -3)
      : new Date(2200, 1, 1);
    if (startA < endB && endA > startB) {
      found = true;
    }

    return found;
  });
  return found;
};

// --------------------------------------------------------------------------------------
// Used to set the inital start and end time for the break
function getDateWithTimeSpan(dateStr: string, ts: string): Date {
  const date = parseISO(dateStr);
  const hours = th.getHoursFromTimeSpan(ts);
  const minutes = th.getMinutesFromTimeSpan(ts);
  return setMinutes(setHours(date, hours), minutes);
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const AddBreakModal: React.FC<AddBreakModalProps> = ({
  onCancel,
  onAdd,
  currentBreaks,
  eventStart,
  eventEnd,
  locSettings,
}: AddBreakModalProps) => {
  const spansDays = !isSameDay(parseISO(eventStart), parseISO(eventEnd));
  const [startDate, setStartDate] = useState(
    getDateWithTimeSpan(
      eventStart,
      locSettings.EnhancedBreakManagementDefaultStartTime
    )
  );
  const [endDate, setEndDate] = useState(
    getDateWithTimeSpan(
      eventEnd,
      locSettings.EnhancedBreakManagementDefaultEndTime
    )
  );
  const [isRestricted, setIsRestricted] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(true);
  const alert = useDialogBox();

  // --------------------------------------------------------------------------------------
  const onAddClick = () => {
    if (startDate > endDate) {
      alert.showAlert(
        "Invalid Start / End",
        "Break Start cannot come after Break End.",
        () => {}
      );
    } else if (parseISO(eventStart) > startDate) {
      setIsValid(false);
      setErrorMessage("Break Start must be after Event Start");
    } else if (parseISO(eventEnd) < endDate) {
      setIsValid(false);
      setErrorMessage("Break End must be before Event End");
    } else if (hasOverlap(startDate, endDate, currentBreaks)) {
      setIsValid(false);
      setErrorMessage("Pre-existing break overlaps");
    } else {
      onAdd(startDate, endDate, isRestricted, isPaid);
    }
  };

  // --------------------------------------------------------------------------------------
  const onStartUpdate = (newStart: string) => {
    const newStartDate = parseISO(newStart);
    setStartDate(newStartDate);
    if (!isBefore(newStartDate, endDate)) {
      setIsValid(false);
      setErrorMessage("Start must be before End");
    } else if (parseISO(eventStart) > newStartDate) {
      setIsValid(false);
      setErrorMessage("Start before Event begins");
    } else if (hasOverlap(newStartDate, endDate, currentBreaks)) {
      setIsValid(false);
      setErrorMessage("Pre-existing break overlaps");
    } else {
      setIsValid(true);
      setErrorMessage("");
    }
  };

  // --------------------------------------------------------------------------------------
  const onEndUpdate = (newEnd: string) => {
    const newEndDate = parseISO(newEnd);
    setEndDate(newEndDate);

    if (!isAfter(newEndDate, startDate)) {
      setIsValid(false);
      setErrorMessage("End must be after Start");
    } else if (newEndDate > parseISO(eventEnd)) {
      setIsValid(false);
      setErrorMessage("End after Event ends");
    } else if (hasOverlap(startDate, newEndDate, currentBreaks)) {
      setIsValid(false);
      setErrorMessage("Pre-existing break overlaps");
    } else {
      setIsValid(true);
      setErrorMessage("");
    }
  };

  return (
    <>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="label" size="3">
              Start
            </IonCol>
            <IonCol size="9">
              <IonDatetime
                mode="ios"
                className="select"
                displayFormat={spansDays ? "h:mm A, M/D DDD" : "h:mm a"}
                pickerFormat={spansDays ? "h:mm A MMM D" : "h:mm A"}
                min="2005-01-01"
                max="2040-01-01"
                minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                value={startDate.toISOString()}
                onIonChange={(e) => onStartUpdate(e.detail.value!)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="label" size="3">
              End
            </IonCol>
            <IonCol size="9">
              <IonDatetime
                mode="ios"
                className="select"
                displayFormat={spansDays ? "h:mm A, M/D DDD" : "h:mm a"}
                pickerFormat={spansDays ? "h:mm A MMM D" : "h:mm A"}
                min="2005-01-01"
                max="2040-01-01"
                minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                value={endDate.toISOString()}
                onIonChange={(e) => onEndUpdate(e.detail.value!)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="3">
              <IonCheckbox
                mode="ios"
                className="checkbox"
                checked={isRestricted}
                onIonChange={(e) => {
                  setIsRestricted(e.detail.checked);
                }}
              />
            </IonCol>
            <IonCol className="label" size="9">
              Restricted Break
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="3">
              <IonCheckbox
                mode="ios"
                className="checkbox"
                onIonChange={(e) => {
                  setIsPaid(e.detail.checked);
                }}
              />
            </IonCol>
            <IonCol className="label" size="9">
              Paid Break
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12" className="error-message">
              {errorMessage}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter className="alert-button-group">
        <IonButton
        className="modal-alert-button"
        onClick={onCancel}>
          Cancel
        </IonButton>
        <IonButton
          className="modal-alert-button ok-button"
          onClick={onAddClick}
          disabled={!isValid}
        >
          OK
        </IonButton>
      </IonFooter>
      {alert.alertBoxJsx}
    </>
  );
};

export default AddBreakModal;
