import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "../global-styles.scss";
import env from "../appFunctions/environment";
import {log} from "../util/logger";

interface ErrorShownComponentProps {
  errMessage: string;
}

// ------------------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------------------
const ErrorShownComponent: React.FC<ErrorShownComponentProps> = ({
  errMessage,
}: ErrorShownComponentProps) => {

  try {
    log("critical_error", { message: errMessage});
  } catch {
    console.log("Log failed");
  }

  return (
    <IonPage>
      <IonContent id="splash">
        <div className="login-content-container">
          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <h2>A critical error occurred.</h2>
            <h4>{errMessage}</h4>
          </div>

          <footer>
            {env.settings.appVersionString} ({env.settings.buildDate})
          </footer>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ErrorShownComponent;
