import React, { useEffect, useState } from "react";
import { IonLabel, IonCheckbox, IonCol, IonIcon } from "@ionic/react";
import "./EventStartEndEditor";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";
import { EventType } from "../../../enums";
import { time } from "ionicons/icons";

interface EventIsClockedInEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventIsClockedInEditor: React.FC<EventIsClockedInEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventIsClockedInEditorProps) => {
  const [isClockedIn, setIsClockedIn] = useState(false);
  const [showIsClockedIn, setShowIsClockedIn] = useState(false);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    if (auth && auth.locations && auth.locations.length > 0) {
      setIsClockedIn(editorValues.isClockedIn);
    }

    // eslint-disable-next-line
  }, [
    auth,
    editorValues.eventStart,
    editorValues.eventEnd,
    editorValues.isClockedIn,
    editorValues.scheduleEventId,
  ]);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    setShowIsClockedIn(editorValues.eventType === EventType.Clock);
  }, [
    editorValues.eventType,
    editorValues.eventStatus,
    editorValues.scheduleEventId,
  ]);

  // -----------------------------------------------------------------------------------
  const onCheckChange = (checked: boolean) => {
    if (checked === isClockedIn || !showIsClockedIn) {
      return;
    }

    setIsClockedIn(checked);
    update({
      type: "ISCLOCKEDIN-UPDATED",
      isClockedIn: checked,
    });
  };

  if (!showIsClockedIn) {
    return <></>;
  }

  return (
    <>
      <IonCol size="4">
        <IonLabel className="label">Clocked In</IonLabel>
      </IonCol>

      <IonCol size="4">
        <IonCheckbox
          mode="ios"
          checked={isClockedIn}
          onIonChange={(e) => {
            onCheckChange(e.detail.checked);
          }}
        />
        <IonIcon className="icon-right-of-checkbox" icon={time} />
      </IonCol>
    </>
  );
};

export default EventIsClockedInEditor;
