export const arrayHelper = {
  // ------------------------------------------------------------------------------------
  areEqual: <T extends {}>(a1: T[], a2: T[]): boolean => {
    if ((!a1 && a2) || (a1 && !a2)) {
      return false;
    }

    if (!a1 && !a2) {
      return true;
    }

    if (a1.length !== a2.length) {
      return false;
    }

    for (let i = 0; i < a1.length; i++) {
      if (!arrayHelper.contains(a2, a1[i])) {
        return false;
      }
    }

    return true;
  },

  // ------------------------------------------------------------------------------------
  contains: <T extends {}>(arr: T[], val: T): boolean => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === val) {
        return true;
      }
    }
    return false;
  },

  containsAny: <T extends {}>(arr1: T[], arr2: T[]): boolean => {
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        return true;
      }
    }

    return false;
  },

  // ------------------------------------------------------------------------------------
  unique: <T extends {}>(arr: T[]): T[] => {
    const newArr: T[] = [];
    // eslint-disable-next-line
    arr.map((item) => {
      if (!arrayHelper.contains(newArr, item)) {
        newArr.push(item);
      }
    });

    return newArr;
  },

  // ------------------------------------------------------------------------------------
  uniqueObject: <T extends {}>(
    arr: T[],
    filt: (o: T, n: T) => boolean
  ): T[] => {
    const newArr: T[] = [];
    // eslint-disable-next-line
    arr.map((item) => {
      let found = false;
      for (let i = 0; i < newArr.length; i++) {
        if (filt(newArr[i], item)) {
          found = true;
          break;
        }
      }

      if (!found) {
        newArr.push(item);
      }
    });

    return newArr;
  },
};


