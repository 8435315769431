import { IonButton, IonButtons, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import React, {useEffect, useState, useContext} from "react";
import { arrowBackOutline } from "ionicons/icons";
import ToggleListEx from "../components/ToggleListEx";
import { PunchClockDeviceData } from "../orbital-interfaces/PunchClockDeviceData";
import useDialogBox from "../hooks/useDialogBox";
import useSimpleToast from "../hooks/useSimpleToast";
import { IdName } from "../orbital-interfaces/IdName";
import { punchClockApi, PunchClockUpdateModel } from "../api/punchClockApi";
import { isApiError } from "../orbital-interfaces/ApiErrorResult";
import {Device} from '@capacitor/device';
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import { initialPunchClockDeviceData } from './../orbital-interfaces/PunchClockDeviceData';
import { PunchClockDeviceEnableResponse } from "../enums";
import PunchClockEnterLoginModal from "./PunchClockEnterLoginModal";
import { add, isAfter } from "date-fns";

interface PunchClockSettingsModalProps {
  onDismissModal: (toastMessage: string, newSettings: PunchClockDeviceData) => void;
  onCancelModal: () => void;
  isOpen: boolean;
}

// --------------------------------------------------------------------------------------
class cloneable {
  public static deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
            o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }
}

// --------------------------------------------------------------------------------------
function shouldReEnterCredentials(currentTime: Date, lastLoginTime: Date) {
  const overTime = add(lastLoginTime, { seconds: 60 });
  return (isAfter(currentTime, overTime));
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const PunchClockSettingsModal: React.FC<PunchClockSettingsModalProps> = ({
  onCancelModal,
  onDismissModal
}) => {
  const { user } = useContext(ApplicationUserContext);
  const [ punchClockDeviceData, setPunchClocDeviceData ] = useState(initialPunchClockDeviceData);

  const [ form, setForm ] = useState<PunchClockDeviceData>(initialPunchClockDeviceData);
  const [ buttonEnabled, setButtonEnabled ] = useState(false);
  const { alertBoxJsx, showAlert } = useDialogBox();
  const { showToast, simpleToastJsx } = useSimpleToast();
  const [ deviceId, setDeviceId ] = useState("");
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showEnterLoginModal, setShowEnterLoginModal ] = useState(false);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    let mount = true;

    if (!deviceId || deviceId.length === 0)
    {
      Device.getId().then(pluginDeviceId => {
        if (mount) {
          setDeviceId(pluginDeviceId.uuid);
        }
      });
    }

    return () => {
      mount = false;
    }
  }, [deviceId, setDeviceId]);

  // -----------------------------------------------------------------------------------
  useEffect(() => {
    let mount = true;

    if (mount) {
      setForm(punchClockDeviceData);
    }

    return () => {
      mount = false;
    }
  }, [ punchClockDeviceData ]);

  // -------------------------------------------------------------------------------
  useEffect(() => {
    let mounted = true;

    if (!isLoading) {
      setIsLoading(true);
    }

    punchClockApi.getPunchClockDeviceData(user).then(result => {
      if (isApiError(result)) {
        console.error("ERROR:" + result.error);
      } else {
        if (mounted) {
          setIsLoading(false);
          setPunchClocDeviceData(result);
        }
      }
    });

    return () => {
      mounted = false;
    }

    // eslint-disable-next-line
  }, [user]);

  // -------------------------------------------------------------------------------
  useEffect(() => {
    const nowTime = new Date();
    let mounted = true;

    if (shouldReEnterCredentials(nowTime, user.loggedInDate) && mounted)
    {
      setShowEnterLoginModal(true);
    }

    return () => {
      mounted = false;
    }
  }, [user]);


  // -----------------------------------------------------------------------------------
  const onCancel = () => {
    if (punchClockDeviceData.response === PunchClockDeviceEnableResponse.DeviceRegistered) {
      onCancelModal();
    }
  }

  // --------------------------------------------------------------------------------------
  const onSelectionChanged = (newSelected: IdName[]) => {
    const newForm = cloneable.deepCopy(form);
    for(let i = 0 ; i < newForm.locations.length; i++) {
      const thisLoc = newForm.locations[i];
      thisLoc.isActive = newSelected.filter(loc => loc.id === thisLoc.locationId).length > 0;
    }

    setButtonEnabled(newForm.locations.filter(l => l.isActive).length > 0 && newForm.deviceName?.length > 0);
    setForm(newForm);
  };

  // --------------------------------------------------------------------------------------
  const onDeviceNameUpdate = (newName?: string | null) => {
    if (!newName) {
      newName = "";
    }

    if (newName !== form.deviceName) {
      const newForm = cloneable.deepCopy(form);
      newForm.deviceName = newName;

      setButtonEnabled(newForm.locations.filter(l => l.isActive).length > 0 && newForm.deviceName?.length > 0);
      setForm(newForm);
    }
  };

  // --------------------------------------------------------------------------------------
  const onUpdateClick = () => {
    const updateData = {
      deviceId,
      deviceName: form.deviceName,
      locationIds: form.locations.filter(loc => loc.isActive).map((loc) => { return loc.locationId})
    } as PunchClockUpdateModel;

    punchClockApi.setPunchClockDeviceData(user, updateData).then((resp) => {
      if (isApiError(resp)) {
        showAlert("Error updating Punch Clock", resp.message, () => {});
      } else {
        setPunchClocDeviceData(resp);
        showToast("Updated " + form.deviceName);
        onDismissModal("Updated " + form.deviceName, resp);
      }
    })
  };

  const allLocations = form.locations.map((l) => { return { id: l.locationId, name: l.name}; });
  const selLocations = form.locations
    .filter((l) => {return l.isActive})
    .map((l) => { return { id: l.locationId, name: l.name}; });

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="punchclock-settings">
        <IonGrid>
          <IonRow>
            <IonLabel className="punchclock-settings-label">Device Name</IonLabel>
          </IonRow>
          <IonRow>
            <IonInput
              type="text"
              className="input-textbox"
              disabled={false}
              value={form.deviceName}
              onIonChange={(e) => onDeviceNameUpdate(e.detail.value)}
            />
          </IonRow>
          <IonRow>
            <IonLabel className="punchclock-settings-label">Locations</IonLabel>
          </IonRow>
          <IonRow>
            <ToggleListEx
              list={allLocations}
              selected={selLocations}
              selectionChanged={onSelectionChanged}
              hideAllToggle={true}
            />
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonButton
              color="secondary"
              onClick={onUpdateClick}
              disabled={!buttonEnabled}
            >
              Update
            </IonButton>
          </IonRow>
          <IonRow>
            <IonButton routerLink="/login">
              Logout
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonFooter>

      <IonModal
        mode="md"
        isOpen={showEnterLoginModal}
        cssClass="reenter-login-modal"
        backdropDismiss={false}
        onDidDismiss={() => {
        }}
      >
        <PunchClockEnterLoginModal
          isOpen={showEnterLoginModal}
          onCancelModal={() => {
            setShowEnterLoginModal(false);
            onCancel();
          }}
          onDismissModal={(msg: string, userChanged: boolean) => {
            setShowEnterLoginModal(false);
            if (userChanged) {
              showToast("Logged in");
            }
          }}
        />
      </IonModal>


      {alertBoxJsx}
      {simpleToastJsx}

    </>
  );
};

export default PunchClockSettingsModal;
