import { EventBreak } from "../orbital-interfaces/EventBreak";
import { EventInformation } from "../orbital-interfaces/TEC";
import { ScheduleEvent } from "../orbital-interfaces/ScheduleEvent";
import {
  addDays, addHours,
  differenceInDays,
  differenceInMinutes,
  format,
  isSameDay,
  isSameYear,
  isToday,
  parseISO,
} from "date-fns";
import {LocationSettings} from "../orbital-interfaces/LocationSettings";
import {TimeFormat} from "../enums";
import { OrbitalTime } from "../orbital-interfaces/OrbitalTime";
import {scheduleEventHelper} from "./scheduleEventHelper";
import {toDate, format as tzFormat} from "date-fns-tz";

const noEndTime = new Date(9000, 1, 1);

// ---------------------------------------------------------------------------------------------
function isScheduleEvent(ev: EventInformation | ScheduleEvent): ev is ScheduleEvent {
  return (ev as ScheduleEvent).BusinessId !== undefined;
}

// ---------------------------------------------------------------------------------------------
function convertTimeWithoutTimeZoneToLocalTime(dtStr: string): string {
  // eslint-disable-next-line
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const newDate = toDate(dtStr, { timeZone: localTimeZone});
  const dateOnlyStr = tzFormat(newDate,'yyyy-MM-dd', { timeZone: localTimeZone});
  const timeOnlyStr = tzFormat(newDate,'HH:mm:ssXXX', { timeZone: localTimeZone});
  return dateOnlyStr + "T" + timeOnlyStr;
}

// ---------------------------------------------------------------------------------------------
function getCurrentLocationDateTime(loc: LocationSettings): Date {
  const now = new Date();
  const utcDateStr = now.toISOString().replace("Z", "");
  return addHours(parseISO(utcDateStr), loc.TimeZoneOffset);
}

// ---------------------------------------------------------------------------------------------
function getDurationFromTimeSpan(timespan: string) {
  if (!timespan) {
    return 0;
  }

  const tok = timespan.split(":");
  let hrs: number;
  let min: number;
  if (tok[0].indexOf(".") >= 0) {
    const hrTok = tok[0].split(".");
    hrs = 24 * parseInt(hrTok[0]) + parseInt(hrTok[1]);
    min = parseInt(tok[1]);
  } else {
    hrs = parseInt(tok[0]);
    min = parseInt(tok[1]);
  }

  return hrs * 60 + min;
}

// ---------------------------------------------------------------------------------------------
function messageDateString(time: string, timezoneOffset: number) {
  const date = addHours(parseISO(time), timezoneOffset);
  const now = new Date();

  if (isToday(date)) {
    return format(date, "h:mmaaa").replace("M", "");
  } else if (differenceInDays(now, date) < 7) {
    return format(date, "EEE");
  } else if (isSameYear(date, now)) {
    return format(date, "MMM d");
  } else {
    return format(date, "M/d/yyyy");
  }
}

// ---------------------------------------------------------------------------------------------
function getTimeStringFromMinutes(
  locSettings: LocationSettings,
  min: number | null
) {
  if (locSettings.TimeFormat === TimeFormat.HoursMinutes) {
    if (min === null) {
      return "00:00";
    }

    const hours = Math.floor(min / 60);
    const minutes = min % 60;

    return `${padZeroes(hours, 2)}:${padZeroes(minutes, 2)}`;
  } else {
    if (min === null) {
      return "0";
    }

    return formatDecimal(
      min / 60,
      locSettings.TimeFormatDigits,
      locSettings.TimeFormatDecimals
    );
  }
}

// ---------------------------------------------------------------------------------------------
function getTimeStringFromOrbitalTime(
  locSettings: LocationSettings,
  time: OrbitalTime
) {
  if (time.ActualTime === "00:00:00") {
    return locSettings.TimeFormat === TimeFormat.HoursMinutes
      ? "00:00"
      : formatDecimal(
          0,
          locSettings.TimeFormatDigits,
          locSettings.TimeFormatDecimals
        );
  }

  if (locSettings.TimeFormat === TimeFormat.HoursMinutes) {
    const tok = time.ActualTime.split(":");
    if (tok.length !== 3) {
      return time.ActualTime;
    }

    if (tok[0].includes(".")) {
      const dayHourTok = tok[0].split(".");
      const hours = 24 * parseInt(dayHourTok[0]) + parseInt(dayHourTok[1]);
      return `${hours}:${tok[1]}`;
    } else {
      const hours = parseInt(tok[0]);
      return `${hours}:${tok[1]}`;
    }
  } else {
    return formatDecimal(
      time.ActualHours,
      locSettings.TimeFormatDigits,
      locSettings.TimeFormatDecimals
    );
  }
}

// ---------------------------------------------------------------------------------------------
// internal function, only used by getMinutesFromTimeSpan and getHoursFromTimeSpan
function getPartOfTimeSpan(ts: string, partNum: number): number {
  if (ts.length === 0) {
    return 0;
  }

  const tok = ts.split(":");
  if (tok.length !== 3) {
    return 0;  // don't mess with messy strings that aren't TimeSpan objects from the API
  }

  return parseInt(tok[partNum]);
}

// ---------------------------------------------------------------------------------------------
// Note that, if given something like "01:30:00" this will return 30 minutes, not 90 minutes.
function getMinutesFromTimeSpan(ts: string): number {
  return getPartOfTimeSpan(ts, 1);
}

// ---------------------------------------------------------------------------------------------
// Similiar to getMinutesFromTimeSpan, this will return >> 1 << hour form "01:30:00" and not
// 1.5.
function getHoursFromTimeSpan(ts: string): number {
  return getPartOfTimeSpan(ts, 0);
}


// ---------------------------------------------------------------------------------------------
function getDurationStringFromTimeSpan(timespan: string) {
  if (!timespan) {
    return "0:00";
  }

  const tok = timespan.split(":");
  let hrs: number;
  let min: number;
  if (tok[0].indexOf(".") >= 0) {
    const hrTok = tok[0].split(".");
    hrs = 24 * parseInt(hrTok[0]) + parseInt(hrTok[1]);
    min = parseInt(tok[1]);
  } else {
    hrs = parseInt(tok[0]);
    min = parseInt(tok[1]);
  }

  return formatDecimal(hrs, 1, 0) + ":" + formatDecimal(min, 2, 0);
}


/*
function getDurationStringFromTimeSpan(timespan: string) {
  if (!timespan) {
    return "(0m)";
  }
  const tok = timespan.split(":");
  let hrs: number;
  let min: number;
  if (tok[0].indexOf(".") >= 0) {
    const hrTok = tok[0].split(".");
    hrs = 24 * parseInt(hrTok[0]) + parseInt(hrTok[1]);
    min = parseInt(tok[1]);
  } else {
    hrs = parseInt(tok[0]);
    min = parseInt(tok[1]);
  }

  return hrs > 0 ? `(${hrs}h ${min}m)` : `(${min}m)`;
}
*/

// ---------------------------------------------------------------------------------------------
function getDesktopDurationStringFromTimeSpan(timespan: string, locSettings: LocationSettings) {
  const minutes = getDurationFromTimeSpan(timespan);
  return getTimeStringFromMinutes(locSettings, minutes);
}


// ---------------------------------------------------------------------------------------------
function getDurationString(minutes: number): string {
  if (minutes < 60) {
    return `(${minutes}m)`;
  }

  const str = `(${Math.floor(minutes / 60)}h`;
  return minutes % 60 === 0 ? str + ")" : str + ` ${minutes % 60}m)`;
}

// ---------------------------------------------------------------------------------------------
function getDuration(ev: EventInformation): number {
  const getDiff = (start: string, end?: string) => {
    const startDate = parseISO(start);
    let endDate = end ? parseISO(end) : new Date();
    if (endDate > noEndTime) {
      endDate = new Date();
    }
    return differenceInMinutes(endDate, startDate);
  };

  let totalMinutes = getDiff(ev.EventStart, ev.EventEnd);
  if (ev.eventBreaks) {
    ev.eventBreaks.map(
      (b) => (totalMinutes -= getDiff(b.BreakStart, b.BreakEnd))
    );
  }

  return totalMinutes;
}

// ---------------------------------------------------------------------------------------------
function getDateString(date: Date): string {
  return format(date, "EEE MMM d, yyyy").toUpperCase();
}

function getCurrentYear(): string {
  return format(new Date(), "yyyy");
}

// ---------------------------------------------------------------------------------------------
function getDateStringNoYear(date: Date): string {
  return format(date, "EEE MMM d").toUpperCase();
}

// ---------------------------------------------------------------------------------------------
function getMessageDeliveryTimeString(dateStr: string, timezoneOffset: number) {
  return format(addHours(parseISO(dateStr), timezoneOffset), "M/d EEE h:mmaa").replace("M", "");
}

// ---------------------------------------------------------------------------------------------
function getBreakStartEnd(brk: EventBreak) {
  const start = format(parseISO(brk.BreakStart), "h:mmaaa").replace("M", "");

  if (!brk.BreakEnd || brk.BreakEnd > noEndTime) {
    return start;
  }

  const end = format(parseISO(brk.BreakEnd), "h:mmaaa").replace("M", "");
  return `${start} - ${end}`;
}

// ---------------------------------------------------------------------------------------------
function getEventInformationStartEnd(ev: EventInformation | ScheduleEvent) {
  const start = format(parseISO(ev.EventStart), "h:mmaaa").replace("M", "");
  let end: string;

  if (!isScheduleEvent(ev) && ev.isClockedIn) {
    end = format(new Date(),"h:mmaaa").replace("M", "");
  } else  if (isScheduleEvent(ev) && scheduleEventHelper.isClockedIn(ev)) {
    end = format(new Date(), "h:mmaaa").replace("M", "");
  } else {
    end = format(parseISO(ev.EventEnd), "h:mmaaa").replace("M", "");
  }

  return `${start} - ${end}`;
}

// ---------------------------------------------------------------------------------------------
function getTimeStringFromEventInformation(evTime: any) {
  return format(parseISO(evTime.replace("Z", "")), "h:mmaaa")
      .replace("M", "");
}

// ---------------------------------------------------------------------------------------------
function getEventBreakStartEndString(brk: EventBreak): string {
  const start = format(parseISO(brk.BreakStart), "h:mmaa").replace("M", "");
  const end = brk.BreakEnd
    ? format(parseISO(brk.BreakEnd), "h:mmaa").replace("M", "")
    : format(new Date(), "h:mmaa").replace("M", "");

  return `${start} - ${end}`;
}

// ---------------------------------------------------------------------------------------------
function getWeekStart(
  date: Date,
  weekStartsOn: string,
  dayStart: string
): Date {
  const dayStartMinutes =
    !dayStart || dayStart.indexOf("T") < 0
      ? 0
      : getDurationFromTimeSpan(dayStart.split("T")[1]);

  let newDate = date;
  newDate.setHours(0, dayStartMinutes, 0, 0);

  let counter = 0;
  while (format(newDate, "EEEE") !== weekStartsOn && counter < 20) {
    newDate = addDays(newDate, -1);
    counter++;
  }

  if (counter > 15) {
    console.error(
      `Could not calculate week start based on date ${date}` +
        `and weekStartsOn "${weekStartsOn}"`
    );
  }

  return newDate;
}

// ---------------------------------------------------------------------------------------------
function getDayStartMinutes(dayStartLocSetting: string) {
  const tok = dayStartLocSetting.split("T");
  return getDurationFromTimeSpan(tok[1]);
}

// ---------------------------------------------------------------------------------------------
// For the date/time passed in (usually new Date() to get "now"), this function returns the
// start of the time as specified by the DayStartTime in location settings.  If, for example,
// it is 2 am on January 5th, and loc settings has DayStartTime set to 4:00am, then
// this function would return January 4th at 4:00am, instead of January 5th.
function getTodayStartForLocation(date: Date, locSettings: LocationSettings): Date {
  const tmpDate = new Date(date.valueOf());
  const dayStartMinutes = getDayStartMinutes(locSettings.DayStartTime);
  tmpDate.setMinutes(-1*dayStartMinutes, tmpDate.getSeconds(), tmpDate.getMilliseconds());
  const needPreviousDate = !isSameDay(tmpDate, date);

  const startDate = new Date(date.valueOf());
  if (needPreviousDate) {
    startDate.setHours(-24, dayStartMinutes, 0, 0);
  } else {
    startDate.setHours(0, dayStartMinutes, 0, 0);
  }

  return startDate;
}

// ---------------------------------------------------------------------------------------------
function formatDateTimeAsMvcString(d: Date) {
  return (
    "" +
    d.getFullYear() +
    "-" +
    padZeroes(d.getMonth() + 1, 2) +
    "-" +
    padZeroes(d.getDate(), 2) +
    "T" +
    padZeroes(d.getHours(), 2) +
    ":" +
    padZeroes(d.getMinutes(), 2) +
    ":00"
  );
}

// ---------------------------------------------------------------------------------------------
function formatDecimal(num: number, digits: number, decimals: number) {
  let val = Number(num).toFixed(decimals).toString();

  let negative = "";
  if (val.indexOf("-") === 0) {
    val = val.substring(1);
    negative = "-";
  }

  const s = "0000000000" + val;
  const decPos = val.indexOf(".");
  if (decPos === -1) {
    return negative + s.substr(s.length - Math.max(val.length, digits));
  } else {
    return (
      negative +
      s.substr(s.length - Math.max(val.length, digits + decimals + 1))
    );
  }
}

// ---------------------------------------------------------------------------------------------
function padZeroes(val: any, digitCount: number) {
  const s = "0000000000" + val;
  return s.substr(s.length - digitCount);
}

export const timeHelper = {
  convertTimeWithoutTimeZoneToLocalTime,
  formatDateTimeAsMvcString,
  getBreakStartEnd,
  getCurrentLocationDateTime,
  getCurrentYear,
  getDateString,
  getDateStringNoYear,
  getDesktopDurationStringFromTimeSpan,
  getDuration,
  getDurationFromTimeSpan,
  getDurationString,
  getDurationStringFromTimeSpan,
  getEventBreakStartEndString,
  getEventInformationStartEnd,
  getHoursFromTimeSpan,
  getMessageDeliveryTimeString,
  getMinutesFromTimeSpan,
  getTimeStringFromEventInformation,
  getTimeStringFromMinutes,
  getTimeStringFromOrbitalTime,
  getTodayStartForLocation,
  getWeekStart,
  messageDateString,
}
