export enum PunchClockDeviceEnableResponse {
  Ok = 0,

  DeviceRegistered = 1,

  UserUnauthorizedToEnablePunchClock = 2,

  NoLocationsHavePunchClockEnabled = 3,

  NoLocationsFoundEnabledForUser = 4,

  OneOrMoreLocationsAreNotAdministeredByUser = 5

}