import React, { useContext } from "react";
import { IonIcon, IonLabel } from "@ionic/react";
// import "./MainTabs.scss";
import "../../global-styles.scss";
import {MessagesContext} from "../../context/MessagesContext";

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const MessagesTabButton: React.FC = () => {
  const { unreadMessagesCount, hasNewMessages } = useContext(MessagesContext);

  return (
    <>
      <div className={hasNewMessages ? "hasMessages" : "noMessages"}>
        {unreadMessagesCount}
      </div>
      <IonIcon src="assets/messages-solid.svg" className="main-footer-svgs"/>
      <IonLabel>Messages</IonLabel>
    </>
  );
};

export default MessagesTabButton;
