import {IdName} from "../orbital-interfaces/IdName";

export enum EventStatus {
  Approved = 1,
  Request = 2,
  RequestDenied = 4,
}

export const eventStatusIdList = [
  { id: 1, name: "Approved"},
  { id: 2, name: "Request"},
  { id: 4, name: "Request Denied"},
] as IdName[];


export function getEventStatusIdName(eventStatus: EventStatus): IdName {
  const idName =  eventStatusIdList.find(et => et.id === eventStatus);
  if (idName === undefined) {
    throw new Error("getEventStatusIdName: Unknown event status: " + eventStatus);
  }

  return idName;
}

