import { useEffect, useRef } from "react";

export function useInterval(
  callback: () => void,
  isRunning: boolean,
  delay: number,
  dependencies: any[],
) {
  const savedProps = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedProps.current = callback;
  }, [callback, dependencies]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedProps.current && isRunning) {
        savedProps.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, isRunning, dependencies]);
}
