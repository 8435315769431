import React, {useEffect, useState} from "react";
import {IonButton, IonCol, IonIcon, IonLabel, IonModal, IonRow,} from "@ionic/react";
import "../../../global-styles.scss";
import {add, lockClosed, logoUsd, trash} from "ionicons/icons";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {EventBreak} from "../../../orbital-interfaces/EventBreak";
import {timeHelper as th} from "../../../util/timeHelper";
import AddBreakModal from "./AddBreakModal";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {EventStatus, EventType} from "../../../enums";
import {authHelper} from "../../../util/authHelper";
import {scheduleEventHelper} from "../../../util/scheduleEventHelper";

interface EventBreakEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventBreakEditor: React.FC<EventBreakEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventBreakEditorProps) => {
  const [breakList, setBreakList] = useState([] as EventBreak[]);
  const [showBreakModal, setShowBreakModal] = useState(false);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    setBreakList(editorValues.eventBreaks);
  }, [editorValues.eventBreaks, editorValues.scheduleEventId]);

  // --------------------------------------------------------------------------------------
  const showRestricted = (b: EventBreak) => {
    return b.IsRestricted ? (
      <IonIcon className="restricted-icon" icon={lockClosed} />
    ) : (
        <span />
      );
  };

  // --------------------------------------------------------------------------------------
  const showPaid = (b: EventBreak) => {
    return b.IsPaidBreak ? (
      <IonIcon className="paid-icon" icon={logoUsd} />
    ) : (
        <span />
      );
  };

  // --------------------------------------------------------------------------------------
  const onAddBreakModalCancel = () => {
    setShowBreakModal(false);
  };

  // --------------------------------------------------------------------------------------
  const onAddBreakModalApply = (
    startDate: Date,
    endDate: Date,
    isRestricted: boolean,
    isPaid: boolean
  ) => {
    setShowBreakModal(false);
    const brk: EventBreak = {
      BreakStart: th.formatDateTimeAsMvcString(startDate),
      BreakEnd: th.formatDateTimeAsMvcString(endDate),
      IsRestricted: isRestricted,
      IsPaidBreak: isPaid,
      ScheduleEventId: editorValues.scheduleEventId,
    };

    const newBreakList = [...breakList, brk];
    setBreakList(newBreakList);
    update({ type: "EVENTBREAKS-UPDATED", breaks: newBreakList });
  };

  // --------------------------------------------------------------------------------------
  const onDeleteBreak = (breakStart: string) => {
    const newBreakList = breakList.filter((b) => b.BreakStart !== breakStart);
    setBreakList(newBreakList);
    update({ type: "EVENTBREAKS-UPDATED", breaks: newBreakList });
  };

  // --------------------------------------------------------------------------------------
  const dismissModal = () => {
    setShowBreakModal(false);
  }


  if (
    editorValues.eventType !== EventType.Clock &&
    (editorValues.eventType !== EventType.Shift && editorValues.eventStatus !== EventStatus.Request) &&
    !scheduleEventHelper.isShiftTypeEvent(
      editorValues.eventType,
      editorValues.eventStatus)
  ) {
    return <div />;
  }

  if (editorValues.eventType === EventType.Absent) {
    return <div />;
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">(B)reaks</IonLabel>
        </IonCol>

        <IonCol size="3">
          <IonButton
            size="small"
            className="add-break-button"
            disabled={editorValues.isReadonly || editorValues.isConfirmed}
            onClick={() => {
              setShowBreakModal(true);
            }}
          >
            <IonIcon slot="icon-only" icon={add} color="dark" />
          </IonButton>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="4">
        </IonCol>
        <IonCol size="8">
          {/* {(!breakList || breakList.length === 0) && (
            <div className="break-div">(B) NONE</div>
          )} */}

          {breakList &&
            breakList.map((brk) => (
              <div key={brk.BreakStart} className="break-div">
                (B) {th.getBreakStartEnd(brk)}
                {showPaid(brk)}
                {showRestricted(brk)}
                <button
                  disabled={editorValues.isReadonly || editorValues.isConfirmed}
                  onClick={() => {
                    onDeleteBreak(brk.BreakStart);
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    mode="ios"
                    icon={trash}
                    color="gray"
                  />
                </button>
              </div>
            ))}
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12">
          <div style={{ borderBottom: "1px solid #ddd", width: "100%" }} />
        </IonCol>
      </IonRow>

      <IonModal
        mode="md"
        isOpen={showBreakModal}
        cssClass="mini-modal"
        onDidDismiss={dismissModal}
        onWillDismiss={dismissModal}
        backdropDismiss={true}
        showBackdrop={true}
      >
        <AddBreakModal
          onCancel={onAddBreakModalCancel}
          onAdd={onAddBreakModalApply}
          currentBreaks={breakList}
          eventStart={editorValues.eventStart}
          eventEnd={editorValues.eventEnd}
          locSettings={authHelper.getLocSettingsForLocationId(auth, editorValues.locationId)}
        />
      </IonModal>
    </>
  );
};

export default EventBreakEditor;
