import React, {useContext} from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar, IonGrid, IonRow, IonCol, IonSelect, IonSelectOption, IonLabel,
  IonSegment, IonSegmentButton,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import {AuthorizedItemsContext} from "../context/AuthorizedItemsContext";
import {ApplicationUserContext} from "../context/ApplicationUserContext";

interface ApplicationSettingsModalProps {
  onDismissModal: () => void;
  isOpen: boolean;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const ApplicationSettingsModal: React.FC<ApplicationSettingsModalProps> = ({
 onDismissModal,
 isOpen,
}: ApplicationSettingsModalProps) => {
  const {auth} = useContext(AuthorizedItemsContext);
  const {
    user,
    actionButtonPositionChanged,
    setDefaultLocation
  } = useContext(ApplicationUserContext);

  // --------------------------------------------------------------------------------------
  function onCancel()  {
    onDismissModal();
  }

  // --------------------------------------------------------------------------------------
  function onActionButtonPositionChange(newValue: string | undefined | null) {
    if ((newValue !== "left" && newValue !== "right") || newValue === user.fabLocation) {
      return;
    }

    actionButtonPositionChanged(newValue);
  }

  // --------------------------------------------------------------------------------------
  function onDefaultLocationChange(newValue: number | undefined | null) {
    if (newValue === undefined || newValue === null || newValue === user.defaultLocationId) {
      return;
    }

    setDefaultLocation(newValue);
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Application Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content">
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Default Location</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                mode="ios"
                className="select"
                interface="action-sheet"
                value={user.defaultLocationId === 0 ? auth.locations[0].id : user.defaultLocationId}
                onIonChange={e => onDefaultLocationChange(e.detail.value)}
              >
                {auth.locations.map((loc) => (
                  <IonSelectOption key={loc.id} value={loc.id}>
                    {loc.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Action Button Position</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSegment
                mode="ios"
                value={user.fabLocation}
                onIonChange={e => onActionButtonPositionChange(e.detail.value)}
              >
                <IonSegmentButton value="left">
                  <IonLabel>Left</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="right">
                  <IonLabel>Right</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>

        </IonGrid>
      </IonContent>

    </>
  );
};

export default ApplicationSettingsModal;
