import React, {useContext, useEffect, useRef, useState} from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {EventFilter, FILTER_ALL_EMPLOYEES,} from "../../orbital-interfaces/EventFilter";
import {arrowBackOutline} from "ionicons/icons";
import "../../global-styles.scss";
import {eventHelper} from "../../util/eventHelper";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {filterStore} from "../../data/filterStore";
import {authHelper} from "../../util/authHelper";
import {EventGroup, EventType, Role } from "../../enums";
import {IdName} from "../../orbital-interfaces/IdName";
import {employeesApi} from "../../api/employeesApi";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import {useShowLoading} from "../../hooks/useShowLoading";
import {
  FilteredRangeType,
  getFilteredRangeTypeFromName,
  getFilteredRangeTypeName
} from "../../enums/FilteredRangeType";
import {isAfter} from "date-fns";
import {timeHelper as th} from "../../util/timeHelper";
import {log} from "../../util/logger";

interface ScheduleFilterModalProps {
  filter: EventFilter;
  auth: AuthorizedItems;
  showRangeFilter: boolean;
  onDismissModal: (f: EventFilter, filterApplied: boolean, needsRefresh: boolean) => void;
  onCancelModal: () => void;
  isOpen: boolean;
}


// --------------------------------------------------------------------------------------
function getInitialPositionSelectionsByFilter(
  auth: AuthorizedItems,
  filter: EventFilter
) {
  const locIds = [] as string[];
  const depIds = [] as string[];
  const posIds = [] as string[];

  if (!authHelper.isAuthItemsValid(auth)) {
    return [[] as string[], [] as string[], [] as string[]];
  }

  auth.locations.map((l) =>
    l.departments.map((d) =>
      d.positions.map((p) => {
        if (filter.positionIds.includes(p.id)) {
          const newLocId = l.id.toString();
          const newDepId = d.id.toString();
          if (!locIds.includes(newLocId)) {
            locIds.push(newLocId);
          }

          if (!depIds.includes(newDepId)) {
            depIds.push(newDepId);
          }

          posIds.push(p.id.toString());
        }

        return p.id;
      })
    )
  );

  return [locIds, depIds, posIds];
}

// --------------------------------------------------------------------------------------
function getInitialEventGroupSelectionByFilter(
  auth: AuthorizedItems,
  filter: EventFilter
): EventType | EventGroup {
  if (
    filter.eventTypeId === EventGroup.All &&
    filter.eventStatusId === EventGroup.All
  ) {
    return EventGroup.All;
  }

  if (
    filter.eventTypeId === EventGroup.ClockAndSched &&
    filter.eventStatusId === EventGroup.ClockAndSched
  ) {
    return EventGroup.ClockAndSched;
  }

  if (
    filter.eventTypeId === EventGroup.DontWork &&
    filter.eventStatusId === EventGroup.DontWork
  ) {
    return EventGroup.DontWork;
  }

  if (
    filter.eventTypeId === EventGroup.Scheduled &&
    filter.eventStatusId === EventGroup.Scheduled
  ) {
    return EventGroup.Scheduled;
  }

  return EventGroup.Custom;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const ScheduleFilterModal: React.FC<ScheduleFilterModalProps> = ({
  auth,
  filter,
  onDismissModal,
  onCancelModal,
  isOpen,
  showRangeFilter,
}: ScheduleFilterModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const [
    initialLocIds,
    initialDepIds,
    initialPosIds,
  ] = getInitialPositionSelectionsByFilter(auth, filter);
  const loading = useShowLoading("Please wait...");
  const [eventGroup, setEventGroup] = useState(
    getInitialEventGroupSelectionByFilter(auth, filter)
  );
  const [myFilter, setMyFilter] = useState(filter);
  const [eventTypeList, setEventTypeList] = useState(
    eventHelper.getEventTypeOptions(auth, user, filter)
  );
  const [locationIds, setLocationIds] = useState(initialLocIds);
  const [departmentIds, setDepartmentIds] = useState(initialDepIds);
  const [positionIds, setPositionIds] = useState(initialPosIds);
  const [allEmployees, setAllEmployees] = useState([] as IdName[]);
  const [isDirty, setIsDirty] = useState(false);
  const empIdRef = useRef(null as number | null);
  const employeeSel = useRef(null as HTMLIonSelectElement | null);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    setMyFilter(filter);
    getEmployees(positionIds);
    log("eventsFilter_openDlg");
    // eslint-disable-next-line
  }, []);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (isOpen) {
      setIsDirty(false);
    }

    // eslint-disable-next-line
  }, [isOpen]);

  // --------------------------------------------------------------------------------------
  const getEmployees = (posIds: string[]) => {
    if (loading.showLoading) {
      return;
    }

    if (auth.role === Role.Employee) {
      setAllEmployees([{id: auth.employeeId, name: auth.fullName}]);
      return;
    }

    const positionIds = posIds.map((p) => parseInt(p));
    loading.setShowLoading(true);
    const locSettings = authHelper.getLocSettingsForLocationId(
      auth,
      user.defaultLocationId
    );

    employeesApi
    .getEmployeesByPositionIdsAndActiveDateRange(
      user,
      positionIds,
      myFilter.start,
      myFilter.end,
      locSettings.EmployeeSort
    )
    .then((res) => {
      loading.setShowLoading(false);
      setAllEmployees(res);

      let newEmployee = myFilter.employee;

      if (myFilter.employee.id !== FILTER_ALL_EMPLOYEES.id) {
        if (res.find((e) => e.id === myFilter.employee.id) === undefined) {
          newEmployee = FILTER_ALL_EMPLOYEES;
          setMyFilter({ ...myFilter, employee: newEmployee });
          setIsDirty(true);
        }
      }

      empIdRef.current = newEmployee.id;
    });
  };

  // --------------------------------------------------------------------------------------
  const onReset = () => {
    setEventGroup(EventGroup.All);
    setEventTypeList(eventHelper.getEventTypeOptions(auth, user, filter));
    setLocationIds(auth.locations.map((l) => "" + l.id));
    setDepartmentIds(
      auth.locations.flatMap((l) => l.departments).map((d) => "" + d.id)
    );

    const newPositionIds = auth.locations
      .flatMap((l) => l.departments)
      .flatMap((d) => d.positions)
      .map((p) => "" + p.id);
    setPositionIds(newPositionIds);
    getEmployees(newPositionIds);

    log("eventsFilter_reset");
    setMyFilter(filterStore.getDefaultFilter(auth, filter));
    setIsDirty(true);
  };

  // --------------------------------------------------------------------------------------
  const onEventGroupSelect = (val: EventGroup) => {
    setEventGroup(val);
    setIsDirty(true);
    setMyFilter(eventHelper.getFilterByEventGroup(myFilter, val));
  };

  // --------------------------------------------------------------------------------------
  const onSetLocations = (locIds: string[]) => {
    setLocationIds(locIds);
    setIsDirty(true);
    setDepartmentIds(
      auth.locations
        .filter((l) => locIds.indexOf(l.id.toString()) >= 0)
        .flatMap((l) => l.departments)
        .map((d) => "" + d.id)
    );
    setEventTypeList(eventHelper.getEventTypeOptions(auth, user, myFilter));
  };

  // --------------------------------------------------------------------------------------
  const onSetDepartmentIds = (deptIds: string[]) => {
    setDepartmentIds(deptIds);
    setIsDirty(true);

    const newPositionIds = auth.locations
      .filter((l) => locationIds.indexOf(l.id.toString()) >= 0)
      .flatMap((l) => l.departments)
      .filter((d) => deptIds.includes(d.id.toString()))
      .flatMap((d) => d.positions)
      .map((p) => "" + p.id);
    setPositionIds(newPositionIds);
    getEmployees(newPositionIds);
  };

  // --------------------------------------------------------------------------------------
  const onSetPositionIds = (posIds: string[]) => {
    setPositionIds(posIds);
    getEmployees(posIds);
    setIsDirty(true);
  };

  // --------------------------------------------------------------------------------------
  const onSetEmployeeId = (newEmployeeId: number | undefined | null) => {
    if (newEmployeeId === undefined || newEmployeeId === null) {
      return;
    }

    if (newEmployeeId === empIdRef.current) {
      return;
    }

    empIdRef.current = newEmployeeId;

    if (newEmployeeId === FILTER_ALL_EMPLOYEES.id) {
      setMyFilter({ ...myFilter, employee: FILTER_ALL_EMPLOYEES });
      setIsDirty(true);
      return;
    }

    const emp = allEmployees.find((e) => e.id === newEmployeeId);
    if (emp === undefined) {
      return;
    }

    setMyFilter({ ...myFilter, employee: emp });
    setIsDirty(true);
  };

  // --------------------------------------------------------------------------------------
  const onSetFilteredRangeType = (newRangeStr: string | undefined | null) => {
    if (newRangeStr === undefined || newRangeStr === null) {
      return;
    }

    // If the user is switching between Week to Day view, AND the current day is within the
    // week that was being viewed, go ahead and set the day to see events for to the current
    // day.  Otherwise it is set to the first day of the week.
    const filteredRangeType = getFilteredRangeTypeFromName(newRangeStr);
    const now = new Date();
    let start = myFilter.start;
    if (filteredRangeType === FilteredRangeType.Day &&
        isAfter(now, myFilter.start) && isAfter(myFilter.end, now)) {
      const locId = user.defaultLocationId ? user.defaultLocationId : auth.locations[0].id;
      const locSettings = authHelper.getLocSettingsForLocationId(auth, locId);
      start = th.getTodayStartForLocation(new Date(), locSettings);
    }

    setMyFilter({ ...myFilter, filteredRangeType, start: start });
    setIsDirty(true);
  }

  // --------------------------------------------------------------------------------------
  const onCloseModal = () => {
    const selectedPositionIds = positionIds.map((p) => parseInt(p));
    const returnedFilter = {
      ...myFilter,
      positionIds: selectedPositionIds,
    };

    if (filter.filteredRangeType !== returnedFilter.filteredRangeType) {
      if (returnedFilter.filteredRangeType === FilteredRangeType.Day) {
        log("eventsFilter_range_day", { rangeType: returnedFilter.filteredRangeType});
      } else {
        log("eventsFilter_range_week", { rangeType: returnedFilter.filteredRangeType});
      }
    }

    const currentFilter = filterStore.getFilter(auth, user);
    const filterApplied = filterStore.isFilterApplied(auth, returnedFilter);
    const needsRefresh = currentFilter.filteredRangeType !== returnedFilter.filteredRangeType;
    if (!filterApplied) {
      log("eventsFilter_reset");
    } else {
      log("eventsFilter_applied", {
        positions: selectedPositionIds.length,
        empId: returnedFilter.employee.id,
        eventType: returnedFilter.eventTypeId,
        eventStatus: returnedFilter.eventStatusId,
        tagId: returnedFilter.tagId,
        filteredRangeType: returnedFilter.filteredRangeType
      });
    }

    onDismissModal(returnedFilter, filterApplied, needsRefresh);

  };

  // --------------------------------------------------------------------------------------
  const onCancel = () => {
    log("eventsFilter_cancelDlg");
    onCancelModal();
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onReset}>RESET</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content">
        <IonGrid>
          {showRangeFilter && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Range</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSegment
                  mode="ios"
                  value={getFilteredRangeTypeName(myFilter.filteredRangeType)}
                  onIonChange={(e) => { onSetFilteredRangeType(e.detail.value) }}
                >
                  <IonSegmentButton value="Day">
                    <IonLabel>Day</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="Week">
                    <IonLabel>Week</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Events</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                mode="ios"
                className="select"
                interface="action-sheet"
                value={eventGroup}
                onIonChange={(e) => onEventGroupSelect(e.detail.value)}
              >
                <IonSelectOption value={EventGroup.All}>
                  All Events
                </IonSelectOption>
                <IonSelectOption value={EventGroup.ClockAndSched}>
                  Clock + Sched
                </IonSelectOption>
                <IonSelectOption value={EventGroup.Scheduled}>
                  Scheduled
                </IonSelectOption>
                <IonSelectOption value={EventGroup.DontWork}>
                  Don&apos;t Work
                </IonSelectOption>
                <IonSelectOption value={EventGroup.Custom}>
                  Custom Events
                </IonSelectOption>
              </IonSelect>
            </IonCol>
          </IonRow>

          {eventGroup === EventGroup.Custom && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Type</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  mode="ios"
                  className="select"
                  interface="action-sheet"
                  value={myFilter.eventTypeId}
                  onIonChange={(e) => {
                    setMyFilter({ ...myFilter, eventTypeId: e.detail.value });
                    setIsDirty(true);
                  }}
                >
                  {eventTypeList.map((et) => (
                    <IonSelectOption key={et.value} value={et.value}>
                      {et.text}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}

          {eventGroup === EventGroup.Custom && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Status</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  mode="ios"
                  className="select"
                  interface="action-sheet"
                  value={myFilter.eventStatusId}
                  onIonChange={(e) => {
                    setMyFilter({ ...myFilter, eventStatusId: e.detail.value });
                    setIsDirty(true);
                  }}
                >
                  <IonSelectOption value={-1}>All Event Statuses</IonSelectOption>
                  <IonSelectOption value={1}>Approved</IonSelectOption>
                  <IonSelectOption value={2}>Request</IonSelectOption>
                  <IonSelectOption value={4}>Request Denied</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">~Tags</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                mode="ios"
                className="select"
                interface="action-sheet"
                value={myFilter.tagId || -1}
                onIonChange={(e) => {
                  setMyFilter({ ...myFilter, tagId: e.detail.value });
                  setIsDirty(true);
                }}
              >
                <IonSelectOption key={-1} value={-1}>
                  All Tags
                </IonSelectOption>
                {auth.tags.map((tag) => (
                  <IonSelectOption key={tag.id} value={tag.id}>
                    ~{tag.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>

          {auth.locations.length > 1 && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Locations</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  mode="ios"
                  className="select"
                  multiple={true}
                  onIonChange={(e) => onSetLocations(e.detail.value)}
                  value={locationIds}
                >
                  {auth.locations.map((loc) => (
                    <IonSelectOption key={loc.id} value={loc.id.toString()}>
                      {loc.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}

          {auth.locations.flatMap((l) => l.departments).length > 1 && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Departments</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  mode="ios"
                  className="select"
                  multiple={true}
                  onIonChange={(e) => onSetDepartmentIds(e.detail.value)}
                  value={departmentIds}
                >
                  {auth.locations
                    .filter((l) => locationIds.indexOf(l.id.toString()) >= 0)
                    .map((loc) =>
                      loc.departments.map((dept) => (
                        <IonSelectOption
                          key={dept.id}
                          value={dept.id.toString()}
                        >
                          {loc.abbr}/{dept.name}
                        </IonSelectOption>
                      ))
                    )}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}

          {auth.locations
            .flatMap((l) => l.departments)
            .flatMap((d) => d.positions).length > 1 && (
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Positions</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  mode="ios"
                  className="select"
                  multiple={true}
                  onIonChange={(e) => onSetPositionIds(e.detail.value)}
                  value={positionIds}
                >
                  {auth.locations
                    .filter((l) => locationIds.indexOf(l.id.toString()) >= 0)
                    .flatMap((l) => l.departments)
                    .filter((d) => departmentIds.indexOf(d.id.toString()) >= 0)
                    .map((dept) =>
                      dept.positions.map((pos) => (
                        <IonSelectOption key={pos.id} value={pos.id.toString()}>
                          {pos.name}
                        </IonSelectOption>
                      ))
                    )}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Employees</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                ref={employeeSel}
                mode="ios"
                className="select"
                interface="action-sheet"
                placeholder={myFilter.employee.name}
                onIonChange={(e) => {
                  onSetEmployeeId(e.detail.value);
                }}
                value={myFilter.employee.id}
              >
                <IonSelectOption value={FILTER_ALL_EMPLOYEES.id}>
                  {FILTER_ALL_EMPLOYEES.name}
                </IonSelectOption>
                {allEmployees.map((emp) => (
                  <IonSelectOption key={emp.id} value={emp.id}>
                    {emp.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
        </IonGrid>

        {loading.showLoadingJsx}
      </IonContent>

      <IonFooter>
        <IonButton
          className="apply-button"
          expand="block"
          onClick={onCloseModal}
          disabled={!isDirty}
        >
          APPLY FILTER
        </IonButton>
      </IonFooter>
    </>
  );
};

export default ScheduleFilterModal;
