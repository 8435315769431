import { IonLoading } from "@ionic/react";
import React, { useState } from "react";

export function useShowLoading(message: string) {
  const [showLoading, setShowLoading] = useState(false);
  const showLoadingJsx = (
    <IonLoading
      isOpen={showLoading}
      onDidDismiss={() => setShowLoading(false)}
      showBackdrop={true}
      // spinner="dots"
      message={message}
      duration={10000}
    />
  );

  return {
    showLoading,
    setShowLoading,
    showLoadingJsx,
  };
}
