import React, { useContext, useState } from "react";
import "../../global-styles.scss";
import { AuthorizedLocation } from "../../orbital-interfaces/AuthorizedItems";
import { AdminAuthorization, Role } from "../../enums";
import { DailyNotes } from "../../orbital-interfaces/DailyNotes";
import { IonModal } from "@ionic/react";
import EditDailyNoteModal from "../EditDailyNote/EditDailyNoteModal";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import { authHelper } from "../../util/authHelper";

interface EventCardProps {
  note: DailyNotes;
  location: AuthorizedLocation;
  onForceRefresh: (toastMessage: string) => void;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventCard: React.FC<EventCardProps> = ({
  note,
  onForceRefresh,
}: EventCardProps) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { auth } = useContext(AuthorizedItemsContext);

  let deptName = note.DepartmentId === -1 ? "All Depts" : note.DepartmentName;
  if (auth.locations.length > 1) {
    const loc = authHelper.getLocationFromLocationId(auth, note.LocationId);
    if (loc.abbr && loc.abbr.length > 0) {
      deptName = loc.abbr + "/" + deptName;
    }
  }

  // --------------------------------------------------------------------------------------
  function onClose(toastMessage: string) {
    onForceRefresh(toastMessage);
    setShowEditModal(false);
  }

  // --------------------------------------------------------------------------------------
  function onClick() {
    if (auth.role === Role.Employee) {
      return;
    }

    if (
      !authHelper.isAdminAuthorizedFor(
        auth,
        note.LocationId,
        note.DepartmentId,
        AdminAuthorization.CanEditSchedules
      )
    ) {
      return false;
    }

    setShowEditModal(true);
  }

  return (
    <>
      <button className="daily-note" onClick={onClick}>
        <div>
          {deptName}: {note.Role === 1 ? "Employees" : "Admins"}
        </div>
        <div className="daily-note-text-row">
          {note.Notes}
        </div>
      </button>

      <IonModal
        mode="md"
        isOpen={showEditModal}
        onDidDismiss={() => setShowEditModal(false)}
      >
        <EditDailyNoteModal
          isOpen={showEditModal}
          note={note}
          onCloseModal={(toast) => onClose(toast)}
          initialDate={new Date()}
          newNoteClicked={true}
        />
      </IonModal>
    </>
  );
};

export default EventCard;
