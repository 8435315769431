import React, { useEffect, useRef, useState } from "react";
import "../../global-styles.scss";
import { ApplicationUserContext } from "../../context/ApplicationUserContext";
import useDialogBox from "../../hooks/useDialogBox";
import {IonContent, IonModal, IonRefresher, IonRefresherContent, IonSpinner} from "@ionic/react";
import { timeHelper as th } from "../../util/timeHelper";
import {
  EventInformation,
  HoursList,
} from "../../orbital-interfaces/TEC";
import EditEventModal from "../EditEvent/EditEventModal";
import { AuthorizedItems } from "../../orbital-interfaces/AuthorizedItems";
import { EventCardSkeleton } from "../EventsPageComponents/CardSkeleton";
import {
  getEditorValuesFromEventInformation,
  INITIAL_EDITOR_VALUES,
} from "../EditEvent/EditEventModalState";
import { useInterval } from "../../hooks/useInterval";
import { getConstants } from "../../appFunctions/environment";
import {
  EventFilter,
  EventFilterView,
  FILTER_ALL_EMPLOYEES,
} from "../../orbital-interfaces/EventFilter";
import { EventGroup } from "../../enums";
import { scheduleEventsApi } from "../../api/scheduleEventsApi";
import { authHelper } from "../../util/authHelper";
import { addDays, addHours } from "date-fns";
import { DailyNotes } from "../../orbital-interfaces/DailyNotes";
import EventList from "../EventsPageComponents/EventList";
import useSimpleToast from "../../hooks/useSimpleToast";
import { EventsContext } from "../../context/EventsContext";
import { FilteredRangeType } from "../../enums/FilteredRangeType";
import { punchClockApi } from "../../api/punchClockApi";

interface ClockTodayTabProps {
  isOpen: boolean;
  auth: AuthorizedItems;
  stationCode?: string;
}

// ------------------------------------------------------------------------------------
function createTodayFilter(auth: AuthorizedItems): EventFilter {
  const positionIds = authHelper.getPositionIdsForEmployee(auth);
  const locationIds = [] as number[];
  for (let i = 0; i < positionIds.length; i++) {
    const locId = authHelper.getLocationIdFromPositionid(auth, positionIds[i]);
    if (!locationIds.includes(locId)) {
      locationIds.push(locId);
    }
  }

  const locSettings = authHelper.getLocSettingsForLocationId(
    auth,
    locationIds[0]
  );
  const start = th.getTodayStartForLocation(new Date(), locSettings);
  const end = addHours(start, 24);

  return {
    settingsLocId: locationIds[0],
    positionIds,
    start,
    end,
    filteredRangeType: FilteredRangeType.Day,
    filterByEmployee: true,
    dnLocIds: locationIds,
    tagId: undefined,
    eventTypeId: EventGroup.All,
    eventStatusId: EventGroup.All,
    view: EventFilterView.clock,
    employee: FILTER_ALL_EMPLOYEES,
  };
}

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const ClockTodayTab: React.FC<ClockTodayTabProps> = ({
  isOpen,
  auth,
  stationCode
}: ClockTodayTabProps) => {
  const [scheduleEvents, setScheduleEvents] = useState(
    [] as EventInformation[]
  );
  const [dailyNotes, setDailyNotes] = useState([] as DailyNotes[]);
  const [hoursPerDay, setHoursPerDay] = useState([] as HoursList[]);
  const toast = useSimpleToast();
  const { user } = React.useContext(ApplicationUserContext);
  const eventsContext = React.useContext(EventsContext);
  const dlg = useDialogBox();
  const [showEventModal, setShowEventModal] = useState(false);
  const [editEventId, setEditEventId] = useState(null as number | null);
  const [initialEditEvent, setInitialEditEvent] = useState(
    INITIAL_EDITOR_VALUES
  );
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(null as boolean | null);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);

  // ------------------------------------------------------------------------------------
  const forceRefresh = (toastMessage: string) => {
    if (toastMessage && toastMessage.length > 0) {
      toast.showToast(toastMessage);
    }

    getData();
  };

  // ------------------------------------------------------------------------------------
  function doRefresh() {
    getData();
  }

  // ------------------------------------------------------------------------------------
  useInterval(
    function useIntervalCallback() {
      getData();
    },
    isOpen && !showEventModal,
    getConstants(user).refreshScheduleTimer,
    []
  );

  // ------------------------------------------------------------------------------------
  const onEventClick = (ev: EventInformation) => {
    setEditEventId(ev.id);
    setInitialEditEvent(getEditorValuesFromEventInformation(auth, ev));
    setShowEventModal(true);
  };

  // ------------------------------------------------------------------------------------
  function getData() {
    if (isOpen) {
      try {
        const apiFilter = createTodayFilter(auth);
        const locSettings = authHelper.getLocSettingsForLocationId(
          auth,
          user.defaultLocationId
        );
        let rangeStart: Date;
        let rangeEnd: Date;
        if (apiFilter.filteredRangeType === FilteredRangeType.Week) {
          rangeStart = apiFilter.start;
          rangeEnd = apiFilter.end;
        } else {
          rangeStart = th.getWeekStart(
            apiFilter.start,
            locSettings.DayWeekStartsOn,
            locSettings.DayStartTime
          );
          rangeEnd = addDays(rangeStart, 7);
        }

        if (stationCode) {
          punchClockApi
          .getScheduleEventsByFilter(user, apiFilter, rangeStart, rangeEnd, stationCode)
          .then((res) => {
            if (!isMountedRef.current) {
              return;
            }

            setIsLoading(false);
            ionRefresherRef.current!.complete();

            setScheduleEvents(res.events);
            setDailyNotes(res.dailyNotes);
            setHoursPerDay(res.hoursPerDay);
          });

        } else {
          scheduleEventsApi
          .getScheduleEventsByFilter(user, apiFilter, rangeStart, rangeEnd)
          .then((res) => {
            if (!isMountedRef.current) {
              return;
            }

            setIsLoading(false);
            ionRefresherRef.current!.complete();

            setScheduleEvents(res.events);
            setDailyNotes(res.dailyNotes);
            setHoursPerDay(res.hoursPerDay);
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          console.log("Error in ClockTodayTab.getData: " + e.message);
        }
      }
    }
  }

  // ------------------------------------------------------------------------------------
  useEffect(
    function useEffectEventsRefreshDate() {
      isMountedRef.current = true;
      if (user && user.isLoggedin && isOpen) {
        setIsLoading(true);
        getData();
      }

      return () => {
        isMountedRef.current = false;
      };
      // eslint-disable-next-line
    },

    // eslint-disable-next-line
    [eventsContext.eventsRefreshDate, isOpen]
  );

  // ------------------------------------------------------------------------------------
  const onEventUpdated = () => {
    setShowEventModal(false);
    eventsContext.setEventsRefreshed();
  };

  return (
    <>
      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          ref={ionRefresherRef}
        >
          <IonRefresherContent
            pullingIcon="arrow-dropdown"
            pullingText="Pull down to refresh"
            refreshingText="Refreshing Events... "
          />
        </IonRefresher>

        {isLoading &&
          ((dailyNotes && dailyNotes.length > 0) ||
            (scheduleEvents && scheduleEvents.length > 0)) && (
            <IonSpinner className="center" />
          )}

        {isLoading &&
          (dailyNotes.length === 0 || scheduleEvents.length === 0) && (
            <EventCardSkeleton count={1} />
          )}

        {!isLoading && authHelper.isAuthItemsValid(auth) && (
          <EventList
            events={scheduleEvents}
            listType={"today"}
            notes={dailyNotes}
            hoursPerDay={hoursPerDay}
            user={user}
            auth={auth}
            isTimeClockPage={true}
            onEdit={onEventClick}
            onForceRefresh={forceRefresh}
            weekStart={new Date()}
          />
        )}
      </IonContent>

      {toast.simpleToastJsx}

      <IonModal
        mode="md"
        isOpen={showEventModal}
        onDidDismiss={() => setShowEventModal(false)}
      >
        <EditEventModal
          isOpen={showEventModal}
          auth={auth}
          onCancelModal={() => setShowEventModal(false)}
          onDismissModal={() => onEventUpdated()}
          scheduleEventId={editEventId}
          initialEvent={initialEditEvent}
        />
      </IonModal>

      {dlg.alertBoxJsx}
    </>
  );
};

export default ClockTodayTab;
