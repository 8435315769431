import React from "react";
import {IonButton, IonCheckbox, IonCol, IonIcon, IonLabel} from "@ionic/react";
import "./EventStartEndEditor";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {AdminAuthorization, EventStatus, EventType, Role} from "../../../enums";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {checkmark} from "ionicons/icons";
import {authHelper} from "../../../util/authHelper";

interface EventConfirmEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
  onEmployeeUpdated: () => void;
}

// --------------------------------------------------------------------------------------
function shouldShowButton(auth: AuthorizedItems, ev:EditorValues): boolean  {
  if (!authHelper.isAuthItemsValid(auth)) {
    return false;
  }

  if (auth.role === Role.Employee) {
    return true;
  }

  if (auth.role === Role.LocationAdmin || auth.role === Role.BusinessAdmin) {
    return false;
  }

  const dept = authHelper.getDepartmentFromPositionId(auth, ev.position.position.id);
  if (dept === null) {
    return false;
  }

  return !authHelper.isAdminAuthorizedFor(
    auth,
    ev.locationId,
    dept!.id,
    AdminAuthorization.CanEditSchedules);
}

// --------------------------------------------------------------------------------------
function shouldShowConfirmed(auth: AuthorizedItems, ev: EditorValues) {
  if (!authHelper.isAuthItemsValid(auth)) {
    return false;
  }

  if (ev.scheduleEventId === -1) {
    return false;
  }

  if (ev.scheduleEventId <= 0 ) {
    if (auth.role === Role.Employee) {
      return false;
    }

    if (auth.role === Role.Admin) {
      const dept = authHelper.getDepartmentFromPositionId(auth, ev.position.position.id);
      if (dept === null) {
        return false;
      }

      if (!authHelper.isAdminAuthorizedFor(
        auth,
        ev.locationId,
        dept!.id,
        AdminAuthorization.CanEditSchedules)) {
        return false;
      }
    }
  }

  if (auth.role === Role.Employee && ev.eventStatus === EventStatus.Request) {
    return false;
  }

  return (ev.eventType === EventType.Shift && ev.eventStatus === EventStatus.Approved) ||
    (ev.eventType === EventType.Cover &&
        (ev.eventStatus === EventStatus.Request ||
        ev.eventStatus === EventStatus.RequestDenied));
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventConfirmEditor: React.FC<EventConfirmEditorProps> = ({
  auth,
  editorValues,
  update,
  onEmployeeUpdated
}: EventConfirmEditorProps) => {


  // ---------------------------------------------------------------------------------
  function onCheckChange() {
    update({
      type: "CONFIRM-UPDATED",
      isConfirmed: !editorValues.isConfirmed,
    });
  }

  // ---------------------------------------------------------------------------------
  function onButtonClick() {
    onEmployeeUpdated();
  }

  if (!shouldShowConfirmed(auth, editorValues)) {
    return (
      <>
      </>
    );
  }

  if (shouldShowButton(auth, editorValues)) {
    return (
      <>
        <IonCol size="4">
          <IonLabel className="label">
            Confirm
          </IonLabel>
        </IonCol>
        <IonCol size="4">
          <IonButton
            onClick={onButtonClick}
          >
            {editorValues.isConfirmed ? "Unconfirm" : "Confirm"}
            <IonIcon
              className="icon-right-of-checkbox"
              icon={checkmark}
            />
          </IonButton>
        </IonCol>
      </>
    );
  } else {
    return (
      <>
        <IonCol size="4">
          <IonLabel className="label">

            Confirm</IonLabel>
        </IonCol>
        <IonCol size="4">
          <IonCheckbox
            mode="ios"
            checked={editorValues.isConfirmed}
            onClick={() => onCheckChange()}
          />
          <IonIcon
            className="icon-right-of-checkbox"
            icon={checkmark}
          />
        </IonCol>
      </>
    );
  }

};

export default EventConfirmEditor;
