import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonFooter,
  IonHeader,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline, bookmark, trash } from "ionicons/icons";
import "../../global-styles.scss";
import { ApplicationUserContext } from "../../context/ApplicationUserContext";
import useDialogBox from "../../hooks/useDialogBox";
import { Message } from "../../orbital-interfaces/Message";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import { timeHelper as th } from "../../util/timeHelper";
import {scheduleEventsApi} from "../../api/scheduleEventsApi";
import {messageApi} from "../../api/messageApi";
import { ScheduleEvent } from "../../orbital-interfaces/ScheduleEvent";
import { MessagesReducerAction } from "../../pages/MessagesPage";
import useYesOrCancelDialogBox from "../../hooks/useYesOrCancelDialogBox";
import { MessagesContext } from "../../context/MessagesContext";
import EventRequestEditorEx from "../EditEvent/editor/EventRequestEditorEx";
import {getEditorValuesFromScheduleEvent} from "../EditEvent/EditEventModalState";
import {authHelper} from "../../util/authHelper";
import {MessageTypes} from "../../enums/MessageTypes";

interface ViewMessageModalProps {
  message: Message | null;
  onCloseModal: (msg: string | null) => void;
  updateMessages: React.Dispatch<MessagesReducerAction>;
}

// --------------------------------------------------------------------------------------
function getFromFieldByMessageType(message: Message) {
  switch(message.messageTypeId) {
    case MessageTypes.CoverRequestMessage:
    case MessageTypes.CoverApprovedMessage:
    case MessageTypes.DirectCoverRequestMessage:
    case MessageTypes.ShiftRequestMessage:
    case MessageTypes.ShiftRequestDeniedMessage:
    case MessageTypes.ShiftRequestReceivedMessage:
    case MessageTypes.ScheduleEventUpdatedMessage:
    case MessageTypes.CustomMessage:
      return message.fromEmployeeFirstName + " " + message.fromEmployeeLastName;

    case MessageTypes.NoClockInAlertMessage:
    case MessageTypes.NoClockOutAlertMessage:
    case MessageTypes.LateClockInMessage:
    case MessageTypes.EarlyClockOutAlertMessage:
    case MessageTypes.PublishedScheduleUpdatedMessage:
    case MessageTypes.PasswordRecoveryMessage:
    case MessageTypes.VerifyCellPhoneMessage:
    case MessageTypes.VerifyCellPhoneCompleteMessage:
    case MessageTypes.VerifyEmailMessage:
    case MessageTypes.WeeklyScheduleForEmployeeMessage:
    default:
      return "Orbital Shift";
  }
}

// --------------------------------------------------------------------------------------
const ViewMessagetModal: React.FC<ViewMessageModalProps> = ({
  message,
  onCloseModal,
  updateMessages,
}) => {
  const dlg = useDialogBox();
  const yesCancelDlg = useYesOrCancelDialogBox();
  const { user } = React.useContext(ApplicationUserContext);
  const { auth } = React.useContext(AuthorizedItemsContext);
  const [scheduleEvent, setScheduleEvent] = useState(null as ScheduleEvent | null);
  const [readCount, setReadCount] = useState(null as number | null);
  const [usageText, setUsageText] = useState(null as string | null);
  const [pinned, setPinned] = useState(false);
  const { refreshCount } = useContext(MessagesContext);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (!message) {
      return;
    }

    if (message.scheduleEventId) {
      scheduleEventsApi
        .getScheduleEvent(user, message.scheduleEventId)
        .then((event) => {
          if (event) {
            setScheduleEvent(event);
          }
        });
    } else {
      if (scheduleEvent) {
        setScheduleEvent(null);
        setUsageText(null);
      }
    }

    messageApi.getReadCount(user, message.messageId).then((newReadCount) => {
      setReadCount(newReadCount);
    });

    if (!message.isViewed) {
      messageApi.setIsViewed(user, message.messageId, true).then(() => {
        updateMessages({
          type: "UPDATE-VIEWED",
          msgId: message.messageId,
          isViewed: true,
        });
      });
    }

    setPinned(message.isPinned);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.messageId]);

  // --------------------------------------------------------------------------------------
  const onPinnedClick = () => {
    const newPinValue = !pinned;
    messageApi.setIsPinned(user, message!.messageId, newPinValue).then(() => {
      setPinned(newPinValue);
      updateMessages({
        type: "UPDATE-PINNED",
        msgId: message!.messageId,
        isPinned: newPinValue,
      });
    });
  };

  // --------------------------------------------------------------------------------------
  const onDeleteClick = () => {
    yesCancelDlg.showDialog(
      "Delete this message?",
      () => {
        messageApi.deleteMessage(user, message!.messageId).then(() => {
          updateMessages({ type: "DELETE", msgId: message!.messageId });
          onCancel("Message Deleted");
        });
      },
      () => { }
    );
  };

  // --------------------------------------------------------------------------------------
  const onCancel = (msg: string | null) => {
    if (!message?.isViewed) {
      refreshCount();
    }

    onCloseModal(msg);
  };

  if (!message || !auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
        {dlg.alertBoxJsx}
      </>
    );
  } else {
    return (
      <>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  onCancel(null);
                }}
              >
                <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>Read Message</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  onPinnedClick();
                }}
              >
                <IonIcon
                  icon={bookmark}
                  style={pinned ? { color: 'var(--ion-color-white)' } : { color: 'var(--ion-color-mediumgrey)' }}
                />
              </IonButton>
              <IonButton
                onClick={() => {
                  onDeleteClick();
                }}
              >
                <IonIcon icon={trash} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent class="outer-contentMessage">
          <IonGrid>
            <IonRow class="messageIonRow-header">
              <IonCol class="messageHeaderSubject" size="12" >
                {message!.subject}
              </IonCol>

              <IonCol class="messageHeaderDetails" size="12">
                From: {getFromFieldByMessageType(message)}
              </IonCol>

              <IonCol class="messageHeaderDetails" size="9" >
                {th.getMessageDeliveryTimeString(message!.deliveryDateTime, locSettings.TimeZoneOffset)}
              </IonCol>

              <IonCol class="readCounter" size="3" >
                {readCount !== null && <span>{readCount} Read</span>}
              </IonCol>
            </IonRow>
            <div/>

            <IonRow class="messageIonRow-content">
              <IonCol class="messageBlock" size="12">
                <div
                  dangerouslySetInnerHTML={{ __html: message?.messageText }}
                />
              </IonCol>
            </IonRow>

            {usageText !== null && (
              <IonRow>
                <IonCol size="12">{usageText}</IonCol>
              </IonRow>
            )}
          </IonGrid>

          {dlg.alertBoxJsx}
          {yesCancelDlg.dialogJsx}
        </IonContent>

        <IonFooter>
          {scheduleEvent !== null && (
            <EventRequestEditorEx
              auth={auth}
              update={() => {}}
              cancelEditModal={onCancel}
              editorValues={getEditorValuesFromScheduleEvent(auth, scheduleEvent)}
              messageId={message.messageId}
              messageType={message.messageTypeId}
            />
          )}
        </IonFooter>
      </>
    );
  }
};

export default ViewMessagetModal;
