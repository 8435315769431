import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import EventsPage from "./EventsPage";
import MessagesPage from "./MessagesPage";
import "../global-styles.scss";
import DataPage from "./DataPage";
import MessagesTabButton from "../components/MessagesPageComponents/MessagesTabButton";
import env from "../appFunctions/environment";
import PunchClockEntryPage from "../punchclock/PunchClockEntryPage";

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const MainTabs: React.FC = () => {
  const isPunchclock = env.settings.appType === "punchclock";
  
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact={true} path="/tabs" to={isPunchclock 
          ? "/tabs/punchclock" 
          : "/tabs/events"} />

        {isPunchclock ? (
          <Route
            path="/tabs/punchclock"
            render={(routeProps) => <PunchClockEntryPage {...routeProps} />}
          />
        ) : (
          <>
            <Route
              path="/tabs/events/:scheduleEventId"
              render={(routeProps) => <EventsPage {...routeProps} />}
            />

            <Route
              path="/tabs/events"
              render={(routeProps) => <EventsPage {...routeProps} />}
              exact={true}
            />

            <Route
              path="/tabs/reports"
              render={(routeProps) => <DataPage {...routeProps} />}
              exact={true}
            />


            <Route
              path="/tabs/messages/:messageId"
              render={(routeProps) => <MessagesPage {...routeProps} />}
            />

            <Route
              path="/tabs/messages"
              render={(routeProps) => <MessagesPage {...routeProps} />}
              exact={true}
            />
          </>
       )}

      </IonRouterOutlet>
      <IonTabBar
        mode="ios"
        hidden={env.settings.appType === "punchclock"}
        slot="bottom">
        <IonTabButton key="1" tab="events" href="/tabs/events">
          <IonIcon src="assets/events-solid.svg" className="main-footer-svgs" />
          <IonLabel>Events</IonLabel>
        </IonTabButton>
        <IonTabButton key="4" tab="map" href="/tabs/messages">
          <MessagesTabButton />
        </IonTabButton>
          <IonTabButton key="3" tab="reports" href="/tabs/reports">
            <IonIcon src="assets/data-solid.svg" className="main-footer-svgs" />
            <IonLabel>Data</IonLabel>
          </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
