import React, { useContext, useState } from "react";
import {IonCol, IonLabel, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import { authHelper, SelectedDepartment } from "../../util/authHelper";
import {AdminAuthorization} from "../../enums";

interface DailyNoteDepartmentSelectorProps {
  currentDepartment: SelectedDepartment;
  onDepartmentUpdate: (dep: SelectedDepartment) => void;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const DailyNoteDepartmentSelector: React.FC<DailyNoteDepartmentSelectorProps> = ({
  currentDepartment,
  onDepartmentUpdate,
}) => {
  const { auth } = useContext(AuthorizedItemsContext);
  const [selectedDepartment, setSelectedDepartment] = useState(
    currentDepartment
  );

  // --------------------------------------------------------------------------------------
  const updateLocation = (locId: number | undefined | null) => {
    if (!locId) {
      return;
    }

    const newSelection = {
      location: auth.locations.filter((l) => l.id === locId)[0],
      department: null,
    };

    setSelectedDepartment(newSelection);
    onDepartmentUpdate(newSelection);
  };

  // --------------------------------------------------------------------------------------
  const updateDepartment = (depId: number | undefined | null) => {
    if (depId === undefined) {
      return;
    }

    const newSelection = {
      location: selectedDepartment.location,
      department:
        depId === null
          ? null
          : selectedDepartment.location.departments.filter(
              (d) => d.id === depId
            )[0],
    };

    setSelectedDepartment(newSelection);
    onDepartmentUpdate(newSelection);
  };

  if (!auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
      </>
    );
  } else {
    const selectedDepartmentId =
      selectedDepartment.department !== null
        ? selectedDepartment.department.id
        : null;

    return (
      <>
        <IonRow>
          <IonCol size="4">
            <IonLabel className="label">Location</IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonSelect
              mode="ios"
              className="select"
              interface="action-sheet"
              value={selectedDepartment.location.id}
              onIonChange={(e) => updateLocation(e.detail.value)}
            >
              {auth.locations.map((loc) => (
                <IonSelectOption key={loc.id} value={loc.id}>
                  {loc.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="4">
            <IonLabel className="label">Department</IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonSelect
              mode="ios"
              className="select"
              interface="action-sheet"
              value={selectedDepartmentId}
              onIonChange={(e) => updateDepartment(e.detail.value)}
            >
              {authHelper.isAdminForAllDepts(
                auth,
                selectedDepartment.location.id
              ) && (
                <IonSelectOption key={-1} value={null}>
                  All Departments
                </IonSelectOption>
              )}
              {selectedDepartment.location.departments
                .filter((dep) =>
                  authHelper.isAdminAuthorizedFor(
                    auth,
                    selectedDepartment.location.id,
                    dep.id,
                    AdminAuthorization.CanEditSchedules
                  )
                )
                .map((dep) => (
                  <IonSelectOption key={dep.id} value={dep.id}>
                    {dep.name}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonCol>
        </IonRow>
      </>
    );
  }
};

export default DailyNoteDepartmentSelector;
