import { IonToast } from "@ionic/react";
import React, { useState } from "react";


function useErrorToast(seconds?: number | null | undefined) {
  const [isOpen, setIsOpen] = useState(false);
  const [toastData, setToastData] = useState({ message: "" });

  const showToast = (message: string) => {
    setToastData({
      message,
    });

    setIsOpen(true);
  };

  const errorToastJsx = (
    <IonToast
      cssClass="error-toast"
      mode="md"
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      position="top"
      message={toastData.message}
      duration={seconds ? seconds : 2500}
    />
  );

  return {
    showToast,
    errorToastJsx,
  };
}

export default useErrorToast;
