import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from './appFunctions/serviceWorkerRegistration';
import {log} from './util/logger';

// ---------------------------------------------------------------------------
const onRegistrationSuccess = (registration: ServiceWorkerRegistration) => {
  console.log("index: service worker registration successful");
}

// ---------------------------------------------------------------------------
const onRegistrationUpdate = (registration: ServiceWorkerRegistration) => {
  console.log("index: service worker registration update");
  registration.waiting?.postMessage({type: 'SKIP_WAITING'});
  registration.update().then(() => {
    console.log("index: service worker registration updated");
    log("serviceWorker_Updated");

    // When the service worker has been updated and we (almost certainly) have
    // new app code/pages, we force the browser to reload the newly-cached content.
    // So, typically what happens is:
    //  1) browser detects new manifest/service worker
    //  2) service worker is installed/activated
    //  3) new app code/pages are cached
    //  4) this function is called, page gets reloaded for latest content
    window.location.reload();

  })
}

ReactDOM.render(<App />, document.getElementById("root"));


serviceWorkerRegistration.register({
  onSuccess: onRegistrationSuccess,
  onUpdate: onRegistrationUpdate
});
