import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonModal,
  IonPage, IonRefresher, IonRefresherContent,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "../global-styles.scss";
import { funnel } from "ionicons/icons";
import { addDays } from "date-fns";
import { AuthorizedItems } from "../orbital-interfaces/AuthorizedItems";
import { scheduleEventsApi } from "../api/scheduleEventsApi";
import { DailyNotes } from "../orbital-interfaces/DailyNotes";
import { EventInformation, HoursList } from "../orbital-interfaces/TEC";
import {
  EventFilter,
  EventFilterView,
} from "../orbital-interfaces/EventFilter";
import WeekChooser from "../components/EventsPageComponents/WeekChooser";
import EventList from "../components/EventsPageComponents/EventList";
import ScheduleFilterModal from "../components/EventsPageComponents/ScheduleFilterModal";
import EditEventModal from "../components/EditEvent/EditEventModal";
import useSimpleToast from "../hooks/useSimpleToast";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import { RouteComponentProps } from "react-router";
import { EventCardSkeleton } from "../components/EventsPageComponents/CardSkeleton";
import NoEventsToShow from "../components/EventsPageComponents/NoEventsToShow";
import {
  getEditorValuesFromEventInformation,
  INITIAL_EDITOR_VALUES,
} from "../components/EditEvent/EditEventModalState";
import { EventGroup } from "../enums";
import { scheduleEventHelper } from "../util/scheduleEventHelper";
import { authHelper } from "../util/authHelper";
import { filterStore } from "../data/filterStore";
import FabButton from "../components/FabButton";
import { EventsContext } from "../context/EventsContext";
import EventsSegmentDropDownList from "../components/EventsPageComponents/EventsSegmentDropDownList";
import { eventSegmentStore } from "../data/eventSegmentStore";
import { appReady } from "../util/appReady";
import { FilteredRangeType } from "../enums/FilteredRangeType";
import { timeHelper as th } from "../util/timeHelper";
import { useInterval } from "../hooks/useInterval";
import { getConstants } from "../appFunctions/environment";
import { log } from "../util/logger";
import SplashComponent from "../components/SplashComponent";

const initialEvents: EventInformation[] = [];
const initialDailyNotes: DailyNotes[] = [];

interface EventsPageProps
  extends RouteComponentProps<{ scheduleEventId?: string }> {}

interface EventListRef {
  scrollToDate: (d: Date) => void;
}

// --------------------------------------------------------------------------------------
function eventPassesFilter(
  ev: EventInformation,
  filter: EventFilter,
  filterByEmployee: boolean
): boolean {
  if (!filter.positionIds.includes(ev.posId)) {
    return false;
  }

  if (filter.tagId !== undefined && filter.tagId !== -1) {
    if (filter.tagId !== ev.tagId) {
      return false;
    }
  }

  // if the filter included an employee in it AND we're filtering by employee
  // (meaning that we're the Requests or All segment, most likely), then do this check
  if (
    filterByEmployee &&
    filter.employee.id !== -1 &&
    filter.employee.id !== ev.empId
  ) {
    return false;
  }

  return scheduleEventHelper.eventMatchesFilter(
    filter.eventTypeId,
    filter.eventStatusId,
    ev.eventTypeId,
    ev.eventStatusId
  );
}

// --------------------------------------------------------------------------------------
function dailyNotePassesFilter(
  auth: AuthorizedItems,
  dn: DailyNotes,
  filter: EventFilter
): boolean {
  if (dn.DepartmentId === -1) {
    if (
      !filter.positionIds
        .map((pid) => {
          return authHelper.getLocationIdFromPositionid(auth, pid);
        })
        .includes(dn.LocationId)
    ) {
      return false;
    }
  } else if (dn.DepartmentId > 0) {
    if (
      !filter.positionIds
        .map((pid) => {
          return authHelper.getDepartmentFromPositionId(auth, pid)?.id;
        })
        .includes(dn.DepartmentId)
    ) {
      return false;
    }
  }

  return true;
}

// ------------------------------------------------------------------------------------
function getAllScheduleEvents(events: EventInformation[], f: EventFilter) {
  return events.filter((ev) =>
    scheduleEventHelper.eventMatchesFilter(
      f.eventTypeId,
      f.eventStatusId,
      ev.eventTypeId,
      ev.eventStatusId
    )
  );
}

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
// eslint-disable-next-line react/prop-types
const EventsPage: React.FC<EventsPageProps> = ({
  match,
  history,
}: EventsPageProps) => {
  const eventListRef = useRef(null as EventListRef | null);
  const fabButtonRef = useRef(null as any | null); // Should be HTMLIonFabElement but  ¯\_(ツ)_/¯
  const { user } = React.useContext(ApplicationUserContext);
  const { auth, refreshAuth, authLoading } = React.useContext(
    AuthorizedItemsContext
  );
  const { eventsRefreshDate } = useContext(EventsContext);
  const toast = useSimpleToast();
  const [segment, setSegment] = useState(eventSegmentStore.getEventSegment());
  const [allScheduleEvents, setAllScheduleEvents] = useState(initialEvents);
  const [allDailyNotes, setAllDailyNotes] = useState(initialDailyNotes);
  const [allFilteredScheduleEvents, setAllFilteredScheduleEvents] = useState(
    initialEvents
  );
  const [allFilteredDailyNotes, setAllFilteredDailyNotes] = useState(
    initialDailyNotes
  );
  const [allHoursPerDay, setAllHoursPerDay] = useState([] as HoursList[]);
  const [showLoading, setShowLoading] = useState(true);
  const [isShowing, setIsShowing] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [editEventId, setEditEventId] = useState(null as number | null);
  const [initialEditEvent, setInitialEditEvent] = useState(
    INITIAL_EDITOR_VALUES
  );
  const storedFilter = filterStore.getFilter(auth, user);
  const [filter, setFilter] = useState({
    tagId: storedFilter.tagId,
    positionIds: storedFilter.positionIds,
    dnLocIds: storedFilter.dnLocIds,
    settingsLocId: storedFilter.settingsLocId,
    eventTypeId: storedFilter.eventTypeId,
    eventStatusId: storedFilter.eventStatusId,
    start: new Date(),
    end: addDays(new Date(), filterStore.getDaysInRange(auth, user)),
    filteredRangeType: storedFilter.filteredRangeType,
    filterByEmployee: false,
    view: EventFilterView.schedule,
    employee: storedFilter.employee,
  } as EventFilter);
  const isGettingScheduleEvents = useRef(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);


  // ------------------------------------------------------------------------------------
  useIonViewWillEnter(() => {
    setIsShowing(true);
    refreshAuth(false, user, needsLogout).then((newAuth) => {
      if (newAuth) {
        getScheduleEvents(newAuth, filter, segment);
      }
    });

    // getScheduleEvents(auth, filter, segment);
  }, [filter, segment]);

  // ------------------------------------------------------------------------------------
  useIonViewWillLeave(() => {
    fabButtonRef.current!.close();
    setIsShowing(false);
  });

  // ------------------------------------------------------------------------------------
  useInterval(
    () => {
      refreshAuth(false, user, needsLogout).then((newAuth) => {
        if (newAuth) {
          getScheduleEvents(newAuth, filter, segment);
        }
      });
    },
    isShowing &&
      !showEventModal &&
      !showFilterModal &&
      user.isLoggedin &&
      !user.loading &&
      !auth.loading &&
      !authLoading &&
      isShowing,
    getConstants(user).refreshScheduleTimer,
    [filter, segment]
  );

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (user.isLoggedin && isShowing && authHelper.isAuthItemsValid(auth)) {
      const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
      let startDate: Date;
      let endDate: Date;

      if (storedFilter.filteredRangeType === FilteredRangeType.Day) {
        startDate = th.getTodayStartForLocation(new Date(), locSettings);
        endDate = addDays(startDate, 1);
      } else {
        startDate = th.getWeekStart(
          new Date(),
          locSettings.DayWeekStartsOn,
          locSettings.DayStartTime
        );
        endDate = addDays(startDate, 7);
      }

      const newFilter = {
        tagId: storedFilter.tagId,
        positionIds: storedFilter.positionIds,
        dnLocIds: storedFilter.dnLocIds,
        settingsLocId: storedFilter.settingsLocId,
        eventTypeId: storedFilter.eventTypeId,
        eventStatusId: storedFilter.eventStatusId,
        start: startDate,
        end: endDate,
        filteredRangeType: storedFilter.filteredRangeType,
        filterByEmployee: false,
        view: EventFilterView.schedule,
        employee: storedFilter.employee,
      };
      setFilter(newFilter);
      setShowLoading(true);
      getScheduleEvents(auth, newFilter, segment);
    }

    // eslint-disable-next-line
  }, [user.isLoggedin, eventsRefreshDate, auth.locations, segment]);

  useEffect(() => {
    if (authHelper.isAuthItemsValid(auth)) {
      const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
      let startDate: Date;
      let endDate: Date;

      if (storedFilter.filteredRangeType === FilteredRangeType.Day) {
        startDate = th.getTodayStartForLocation(new Date(), locSettings);
        endDate = addDays(startDate, 1);
      } else {
        startDate = th.getWeekStart(
          new Date(),
          locSettings.DayWeekStartsOn,
          locSettings.DayStartTime
        );
        endDate = addDays(startDate, 7);
      }

      setFilter({
        tagId: storedFilter.tagId,
        positionIds: storedFilter.positionIds,
        dnLocIds: storedFilter.dnLocIds,
        settingsLocId: storedFilter.settingsLocId,
        eventTypeId: storedFilter.eventTypeId,
        eventStatusId: storedFilter.eventStatusId,
        start: startDate,
        end: endDate,
        filteredRangeType: storedFilter.filteredRangeType,
        filterByEmployee: false,
        view: EventFilterView.schedule,
        employee: storedFilter.employee,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.locations, user.defaultLocationId]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (match.params.scheduleEventId) {
      // eslint-disable-next-line react/prop-types
      setEditEventId(parseInt(match.params.scheduleEventId));
      setShowEventModal(true);
    }

    // eslint-disable-next-line react/prop-types
  }, [match.params.scheduleEventId]);

  // ------------------------------------------------------------------------------------
  const onEventClick = useCallback(
    (ev: EventInformation) => {
      setEditEventId(ev.id);
      setInitialEditEvent(getEditorValuesFromEventInformation(auth, ev));
      setShowEventModal(true);
    },

    // eslint-disable-next-line
    [auth.locations]
  );

  if (!appReady(user, auth)) {
    return <SplashComponent /> ;
  }

  // ----------------------------------------------------------------------------------------------
  function needsLogout() {
    history.push("/login");
    history.goForward();
  }

  // ------------------------------------------------------------------------------------
  function getScheduleEvents(auth: AuthorizedItems, f: EventFilter, thisSegment: string) {
    if (isGettingScheduleEvents.current) {
      return;
    }

    const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
    const defaultFilter = filterStore.getDefaultFilter(auth, f);
    const apiFilter: EventFilter = {
      ...defaultFilter,
      start: f.start,
      end: f.end,
      filterByEmployee: false,
      eventTypeId: EventGroup.All,
      eventStatusId: EventGroup.All,
    };

    let rangeStart: Date;
    let rangeEnd: Date;
    if (f.filteredRangeType === FilteredRangeType.Week) {
      rangeStart = apiFilter.start;
      rangeEnd = apiFilter.end;
    } else {
      rangeStart = th.getWeekStart(
        apiFilter.start,
        locSettings.DayWeekStartsOn,
        locSettings.DayStartTime
      );
      rangeEnd = addDays(rangeStart, 7);
    }

    // Call a different api for the Requests panel/segment
    const apiCall =
      thisSegment === "requests"
        ? scheduleEventsApi.getRequestEventsByFilter
        : scheduleEventsApi.getScheduleEventsByFilter;

    isGettingScheduleEvents.current = true;
    apiCall(user, apiFilter, rangeStart, rangeEnd)
      .then((res) => {
        setShowLoading(false);
        isGettingScheduleEvents.current = false;
        ionRefresherRef.current!.complete();

        const filteredDailyNotes = res.dailyNotes.filter((dn) =>
          dailyNotePassesFilter(auth, dn, f)
        );
        const allSE = getAllScheduleEvents(res.events, apiFilter);
        setAllScheduleEvents(allSE);
        setAllDailyNotes(res.dailyNotes);
        setAllFilteredScheduleEvents(
          allSE.filter((se) => eventPassesFilter(se, f, true))
        );
        setAllFilteredDailyNotes(filteredDailyNotes);
        setAllHoursPerDay(res.hoursPerDay);
      })
      .catch((e) => {
        isGettingScheduleEvents.current = false;
        if (e && e.toString().indexOf("Failed to fetch") > 1) {
          toast.showToast("Error getting events: No connection");
        } else {
          toast.showToast("Error getting events: " + e);
        }

        setShowLoading(false);
        if (ionRefresherRef.current) {
          ionRefresherRef.current!.complete();
        }
      });
  }

  // ------------------------------------------------------------------------------------
  function onSegmentChanged(newSegment: string) {
    if (segment === newSegment) {
      return;
    }

    eventSegmentStore.putEventSegment(newSegment);
    setSegment(newSegment);
    setShowLoading(true);
    log("eventsPage_changeSegment", { segment: newSegment });
    getScheduleEvents(auth, filter, newSegment);
  }

  // ------------------------------------------------------------------------------------
  function onDismissFilterModal(
    f: EventFilter,
    filterApplied: boolean,
    needRefresh: boolean
  ) {
    if (needRefresh && authHelper.isAuthItemsValid(auth)) {
      const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
      let startDate: Date;
      let endDate: Date;

      if (f.filteredRangeType === FilteredRangeType.Day) {
        startDate = f.start;
        endDate = addDays(startDate, 1);
      } else {
        startDate = th.getWeekStart(
          f.start,
          locSettings.DayWeekStartsOn,
          locSettings.DayStartTime
        );
        endDate = addDays(startDate, 7);
      }

      const newFilter = {
        ...f,
        start: startDate,
        end: endDate,
      };

      setShowLoading(true);
      setFilter(newFilter);
      filterStore.putFilter(newFilter);

      getScheduleEvents(auth, newFilter, segment);
    } else {
      setFilter(f);
      filterStore.putFilter(f);
      setAllFilteredScheduleEvents(
        allScheduleEvents.filter((se) => eventPassesFilter(se, f, true))
      );
      setAllFilteredDailyNotes(
        allDailyNotes.filter((dn) => dailyNotePassesFilter(auth, dn, f))
      );
    }

    setShowFilterModal(false);
  }

  // ------------------------------------------------------------------------------------
  function onFabModalClosed(
    updateMessages: boolean,
    updateEventsOrNotes: boolean,
    toastMessage?: string
  ) {
    if (toastMessage) {
      toast.showToast(toastMessage);
    }

    if (updateMessages) {
    }

    if (updateEventsOrNotes) {
      getScheduleEvents(auth, filter, segment);
    }
  }

  // ------------------------------------------------------------------------------------
  function onEventUpdated(toastMessage?: string) {
    if (toastMessage) {
      toast.showToast(toastMessage!);
    }

    getScheduleEvents(auth, filter, segment);
    setShowEventModal(false);
  }

  // ------------------------------------------------------------------------------------
  function setStartDate(date: Date) {
    if (date === filter.start) {
      return;
    }

    const newFilter = {
      ...filter,
      start: date,
      end: addDays(date, filterStore.getDaysInRange(auth, user)),
    };

    setFilter(newFilter);
    setShowLoading(true);
    getScheduleEvents(auth, newFilter, segment);
  }

  // ------------------------------------------------------------------------------------
  function onWeekdayClick(date: Date) {
    if (eventListRef.current !== null) {
      eventListRef.current!.scrollToDate(date);
    }

    if (filter.filteredRangeType === FilteredRangeType.Day) {
      const newFilter = {
        ...filter,
        start: date,
        end: addDays(date, 1),
      };

      setFilter(newFilter);
      setShowLoading(true);
      getScheduleEvents(auth, newFilter, segment);
    }
  }

  // ------------------------------------------------------------------------------------
  function forceRefresh(toastMessage: string) {
    if (toastMessage && toastMessage.length > 0) {
      toast.showToast(toastMessage);
    }

    getScheduleEvents(auth, filter, segment);
  }

  // ----------------------------------------------------------------------------------------------
  function internalRefresh() {
    getScheduleEvents(auth, filter, segment);
  }


  return (
    <IonPage id="schedule-page">
      <IonHeader className="ion-no-border">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonMenuButton className="ion-menu-button" />
          </IonButtons>
          <IonTitle slot="start">
            <EventsSegmentDropDownList
              itemList={"requests,all"}
              selectedItem={segment}
              onSelectedItemChanged={(e) => onSegmentChanged(e)}
            />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowFilterModal(true)}>
              <IonIcon
                icon={funnel}
                mode="ios"
                slot="icon-only"
                className={
                  filterStore.isFilterApplied(auth, filter)
                    ? "filter-applied"
                    : "filter-not-applied"
                }
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {segment !== "requests" && (
        <WeekChooser
          onStartDateUpdated={setStartDate}
          onWeekdayClick={onWeekdayClick}
          selectedDate={
            storedFilter.filteredRangeType === FilteredRangeType.Day
              ? filter.start
              : null
          }
        />
      )}

      <IonContent>
        <IonRefresher
          slot="fixed"
          onIonRefresh={internalRefresh}
          ref={ionRefresherRef}
        >
          <IonRefresherContent
            pullingIcon="arrow-dropdown"
            pullingText="Pull down to refresh"
            refreshingText="Refreshing Events..."
          />
        </IonRefresher>

        {showLoading && <EventCardSkeleton count={5} />}

        {!showLoading && allScheduleEvents?.length === 0 && (
          <NoEventsToShow message="No Events" />
        )}

        {!showLoading &&
          (segment === "all" || segment === "requests") &&
          allScheduleEvents?.length > 0 && (
            <EventList
              events={allFilteredScheduleEvents}
              listType={segment}
              notes={allFilteredDailyNotes}
              hoursPerDay={allHoursPerDay}
              auth={auth}
              user={user}
              onEdit={onEventClick}
              onForceRefresh={forceRefresh}
              isTimeClockPage={false}
              ref={eventListRef}
              weekStart={filter.start}
            />
          )}
      </IonContent>

      <FabButton fabRef={fabButtonRef} onFabModalClosed={onFabModalClosed} />

      {toast.simpleToastJsx}

      <IonModal
        mode="md"
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
      >
        <ScheduleFilterModal
          filter={filter}
          auth={auth}
          showRangeFilter={segment === "all"}
          onDismissModal={onDismissFilterModal}
          isOpen={showFilterModal}
          onCancelModal={() => setShowFilterModal(false)}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showEventModal}
        onDidDismiss={() => setShowEventModal(false)}
      >
        <EditEventModal
          isOpen={showEventModal}
          auth={auth}
          onCancelModal={() => setShowEventModal(false)}
          onDismissModal={(toastMessage) => onEventUpdated(toastMessage)}
          scheduleEventId={editEventId}
          initialEvent={initialEditEvent}
        />
      </IonModal>
    </IonPage>
  );
};

export default EventsPage;
