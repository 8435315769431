import {EventGroup} from "../enums";
import {IdName} from "./IdName";
import {FilteredRangeType} from "../enums/FilteredRangeType";

export enum EventFilterView {
  schedule = 0,
  clock = 1
}

export const FILTER_ALL_EMPLOYEES: IdName = {
  id: -1,
  name: "All Employees"
};

export interface EventFilter {
  settingsLocId: number;
  dnLocIds: number[];
  start: Date;
  end: Date;
  filteredRangeType: FilteredRangeType;
  filterByEmployee: boolean;
  positionIds: number[];
  eventTypeId: number;
  eventStatusId: number;
  employee: IdName;
  tagId?: number;
  view: EventFilterView;
}

export const initialEventFilter: EventFilter = {
  settingsLocId: 0,
  dnLocIds: [],
  start: new Date(),
  end: new Date(),
  filteredRangeType: FilteredRangeType.Week,
  filterByEmployee: false,
  positionIds: [],
  eventTypeId: EventGroup.All,
  eventStatusId: EventGroup.All,
  employee: FILTER_ALL_EMPLOYEES,
  tagId: undefined,
  view: EventFilterView.schedule
};

export interface StoredEventFilter {
  settingsLocId: number;
  dnLocIds: number[];
  filteredRangeType: FilteredRangeType;
  positionIds: number[];
  tagId?: number;
  eventTypeId: number;
  eventStatusId: number;
  employee: IdName;
}

export const initialStoredEventFilter: StoredEventFilter = {
  settingsLocId: 0,
  dnLocIds: [] as number[],
  filteredRangeType: FilteredRangeType.Week,
  positionIds: [] as number[],
  tagId: undefined as number | undefined,
  eventTypeId: EventGroup.All,
  eventStatusId: EventGroup.All,
  employee: FILTER_ALL_EMPLOYEES,
};

