import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonModal, IonGrid, IonRow, IonCol,
} from "@ionic/react";
import "../../global-styles.scss";
import {AuthorizedItemsContext} from "../../context/AuthorizedItemsContext";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {Role} from "../../enums";
import {authHelper, PositionEx} from "../../util/authHelper";
import PositionSelector from "./PositionSelector";
import {employeesApi} from "../../api/employeesApi";
import {EmployeeCommunication} from "../../orbital-interfaces/EmployeeCommunication";
import {ApplicationUser, ApplicationUserContext} from "../../context/ApplicationUserContext";
import EmployeeSelectorModal from "./EmployeeSelectorModal";
import { useShowLoading } from "../../hooks/useShowLoading";

interface MessageChannel {
  id: number;
  text: string;
}

const messageChannels: MessageChannel[] = [
  { id: -1, text: "All Channels" },
  { id: 1, text: "Email" },
  { id: 2, text: "Text" },
  { id: 3, text: "Push Notification" },
];

interface EmployeesSelectorProps {
  onUpdate: (
    empList: EmployeeCommunication[],
    locId: number,
    channel: number
  ) => void;
}

// --------------------------------------------------------------------------------------
function getFirstAvailablePosition(auth: AuthorizedItems, user: ApplicationUser): PositionEx {
  // Try to find in default location first.
  const defaultLoc = authHelper.getLocationFromLocationId(auth, user.defaultLocationId);
  if (defaultLoc.adminAllDepts) {
    return {
      location: defaultLoc,
      department: null,
      position: null
    };
  }

  for (let j = 0; j < defaultLoc.departments.length; j++) {
    const curDep = defaultLoc.departments[j];
    if (curDep.isDeptAdmin) {
      return {
        location: defaultLoc,
        department: curDep,
        position: null,
      };
    }
  }

  // nothing in the default location, try the other locations

  for (let i = 0; i < auth.locations.length; i++) {
    const curLoc = auth.locations[i];

    if (curLoc.adminAllDepts) {
      return {
        location: curLoc,
        department: null,
        position: null,
      };
    }

    for (let j = 0; j < curLoc.departments.length; j++) {
      const curDep = curLoc.departments[j];
      if (curDep.isDeptAdmin) {
        return {
          location: curLoc,
          department: curDep,
          position: null,
        };
      }
    }
  }

  throw new Error(
    "Could not find a department where this user could send messages"
  );
}

// --------------------------------------------------------------------------------------
function getEmployeeButtonName(selList: EmployeeCommunication[]) {
  if (selList.length === 0) {
    return "None Selected";
  }

  if (selList.length === 1) {
    return selList[0].name;
  }

  return `${selList.length} Selected`;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EmployeesSelector: React.FC<EmployeesSelectorProps> = ({ onUpdate }) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const [selectedRole, setSelectedRole] = useState("All" as "All" | Role);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const loading = useShowLoading("Retreiving employee list");
  const [selectedChannel, setSelectedChannel] = useState(-1);
  const [selectedPosition, setSelectedPosition] = useState(getFirstAvailablePosition(auth, user));
  const [selectedEmployees, setSelectedEmployees] = useState([] as EmployeeCommunication[]);
  const [employeeList, setEmployeeList] = useState([] as EmployeeCommunication[]);

  // --------------------------------------------------------------------------------------
  function onAddMessageModalCancel() {
    setShowEmployeeModal(false);
  }

  // --------------------------------------------------------------------------------------
  function fetchEmployees(
    locId: number,
    depId: number,
    posId: number,
    channel: number,
    role: string
  ) {
    loading.setShowLoading(true);
    employeesApi
      .getEmployeeListForCommunication(user, locId, depId, posId, channel, role)
      .then((emp) => {
        setEmployeeList(emp);
      })
      .finally(() => {
        loading.setShowLoading(false);
      });
  }

  // --------------------------------------------------------------------------------------
  function onRoleUpdate(newRole: "All" | Role) {
    if (newRole !== selectedRole) {
      setSelectedRole(newRole);
      setSelectedEmployees([]);
      fetchEmployees(
        selectedPosition.location.id,
        selectedPosition.department === null
          ? -1
          : selectedPosition.department.id,
        selectedPosition.position === null ? -1 : selectedPosition.position.id,
        selectedChannel,
        newRole
      );
    }
  }

  // --------------------------------------------------------------------------------------
  function onPositionUpdate(posEx: PositionEx) {
    setSelectedPosition(posEx);
    setSelectedEmployees([]);
    fetchEmployees(
      posEx.location.id,
      posEx.department === null ? -1 : posEx.department.id,
      posEx.position === null ? -1 : posEx.position.id,
      selectedChannel,
      selectedRole
    );
  }

  // --------------------------------------------------------------------------------------
  function onChannelUpdate(newChannelId: number | null | undefined)  {
    if (newChannelId === null || newChannelId === undefined) {
      return;
    }

    if (newChannelId !== selectedChannel) {
      setSelectedEmployees([]);
      setSelectedChannel(newChannelId);
    }
  }

  // --------------------------------------------------------------------------------------
  function onEmployeeUpdate(newEmployeeList: EmployeeCommunication[]) {
    setSelectedEmployees(newEmployeeList);
    setShowEmployeeModal(false);
    onUpdate(newEmployeeList, selectedPosition.location.id, selectedChannel);
  }

  // --------------------------------------------------------------------------------------
  // On initial render, fetch from the first location in the list since that's what's
  // selected when the modal is initially rendered.  Subsequent updates to the
  // location/department/position will use the right location id
  useEffect(() => {
    if (auth.locations && auth.locations.length > 0) {
      fetchEmployees(auth.locations[0].id, -1, -1, 0, "All");
    }

    // eslint-disable-next-line
  }, [auth.locations]);

  if (!auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
      </>
    );
  } else {
    return (
      <>
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Role</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                mode="ios"
                className="select"
                interface="action-sheet"
                value={selectedRole}
                onIonChange={(e) => {
                  onRoleUpdate(e.detail.value);
                }}
              >
                <IonSelectOption value={"All"} key={-1}>
                  All Roles
                </IonSelectOption>
                <IonSelectOption value={Role.BusinessAdmin} key={2}>
                  Business Admin
                </IonSelectOption>
                <IonSelectOption value={Role.LocationAdmin} key={3}>
                  Location Admin
                </IonSelectOption>
                <IonSelectOption value={Role.Admin} key={4}>
                  Admin
                </IonSelectOption>
                <IonSelectOption value={Role.Employee} key={5}>
                  Employee
                </IonSelectOption>
              </IonSelect>
            </IonCol>
          </IonRow>
          <PositionSelector
            currentPosition={selectedPosition}
            onPositionUpdate={onPositionUpdate}
          />
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">Send To</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonSelect
                mode="ios"
                className="select"
                interface="action-sheet"
                value={selectedChannel}
                onIonChange={(e) => onChannelUpdate(e.detail.value)}
              >
                {messageChannels.map((channel) => (
                  <IonSelectOption key={channel.id} value={channel.id}>
                    {channel.text}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <IonLabel className="label">
                Employees <br />({selectedEmployees.length} / {employeeList.length})
              </IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonButton
                className="button-selectEmployees-messages"
                onClick={() => setShowEmployeeModal(true)}>
                {getEmployeeButtonName(selectedEmployees)}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {loading.showLoadingJsx}
        <IonModal
          mode="md"
          cssClass="mini-modal"
          onDidDismiss={() => setShowEmployeeModal(false)}
          isOpen={showEmployeeModal}
        >
          <EmployeeSelectorModal
            channel={selectedChannel}
            onCancel={onAddMessageModalCancel}
            onOk={onEmployeeUpdate}
            employeeList={employeeList}
            selectedEmployeeList={selectedEmployees}
          />
        </IonModal>
      </>
    );
  }
};

export default EmployeesSelector;
