import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonModal} from "@ionic/react";
import "../../../global-styles.scss";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {EditorReducerAction, EditorValues, isAdminForThisEvent} from "../EditEventModalState";
import {authHelper} from "../../../util/authHelper";
import {requestsApi} from "../../../api/requestsApi";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";
import useDialogBox from "../../../hooks/useDialogBox";
import {EventStatus, EventType} from "../../../enums";
import EventRequestModalEx from "./EventRequestModalEx";
import {useShowLoading} from "../../../hooks/useShowLoading";
import {MessageTypes} from "../../../enums/MessageTypes";

interface EventRequestEditorProps {
  auth: AuthorizedItems;
  update: React.Dispatch<EditorReducerAction>;
  cancelEditModal: (message: string) => void;
  editorValues: EditorValues;
  messageId?: number | undefined;
  messageType: MessageTypes | null;
}

export enum EventRequestEnum {
  None,
  UndoCoverRequest,
  CreateShiftRequest,
  DeleteRequest,
  CreateCoverRequest,
  ApproveShiftRequest,
  UndoCover
}


// --------------------------------------------------------------------------------------
function getEventRequestType(
  ev: EditorValues,
  auth: AuthorizedItems,
  messageType: MessageTypes | null): EventRequestEnum {

  if (messageType === MessageTypes.NoClockOutAlertMessage ||
      messageType === MessageTypes.NoClockInAlertMessage ||
      messageType === MessageTypes.LateClockInMessage) {
    return EventRequestEnum.None;
  }

  const isAdmin = isAdminForThisEvent(ev, auth);

  if (ev.scheduleEventId <= 0) {
    return EventRequestEnum.None;
  }

  if ((ev.transferToScheduleEventId !== null || ev.transferFromScheduleEventId !== null) &&
      ev.eventStatus === EventStatus.Approved &&
      (ev.eventType === EventType.Cover || ev.eventType === EventType.Shift)) {
    if (isAdmin) {
      return EventRequestEnum.UndoCover;
    }
  }

  // For Cover-Requests
  if (ev.eventType === EventType.Cover && ev.eventStatus === EventStatus.Request)  {
    if (ev.employee.id === auth.employeeId) {
      return isAdmin ? EventRequestEnum.None :  EventRequestEnum.UndoCoverRequest;
    }

    if (authHelper.hasPosition(auth, ev.position.position.id)) {
      return EventRequestEnum.CreateShiftRequest;
    }

    return EventRequestEnum.None;
  }

  // For Shift-Requests
  if (ev.eventType === EventType.Shift && ev.eventStatus === EventStatus.Request) {
    if (isAdmin) {
      return EventRequestEnum.ApproveShiftRequest;
    }

    if (ev.employee.id === auth.employeeId) {
      return EventRequestEnum.DeleteRequest;
    }
  }


  // For Shift-Approveds
  if (ev.eventType === EventType.Shift &&
      ev.eventStatus === EventStatus.Approved &&
      ev.employee.id === auth.employeeId) {
    return EventRequestEnum.CreateCoverRequest;
  }

  return EventRequestEnum.None;
}

// --------------------------------------------------------------------------------------
function getButtonText(ev: EditorValues, auth: AuthorizedItems, messageType: MessageTypes | null) {
  const isAdmin = isAdminForThisEvent(ev, auth);

  if (messageType === MessageTypes.NoClockOutAlertMessage ||
      messageType === MessageTypes.NoClockInAlertMessage ||
      messageType === MessageTypes.LateClockInMessage) {
    return "";
  }

  if (ev.eventStatus === EventStatus.Approved && (ev.eventType === EventType.Cover || ev.eventType === EventType.Shift) &&
    (ev.transferFromScheduleEventId !== null || ev.transferToScheduleEventId !== null) && isAdmin) {
    return "Undo Cover-Approved";
  }

  if (ev.eventType === EventType.Shift && ev.eventStatus === EventStatus.Approved) {
    return "Create Cover-Request";
  }

  if (ev.eventType === EventType.Shift && ev.eventStatus === EventStatus.Request) {
    if (isAdmin) {
      return "Approve Shift-Request";

    }
    if (ev.employee.id === auth.employeeId) {
      return "Delete Shift-Request";
    }
  }

  if (ev.eventType === EventType.Cover && ev.eventStatus === EventStatus.Request) {
    if (ev.employee.id === auth.employeeId) {
      return "Undo Cover-Request";
    } else {
      return "Make Shift-Request";
    }
  }


  return "UNKNOWN";
}


// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventRequestEditorEx: React.FC<EventRequestEditorProps> = ({
  auth,
  cancelEditModal,
  editorValues,
  messageId,
  messageType
}: EventRequestEditorProps) => {
  const { user } = useContext(ApplicationUserContext);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const dlg = useDialogBox();
  const loading = useShowLoading("Please wait...");
  const [canMakeShiftRequest, setCanMakeShiftRequest] = useState(undefined as boolean | undefined);
  const requestType = getEventRequestType(editorValues, auth, messageType);
  const buttonText = getButtonText(editorValues, auth, messageType);
  const onComplete = (message: string) => {
    setShowRequestModal(false);
    cancelEditModal(message);
  };

  useEffect(() => {
    if ("Make Shift-Request" === buttonText && canMakeShiftRequest === undefined) {
      requestsApi
        .canMakeShiftRequest(user, editorValues)
        .then((results) => {
          setCanMakeShiftRequest(results.canMakeShiftRequest);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonText, canMakeShiftRequest]);

  // -----------------------------------------------------------------------------------
  function onApply(employeeId: number | null, notes: string, sendMessage: boolean) {
    loading.setShowLoading(true);
    switch (requestType) {
      case EventRequestEnum.CreateCoverRequest:
        requestsApi
          .createCoverRequest(
            user,
            editorValues.scheduleEventId,
            editorValues.locationId,
            0,
            sendMessage,
            notes,
            employeeId
          )
          .then(() => {
            loading.setShowLoading(false);
            onComplete("Cover-Request Created");
          });
        break;

      case EventRequestEnum.CreateShiftRequest:
        requestsApi
          .makeShiftRequest(
            user,
            editorValues.scheduleEventId,
            editorValues.locationId,
            0,
            sendMessage,
            notes
          )
          .then((results) => {
            loading.setShowLoading(false);
            if (results.error) {
              dlg.showAlert("Shift-Request Failed", results.message, () => {});
            } else {
              onComplete(results.message); // "Shift-Request Created"
            }
          });
        break;

      case EventRequestEnum.DeleteRequest:
        requestsApi
          .removeShiftRequest(
            user,
            editorValues.locationId,
            editorValues.scheduleEventId,
            editorValues.eventType,
            editorValues.eventStatus,
            undefined
          )
          .then(() => {
            loading.setShowLoading(false);
            onComplete("Shift-Request Removed");
          });
        break;

      case EventRequestEnum.UndoCoverRequest:
        requestsApi.undoCoverRequest(user, editorValues.scheduleEventId).then(() => {
          loading.setShowLoading(false);
          onComplete("Removed Cover-Request");
        });
        break;

      case EventRequestEnum.ApproveShiftRequest:
        if (messageId === null || messageId === undefined) {
          break;
        }

        requestsApi.approveShiftRequest(user, messageId).then(res => {
          loading.setShowLoading(false);
          if (res.error) {
            dlg.showAlert("Could not approve", res.message, () => {});
          } else {
            onComplete("Approved");
          }
        });

        break;

      case EventRequestEnum.UndoCover:
        requestsApi.undoCoverApproved(user, editorValues.scheduleEventId).then(res => {
          loading.setShowLoading(false);
          if (res.error) {
            dlg.showAlert("Could not undo Cover", res.message, () => {});
          } else {
            onComplete("Cover Undone");
          }
        });

        break;
    }
  }

  if (!authHelper.isAuthItemsValid(auth) || requestType === EventRequestEnum.None) {
    return <></>;
  }

  return (
    <>
      <IonButton
        className="apply-button"
        expand="block"
        disabled={buttonText === "Make Shift-Request" && !canMakeShiftRequest}
        onClick={() => {
          setShowRequestModal(true);
        }}
      >
        {buttonText}
      </IonButton>

      <IonModal
        mode="md"
        isOpen={showRequestModal}
        cssClass="mini-modal event-request-modal"
        onDidDismiss={() => setShowRequestModal(false)}
      >
        <EventRequestModalEx
          onCancel={() => {
            setShowRequestModal(false);
          }}
          onOk={onApply}
          editorValues={editorValues}
          eventRequestType={requestType}
          buttonText={buttonText}
        />
        {dlg.alertBoxJsx}
        {loading.showLoadingJsx}
      </IonModal>
    </>
  );
};

export default EventRequestEditorEx;
