import React, { useState } from "react";
import { useInterval } from './../hooks/useInterval';
import { format } from 'date-fns';

// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const PunchClockHeader: React.FC = () => {
  const [ time, setTime ] = useState(new Date());
  const [ toggle, setToggle ] = useState(false);

  // -----------------------------------
  // Refresh time every X seconds (see 3rd param, in milliseconds)
  useInterval(() => {
    setTime(new Date());
    setToggle(!toggle);
  }, true, 1 * 1000, []);

  return (
    <>
      {format(time, "h")}
      <span style={toggle ? {color: "lightgray"} : {color: "white"}}>:</span>
      {format(time, "mm aa").toUpperCase()}
    </>
  );
};

export default PunchClockHeader;
