import {
  ClockInRequirement,
  ClockLocationRequirement,
  ClockOutValueType,
  EmployeeSort,
  TimeFormat,
  UnscheduledBreakOptions,
} from "../enums";

export interface LocationSettings {
  LocationId: number;
  DayStartTime: any;
  DayWeekStartsOn: string;
  EmployeeRequestWindowStart: number;
  EmployeeRequestWindowEnd: number;
  EmployeeDaysToShowSchedule: number;
  RequestPanelDisplayDays: number;
  EmployeesShowShiftEventType: boolean;
  ShowUnavailableEventType: boolean;
  ShowVacationEventType: boolean;
  ShowAbsentEventType: boolean;
  ShowSpecialDates: boolean;
  AllowEmployeesToDeleteUnavailableAndVacationApproved: boolean;
  EmployeesOnlyViewOwnPositions: boolean;
  EmployeesOnlyViewOwnDepartments: boolean;
  EnableLegacyBreak: boolean;
  EnableLegacyBreakStart: boolean;
  DefaultBreakDuration: any;
  DefaultBreakStartTime: any;
  ShowTimeClockBreak: boolean;
  ShowBreak: boolean;
  EnableBreakStartTime: boolean;
  EnableEnhancedBreakManagement: boolean;
  TimeZoneOffset: number;
  OtherEmployeesViewableTypeAndStatus: any;
  NotesPanel: number;
  ClockOutValueLabel: string;
  ClockOutValueType: ClockOutValueType;
  EnableEventConfirm: boolean;
  TimeFormat: TimeFormat;
  TimeFormatDigits: number;
  TimeFormatDecimals: number;
  LockDate: any;
  HideScheduleEventsInPast: boolean;
  NewEventDefaultStartTime: any;
  NewEventDefaultEndTime: any;
  EnableAutoAdjustingDateTimes: any;
  GenericEmployeeIdName: string;
  GenericEmployeeId2Name: string;
  GenericEmployeeId3Name: string;
  GenericEmployeePerPositionIdName: string;
  GenericEmployeePerPositionId2Name: string;
  GenericEmployeePerPositionId3Name: string;
  GenericPositionIdName: string;
  GenericPositionId2Name: string;
  GenericPositionId3Name: string;
  GenericDepartmentIdName: string;
  GenericDepartmentId2Name: string;
  GenericDepartmentId3Name: string;
  GenericLocationIdName: string;
  GenericLocationId2Name: string;
  GenericLocationId3Name: string;
  CalculatedSchedulePublishedTo: any;
  RequireGeoLocation: boolean;
  RequireIPAddress: boolean;
  EnablePTO: boolean;
  ClockInWithDifferentTag: boolean;
  UnscheduledBreakOptions: UnscheduledBreakOptions;
  ClockInRequirement: ClockInRequirement;
  ClockLocationRequirement: ClockLocationRequirement;
  EmployeeSort: EmployeeSort;
  EnableDirectCoverRequest: boolean;
  EnablePositionChangeForEmployeePosition: boolean;
  EnhancedBreakManagementDefaultStartTime: string;   // TimeSpan
  EnhancedBreakManagementDefaultEndTime: string;     // TimeSpan
  ForgotToClockOutTime: string; // TimeSpan
}

export const dummyLocationSettings: LocationSettings = {
  LocationId: -1,
  DayStartTime: null,
  DayWeekStartsOn: "",
  EmployeeRequestWindowStart: -1,
  EmployeeRequestWindowEnd: -1,
  EmployeeDaysToShowSchedule: -1,
  RequestPanelDisplayDays: -1,
  EmployeesShowShiftEventType: false,
  ShowUnavailableEventType: false,
  ShowVacationEventType: false,
  ShowAbsentEventType: false,
  ShowSpecialDates: false,
  AllowEmployeesToDeleteUnavailableAndVacationApproved: false,
  EmployeesOnlyViewOwnPositions: false,
  EmployeesOnlyViewOwnDepartments: false,
  EnableLegacyBreak: false,
  EnableLegacyBreakStart: false,
  DefaultBreakDuration: null,
  DefaultBreakStartTime: null,
  ShowTimeClockBreak: false,
  ShowBreak: false,
  EnableBreakStartTime: false,
  EnableEnhancedBreakManagement: false,
  TimeZoneOffset: -1,
  OtherEmployeesViewableTypeAndStatus: null,
  NotesPanel: -1,
  ClockOutValueLabel: "",
  ClockOutValueType: ClockOutValueType.Money,
  EnableEventConfirm: false,
  TimeFormat: TimeFormat.HoursMinutes,
  TimeFormatDigits: -1,
  TimeFormatDecimals: -1,
  LockDate: null,
  HideScheduleEventsInPast: false,
  NewEventDefaultStartTime: null,
  NewEventDefaultEndTime: null,
  EnableAutoAdjustingDateTimes: null,
  GenericEmployeeIdName: "",
  GenericEmployeeId2Name: "",
  GenericEmployeeId3Name: "",
  GenericEmployeePerPositionIdName: "",
  GenericEmployeePerPositionId2Name: "",
  GenericEmployeePerPositionId3Name: "",
  GenericPositionIdName: "",
  GenericPositionId2Name: "",
  GenericPositionId3Name: "",
  GenericDepartmentIdName: "",
  GenericDepartmentId2Name: "",
  GenericDepartmentId3Name: "",
  GenericLocationIdName: "",
  GenericLocationId2Name: "",
  GenericLocationId3Name: "",
  CalculatedSchedulePublishedTo: null,
  RequireGeoLocation: false,
  RequireIPAddress: false,
  EnablePTO: false,
  ClockInWithDifferentTag: false,
  UnscheduledBreakOptions: UnscheduledBreakOptions.None,
  ClockInRequirement: ClockInRequirement.AnyShift,
  ClockLocationRequirement: ClockLocationRequirement.ClockAnywhere,
  EmployeeSort: EmployeeSort.LastName,
  EnableDirectCoverRequest: false,
  EnablePositionChangeForEmployeePosition: false,
  EnhancedBreakManagementDefaultStartTime: "",   // TimeSpan
  EnhancedBreakManagementDefaultEndTime: "",     // TimeSpan
  ForgotToClockOutTime: "", // TimeSpan
}

