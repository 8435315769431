import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonList,
  IonText,
  IonLoading, IonFooter,
} from "@ionic/react";
import "../global-styles.scss";
import { userApi } from "../api/userApi";
import { RouteComponentProps } from "react-router";
import useDialogBox from "../hooks/useDialogBox";
import env from "../appFunctions/environment";
import { timeHelper as th } from '../util/timeHelper';

interface ResetPasswordPageProps extends RouteComponentProps { }

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ history }: ResetPasswordPageProps) => {
  const { alertBoxJsx, showAlert } = useDialogBox();
  const [showLoading, setShowLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [usernameErrors] = useState("");
  const [emailErrors] = useState("");
  const [loginEnabled, setLoginEnabled] = useState(false);

  // ----------------------------------------------------------------------------------------------
  function validate(username: string, email: string): boolean {
    if (username.length < 6 || email.length === 0) {
      return false;
    }

    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }

  // ----------------------------------------------------------------------------------------------
  function onUsernameChange(newUsername: string) {
    setUsername(newUsername);
    setLoginEnabled(validate(newUsername, email));
  }

  // ----------------------------------------------------------------------------------------------
  function onEmailChange(newEmail: string) {
    if (newEmail === email) {
      return;
    }

    setEmail(newEmail);
    setLoginEnabled(validate(username, newEmail));
  }

  // ----------------------------------------------------------------------------------------------
  function onSendClick() {
    const envUser = env.getUserAndEnvironmentFromInput(username);
    setShowLoading(true);
    userApi.resetPassword(envUser.username, email, envUser.env.key).then(response => {
      setShowLoading(false);

      if (!response.error) {
        showAlert(
          "Sent",
          "Check your email for a 'Change Password' message from Orbital Shift, " +
          "then return to log in with your new password",
          () => {
            history.push("/login", { direction: "none" });
            history.goForward();
          });

        return;
      }

      showAlert("Error", response.message, () => { });
      return;
    })
  }

  // ----------------------------------------------------------------------------------------------
  // function onBackClick() {
  //   history.push("/login", { direction: "none" });
  //   history.goForward();
  // }

  return (
    <IonPage id="login-page">
      <IonContent className="login-content">
        <div className="login-content-container">
          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            // spinner="dots"
            message={"Logging in..."}
            duration={0}
          />

          {alertBoxJsx}

          <IonList className="form-list">
            <div className="item">
              <div className="label">User</div>
              <div className="field-container">
                <input
                  className="input"
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                  onChange={(e) => {
                    onUsernameChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {usernameErrors.length > 0 && (
              <IonText color="light">
                <p className="ion-padding-start">{usernameErrors}</p>
              </IonText>
            )}

            <div className="item">
              <div className="label">Email</div>
              <div className="field-container">
                <input
                  className="input"
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    onEmailChange(e.target.value);
                  }}
                />
              </div>
            </div>

            {emailErrors.length > 0 && (
              <IonText color="light">
                <p className="ion-padding-start">{emailErrors}</p>
              </IonText>
            )}


            <IonButton
              className="login-button"
              type="submit"
              onClick={onSendClick}
              expand="block"
              disabled={!loginEnabled}
            >
              Send Password Reset Email
            </IonButton>
            {/* <IonButton
              className="login-button"
              type="submit"
              onClick={onBackClick}
              expand="block"
            >
              Back
            </IonButton> */}
          </IonList>
        </div>
      </IonContent>
      <IonFooter className="login-footer">
        <div className="divider-line"></div>
        <div className="legal-statement">
          By continuing, you agree to{" "}
          <a className="terms-privacy-link" href="https://app.orbitalshift.com/Terms">
            Terms of Service
          </a>{" "}
          and{" "}
          <a className="terms-privacy-link" href="https://app.orbitalshift.com/Privacy">
            Privacy Policy.
          </a>
        </div>
        <div className="copyright">
          © 2009-{th.getCurrentYear()} Orbital Shift, Inc. All rights reserved.
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default ResetPasswordPage;
