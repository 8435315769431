import { EventFilter } from "../orbital-interfaces/EventFilter";
import { AuthorizedItems } from "../orbital-interfaces/AuthorizedItems";
import {EventGroup, EventType} from "../enums";
import {authHelper} from "./authHelper";
import {ApplicationUser} from "../context/ApplicationUserContext";

export interface EventTypeOption {
  value: number;
  text: string;
  color: string;
}

// ---------------------------------------------------------------------------------
function getFilterByEventGroup(f: EventFilter, group: EventGroup): EventFilter {
  return group === EventGroup.Custom
    ? { ...f, eventTypeId: f.eventTypeId, eventStatusId: f.eventStatusId }
    : { ...f, eventTypeId: group, eventStatusId: group };
}

// ---------------------------------------------------------------------------------
function getEventTypeOptions(auth: AuthorizedItems, user: ApplicationUser, filter?: EventFilter): EventTypeOption[] {
  if (!auth || !auth.locations || auth.locations.length === 0) {
    return [];
  }

  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);

  const eventTypeOptions: EventTypeOption[] = [
    {
      value: EventType.Shift,
      text: "Shift",
      color: "green",
    },
    {
      value: EventType.Cover,
      text: "Cover",
      color: "red",
    },
  ];

  if (filter) {
    eventTypeOptions.unshift({ value: -1, text: "All Event Types", color: "black" });
  }

  eventTypeOptions.push({
    value: EventType.Clock,
    text: "Clock",
    color: "green",
  });

  if (locSettings.ShowUnavailableEventType) {
    eventTypeOptions.push({
      value: EventType.Unavailable,
      text: "Unavailable",
      color: "yellow",
    });
  }

  if (locSettings.ShowVacationEventType) {
    eventTypeOptions.push({
      value: EventType.Vacation,
      text: "Vacation",
      color: "yellow",
    });
  }

  if (locSettings.ShowAbsentEventType) {
    const peTypes: EventTypeOption[] = [];
    const p2l: any = {};
    auth.locations.flatMap((l) =>
      l.departments.flatMap((d) => d.positions.map((p) => (p2l[p.id] = l)))
    );

    auth.payrollEvents.map((pe) => {
      if (!pe.name.startsWith("--")) {
        if (filter) {
          if (
            pe.positionIds.some((id) => filter.positionIds.indexOf(id) >= 0)
          ) {
            peTypes.push({
              value: pe.id,
              text: `${pe.name} (${p2l[pe.positionIds[0]].name})`,
              color: "yellow",
            });
          }
        } else {
          peTypes.push({
            value: pe.id,
            text: `${pe.name} (${p2l[pe.positionIds[0]].name})`,
            color: "yellow",
          });
        }

        return pe;
      } else {
        return null;
      }
    });
    peTypes.sort((p1, p2) => {
      return p1.text > p2.text ? 1 : -1;
    });
    return eventTypeOptions.concat(peTypes);
  }

  return eventTypeOptions;
}

export const eventHelper = {
  getFilterByEventGroup,
  getEventTypeOptions
}
