import React, {useContext, useEffect, useRef, useState} from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {arrowBackOutline} from "ionicons/icons";
import useDialogBox from "../../hooks/useDialogBox";
import useOrbitalDialogBox from "../../hooks/useOrbitalDialogBox";
import {AuthorizedItemsContext} from "../../context/AuthorizedItemsContext";
import {DailyNotes} from "../../orbital-interfaces/DailyNotes";
import {isSameDay, parseISO} from "date-fns";
import {authHelper, SelectedDepartment} from "../../util/authHelper";
import DailyNoteDepartmentSelector from "./DailyNoteDepartmentSelector";
import "../../global-styles.scss";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import useSimpleToast from "../../hooks/useSimpleToast";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {Role} from "../../enums";
import {ApiErrorResult} from "../../orbital-interfaces/ApiErrorResult";
import {dailyNotesApi} from "../../api/dailyNotesApi";
import {log} from "../../util/logger";

interface EditDailyNoteModalProps {
  onCloseModal: (toastMessage: string) => void;
  isOpen: boolean;
  note: DailyNotes | null;
  initialDate: Date;
  newNoteClicked: boolean;
}

// --------------------------------------------------------------------------------------
function isDifferentNote(left: DailyNotes | null, right: DailyNotes | null) {
  if (left === null && right !== null) {
    return true;
  }

  if (left !== null && right === null) {
    return true;
  }

  if (left === null && right === null) {
    return false;
  }

  if (left?.Role !== right?.Role) {
    return true;
  }

  if (left?.DailyNoteId !== right?.DailyNoteId) {
    return true;
  }
}

// --------------------------------------------------------------------------------------
function getFirstAvailableDepartment(
  auth: AuthorizedItems
): SelectedDepartment {

  if (authHelper.isAuthItemsValid(auth)) {
    if (auth.role === Role.Employee) {
      return {
        location: auth.locations[0],
        department: null
      }
    }
  } else {
    throw new Error("No authorized items to determine how to edit notes");
  }

  for (let i = 0; i < auth.locations.length; i++) {
    const curLoc = auth.locations[i];

    if (curLoc.adminAllDepts) {
      return {
        location: curLoc,
        department: null,
      };
    }

    for (let j = 0; j < curLoc.departments.length; j++) {
      const curDep = curLoc.departments[j];

      if (
        auth.role === Role.LocationAdmin ||
        auth.role === Role.BusinessAdmin
      ) {
        return {
          location: curLoc,
          department: curDep,
        };
      }

      if (curDep.isDeptAdmin) {
        return {
          location: curLoc,
          department: curDep,
        };
      }
    }
  }

  throw new Error("Could not find a department for this user to add a note");
}

// --------------------------------------------------------------------------------------
const EditDailyNoteModal: React.FC<EditDailyNoteModalProps> = ({
  onCloseModal,
  isOpen,
  note,
  initialDate,
}) => {
  const [originalNote, setOriginalNote] = useState(note);
  const toast = useSimpleToast();
  const dlg = useDialogBox();
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const dialog = useOrbitalDialogBox();
  const [selectedRole, setSelectedRole] = useState(
    note !== null ? note.Role : 1
  );

  const [selectedDepartment, setSelectedDepartment] = useState(
    note !== null
      ? authHelper.getSelectedDepartment(
          auth,
          note.LocationId,
          note.DepartmentId
        )
      : getFirstAvailableDepartment(auth)
  );

  const [noteText, setNoteText] = useState(note !== null ? note.Notes : "");
  const noteCharCountRef = useRef(note !== null ? note.Notes.length : 0);
  const [selectedDate, setSelectedDate] = useState(
    note !== null ? parseISO(note.EventStart) : initialDate
  );

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    log("dailyNotes_openDlg");
  }, []);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    let isMounted = true;
    if (isOpen) {
      if (originalNote === null && isMounted) {
        fetchDailyNote(selectedDepartment, selectedRole, selectedDate);
      }
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [isOpen, originalNote]);

  // --------------------------------------------------------------------------------------
  const fetchDailyNote = (
    dep: SelectedDepartment,
    role: number,
    date: Date
  ) => {
    dailyNotesApi
      .getDailyNote(
        user,
        dep.location.id,
        dep.department === null ? -1 : dep.department.id,
        role,
        date
      )
      .then((res: DailyNotes | ApiErrorResult) => {
        if ("message" in res) {
          if (res.error) {
          } else {
            setOriginalNote(null);
          }
        } else {
          if (
            originalNote !== null &&
            originalNote.DailyNoteId === res.DailyNoteId
          ) {
            return;
          }

          if (isDifferentNote(res, originalNote)) {
            setOriginalNote(res);
          }

          const newStart = parseISO(res.EventStart);
          if (!isSameDay(newStart, selectedDate)) {
            setSelectedDate(newStart);
          }

          if (
            res.LocationId !== selectedDepartment.location.id ||
            (res.DepartmentId === -1 &&
              selectedDepartment.department !== null) ||
            (res.DepartmentId !== -1 &&
              selectedDepartment.department === null) ||
            (selectedDepartment.department !== null &&
              res.DepartmentId !== selectedDepartment.department.id)
          ) {
            setSelectedDepartment(
              authHelper.getSelectedDepartment(
                auth,
                res.LocationId,
                res.DepartmentId
              )
            );
          }

          if (res.Role !== selectedRole) {
            setSelectedRole(res.Role);
          }

          if (res.Notes !== noteText) {
            setNoteText(res.Notes);
            noteCharCountRef.current = res.Notes.length;
          }
        }
      });
  };

  // --------------------------------------------------------------------------------------
  const onCancel = () => {
    if (
      (originalNote === null && noteText != null) ||
      (originalNote !== null && originalNote.Notes !== noteText)
    ) {
      updateBackend(
        selectedDepartment,
        selectedRole,
        selectedDate,
        noteText,
        true
      );
    } else {
      onCloseModal("");
    }
  };

  // --------------------------------------------------------------------------------------
  const updateBackend = (
    dep: SelectedDepartment,
    role: number,
    date: Date,
    text: string,
    isClosing: boolean
  ) => {
    if (
      (originalNote === null && noteText.length === 0) ||
      (originalNote !== null && originalNote.Notes === noteText)
    ) {
      if (isClosing) {
        onCloseModal("");
      }
      return;
    }

    if (originalNote !== null) {
      if (text.length === 0) {
        dailyNotesApi
          .deleteDailyNote(user, originalNote.DailyNoteId)
          .then((res) => {
            if (res.error) {
              dlg.showAlert("Can't Delete Note", res.message, () => {});
            } else {
              log("dailyNotes_deleted");
              if (isClosing) {
                onCloseModal("Note Deleted");
              } else {
                toast.showToast("Note Deleted");
              }
            }
          });
      }

      dailyNotesApi
        .updateDailyNote(user, originalNote.DailyNoteId, text, role, date)
        .then((res) => {
          if (res.error) {
            dlg.showAlert("Can't Update Note", res.message, () => {});
          } else {
            log("dailyNotes_updated");
            if (isClosing) {
              onCloseModal("Note Updated");
            } else {
              toast.showToast("Note Updated");
            }
          }
        });
    } else {
      dailyNotesApi
        .insertDailyNote(
          user,
          dep.location.id,
          dep.department === null ? -1 : dep.department.id,
          role,
          date,
          text
        )
        .then((res) => {
          if (res.error) {
            dlg.showAlert("Can't Add Daily Note", res.message, () => {});
          } else {
            log("dailyNotes_added");
            if (isClosing) {
              onCloseModal("Note Added");
            } else {
              toast.showToast("Note Added");
            }
          }
        });
    }
  };

  // --------------------------------------------------------------------------------------
  const onDepartmentUpdate = (dep: SelectedDepartment) => {
    if (
      dep.location.id !== selectedDepartment.location.id ||
      dep.department !== selectedDepartment.department
    ) {
      setSelectedDepartment(dep);
      updateBackend(
        selectedDepartment,
        selectedRole,
        selectedDate,
        noteText,
        false
      );
      fetchDailyNote(dep, selectedRole, selectedDate);
    }
  };

  // --------------------------------------------------------------------------------------
  const onRoleUpdate = (newRole: number) => {
    if (newRole !== selectedRole) {
      setSelectedRole(newRole);
      updateBackend(
        selectedDepartment,
        selectedRole,
        selectedDate,
        noteText,
        false
      );
      fetchDailyNote(selectedDepartment, newRole, selectedDate);
    }
  };

  // --------------------------------------------------------------------------------------
  const onDateSelected = (dateStr: string | null | undefined) => {
    if (dateStr === null || dateStr === undefined) {
      return;
    }

    const date = parseISO(dateStr);
    if (date !== selectedDate) {
      setSelectedDate(date);
      updateBackend(
        selectedDepartment,
        selectedRole,
        selectedDate,
        noteText,
        false
      );
      fetchDailyNote(selectedDepartment, selectedRole, date);
    }
  };

  // --------------------------------------------------------------------------------------
  const onTextChanged = (newText: string | null | undefined) => {
    if (newText === null || newText === undefined) {
      return;
    }

    if (newText.length !== noteCharCountRef.current) {
      noteCharCountRef.current = newText.length;
    }

    if (newText !== noteText) {
      setNoteText(newText);
    }
  };

  if (!isOpen || !auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
        {dlg.alertBoxJsx}
      </>
    );
  } else {
    return (
      <>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <IonIcon icon={arrowBackOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>Daily Notes</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent class="outer-content edit-daily-modal-content">
          <IonGrid>
            <DailyNoteDepartmentSelector
              currentDepartment={selectedDepartment}
              onDepartmentUpdate={onDepartmentUpdate}
            />
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Viewable By</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonSelect
                  className="select"
                  mode="ios"
                  interface="action-sheet"
                  value={selectedRole}
                  onIonChange={(e) => {
                    onRoleUpdate(e.detail.value);
                  }}
                >
                  <IonSelectOption value={1} key={1}>
                    All Employees
                  </IonSelectOption>
                  <IonSelectOption value={2} key={2}>
                    Only Admins
                  </IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Date</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonDatetime
                  mode="ios"
                  className="select"
                  displayFormat="DDD, M/D/YY"
                  pickerFormat="MMM D YYYY"
                  min="2005-01-01"
                  max="2040-01-01"
                  value={selectedDate.toISOString()}
                  onIonChange={(e) => {
                    onDateSelected(e.detail.value);
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div
                  style={{ width: "100%", borderBottom: "1px solid #ddd" }}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Daily Notes</IonLabel>
              </IonCol>
              <IonCol size="8"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonTextarea
                  mode="md"
                  className="textarea"
                  value={noteText}
                  // placeholder={"Enter note"}
                  maxlength={180}
                  rows={4}
                  spellcheck={true}
                  wrap="hard"
                  autoGrow={true}
                  debounce={50}
                  onIonChange={(e) => {
                    onTextChanged(e.detail.value);
                  }}
                />
                <div className="character-counter">
                  {noteText?.length ?? 0}/180
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        {dlg.alertBoxJsx}
        {dialog.dialogBoxJsx}
        {toast.simpleToastJsx}
      </>
    );
  }
};

export default EditDailyNoteModal;
