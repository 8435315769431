import {EventType, EventStatus, AdminAuthorization, Role} from "../../enums";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {isAfter, parseISO} from "date-fns";
import {authHelper} from "../../util/authHelper";
import {scheduleEventHelper} from "../../util/scheduleEventHelper";

// --------------------------------------------------------------------------------------
export function getEventEditAuthorization(
  eventType: EventType,
  eventStatus: EventStatus,
  eventEmployeeId: number,
  eventStart: string,
  eventEnd: string,
  eventLocationId: number,
  eventDepartmentId: number,
  eventPositionId: number,
  eventConfirmed: boolean,
  auth: AuthorizedItems
): boolean  {


  if (auth.role === Role.BusinessAdmin) {
    return true;
  }

  if (auth.role === Role.LocationAdmin) {
    if (scheduleEventHelper.isPositionSpecificEvent(eventType)) {
      if (auth.locations.filter((l) => l.id === eventLocationId).length === 0) {
        return false;
      }
    }

    return true;
  }

  const endOfEvent = parseISO(eventEnd);
  const now = new Date();
  const afterEnd = isAfter(now, endOfEvent);
  let employeeEvent = false;
  const adminAuth = authHelper.getAdminAuthorizationForDepartment(
    auth,
    eventDepartmentId
  );

  // If the user is in the Admin role
  if (auth.role === Role.Admin) {
    if (eventEmployeeId === auth.employeeId) {
      if (eventType === EventType.Clock) {
        return (
          (adminAuth & AdminAuthorization.CanEditOwnClockEvents) ===
          AdminAuthorization.CanEditOwnClockEvents
        );
      }

      if ((adminAuth & AdminAuthorization.CanEditSchedules) ===
        AdminAuthorization.CanEditSchedules) {
        return true;
      }

      employeeEvent = true;
    } else {
      // Not the user's event, someone else's

      if (eventType === EventType.Clock) {
        return ((adminAuth & AdminAuthorization.CanEditEmployeeClockEvents) ===
          AdminAuthorization.CanEditEmployeeClockEvents
        );
      }

      if (authHelper.canEditSchedules(auth, eventLocationId, eventDepartmentId)) {
        return true;
      } else {
        // If the admin has a position in the selected department
        if (authHelper.adminIsInDepartment(auth, eventDepartmentId)) {
          // Treat the admin as an employee for event editing
          employeeEvent = true;
        } else {
          // disable editing
          return false;
        }
      }
    }
  }

  if (auth.role === Role.Employee || employeeEvent) {
    if (eventEmployeeId === auth.employeeId) {
      if (afterEnd) {
        return false;
      }

      if (eventType === EventType.Clock) {
        return false;
      }

      if (eventType === EventType.Shift) {
        if (eventStatus === EventStatus.Approved) {
          return true;
        }

        if (eventStatus === EventStatus.Request) {
          return true;
        }

        // else Shift Request-Denied
        return false;
      }

      if (eventType === EventType.Cover) {
        return eventStatus === EventStatus.Request;
      }

      if (!scheduleEventHelper.isPositionSpecificEvent(eventType) &&
           authHelper.canEditNonPositionEvents(auth, eventDepartmentId)) {
        return true;
      }

      return eventStatus === EventStatus.Request;
    }

    // This is someone else's event being viewed by an Employee
    return eventType === EventType.Cover &&
           eventStatus === EventStatus.Request &&
           !afterEnd;
  }

  return false;
}

