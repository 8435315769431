import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonLoading,
  useIonViewDidEnter,
} from "@ionic/react";
// import "./Login.scss";
import "../global-styles.scss";
import { RouteComponentProps } from "react-router";
import useDialogBox from "../hooks/useDialogBox";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import env from "../appFunctions/environment";
import { log } from "../util/logger";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";

interface LoginProps extends RouteComponentProps {}

// ----------------------------------------------------------------------------------------------
function getQueryParameterByName(name: string): string {
  const url = window.location.href;

  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// ----------------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------------
const AuthPage: React.FC<LoginProps> = ({ history }: LoginProps) => {
  const { alertBoxJsx, showAlert } = useDialogBox();
  const { userLoggedIn, logout } = React.useContext(ApplicationUserContext);
  const { refreshAuth } = React.useContext(AuthorizedItemsContext);
  const [showLoading, setShowLoading] = useState(true);
  const { clearAuth } = React.useContext(AuthorizedItemsContext);

  // ----------------------------------------------------------------------------------------------
  function needsLogout() {
    history.push("/login");
    history.goForward();
    return;
  }

  // ----------------------------------------------------------------------------------------------
  const authUser = async () => {
    const envName = getQueryParameterByName("e");
    const userName = getQueryParameterByName("u");
    const token = getQueryParameterByName("t");
    const environ = env.getEnvironmentFromInput(envName);

    if (envName.length === 0 || userName.length === 0 || token.length === 0) {
      setShowLoading(false);
      history.push("/login", { direction: "none" });
      history.goForward();
      return;
    }

    // user is logged in, get auth-item data;
    userLoggedIn(userName, token, envName)
      .then((newUser) => {
        refreshAuth(true, newUser, needsLogout)
          .then(() => {
            log("login_auth", {
              username: userName,
              environment: environ.name,
            });

            console.log(`logged in to ${environ.name}: ${userName}`);
            history.push("/tabs/events", { direction: "none" });
            history.goForward();
            setShowLoading(false);
          })
          .catch(() => {
            setShowLoading(false);
            showAlert(
              "Failed to log in",
              "Could not retreive user data",
              () => {
                logout();
              }
            );
          });
      })
      .catch(() => {
        setShowLoading(false);
        showAlert("Failed to log in", "Could not set access token", () => {
          logout();
        });
      });
  };

  useEffect(() => {}, []);

  useIonViewDidEnter(() => {
    Promise.all([clearAuth(), logout()]).then(() => {
      localStorage.clear();
      setTimeout(() => {
        authUser();
      }, 250);
    });
  });

  return (
    <IonPage id="login-page">
      <IonContent className="login-content">
        <div className="login-content-container">
          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            // spinner="dots"
            message={"Please wait..."}
            duration={0}
          />

          {alertBoxJsx}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AuthPage;
