import { Message } from "../orbital-interfaces/Message";
import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";
import env from "../appFunctions/environment";

// ----------------------------------------------------------------------------------------------
async function getUserMessage(user: ApplicationUser, msgId: number) {
  log("api_message_getUserMessageMsgId");
  return (await apiHelper.get(
    user,
    "api/v2/employee/messages/" + msgId
  )) as Message;
}

// ----------------------------------------------------------------------------------------------
async function getUserMessages(user: ApplicationUser) {
  log("api_message_getUserMessage");
  return (await apiHelper.get(user, "api/v2/employee/messages")) as Message[];
}

// ----------------------------------------------------------------------------------------------
async function getUserMessagesByPage(user: ApplicationUser, page: number) {
  log("api_message_getUserMessagesByPage");
  return (await apiHelper.post(user, "api/v2/employee/messages", { page })) as Message[];
}


// ----------------------------------------------------------------------------------------------
async function getReadCount(user: ApplicationUser, msgId: number) {
  log("api_message_getReadCount");
  return (await apiHelper.get(
    user,
    "api/v1/employee/messages/" + msgId + "/readCount"
  )) as number;
}

// ----------------------------------------------------------------------------------------------
async function setIsPinned(
  user: ApplicationUser,
  msgId: number,
  isPinned: boolean
) {
  log("api_message_setIsPinned");
  return await apiHelper.post(
    user,
    "api/v1/employee/messages/" + msgId + "/togglePin",
    { isPinned }
  );
}

// ----------------------------------------------------------------------------------------------
async function setIsViewed(
  user: ApplicationUser,
  msgId: number,
  isViewed: boolean
) {
  log("api_message_setIsViewed");
  return await apiHelper.post(
    user,
    "api/v1/employee/messages/" + msgId + "/toggleView",
    { isViewed }
  );
}

// ----------------------------------------------------------------------------------------------
async function deleteMessage(user: ApplicationUser, msgId: number) {
  log("api_message_deleteMessage");
  return await apiHelper.del(user, "api/v1/employee/messages/" + msgId);
}

// ----------------------------------------------------------------------------------------------
async function getUnreadCount(user: ApplicationUser) {
  if (!user.isLoggedin || env.settings.appType === "punchclock") {
    return 0;
  }

  try
  {
    log("api_message_getUnreadCount");
    return (await apiHelper.get(
      user,
      "api/v2/employee/messages/unreadCount"
    )) as number;  
  }
  catch
  {
    return 0;
  }
}

// ----------------------------------------------------------------------------------------------
async function sendMessage(
  user: ApplicationUser,
  locId: number,
  channel: number,
  empIds: number[],
  text: string
) {
  log("api_message_sendMessage");
  return (await apiHelper.post(user, "api/v2/employee/messages/send", {
    locId,
    channel,
    empIds,
    text,
  })) as ApiErrorResult;
}

export const messageApi = {
  getUserMessage,
  getUserMessages,
  getUserMessagesByPage,
  getReadCount,
  setIsPinned,
  setIsViewed,
  deleteMessage,
  getUnreadCount,
  sendMessage,
};
