import React, {useState} from "react";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";
import {SubscriptionType, Role} from "../enums";
import db from "../data/dbStore";
import {userApi} from "../api/userApi";
import {ApplicationUser,} from "./ApplicationUserContext";
import {log, setFirebaseUserProperties} from "../util/logger";
import {differenceInMinutes} from "date-fns";
import {getConstants} from "../appFunctions/environment";
import {authHelper} from "../util/authHelper";
import {isApiError} from "../orbital-interfaces/ApiErrorResult";

export interface IAuthorizedItemsContext {
  auth: AuthorizedItems;
  refreshAuth: (
    force: boolean,
    newUser: ApplicationUser,
    logout: () => void,
  ) => Promise<AuthorizedItems | null>;
  clearAuth: () => Promise<void>;
  authLoading: boolean;
}

export const INITIAL_AUTHORIZED_ITEMS: AuthorizedItems = {
  subscriptionType: SubscriptionType.None,
  businessId: 0,
  businessName: "",
  employeeId: 0,
  firstOrNickName: "",
  firstName:  "",
  nickName: "",
  middleName: "",
  lastName: "",
  fullName: "",
  timeClock: false,
  role: Role.Employee,
  locations: [],
  loading: false,
  payrollEvents: [],
  tags: [],
  lastRefresh: undefined,
};

const INITIAL_AUTHITEMS_CONTEXT = {
  auth: INITIAL_AUTHORIZED_ITEMS,
  refreshAuth: (force: boolean, newUser: ApplicationUser, logout: () => void ) => {
    console.log(force, newUser, logout);
    return new Promise<AuthorizedItems | null>(() => {});
  },
  clearAuth: async () => {},
  authLoading: false,
};

export const AuthorizedItemsContext = React.createContext<
  IAuthorizedItemsContext
>(INITIAL_AUTHITEMS_CONTEXT);

// ----------------------------------------------------------------------------------------------
async function getAuthItems(
    user: ApplicationUser,
    force: boolean,
    logout: () => void): Promise<AuthorizedItems | null> {
  if (!user.isLoggedin) {
    const empty = { ...INITIAL_AUTHORIZED_ITEMS };
    await db.putAuthItems(empty).then(() => {
      log("indexdb_authitems_cleared");
    });

    return empty;
  }

  if (!force) {
    const authFromStore = await db.getAuthItems();

    // if the auth in the IndexDB store had valid locations/data and
    // the data wasn't 'stale' (older than our max number of minutes it can be old),
    // then just return what was in the IndexDB
    if (
      authHelper.isAuthItemsValid(authFromStore) &&
      authFromStore!.lastRefresh !== undefined &&
      differenceInMinutes(new Date(), authFromStore!.lastRefresh) <
        getConstants(user).maxStaleAuthItemsInMinutes
    ) {
      setFirebaseUserProperties(user, authFromStore!);
      return authFromStore!;
    }
  }

  // serviceWorkerBootstrapper.checkForUpdate();  // check if service worker / app has been updated

  try {
    const authFromApi = await userApi.getAuthorizedItems(user);
    if (isApiError(authFromApi)) {
      logout();
      return null;
    }  

    if (
      authFromApi &&
      authFromApi.locations &&
      authFromApi.locations.length > 0
    ) {
      await db.putAuthItems({ ...authFromApi, lastRefresh: new Date() });
      log("indexdb_authitems_put", { employeeId: authFromApi.employeeId });
      setFirebaseUserProperties(user, authFromApi);
      return authFromApi;
    } else {
      return null;
    }  
  } catch(e) {
    console.error("ERROR getting authorized items: " + e);
    return null;
  }
}

// ----------------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------------
export const AuthorizedItemsContextProvider = (props: any) => {
  const [auth, internalSetAuth] = useState({ ...INITIAL_AUTHORIZED_ITEMS });
  const [authLoading, setLoading] = useState(false);

  // ----------------------------------------------------------------------------------------------
  const refreshAuth = async (force: boolean, newUser: ApplicationUser, logout: () => void) => {
    setLoading(true);

    if (!newUser.isLoggedin) {
      const empty = { ...INITIAL_AUTHORIZED_ITEMS };
      internalSetAuth(empty);
      db.putAuthItems(empty).then(() => {
        log("indexdb_authitems_cleared");
      });

      setLoading(false);
    }

    // If we're not being asked to force a refresh from the API, and we already have
    // auth data, and the the last refresh was less than the max time we're allowed to
    // have 'stale' auth items, just return what we have
    if (
      !force &&
      auth.employeeId > 0 &&
      auth.lastRefresh !== undefined &&
      auth.locations.length > 0
    ) {

      const expired = differenceInMinutes(new Date(), auth.lastRefresh) >=
                      getConstants(newUser).maxStaleAuthItemsInMinutes;

      if (expired) {
        force = true;
      } else {
        setLoading(false);
        return auth;
      }
    }


    const newAuth = await getAuthItems(newUser, force, logout);
    setLoading(false);
    if (newAuth) {
      internalSetAuth(newAuth);
    }

    return newAuth;
  };

  // ----------------------------------------------------------------------------------------------
  const clearAuth = async (): Promise<void> => {
    internalSetAuth({ ...INITIAL_AUTHORIZED_ITEMS});
    return await db.putAuthItems({ ...INITIAL_AUTHORIZED_ITEMS});
  };

  return (
    <AuthorizedItemsContext.Provider
      value={{
        auth,
        refreshAuth,
        clearAuth,
        authLoading,
      }}
    >
      {props.children}
    </AuthorizedItemsContext.Provider>
  );
};
