
export enum FilteredRangeType {
    Day,
    Week,
    Month,
    Payroll,
    Custom
}

export function getFilteredRangeTypeName(filteredRangeType: FilteredRangeType) {
    switch(filteredRangeType) {
        case 0:
            return "Day";
        case 1:
            return "Week";
        case 2:
            return "Month";
        case 3:
            return "Payroll";
        case 4:
            return "Custom";
        default:
            return "";
    }
}

export function getFilteredRangeTypeFromName(filteredRangeTypeName: string) {
    switch(filteredRangeTypeName) {
        case "Day":
            return FilteredRangeType.Day;
        case "Week":
            return FilteredRangeType.Week;
        case "Month":
            return FilteredRangeType.Month;
        case "Payroll":
            return FilteredRangeType.Payroll;
        case "Custom":
            return FilteredRangeType.Custom;
        default:
            return FilteredRangeType.Day;
    }
}