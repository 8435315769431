import {IdName} from "../orbital-interfaces/IdName";

export enum MessageTypes {
    CoverRequestMessage = 1,
    CoverApprovedMessage = 2,
    DirectCoverRequestMessage = 3,
    ShiftRequestMessage = 5,
    ShiftRequestDeniedMessage = 6,
    ShiftRequestReceivedMessage = 10,
    NoClockInAlertMessage = 20,
    NoClockOutAlertMessage = 21,
    LateClockInMessage = 22,
    EarlyClockOutAlertMessage = 23,
    ScheduleEventUpdatedMessage = 50,
    PublishedScheduleUpdatedMessage = 60,
    PasswordRecoveryMessage = 80,
    VerifyCellPhoneMessage = 81,
    VerifyCellPhoneCompleteMessage = 82,
    VerifyEmailMessage = 83,
    CustomMessage = 100,
    WeeklyScheduleForEmployeeMessage = 101,
}

export function getMessageTypeIdNames(): IdName[] {
    return [
        { id: 1, name: "Cover-Request"} ,
        { id: 2, name: "Cover-Approved"} ,
        { id: 3, name: "Direct Cover-Request"} ,
        { id: 5, name: "Shift-Request"} ,
        { id: 6, name: "Shift-Denied"} ,
        { id: 10, name: "Shift-Request Received"} ,
        { id: 20, name: "No Clock In Alert"} ,
        { id: 21, name: "No Clock Out Alert"} ,
        { id: 22, name: "Late Clock In Alert"} ,
        { id: 23, name: "Early Clock Out Alert"} ,
        { id: 50, name: "Schedule Event Updated"} ,
        { id: 60, name: "Published Schedule Updated"} ,
        { id: 80, name: "Password Recovery"} ,
        { id: 81, name: "Verify Cell Phone"} ,
        { id: 82, name: "Verify Cell Phone Completed"} ,
        { id: 83, name: "Verify Email"} ,
        { id: 100, name: "Custom Messages"} ,
        { id: 101, name: "Employee Weekly Schedule"} ,
    ]
}
