import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";
import { Device } from '@capacitor/device';
import {PunchClockDeviceData} from "../orbital-interfaces/PunchClockDeviceData";
import { TimeClockSettingsData } from './../orbital-interfaces/TimeClockSettingsData';
import { AuthorizedItems } from './../orbital-interfaces/AuthorizedItems';
import { EventFilter } from "../orbital-interfaces/EventFilter";
import { timeHelper as th } from "../util/timeHelper";
import { TECGetData } from "../orbital-interfaces/TEC";
import { ClockInResponse } from "../orbital-interfaces/ClockInResponse";
import { ClockOutResponse } from "../orbital-interfaces/ClockOutResponse";
import { SwitchPositionOrTagResponse } from "../orbital-interfaces/SwitchPositionOrTagResponse";
import { ScheduleEvent } from "../orbital-interfaces/ScheduleEvent";
import { EndBreakResponse } from "../orbital-interfaces/EndBreakResponse";
import { StartBreakResponse } from "../orbital-interfaces/StartBreakResponse";
import { BreakType } from "../enums";


// ----------------------------------------------------------------------------------------------
async function getPunchClockAdmin(
  user: ApplicationUser,
  userName: string
) {
  log("api_punchclock_getPunchClockAdmin");
  const data = {
    userName
  };

  return (await apiHelper.post(
    user,
    "api/v2/punchclock/getPunchClockAdmin",
    data
  )) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function getPunchClockDeviceData(
  user: ApplicationUser
) {
  log("api_punchclock_getDeviceData");
  const uuid = (await Device.getId()).uuid;
  const data = {
    deviceId: uuid
  };

  return (await apiHelper.post(
    user,
    "api/v2/punchclock/getPunchClockDeviceData",
    data
  )) as PunchClockDeviceData | ApiErrorResult;
}


export interface PunchClockUpdateModel
{
  deviceId: string;
  deviceName: string;
  locationIds: number[];
}

// ----------------------------------------------------------------------------------------------
async function setPunchClockDeviceData(
  user: ApplicationUser,
  deviceData: PunchClockUpdateModel
) {
  log("api_punchclock_setDeviceData");
  return (await apiHelper.put(
    user,
    "api/v2/punchclock/setPunchClockDeviceData",
    deviceData
  )) as PunchClockDeviceData | ApiErrorResult;
}

export interface EnterStationCodeResult {
  timeclockData: TimeClockSettingsData;
  auth: AuthorizedItems;
  userName: string;
}

// ----------------------------------------------------------------------------------------------
async function enterStationCode(
  user:ApplicationUser,
  stationCode: string
) {
  const uuid = (await Device.getId()).uuid;
  const data = {
    deviceId: uuid,
    stationCode
  };

  log("api_punchclock_setDeviceData");
  return (await apiHelper.post(
    user,
    "api/v2/punchclock/enterStationCode",
    data
  )) as EnterStationCodeResult | ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function refreshStationCode(
  user:ApplicationUser,
  stationCode: string,
  lat?: number | undefined,
  lng?: number | undefined
) {
  const uuid = (await Device.getId()).uuid;
  const data = {
    deviceId: uuid,
    stationCode
  };

  log("api_punchclock_setDeviceData");
  return (await apiHelper.post(
    user,
    "api/v2/punchclock/refreshStationCode",
    data
  )) as EnterStationCodeResult | ApiErrorResult;
}

export interface GetTimeResult {
  currentDateTime: string
}

// ----------------------------------------------------------------------------------------------
async function getTimeForPunchClock(
  user:ApplicationUser
) {
  const uuid = (await Device.getId()).uuid;
  const data = {
    deviceId: uuid,
  };

  log("api_punchclock_getTime");
  return (await apiHelper.post(
    user,
    "api/v2/punchclock/getTimeForPunchClock",
    data
  )) as GetTimeResult | ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function getScheduleEventsByFilter(
  user: ApplicationUser,
  filter: EventFilter,
  rangeStart: Date,
  rangeEnd: Date,
  stationCode: string
) {
  log("api_punchclock_getEventsByFilter");
  const uuid = (await Device.getId()).uuid;

  const filterToSend = {
    ...filter,
    start: th.formatDateTimeAsMvcString(filter.start),
    end: th.formatDateTimeAsMvcString(filter.end),
    rangeStart: th.formatDateTimeAsMvcString(rangeStart),
    rangeEnd: th.formatDateTimeAsMvcString(rangeEnd)
  };

  const data = {
    filter: filterToSend,
    stationCode,
    deviceId: uuid
  }

  return (await apiHelper.post(
    user,
    "api/v2/punchclock/getEventsByFilter",
    data
  )) as TECGetData;
}

// ----------------------------------------------------------------------------------------------
async function clockIn(
  user: ApplicationUser,
  stationCode: string,
  posId: number,
  tagId: number | null,
  lat: number | undefined,
  lng: number | undefined
) {
  const uuid = (await Device.getId()).uuid;
  log("api_punchClock_clockIn");

  const data = {
    deviceId: uuid,
    stationCode,
    posId,
    tagId,
    lat,
    lng
  }

  const baseUrl = `api/v2/punchclock/clockin`;
  return (await apiHelper.post(user, baseUrl, data)) as ClockInResponse;
}

// ----------------------------------------------------------------------------------------------
async function clockOut(
  user: ApplicationUser,
  stationCode: string,
  lat: number | undefined,
  lng: number | undefined,
  clockOutValue?: number,
) {
  const uuid = (await Device.getId()).uuid;
  const baseUrl = 'api/v2/punchclock/clockout';
  log("api_punchClock_clockOut");

  const data = {
    deviceId: uuid,
    stationCode,
    timeClockBreak: null,
    breakStart: null,
    clockOutValue,
    lat,
    lng
  }

  return (await apiHelper.post(
    user,
    baseUrl,
    data
  )) as ClockOutResponse;
}

// ----------------------------------------------------------------------------------------------
async function switchPositionOrTag(
  user: ApplicationUser,
  stationCode: string,
  posId: number,
  tagId: number | null,
  lat: number | undefined,
  lng: number | undefined  ,
  clockOutValue?: number
) {
  log("api_punchClock_switchPositionOrTag");
  const uuid = (await Device.getId()).uuid;
  const baseUrl = 'api/v2/punchclock/switchPosition';

  const data = {
    deviceId: uuid,
    stationCode,
    timeClockBreak: null,
    breakStart: null,
    posId,
    tagId,
    lat,
    lng,
    clockOutValue
  };

  return (await apiHelper.post(
    user,
    baseUrl,
    data
  )) as SwitchPositionOrTagResponse;
}

// ----------------------------------------------------------------------------------------------
async function startBreak(
  user: ApplicationUser,
  stationCode: string,
  clockEvent: ScheduleEvent,
  shiftEvent: ScheduleEvent | null,
  breakType: BreakType,
  lat: number | undefined,
  lng: number | undefined,
  breakStart?: string
) {
  const uuid = (await Device.getId()).uuid;
  const baseUrl = 'api/v2/punchclock/startBreak';
  log("api_punchClock_startBreak");

  const data = {
    deviceId: uuid,
    stationCode,
    clockEventId: clockEvent.ScheduleEventId,
    shiftEventId: shiftEvent ? shiftEvent!.ScheduleEventId : null,
    shiftBreakStart: breakStart,
    breakType,
    lat,
    lng
  };

  return (await apiHelper.post(
    user,
    baseUrl,
    data
  )) as StartBreakResponse;
}

// ----------------------------------------------------------------------------------------------
async function endBreak(
  user: ApplicationUser,
  stationCode: string,
  clockEvent: ScheduleEvent,
  shiftEvent: ScheduleEvent | null,
  lat: number | undefined,
  lng: number | undefined
) {
  log("api_punchClock_endBreak");
  const uuid = (await Device.getId()).uuid;
  const baseUrl = 'api/v2/punchclock/endBreak'
  const shiftEventId = shiftEvent ? shiftEvent.ScheduleEventId : "";

  const data = {
    deviceId: uuid,
    stationCode,
    clockedInEventId: clockEvent.ScheduleEventId,
    scheduleEventId: shiftEventId,
    lat,
    lng
  };

  return (await apiHelper.post(
    user,
    baseUrl,
    data)) as EndBreakResponse;
}

export const punchClockApi = {
  clockIn,
  clockOut,
  endBreak,
  enterStationCode,
  getPunchClockAdmin,
  getPunchClockDeviceData,
  setPunchClockDeviceData,
  getScheduleEventsByFilter,
  getTimeForPunchClock,
  refreshStationCode,
  startBreak,
  switchPositionOrTag
};
