import { IonButton, IonContent, IonList } from "@ionic/react";
import React, { useState, useContext, useEffect} from "react";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import { useShowLoading } from "../hooks/useShowLoading";
import { userApi } from "../api/userApi";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import { punchClockApi } from "../api/punchClockApi";
import env from "../appFunctions/environment";
import useDialogBox from "../hooks/useDialogBox";

interface PunchClockEnterLoginModalProps {
  onDismissModal: (toastMessage: string, userChanged: boolean) => void;
  onCancelModal: () => void;
  isOpen: boolean;
}

// ----------------------------------------------------------------------------------------------
function validate(username: string, password: string): boolean {
  return username.length > 0 && password.length > 5;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const PunchClockEnterLoginModal: React.FC<PunchClockEnterLoginModalProps> = ({
  onCancelModal,
  onDismissModal,
  isOpen
}) => {
  const loading = useShowLoading("Logging in...");
  const { alertBoxJsx, showAlert } = useDialogBox();
  const { refreshAuth } = React.useContext(AuthorizedItemsContext);
  const { user, userLoggedIn, logout } = useContext(ApplicationUserContext);
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState("");
  const [ enterButtonEnabled, setEnterButtonEnabled ] = useState(false);

  useEffect(() => {
    let mount = true;

    if (isOpen && password.length > 0 && mount) {
      setPassword("");
    }

    return () => {
      mount = false;
    }

    //eslint-disable-next-line
  }, [isOpen]);

  // -----------------------------------------------------------------------------------
  const onCancel = () => {
    onCancelModal();
  }

  // --------------------------------------------------------------------------------------
  const onSubmitClick = async (e: React.FormEvent | null) => {
    if (e) {
      e.preventDefault();
    }

    if (!username || !password) {
      return;
    }

    const envUser = env.getUserAndEnvironmentFromInput(username);
    loading.setShowLoading(true);

    const result = await punchClockApi.getPunchClockAdmin(user, envUser.username);
    if (result.error) {
      loading.setShowLoading(false);
      showAlert("Unauthorized", result.message, () => {
        setUsername("");
        setPassword("");
        setEnterButtonEnabled(false);  
      });

      return;
    }

    const userResponse = await userApi.authenticateUser(envUser.username, password, envUser.env.key);
    if (!userResponse.loggedIn)
    {
      loading.setShowLoading(false);
      const msg = userResponse.error ? userResponse.error : "Could not log in";
      showAlert("Failed to log in", msg, () => {
        setPassword("");
        setEnterButtonEnabled(false);        
      });

      return;
    }

    const oldUserName = user.username;
    const newUser = await userLoggedIn(username, userResponse.access_token, envUser.env.key);
    await refreshAuth(true, newUser, logout);

    loading.setShowLoading(false);
    setPassword("");
    onDismissModal("Succeeded", oldUserName !== username);
  };

  // ----------------------------------------------------------------------------------------------
  const onUsernameChange = (newUsername: string) => {
    setUsername(newUsername);
    setEnterButtonEnabled(validate(newUsername, password));
  };

  // ----------------------------------------------------------------------------------------------
  const onPasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    setEnterButtonEnabled(validate(username, newPassword));
  };

  return (
    <>
      <IonContent className="login-content">
        <form
          noValidate={true}
          className="login-content-container"
          onSubmit={onSubmitClick}
          autoComplete="off"
        >

          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          <IonList className="form-list">
            <div className="item">
              <div className="label">              
                <span>Location Admin / Business Admin</span>
              </div>

              <div className="field-container">
                <input
                  className="input"
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                  tabIndex={1}
                  onChange={(e) => {
                    onUsernameChange(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="item">
              <div className="label">
                Password
              </div>

              <div className="field-container">
                <input
                  className="input"
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  value={password}
                  tabIndex={2}
                  onChange={(e) => {
                    onPasswordChange(e.target.value);
                  }}
                />
              </div>
            </div>

            <IonButton
              className="login-button"
              onClick={onSubmitClick}
              expand="block"
              disabled={!enterButtonEnabled}
            >
              Log In
            </IonButton>

            <IonButton
              className="login-button"
              expand="block"
              color="medium" 
              onClick={onCancel}
            >
              Cancel
            </IonButton>

            <input
              type="submit"
              tabIndex={-1}
              style={{position: "absolute", visibility: "hidden", left: "-1000px"}}
            />

          </IonList>
        </form>
      </IonContent>
      
      {loading.showLoadingJsx}
      {alertBoxJsx}
    </>
  );
};

export default PunchClockEnterLoginModal;