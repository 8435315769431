const INITIAL_EVENT_SEGMENT = "all";
const EVENT_SEGMENT_STORAGE_KEY = "eventPageSegmentv2";

// ------------------------------------------------------------------------------------
function getEventSegment(): string {
  const str = localStorage.getItem(EVENT_SEGMENT_STORAGE_KEY);
  if (str !== null) {
    if (str === "my") {
      return "requests"
    }
    
    return str;
  }


  return INITIAL_EVENT_SEGMENT;
}

// ------------------------------------------------------------------------------------
function putEventSegment(str: string) {
  localStorage.setItem(EVENT_SEGMENT_STORAGE_KEY, str);
}

// ------------------------------------------------------------------------------------
function clear() {
  localStorage.removeItem(EVENT_SEGMENT_STORAGE_KEY);
}

export const eventSegmentStore = {
  getEventSegment,
  putEventSegment,
  clear,
}
