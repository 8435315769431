import React from "react";
import {IonSelect, IonSelectOption} from "@ionic/react";

interface EventsSegmentDropDownListProps {
  itemList: string;
  selectedItem: string;
  onSelectedItemChanged: (newItem: string) => void;
}

const segmentList = [
  {
    id: "requests",
    name: "Requests"
  },

  {
    id: "all",
    name: "Events"
  }
]
// -----------------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------------
const EventsSegmentDropDownList: React.FC<EventsSegmentDropDownListProps> = ({
  selectedItem,
  onSelectedItemChanged
}: EventsSegmentDropDownListProps) => {

  // -----------------------------------------------------------------------------------
  function onUpdate(newItem: string) {
    if (newItem !== selectedItem) {
      onSelectedItemChanged(newItem);
    }
  }

  return (
    <IonSelect
      mode="ios"
      interface="action-sheet"
      value={selectedItem}
      onIonChange={(e) => onUpdate(e.detail.value)}
    >
      {segmentList.map(item => (
        <IonSelectOption key={item.id} value={item.id}>
          {item.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
}

export default EventsSegmentDropDownList;
