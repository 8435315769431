import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import "../../global-styles.scss";
import {AuthorizedItems} from "../../orbital-interfaces/AuthorizedItems";
import {BreakType, UnscheduledBreakOptions} from "../../enums";
import { TimeClockSettingsData } from "../../orbital-interfaces/TimeClockSettingsData";
import { timeHelper as th } from "../../util/timeHelper";

interface ScheduleFilterModalProps {
  auth: AuthorizedItems;
  tcData: TimeClockSettingsData;
  onDismissModal: (selection: BreakItem) => void;
}

export interface BreakItem {
  key: string;
  name: string;
  breakType: BreakType;
}

// --------------------------------------------------------------------------------------
const BreakSelectionModal: React.FC<ScheduleFilterModalProps> = ({
  auth,
  onDismissModal,
  tcData,
}: ScheduleFilterModalProps) => {
  const [breakList, setBreakList] = useState([] as BreakItem[]);
  const cancelOption = {
    key: "Cancel",
    name: "",
    breakType: BreakType.UnscheduledUnpaid,
  };

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (auth.locations.length === 0 || !tcData.ClockedInScheduleEvent) {
      return;
    }

    const locSettings = auth.locations.filter(
      (l) => l.id === tcData.ClockedInScheduleEvent!.LocationId
    )[0].settings;
    const breaks: BreakItem[] = [];

    if (tcData.ScheduleEventsForClockIn.length > 0) {
      tcData.ScheduleEventsForClockIn[0].EventBreaks.map((b) => {
        return breaks.push({
          key: b.BreakStart,
          name: th.getEventBreakStartEndString(b),
          breakType: BreakType.Scheduled,
        });
      });
    }

    switch (locSettings.UnscheduledBreakOptions) {
      case UnscheduledBreakOptions.Both:
        breaks.push({
          key: "UnScheduledUnpaid",
          name: "Unscheduled",
          breakType: BreakType.UnscheduledUnpaid,
        });
        breaks.push({
          key: "UnScheduledPaid",
          name: "Unscheduled $",
          breakType: BreakType.UnscheduledPaid,
        });
        break;

      case UnscheduledBreakOptions.Paid:
        breaks.push({
          key: "UnScheduledPaid",
          name: "Unscheduled $",
          breakType: BreakType.UnscheduledPaid,
        });
        break;

      case UnscheduledBreakOptions.Unpaid:
        breaks.push({
          key: "UnScheduledUnpaid",
          name: "Unscheduled",
          breakType: BreakType.UnscheduledUnpaid,
        });
        break;

      default:
        break;
    }

    setBreakList(breaks);
  }, [tcData, auth]);

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Choose Break</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismissModal(cancelOption)}>
              <IonIcon icon={ close } mode="ios" slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content">
        {breakList.map((brk) => (
          <IonButton
            className="apply-button"
            expand="block"
            key={brk.key}
            color="secondary"
            onClick={() => {
              onDismissModal(brk);
            }}
          >
            {brk.name}
          </IonButton>
        ))}
      </IonContent>
    </>
  );
};

export default BreakSelectionModal;
