import React, {useRef} from "react";
import "../../global-styles.scss";
import {IonIcon} from "@ionic/react";
import { addDays, format } from "date-fns";
import {enableGesture} from "../EventsPageComponents/WeekChooserGesture";

interface PayrollPeriodChooserProps {
  startDate: Date | null;
  endDate: Date | null;
  onNextPayPeriod: () => void;
  onPrevPayPeriod: () => void;
}

function getPayPeriodDateText(start: Date | null, end: Date | null) {
  if (!start || !end) {
    return " ";
  }

  if (start.getMonth() !== end.getMonth()) {
    return (
      format(start, "eee, MMM d") +
      " - " +
      format(addDays(end, -1), "eee, MMM d")
    );
  }

  return (
    format(start, "eee, MMM d") + " - " + format(addDays(end, -1), "eee, MMM d")
  );
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const PayrollPeriodChooser: React.FC<PayrollPeriodChooserProps> = ({
  startDate,
  endDate,
  onNextPayPeriod,
  onPrevPayPeriod,
}: PayrollPeriodChooserProps) => {
  const chooserRef = useRef(null as null | HTMLDivElement);

  const isLoading = startDate === null || endDate === null;
  const payPeriodDateText = isLoading
    ? ""
    : getPayPeriodDateText(startDate, endDate);


  if (chooserRef.current !== null) {
    enableGesture(
      chooserRef.current,
      () => { onPrevPayPeriod()},
      () => { onNextPayPeriod()});
  }


  return (
    <div className="chooser" ref={chooserRef}>
      <div className="top">
        <button onClick={onPrevPayPeriod} className="arrow" key={-1}>
          <IonIcon src="assets/prev-triangle-solid.svg" />
        </button>

        <div className="month-year-container">
          <div className="datetime-picker-container">
            <div className="datetime-picker">
              {payPeriodDateText}
            </div>
          </div>
        </div>

        <button onClick={onNextPayPeriod} className="arrow" key={7}>
          <IonIcon src="assets/next-triangle-solid.svg" />
        </button>
      </div>
    </div>
  );
};

export default PayrollPeriodChooser;
