import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import {DeviceOperatingSystem} from "../enums";
import { NotificationTypeModel } from "../orbital-interfaces/NotificationTypeModel";
import env from "../appFunctions/environment";
import { log } from "../util/logger";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {Plugins} from '@capacitor/core';
const {Device} = Plugins;

// ----------------------------------------------------------------------------------------------
async function subscribe(user: ApplicationUser, token: string) {
  log("api_pushNotifications_subscribe");
  const url = `api/v2/push-notifications/subscribe`;
  const operatingSystem =
    env.settings.platform === "ios"
      ? DeviceOperatingSystem.iOS
      : DeviceOperatingSystem.Android;
  const deviceInfo = await Device.getInfo();


  log("pushNotifications_subscribeCalled", {
    token,
    operatingSystem,
    username: user.username,
    access_token: user.access_token,
  });

  return await apiHelper.putData(user, url, {
    TokenId: token,
    OperatingSystem: operatingSystem,
    uuid: deviceInfo.uuid,
  });
}

// ----------------------------------------------------------------------------------------------
async function unsubscribe(user: ApplicationUser, pushToken: string) {
  log("api_pushNotifications_unsubscribe");
  const url = `api/v2/push-notifications/unsubscribe`;
  const deviceInfo = await Device.getInfo();
  const operatingSystem =
    env.settings.platform === "ios"
      ? DeviceOperatingSystem.iOS
      : DeviceOperatingSystem.Android;

  return await apiHelper.post(user, url, {
    tokenId: pushToken,
    operatingSystem,
    uuid: deviceInfo.uuid,
  }) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function getTypesSelectedByUser (user: ApplicationUser) {
  log("api_pushNotifications_getTypes");
  const url = `api/v2/push-notifications/types`;
  return (await apiHelper.get(user, url)) as NotificationTypeModel[];
}

// ----------------------------------------------------------------------------------------------
async function putTypes(user: ApplicationUser, typeIds: number[]) {
  log("api_pushNotifications_putTypes");
  const url = `api/v2/push-notifications/types`;
  const data = {
    ids: typeIds,
  };

  return (await apiHelper.put(user, url, data)) as void;
}

// ----------------------------------------------------------------------------------------------
async function listTypes(user: ApplicationUser) {
  log("api_pushNotifications_listTypes");
  const url = `api/v2/push-notifications/list-types`;

  // NOTE: the API no longer really uses passed-in data since the
  // "Communications rewrite"

  return (await apiHelper.get(user, url)) as NotificationTypeModel[];
}

export const pushNotificationsApi = {
  subscribe,
  unsubscribe,
  getTypesSelectedByUser,
  putTypes,
  listTypes,
};
