import React, {useEffect, useState} from "react";
import {IonCol, IonLabel, IonRow} from "@ionic/react";
import "../../../global-styles.scss";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {LocationSettings} from "../../../orbital-interfaces/LocationSettings";
import {
  AdminAuthorization,
  EventStatus,
  eventStatusIdList,
  EventType,
  getEventStatusIdName,
  getEventTypeIdName,
  Role,
} from "../../../enums";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {authHelper} from "../../../util/authHelper";
import {IdName} from "../../../orbital-interfaces/IdName";
import OptionSelector from "../../EventsPageComponents/OptionSelector";

interface EventTypeStatusEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
function getSelectedEventTypeId(ev: EditorValues) {
  if (ev.eventType !== EventType.Absent) {
    return ev.eventType;
  }

  return ev.absentId;
}

// --------------------------------------------------------------------------------------
function showClockEventType(
  auth: AuthorizedItems,
  ev: EditorValues,
  adminAuth: AdminAuthorization
) {
  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return true;
  }

  if (auth.role === Role.Employee) {
    return false;
  }

  if (ev.employee.id === auth.employeeId) {
    return authHelper.adminCan(
      adminAuth,
      AdminAuthorization.CanEditOwnClockEvents
    );
  }

  return authHelper.adminCan(
    adminAuth,
    AdminAuthorization.CanEditEmployeeClockEvents
  );
}

// --------------------------------------------------------------------------------------
function getPayrollEventIdNames(auth: AuthorizedItems, posId: number) {
  return auth.payrollEvents
    .filter((pe) => pe.positionIds.includes(posId) && !pe.name.startsWith("--"))
    .map((pe) => {
      return { id: pe.id, name: pe.name };
    })
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
}

// --------------------------------------------------------------------------------------
function getEventTypes(auth: AuthorizedItems, ev: EditorValues): IdName[] {
  if (!authHelper.isAuthItemsValid(auth)) {
    return [] as IdName[];
  }

  let payrollEvents: IdName[] = [];
  const eventTypes: EventType[] = [];
  const locSettings = ev.position.location.settings;
  const dept = authHelper.getDepartmentFromPositionId(
    auth,
    ev.position.position.id
  );
  if (dept === null) {
    return [] as IdName[];
  }

  const adminAuth = authHelper.getAdminAuthorizationForDepartment(
    auth,
    dept!.id
  );

  if (showClockEventType(auth, ev, adminAuth)) {
    eventTypes.push(EventType.Clock);
  }

  // Handle case where user is an Admin but does not have admin to this particular
  // Cover Request
  if (auth.role === Role.Admin &&
      ev.eventType === EventType.Cover &&
      ev.eventStatus === EventStatus.Request &&
      ev.employee.id !== auth.employeeId &&
      !authHelper.isAdminAuthorizedFor(auth, ev.locationId, dept.id, AdminAuthorization.CanEditSchedules))
  {
    return [ getEventTypeIdName(EventType.Cover)];
  }

  // Handle case where user is an Admin and does not have admin to this particular
  // Cover Request, which is his own Cover Request
  if (auth.role === Role.Admin &&
    ev.eventType === EventType.Cover &&
    ev.eventStatus === EventStatus.Request &&
    ev.employee.id === auth.employeeId &&
    !authHelper.isAdminAuthorizedFor(auth, ev.locationId, dept.id, AdminAuthorization.CanEditSchedules))
  {
    return [ getEventTypeIdName(EventType.Cover)];
  }

  eventTypes.push(EventType.Shift);

  if (
    auth.role === Role.BusinessAdmin ||
    auth.role === Role.LocationAdmin ||
    (auth.role === Role.Admin &&
      dept !== undefined &&
      authHelper.isAdminAuthorizedFor(
        auth,
        ev.locationId,
        dept.id,
        AdminAuthorization.CanEditSchedules
      ))
  ) {
    eventTypes.push(EventType.Cover);

    if (locSettings.ShowVacationEventType) {
      eventTypes.push(EventType.Vacation);
    }

    if (locSettings.ShowUnavailableEventType) {
      eventTypes.push(EventType.Unavailable);
    }

    if (locSettings.ShowAbsentEventType) {
      payrollEvents = getPayrollEventIdNames(auth, ev.position.position.id);
    }
  } else if (auth.role === Role.Admin && dept !== undefined) {
    if (locSettings.ShowVacationEventType) {
      eventTypes.push(EventType.Vacation);
    }

    if (locSettings.ShowUnavailableEventType) {
      eventTypes.push(EventType.Unavailable);
    }

    if (locSettings.ShowAbsentEventType) {
      payrollEvents = getPayrollEventIdNames(auth, ev.position.position.id);
    }
  } else {
    if (ev.scheduleEventId > 0 && ev.eventType === EventType.Cover) {
      return [getEventTypeIdName(ev.eventType)];
    }

    if (locSettings.ShowVacationEventType) {
      eventTypes.push(EventType.Vacation);
    }

    if (locSettings.ShowUnavailableEventType) {
      eventTypes.push(EventType.Unavailable);
    }

    if (locSettings.ShowAbsentEventType) {
      payrollEvents = getPayrollEventIdNames(auth, ev.position.position.id);
    }
  }

  const idNames = eventTypes.map((et) => {
    return getEventTypeIdName(et);
  }) as IdName[];
  return idNames.concat(payrollEvents);
}

// --------------------------------------------------------------------------------------
function getEventStatuses(auth: AuthorizedItems, ev: EditorValues): IdName[] {
  if (!authHelper.isAuthItemsValid(auth)) {
    return [] as IdName[];
  }

  if (auth.role === Role.Employee) {
    if (ev.scheduleEventId === 0) {
      return [getEventStatusIdName(EventStatus.Request)];
    } else {
      return [getEventStatusIdName(ev.eventStatus)];
    }
  }

  if (auth.role === Role.LocationAdmin || auth.role === Role.BusinessAdmin) {
    return eventStatusIdList;
  }

  const dept = authHelper.getDepartmentFromPositionId(
    auth,
    ev.position.position.id
  );

  // Handle case where user is an Admin but does not have admin to this particular
  // Cover Request
  if (dept !== null &&
    auth.role === Role.Admin &&
    ev.eventType === EventType.Cover &&
    ev.eventStatus === EventStatus.Request &&
    ev.employee!.id !== auth.employeeId &&
    !authHelper.isAdminAuthorizedFor(auth, ev.locationId, dept.id, AdminAuthorization.CanEditSchedules))
  {
    return [ getEventStatusIdName(EventStatus.Request)];
  }


  if (
    authHelper.isAdminAuthorizedFor(
      auth,
      ev.locationId,
      dept === null ? null : dept.id,
      AdminAuthorization.CanEditSchedules
    )
  ) {
    return eventStatusIdList;
  }

  if (ev.scheduleEventId === 0) {
    return [getEventStatusIdName(EventStatus.Request)];
  } else {
    return [getEventStatusIdName(ev.eventStatus)];
  }
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventTypeStatusEditor: React.FC<EventTypeStatusEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventTypeStatusEditorProps) => {
  const [locSettings, setLocSettings] = useState(null as LocationSettings | null);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }
    // If this is an employee and we're creating an event
    if (
      auth.role === Role.Employee &&
      editorValues.scheduleEventId <= 0 &&
      editorValues.eventStatus !== EventStatus.Request
    ) {
      update({
        type: "TYPESTATUS-UPDATED",
        eventType: editorValues.eventType,
        eventStatus: EventStatus.Request,
        absentId: editorValues.absentId,
      });
    }

    // eslint-disable-next-line
  }, [
    auth,
    editorValues.eventType,
    editorValues.eventStatus,
    editorValues.position,
    editorValues.absentId,
    editorValues.scheduleEventId,
  ]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.isFromEventInfo) {
      return; // won't have accurrate data for absents yet
    }

    const eventTypes = getEventTypes(auth, editorValues);
    if (eventTypes.length === 0) {
      return;
    }

    const curEventType = eventTypes.find(
      (et) => et.id === getSelectedEventTypeId(editorValues)
    );
    if (curEventType === undefined) {
      update({
        type: "TYPESTATUS-UPDATED",
        eventType: eventTypes[0].id > 100 ? EventType.Absent : eventTypes[0].id,
        eventStatus: editorValues.eventStatus,
        absentId: eventTypes[0].id > 100 ? eventTypes[0].id : undefined,
      });
    }

    // eslint-disable-next-line
  }, [editorValues.employee, editorValues.isFromEventInfo]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    const eventStatuses = getEventStatuses(auth, editorValues);
    const curEventStatus = eventStatuses.find(
      (es) => es.id === editorValues.eventStatus
    );
    if (curEventStatus === undefined) {
      update({
        type: "TYPESTATUS-UPDATED",
        eventType: editorValues.eventType,
        eventStatus: eventStatuses[0].id,
        absentId: editorValues.absentId,
      });
    }

    // eslint-disable-next-line
  }, [editorValues.position]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    if (locSettings && editorValues.locationId === locSettings.LocationId) {
      return;
    }

    if (!auth || !auth.locations || auth.locations.length === 0) {
      return;
    }

    const newLocSettings = authHelper.getLocSettingsForLocationId(
      auth,
      editorValues.locationId
    );
    setLocSettings(newLocSettings);

    // eslint-disable-next-line
  }, [editorValues.locationId, editorValues.scheduleEventId]);

  // ------------------------------------------------------------------------------------
  function onEventTypeChange(newEventType: EventType) {
    if (newEventType === editorValues.eventType) {
      return;
    }

    update({
      type: "TYPESTATUS-UPDATED",
      eventType: newEventType >= 100 ? EventType.Absent : newEventType,
      eventStatus: editorValues.eventStatus,
      absentId: newEventType >= 100 ? newEventType : undefined,
    });
  }

  // ------------------------------------------------------------------------------------
  function onEventStatusChange(newEventStatus: EventStatus) {
    if (newEventStatus === editorValues.eventStatus) {
      return;
    }

    update({
      type: "TYPESTATUS-UPDATED",
      eventType: editorValues.eventType,
      eventStatus: newEventStatus,
      absentId: editorValues.absentId,
    });
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">Type</IonLabel>
        </IonCol>
        <IonCol size="8">
          <OptionSelector
            options={getEventTypes(auth, editorValues)}
            initialOption={getSelectedEventTypeId(editorValues)}
            onOptionUpdated={(e) => onEventTypeChange(e)}
            isDisabled={editorValues.isReadonly ||
            editorValues.isConfirmed ||
            editorValues.transferToEmployeeId !== null}
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">Status</IonLabel>
        </IonCol>
        <IonCol size="8">
          <OptionSelector
            options={getEventStatuses(auth, editorValues)}
            initialOption={editorValues.eventStatus}
            onOptionUpdated={(e) => onEventStatusChange(e)}
            isDisabled={
              editorValues.isReadonly ||
              editorValues.isConfirmed ||
              editorValues.transferToEmployeeId !== null ||
              (auth.role === Role.Employee && editorValues.scheduleEventId <= 0)
            } />
        </IonCol>
      </IonRow>
    </>
  );
};

export default EventTypeStatusEditor;
