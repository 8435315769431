import { IonAlert } from "@ionic/react";
import React, { useState } from "react";
import {log} from "../util/logger";

function useDialogBox() {
  const [isOpen, setIsOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    header: "",
    message: "",
    onDismiss: () => { },
  });

  // ------------------------------------------------------------------------------------
  const showAlert = (
    header: string,
    message: string,
    onDismiss: () => void
  ) => {
    log("dialogBox_showAlert", { msg: message});
    setAlertData({
      header,
      message,
      onDismiss,
    });

    setIsOpen(true);
  };

  const alertBoxJsx = (
    <IonAlert
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false);
        alertData.onDismiss();
      }}

      header={alertData.header}
      message={alertData.message}
      buttons={["OK"]}
    />
  );

  return {
    showAlert,
    alertBoxJsx,
  };
}

export default useDialogBox;
