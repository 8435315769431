import { PunchClockDeviceEnableResponse } from '../enums';
import { PunchClockLocationsData } from './PunchClockLocationsData';

export interface PunchClockDeviceData {
  response: PunchClockDeviceEnableResponse;
  responseName: string;
  deviceName: string;
  employeeId: number;
  enabledDate: Date;
  locations: PunchClockLocationsData[];
}

export const initialPunchClockDeviceData = {
  locations: [],
  deviceName: "",
  employeeId: -2,
  enabledDate: new Date(),
  response: PunchClockDeviceEnableResponse.UserUnauthorizedToEnablePunchClock,
  responseName: ""
} as PunchClockDeviceData;



