import React from "react";
import { IonContent, IonLoading } from "@ionic/react";
import "../global-styles.scss";
import env from "../appFunctions/environment";

const SplashComponent: React.FC = () => {
  return (
    <>
      <IonContent id="splash">
        <div className="login-content-container">
          <div className="login-logo">
            <img
              className="logo"
              src="assets/img/Orbital-Shift-Scheduling-And-Time-Tracking-App.svg"
              alt="Orbital Shift Logo"
            />
          </div>

          <IonLoading
            isOpen={true}
            // spinner="dots"
            message={"Getting Data..."}
            showBackdrop={true}
            duration={0}
          />

          <footer>
            {env.settings.appVersionString} ({env.settings.buildDate})
          </footer>
        </div>
      </IonContent>
    </>
  );
};

export default SplashComponent;
