import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { IdName } from "../../orbital-interfaces/IdName";
import {ReportType} from "../../enums";

interface ReportChooserProps {
  reports: IdName[];
  selectedReportId: ReportType;
  onReportChanged: (id: ReportType) => void;
}

//       className="report-chooser-select"

// ----------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------
const ReportChooser: React.FC<ReportChooserProps> = ({
  reports,
  onReportChanged,
  selectedReportId,
}: ReportChooserProps) => {
  function onSelectionChanged(id: number | null | undefined) {
    if (id === null || id === undefined) {
      return;
    }

    onReportChanged(id as ReportType);
  }

  return (
    <IonSelect
      mode="ios"
      interface="action-sheet"
      value={selectedReportId}
      onIonChange={(e) => onSelectionChanged(e.detail.value)}
    >
      {reports.map((rep) => (
        <IonSelectOption value={rep.id} key={rep.id}>
          {rep.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
};

export default ReportChooser;
