import React, { useState, useEffect } from "react";
// import "./ClockPanel.scss";
import "../../global-styles.scss";
import { IonButton } from "@ionic/react";
import { TimeClockSettingsData } from "../../orbital-interfaces/TimeClockSettingsData";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";

interface SwitchPositionOrTagButtonProps {
  tcData: TimeClockSettingsData;
  locSettings: LocationSettings;
  selectedPosId: number;
  selectedTagId: number | null;
  onSwitch: () => void;
}

const SwitchPositionOrTagButton: React.FC<SwitchPositionOrTagButtonProps> = ({
  tcData,
  locSettings,
  selectedPosId,
  selectedTagId,
  onSwitch,
}: SwitchPositionOrTagButtonProps) => {
  const [disabled, setDisabled] = useState(true);

  const hiddenJsx = <span />;

  useEffect(() => {
    if (tcData.UserClockedIn) {
      const curPosId = tcData.ClockedInScheduleEvent!.PositionId;
      let curTagId = tcData.ClockedInScheduleEvent!.TagId;
      if (!curTagId) {
        curTagId = -1;
      }

      if (curPosId !== selectedPosId) {
        setDisabled(false);
      } else if (curTagId !== selectedTagId) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [
    tcData.UserClockedIn,
    tcData.ClockedInScheduleEvent,
    selectedPosId,
    selectedTagId,
  ]);

  if (
    !locSettings.ClockInWithDifferentTag &&
    !locSettings.EnablePositionChangeForEmployeePosition
  ) {
    return hiddenJsx;
  }

  if (!tcData.UserClockedIn) {
    return hiddenJsx;
  }

  return (
    <IonButton
      className="ion-no-margin apply-button"
      disabled={disabled}
      expand="block"
      onClick={onSwitch}
    >
      SWITCH
    </IonButton>
  );
};

export default SwitchPositionOrTagButton;
