import {OrbitalTime} from "../orbital-interfaces/OrbitalTime";
import {LocationSettings} from "../orbital-interfaces/LocationSettings";
import {TimeFormat} from "../enums/TimeFormat";

// --------------------------------------------------------------------------------
function getZero(): OrbitalTime {
  return {
    ActualTime: "00:00:00",
    ActualHours: 0.0
  } as OrbitalTime;
}

// --------------------------------------------------------------------------------
function getString(orbitalTime: OrbitalTime, locSettings: LocationSettings) {
  if (!orbitalTime || orbitalTime.ActualTime === null || orbitalTime.ActualTime === undefined) {
    return '-';
  }

  if (orbitalTime.ActualTime === "00:00:00") {
    return '-';
  }

  if (locSettings.TimeFormat === TimeFormat.HoursMinutes) {
    return getHoursMinutesString(orbitalTime);
  } else if (locSettings.TimeFormat === TimeFormat.HoursAndDecimals) {
    return formatDecimal(
      orbitalTime.ActualHours,
      locSettings.TimeFormatDigits,
      locSettings.TimeFormatDecimals);
  }
}

// --------------------------------------------------------------------------------
function getHoursMinutesString(orbitalTime: OrbitalTime) {
  if (!orbitalTime || orbitalTime.ActualTime === null || orbitalTime.ActualTime === undefined) {
    return '-';
  }

  if (orbitalTime.ActualTime === "00:00:00") {
    return '-';
  }

  let actualTime = orbitalTime.ActualTime;
  let negSign = '';
  if (actualTime.indexOf('-') > -1) {
    negSign = '-';
    actualTime = actualTime.substring(1);
  }

  let days = 0;
  if (actualTime.indexOf(".") >= 0) {
    const dayStrSplit = actualTime.split(".");
    days = dayStrSplit[0];
    actualTime = dayStrSplit[1];
  }

  const tok = actualTime.split(":");
  const hoursString = formatDecimal(parseInt(tok[0])  + (days * 24), 1, 0);
  const minutesString = formatDecimal(parseInt(tok[1]), 2, 0);
  return negSign + hoursString + ":" + minutesString;
}

// --------------------------------------------------------------------------------
function add(values: OrbitalTime[]): OrbitalTime {
  const sum = getZero();
  let totalMinutes = 0;

  values.map(val => {
    if (!val) {
      return 0;
    }

    sum.ActualHours += val.ActualHours;
    totalMinutes += getMinutesFromActualTime(val.ActualTime);
    return totalMinutes;
  });

  sum.ActualTime = getTimeStringFromMinutes(totalMinutes);
  return sum;
}

// --------------------------------------------------------------------------------
function getTimeStringFromMinutes(min: number): string {
  const days = Math.floor(min / (24 * 60));
  min = min - (days * 24 * 60);
  const hours = Math.floor( min / 60);
  min = min - (hours * 60);

  return formatDecimal((24 * days) + hours, 1, 0) + ":" +
         formatDecimal(min, 2, 0) + ":00";
}

// --------------------------------------------------------------------------------
function getOrbitalTimeFromActualTime(actualTime: string): OrbitalTime {
  const min = getMinutesFromActualTime(actualTime);

  return {
    ActualTime: actualTime,
    ActualHours: min / 60
  } as OrbitalTime
}


// --------------------------------------------------------------------------------
function getMinutesFromActualTime(str: string): number {
  if (!str || str.length === 0) {
    return 0;
  }

  let days = 0;
  let hours = 0;
  let minutes = 0;
  if (str.indexOf(".") >= 0) {
    const dayStrSplit = str.split(".");
    days = parseInt(dayStrSplit[0]);
    const tok = dayStrSplit[1].split(":");
    hours = parseInt(tok[0]);
    minutes = parseInt(tok[1]);
  } else {
    const tok = str.split(":");
    hours = parseInt(tok[0]);
    minutes = parseInt(tok[1]);
  }

  return (
    (days * 24 * 60) +
    (hours * 60) +
    minutes
  );
}

// ---------------------------------------------------------------------------------------------
// Note that this gets it from an OrbitalTime type of string from the API.  If passed something
// like "01:30:00" then it will return 90 minutes, not 30 minutes.  For something that gets
// 30 minutes from a >> TimeSpan <<, then see getMinutesFromTimeSpan below

function getMinutesFromTimeString(
  locSettings: LocationSettings,
  str: string
) {
  if (!str || str.length === 0) {
    return 0;
  }

  if (locSettings.TimeFormat === TimeFormat.HoursMinutes) {
    const hourMinRegEx = /^\d*(:\d{2})?$/;
    if (!hourMinRegEx.test(str)) {
      return 0;
    }

    if (str[0] === ":") {
      return parseInt(str.substring(1));
    }

    const tok = str.split(":");
    if (tok.length === 1) {
      return parseInt(str) * 60;
    }

    return parseInt(tok[0]) * 60 + parseInt(tok[1]);
  } else {
    const decimalRegex = /^\d+(.\d*)?$/;
    if (!decimalRegex.test(str)) {
      return 0;
    }

    return Math.round(parseFloat(str) * 60.0);
  }
}

// --------------------------------------------------------------------------------
function formatDecimal(num: number, digits: number, decimals: number) {
  let val = (Number(num).toFixed(decimals)).toString();

  let negative = "";
  if (val.indexOf("-") === 0) {
    val = val.substring(1);
    negative = "-";
  }

  const s = "0000000000" + val;
  const decPos = val.indexOf(".");
  if (decPos === -1) {
    return  negative + s.substr(s.length - (Math.max(val.length, digits)));
  } else {
    return  negative + s.substr(s.length - (Math.max(val.length, digits + decimals + 1)));
  }
}

export const orbitalTimeHelper = {
  add,
  getHoursMinutesString,
  getString,
  getZero,
  getMinutesFromTimeString,
  getOrbitalTimeFromActualTime,
}
