import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";
import { LogLevel } from '../enums';
import { Device } from '@capacitor/device';

// ----------------------------------------------------------------------------------------------
async function writeLog(
  user: ApplicationUser,
  level: LogLevel,
  message: string
) {
  if (!user || !user.isLoggedin) {
    return;
  }

  log("api_writeLog");
  const deviceId = await Device.getId();

  return (await apiHelper.post(user, "api/v2/app-logs/write", {
    level,
    message,
    uuid: deviceId.uuid
  })) as ApiErrorResult;
}

export const appLogApi = {
  writeLog
};
