import React, { useContext, useState } from "react";
import {
  IonContent,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonIcon,
  IonFooter,
  IonGrid,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonInput,
  IonLabel,
} from "@ionic/react";
import { close } from "ionicons/icons";
import useDialogBox from "../hooks/useDialogBox";
import { useShowLoading } from "../hooks/useShowLoading";
import { employeesApi } from "../api/employeesApi";
import { ApplicationUserContext } from "../context/ApplicationUserContext";

interface UpdatePasswordModalProps {
  onClose: () => void;
  isOpen: boolean;
  employeeId: number | null;
}

const MIN_PASSWORD_CHARACTERS = 6;

// --------------------------------------------------------------------------------------
function isInputValid(
  employeeId: number | null,
  oldPass: string,
  newPass: string,
  confirmPass: string
) {
  if (employeeId === null && oldPass.length < MIN_PASSWORD_CHARACTERS) {
    return false;
  }

  if (
    newPass.length < MIN_PASSWORD_CHARACTERS ||
    confirmPass.length < MIN_PASSWORD_CHARACTERS
  ) {
    return false;
  }

  if (newPass !== confirmPass) {
    return false;
  }

  return true;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({
  onClose,
  employeeId,
}: UpdatePasswordModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const alert = useDialogBox();
  const showLoading = useShowLoading("Updating...");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errList, setErrList] = useState([] as string[]);

  // -------------------------------------------------------------------------------------
  function onUpdateClick() {
    showLoading.setShowLoading(true);
    employeesApi
      .updatePassword(user, employeeId, oldPassword, newPassword)
      .then((res) => {
        showLoading.setShowLoading(false);
        if (res.error) {
          setErrList(res.messages);
        } else {
          const message =
            employeeId === null
              ? "Your password has been updated"
              : "Password for employee has been updated";
          alert.showAlert("Updated", message, () => {
            onClose();
          });
        }
      });
  }

  // -------------------------------------------------------------------------------------
  function onOldPasswordUpdate(val: string | null | undefined) {
    if (val === null || val === undefined) {
      return;
    }

    setOldPassword(val);
  }

  // -------------------------------------------------------------------------------------
  function onNewPasswordUpdate(val: string | null | undefined) {
    if (val === null || val === undefined) {
      return;
    }

    setNewPassword(val);
  }

  // -------------------------------------------------------------------------------------
  function onConfirmPasswordUpdate(val: string | null | undefined) {
    if (val === null || val === undefined) {
      return;
    }

    setConfirmPassword(val);
  }

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>Update Password</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>
              <IonIcon icon={close} mode="ios" slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="outer-content">
        <IonGrid>
          {employeeId === null && (
            <IonRow>
              <IonCol className="label" size="4">
                <IonLabel className="label">Old Password</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonInput
                  type="password"
                  className="input-textbox"
                  value={oldPassword}
                  onIonChange={(e) => onOldPasswordUpdate(e.detail.value)}
                />
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol className="label" size="4">
              <IonLabel className="label">New Password</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="password"
                className="input-textbox"
                value={newPassword}
                onIonChange={(e) => onNewPasswordUpdate(e.detail.value)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="label" size="4">
              <IonLabel className="label">Confirm</IonLabel>
            </IonCol>
            <IonCol size="8">
              <IonInput
                type="password"
                className="input-textbox"
                value={confirmPassword}
                onIonChange={(e) => onConfirmPasswordUpdate(e.detail.value)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <ul>
                {errList.map((err) => (
                  <li key={err} style={{ color: "red" }}>
                    {err}
                  </li>
                ))}
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="block"
          className="button-selectEmployees-messages"
          onClick={onUpdateClick}
          disabled={
            !isInputValid(employeeId, oldPassword, newPassword, confirmPassword)
          }
        >
          Update
        </IonButton>
      </IonFooter>
      {alert.alertBoxJsx}
      {showLoading.showLoadingJsx}
    </>
  );
};

export default UpdatePasswordModal;
