
// Results returned from getPosition and in watchPosition's callback, regardless of whether
// we're using the web-navigator or Capacitor plugin implementation.
export interface GeoLocationResults {
  lat: number | undefined;
  lng: number | undefined;
  error: string | null;
}

export const INITIAL_GEOLOCATION_RESULTS = {
  lat: undefined,
  lng: undefined,
  error: null
} as GeoLocationResults;

// Options passed to both web-navigator and Capacitor plugin implementations
export const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: 5000
} as PositionOptions;

