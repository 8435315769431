/* eslint camelcase: 0 */
import { ScheduleEvent } from "../orbital-interfaces/ScheduleEvent";
import { EditorValues } from "../components/EditEvent/EditEventModalState";
import { TECGetData } from "../orbital-interfaces/TEC";
import { EventFilter } from "../orbital-interfaces/EventFilter";
import { timeHelper as th } from "../util/timeHelper";
import { ApplicationUser } from "../context/ApplicationUserContext";
import apiHelper from "./orbitalApiHelper";
import { RecurrenceUpdateType } from "../enums";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";

// ----------------------------------------------------------------------------------------------
async function getScheduleEvent(
  user: ApplicationUser,
  scheduleEventId: number
): Promise<ScheduleEvent | null> {
  log("api_scheduleEvents_getScheduleEvent");
  const result = await apiHelper.get(
    user,
    "api/v2/employee/schedule-events/get/" + scheduleEventId
  );

  if (result.error) {
    return null;
  }

  return result as ScheduleEvent;
}

// ----------------------------------------------------------------------------------------------
async function getScheduleEventsByFilter(
  user: ApplicationUser,
  filter: EventFilter,
  rangeStart: Date,
  rangeEnd: Date
) {
  log("api_scheduleEvents_getEventsByFilter");
  const filterToSend = {
    ...filter,
    start: th.formatDateTimeAsMvcString(filter.start),
    end: th.formatDateTimeAsMvcString(filter.end),
    rangeStart: th.formatDateTimeAsMvcString(rangeStart),
    rangeEnd: th.formatDateTimeAsMvcString(rangeEnd)
  };

  return (await apiHelper.post(
    user,
    "api/v2/events/filter",
    filterToSend
  )) as TECGetData;
}

// ----------------------------------------------------------------------------------------------
async function getRequestEventsByFilter(
  user: ApplicationUser,
  filter: EventFilter,
  rangeStart: Date,
  rangeEnd: Date
) {
  log("api_scheduleEvents_getRequests");
  const filterToSend = {
    ...filter,
    start: th.formatDateTimeAsMvcString(filter.start),
    end: th.formatDateTimeAsMvcString(filter.end),
    rangeStart: th.formatDateTimeAsMvcString(rangeStart),
    rangeEnd: th.formatDateTimeAsMvcString(rangeEnd)
  };

  return (await apiHelper.post(
    user,
    "api/v2/events/requests",
    filterToSend
  )) as TECGetData;
}

// ----------------------------------------------------------------------------------------------
export function fixIsoStringForMvc(ds: string | null): string | null {
  if (ds === null) {
    return null;
  }

  if (ds.length > 19) {
    return ds.slice(0, 19);
  }

  return ds;
}

// ----------------------------------------------------------------------------------------------
async function updateScheduleEvent(
  user: ApplicationUser,
  eventValues: EditorValues,
  recurrenceUpdateType: RecurrenceUpdateType | null
) {
  log("api_scheduleEvents_updateScheduleEvent");
  const data = {
    scheduleEventId: eventValues.scheduleEventId,
    eventType: eventValues.eventType,
    eventStatus: eventValues.eventStatus,
    isConfirmed: eventValues.isConfirmed,
    positionId: eventValues.position.position.id,
    employeeId: eventValues.employee.id,
    eventStart: fixIsoStringForMvc(eventValues.eventStart),
    eventEnd: fixIsoStringForMvc(eventValues.eventEnd),
    tagId: eventValues.tag?.id,
    eventBreaks: eventValues.eventBreaks,
    isClockedIn: eventValues.isClockedIn,
    breakStart: fixIsoStringForMvc(eventValues.breakStartTime),
    breakDuration: eventValues.lunchHourTime,
    clockOutValue: eventValues.clockoutValue,
    absentId: eventValues.absentId,
    absentTime: eventValues.absentTimeMinutes,
    notes: eventValues.notes,
    sendMessage: true,
    recurrenceId: eventValues.recurrenceId,
    recurrenceStart: eventValues.recurrenceStart,
    recurrenceEnd: eventValues.recurrenceEnd,
    recurrenceType: eventValues.recurrenceType,
    repeatInterval: eventValues.repeatInterval,
    repeatWeekdays: eventValues.repeatWeekdays,
    recurrenceUpdateType,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employee/schedule-events/update",
    data
  )) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function createScheduleEvent(
  user: ApplicationUser,
  eventValues: EditorValues,
  recurrenceUpdateType: RecurrenceUpdateType | null
) {
  log("api_scheduleEvents_createScheduleEvent");
  const data = {
    sceduleEventId: 0,
    eventType: eventValues.eventType,
    eventStatus: eventValues.eventStatus,
    isConfirmed: eventValues.isConfirmed,
    positionId: eventValues.position.position.id,
    employeeId: eventValues.employee.id,
    eventStart: fixIsoStringForMvc(eventValues.eventStart),
    eventEnd: fixIsoStringForMvc(eventValues.eventEnd),
    tagId: eventValues.tag?.id,
    eventBreaks: eventValues.eventBreaks,
    isClockedIn: eventValues.isClockedIn,
    breakStart: fixIsoStringForMvc(eventValues.breakStartTime),
    breakDuration: eventValues.lunchHourTime,
    clockOutValue: eventValues.clockoutValue,
    absentId: eventValues.absentId,
    absentTime: eventValues.absentTimeMinutes,
    notes: eventValues.notes,
    sendMessage: false,
    recurrenceId: eventValues.recurrenceId,
    recurrenceStart: eventValues.recurrenceStart,
    recurrenceEnd: eventValues.recurrenceEnd,
    recurrenceType: eventValues.recurrenceType,
    repeatInterval: eventValues.repeatInterval,
    repeatWeekdays: eventValues.repeatWeekdays,
    recurrenceUpdateType,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employee/schedule-events/create",
    data
  )) as ApiErrorResult;
};

// ----------------------------------------------------------------------------------------------
async function deleteScheduleEvent(
  user: ApplicationUser,
  scheduleEventId: number,
  recurrenceUpdateType: RecurrenceUpdateType | null
) {
  log("api_scheduleEvents_deleteScheduleEvent");
  const data = {
    scheduleEventId,
    recurrenceUpdateType,
    sendMessage: false,
  };

  return (await apiHelper.post(
    user,
    "api/v2/employee/schedule-events/delete",
    data
  )) as ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function updateConfirmedScheduleEvent(
  user: ApplicationUser,
  id: number,
  confirm: boolean
) {
  log("api_scheduleEvents_updateConfirmed");
  const data = {id, confirm};
  return (await apiHelper.post(
    user,
    "api/v2/employee/schedule-events/confirm",
    data)) as ApiErrorResult;
}

export const scheduleEventsApi = {
  getScheduleEvent,
  getScheduleEventsByFilter,
  getRequestEventsByFilter,
  updateScheduleEvent,
  createScheduleEvent,
  deleteScheduleEvent,
  updateConfirmedScheduleEvent,
};
