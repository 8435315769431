import React, { CSSProperties, useContext } from "react";
import { timeHelper as th} from "../../util/timeHelper";
import "../../global-styles.scss";
import { AuthorizedLocation } from "../../orbital-interfaces/AuthorizedItems";
import { format, parseISO } from "date-fns";
import { EventInformation } from "../../orbital-interfaces/TEC";
import {time, cafe, checkmark, flag, lockClosed} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { getEventEditAuthorization } from "../EditEvent/EditEventAuthorization";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import {scheduleEventHelper} from "../../util/scheduleEventHelper";
import {authHelper} from "../../util/authHelper";
import {orbitalTimeHelper} from "../../util/orbitalTimeHelper";
import {EventType} from "../../enums";

interface EventCardProps {
  event: EventInformation;
  location: AuthorizedLocation;
  multiLocation: boolean;
  showEmployeeName: boolean;
  showTimeColumn: boolean;
  onEdit: (ev: EventInformation) => void;
  isTimeClockPage: boolean;
}

const colorMap: any = {
  1: "#b2cb89", // Shift old #9CBA6A, #2BBA74
  2: "#a5acaf", // Unavailable old #BFBFBF
  3: "#BAA622", // Payroll old #FFAD00
  4: "#cc5500", // Cover old #C41A26
  6: "#6a8049", // Clock old #00747f, #677A46
  7: "#ffad00", // Payroll old #FFAD00
};

const statusNameColor: any = {
  1: { color: "#4c4c4c" }, // Approved old #000000
  2: { color: "#cc5500" }, // Request old #C41A26
  4: { color: "#a5acaf" }, // Request-Denied old #BCBCBC
};

const extendedIconColor = "#fecd5b"; // Flag old orange
const regularIconColor = "#4c4c4c";

// --------------------------------------------------------------------------------------
// Note that the EventCard is laid out as follows:
//
//  --- card -----------------------------------------------------------------------------------------------|
//  |-- card-outer -----------------------------------------------------------------------------------------|
//  || card-name-column | card-duration-column | card-start-column | card-end-column | card-position-column |
//  ||  first name      |  event duration      |   day of wk start |  day of wk end  | Location/Dept        |
//  ||  last  name      |  week duration       |   time start      |  time end       | Position ~tag        |
//  ||                  |                      |                   |  Break 1                               |
//  ||                  |                      |                   |  Break 2                               |
//  | ------------------------------------------------------------------------------------------------------|
//
// where the rows for Breaks within the card-position-column are laid out as:
//
//   |-- card-position-column ---------------------------------------------------------------------|
//   || break-b  |  break-start  |  break-dash  |  break-end  |  break-paid  |  break-restricted  ||
//   |---------------------------------------------------------------------------------------------|
//
// --------------------------------------------------------------------------------------
const EventCard: React.FC<EventCardProps> = ({
  location,
  event,
  multiLocation,
  onEdit,
  showEmployeeName,
  showTimeColumn,
  isTimeClockPage,
}: EventCardProps) => {
  const { auth } = useContext(AuthorizedItemsContext);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, event.locId);
  const multiDepartment = multiLocation
    ? true
    : location.departments.filter((d) =>
        d.positions.filter((p) => p.hasPosition)
      ).length > 1;



  let locDepDisplay = "";
  if (event.eventTypeId !== EventType.Unavailable) {
    if (multiLocation && event.abbrevLocName) {
      locDepDisplay = event.abbrevLocName + "/";
    }

    if (multiDepartment && event.abbrevDeptName) {
      locDepDisplay += event.abbrevDeptName;
    }
  }

  const isOnBreak = event.eventBreaks?.find(b => b.BreakEnd === null) !== undefined;
  const nameColor: CSSProperties = statusNameColor[event.eventStatusId];

  const iconColor = scheduleEventHelper.isExtendedClock(event, locSettings)
    ? extendedIconColor
    : regularIconColor;

  const isThisUser = event.empId === auth.employeeId;
  const cardStyle = (showEmployeeName && isThisUser && !isTimeClockPage)
    ? {backgroundColor: "var(--ion-color-lightgrey)" } as CSSProperties
    : {} as CSSProperties;
  const eventTimeStyle = (event.eventOverTime)
    ? {color: "var(--ion-color-red)"} as CSSProperties
    : {} as CSSProperties;
  const weekTimeStyle = (event.weekOverTime || event.eventOverTime)
    ? {color: "var(--ion-color-red)"} as CSSProperties
    : {} as CSSProperties;
  const startDayOfWeek = format(parseISO(event.EventStart), "iii");
  const endDayOfWeek = format(parseISO(event.EventEnd), "iii");

  const eventTimeString = orbitalTimeHelper.getHoursMinutesString(event.eventTime);
  let weekTimeString = (eventTimeString === "-")
    ? ""
    : orbitalTimeHelper.getHoursMinutesString(event.totalTime);

  if (weekTimeString === eventTimeString)
  {
    weekTimeString = "";
  }

  const positionName = event.eventTypeId === EventType.Unavailable ? "" : event.posName;
  const notesIndicator =
    ((positionName.length > 0 && event.notes) ? ", " : "") +   // <-- show a comma?
    (event.notes ? "(N)" : "")            // <-- show the notes

  // --------------------------------------------------------------------------------------
  const onUserClick = () => {
    if (!getEventEditAuthorization(
      event.eventTypeId,
      event.eventStatusId,
      event.empId,
      event.EventStart,
      event.EventEnd,
      event.locId,
      event.deptId!,
      event.posId,
      event.isConfirmed,
      auth
    )) {
      return;
    }

    onEdit(event);
  };

  return (
    <button
      className="card"
      style={cardStyle}
      onClick={() => {
        onUserClick();
      }}
    >
      <div className="card-outer" style={{borderLeft: `7px solid ${colorMap[event.eventTypeId]}`}}>
        <table>
          <tbody>
            <tr>
              <td
                className="card-name-col"
                style={nameColor}
              >
                {isOnBreak && <IonIcon icon={cafe} style={{color: iconColor}} />}
                {event.isClockedIn && !isOnBreak && (
                  <IonIcon icon={time} style={{color: iconColor}} />
                )}
                {scheduleEventHelper.showFlagForExtendedClock(event, locSettings) && (
                  <IonIcon icon={flag} style={{color: extendedIconColor}} />
                )}
                {event.firstName}
              </td>
              {showTimeColumn && (
                <td
                  className="card-duration-col"
                  style={eventTimeStyle}
                >
                  {eventTimeString}
                </td>
              )}
              <td
                className="card-start-col"
              >
                {startDayOfWeek}
              </td>
              <td
                className="card-end-col"
              >
                {startDayOfWeek !== endDayOfWeek ? endDayOfWeek : ""}
              </td>
              <td className="card-position-col">
                {locDepDisplay}
              </td>
            </tr>


            <tr>
              <td
                className="card-name-col"
                style={nameColor}
              >
                {event.lastName}
              </td>
              {showTimeColumn && (
                <td
                  className="card-duration-col"
                  style={weekTimeStyle}
                >
                  {weekTimeString}
                </td>
              )}
              <td
                className="card-start-col"
              >
                {th.getTimeStringFromEventInformation(event.EventStart)}
              </td>
              <td
                className="card-end-col"
              >
                {th.getTimeStringFromEventInformation(event.EventEnd)}
              </td>
              <td className="card-position-col">
                {event.isConfirmed && <IonIcon icon={checkmark} />}
                {positionName}
                {event.tag && `  ~${event.tag}`}{notesIndicator}
              </td>            
            </tr>
            {event.eventBreaks && event.eventBreaks.length > 0 && isThisUser && (
              <tr>
                <td colSpan={3}></td>
                <td colSpan={2} className="card-position-col">
                  <table>
                    <tbody>
                      {event.eventBreaks.map((b) => (
                        <tr key={b.BreakStart}>
                          <td className="break-b">(B)</td>
                          <td className="break-start">{th.getTimeStringFromEventInformation(b.BreakStart)}</td>
                          <td className="break-dash"> - </td>
                          <td className="break-end">{b.BreakEnd ? th.getTimeStringFromEventInformation(b.BreakEnd) : ""}</td>
                          <td className="break-paid">
                            {b.IsPaidBreak ? "$" : " "}
                          </td>
                          <td className="break-restricted">
                            {b.IsRestricted ? (
                              <IonIcon icon={lockClosed} />
                            ) : " "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    </table>
                </td>
              </tr>

            )}
          </tbody>
        </table>
      </div>
    </button>
  );
};

export default EventCard;
