import React from "react";
import { Redirect } from "react-router";
import MainTabs from "./../pages/MainTabs";
import { ApplicationUserContext } from "../context/ApplicationUserContext";

interface StateProps {}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const AuthGate: React.FC<StateProps> = () => {
  const { user } = React.useContext(ApplicationUserContext);

  if (user.loading) {
    return <></>;
  }

  if (user.isLoggedin) {
    return <MainTabs />;
  }

  return <Redirect to="/login" />;
};

export default AuthGate;
