import {ScheduleEvent} from "../../orbital-interfaces/ScheduleEvent";
import {authHelper, SelectedPosition} from "../../util/authHelper";
import {EventBreak} from "../../orbital-interfaces/EventBreak";
import {
  addHours,
  addMinutes,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  setHours,
  setMinutes,
  subHours,
  subMinutes,
} from "date-fns";
import {timeHelper as th} from "../../util/timeHelper";
import {AdminAuthorization, EventStatus, EventType, RecurrenceType, Role,} from "../../enums";
import {AuthorizedItems, AuthorizedLocation, AuthorizedPosition,} from "../../orbital-interfaces/AuthorizedItems";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";
import {EventInformation} from "../../orbital-interfaces/TEC";
import {IdName} from "../../orbital-interfaces/IdName";
import {ApplicationUser} from "../../context/ApplicationUserContext";

export interface EditorValues {
  isFromEventInfo: boolean;
  scheduleEventId: number;
  locationId: number;
  isUpdating: boolean;
  eventType: EventType;
  eventStatus: EventStatus;
  isConfirmed: boolean;
  position: SelectedPosition;
  employee: IdName;
  eventStart: string;
  eventEnd: string;
  tag: IdName;
  lunchHourTime: string | null;
  breakStartTime: string | null;
  eventBreaks: EventBreak[];
  isClockedIn: boolean;
  valid: boolean;
  validEventStartEnd: boolean;
  validEmployee: boolean;
  validTag: boolean;
  validEventTypeStatus: boolean;
  validClockoutValue: boolean;
  validRecurrenceStartEnd: boolean;
  validRepeatInterval: boolean;
  validRepeatWeekdays: boolean;
  absentId?: number;
  absentName?: string;
  absentTimeMinutes: number | null;
  notes: string;
  clockoutValue?: string;
  isReadonly: boolean;
  recurrenceId?: number;
  recurrenceStart?: string;
  recurrenceEnd?: string | null;
  recurrenceType?: RecurrenceType;
  repeatInterval?: number;
  repeatWeekdays?: string;
  transferToEmployeeId: number | null;
  transferToScheduleEventId: number | null;
  transferFromScheduleEventId: number | null;
}

export const INITIAL_EDITOR_VALUES: EditorValues = {
  isFromEventInfo: false,
  scheduleEventId: -1,
  locationId: 0,
  isUpdating: false,
  eventType: EventType.Shift,
  eventStatus: EventStatus.Approved,
  isConfirmed: false,
  position: {
    location: {} as AuthorizedLocation,
    position: {} as AuthorizedPosition,
  },
  employee: { id: -1, name: "SELECT EMPLOYEE" },
  eventStart: th.convertTimeWithoutTimeZoneToLocalTime(th.formatDateTimeAsMvcString(setHours(setMinutes(new Date(), 0), 8))),
  eventEnd: th.convertTimeWithoutTimeZoneToLocalTime(th.formatDateTimeAsMvcString(setHours(setMinutes(new Date(), 0), 17))),
  tag: { id: -1, name: "SELECT TAG" },
  lunchHourTime: null,
  breakStartTime: null,
  eventBreaks: [] as EventBreak[],
  isClockedIn: false,
  valid: true,
  validEventStartEnd: true,
  validEmployee: true,
  validTag: true,
  validEventTypeStatus: true,
  validClockoutValue: true,
  validRecurrenceStartEnd: true,
  validRepeatInterval: true,
  validRepeatWeekdays: true,
  absentId: undefined,
  absentName: undefined,
  absentTimeMinutes: null,
  notes: "",
  clockoutValue: undefined,
  isReadonly: false,
  transferToEmployeeId: null,
  transferFromScheduleEventId: null,
  transferToScheduleEventId: null,
};

export type EditorReducerAction =
  | { type: "LOADING"; data: "we're loading " }
  | {
  type: "BEGIN-UPDATE";
  auth: AuthorizedItems;
  ev: ScheduleEvent;
}
  | { type: "CANCEL" }
  | { type: "CREATE-EVENT"; auth: AuthorizedItems, user: ApplicationUser }
  | {
  type: "TYPESTATUS-UPDATED";
  eventType: EventType;
  eventStatus: EventStatus;
  absentId?: number;
}
  | { type: "POSITION-UPDATED"; selectedPosition: SelectedPosition }
  | { type: "EMPLOYEE-UPDATED"; employee: IdName }
  | { type: "START-UPDATED"; eventStart: string }
  | { type: "END-UPDATED"; eventEnd: string }
  | { type: "EVENTBREAKS-UPDATED"; breaks: EventBreak[] }
  | { type: "TAG-UPDATED"; tag: IdName }
  | { type: "CONFIRM-UPDATED"; isConfirmed: boolean }
  | { type: "ISCLOCKEDIN-UPDATED"; isClockedIn: boolean }
  | { type: "NOTES-UPDATED"; notes: string }
  | { type: "LUNCHHOURTIME-UPDATED"; lunchHourTime: string | null}
  | { type: "BREAKSTARTTIME-UPDATED"; breakStartTime: string | null}
  | {
  type: "CLOCKOUTVALUE-UPDATED";
  clockoutValue?: string;
  locSettings: LocationSettings;
}
  | { type: "TRANSFERTO-EMPLOYEE-UPDATED"; transferToEmployeeId: number | null}
  | { type: "SET-READONLY"; isReadonly: boolean }
  | {
    type: "REPEATING-UPDATED";
    recurrenceStart?: string;
    recurrenceEnd?: string | null;
    recurrenceType?: RecurrenceType;
    repeatInterval?: number;
    repeatWeekDays?: string;
}
  | { type: "ABSENTTIME-UPDATED"; absentTimeMinutes: number | null}
;

// --------------------------------------------------------------------------------------
export function isAdminForThisEvent(ev: EditorValues, auth: AuthorizedItems): boolean {
  if (auth.role === Role.Employee) {
    return false;
  }

  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return true;
  }

  if (auth.role === Role.Admin) {
    const dept = authHelper.getDepartmentFromPositionId(auth, ev.position.position.id);
    if (dept === null) {
      return false;
    }

    return (authHelper.isAdminAuthorizedFor(
      auth,
      ev.locationId,
      dept.id,
      AdminAuthorization.CanEditSchedules
    ));
  }

  return false;
}


// ------------------------------------------------------------------------------------------
export function getEditorValuesFromEventInformation(
  auth: AuthorizedItems,
  ev: EventInformation
): EditorValues {
  return {
    ...INITIAL_EDITOR_VALUES,
    isFromEventInfo: true,
    eventStatus: ev.eventStatusId,
    eventType: ev.eventTypeId,
    eventStart: ev.EventStart,
    eventEnd: ev.EventEnd,
    notes: ev.notes,
    isClockedIn: ev.isClockedIn,
    isConfirmed: ev.isConfirmed,
    scheduleEventId: ev.id,
    position: authHelper.getSelectedPositionWithLocId(
      auth.locations,
      ev.locId,
      ev.posId
    ),
    employee: { id: ev.empId, name: ev.firstName + " " + ev.lastName },
    eventBreaks: ev.eventBreaks,
  };
}

// ------------------------------------------------------------------------------------------
export function getEditorValuesFromScheduleEvent(
  auth: AuthorizedItems,
  ev: ScheduleEvent) {
  const isClockedIn = getIsClockedIn(ev);

  const editorValues = {
    isFromEventInfo: false,
    scheduleEventId: ev.ScheduleEventId,
    locationId: ev.LocationId,
    isUpdating: false,
    eventType: ev.EventTypeId,
    eventStatus: ev.EventStatusId,
    isConfirmed: ev.ConfirmedBy !== undefined && ev.ConfirmedBy !== null,
    position: {
      location: getLocationById(auth, ev.LocationId),
      position: getPositionById(auth, ev.PositionId),
    },
    employee: { id: ev.EmployeeId, name: ev.FirstName + " " + ev.LastName },
    eventStart: ev.EventStart,
    eventEnd: isClockedIn
      ? th.formatDateTimeAsMvcString(new Date())
      : ev.EventEnd,
    tag: { id: ev.TagId ? ev!.TagId : -1, name: "" },
    lunchHourTime: ev.LunchHourTime,
    breakStartTime: ev.BreakStartTime,
    eventBreaks: ev.EventBreaks,
    isClockedIn: isClockedIn,
    valid: true,
    validEventStartEnd: true,
    validEmployee: true,
    validTag: true,
    validEventTypeStatus: true,
    validClockoutValue: true,
    absentName: ev.AbsentName,
    absentId: ev.AbsentId,
    absentTimeMinutes: ev.AbsentTimeMinutes !== null && ev.AbsentTimeMinutes !== undefined
      ? ev!.AbsentTimeMinutes
      : null,
    notes: ev.Notes,
    clockoutValue: ev.ClockOutValue,
    isReadonly: isReadonly(auth, ev),
    recurrenceId: ev.EventRecurrenceId,
    recurrenceStart: ev.EventRecurrenceStart,
    recurrenceEnd: ev.EventRecurrenceEnd,
    recurrenceType: ev.RepeatType,
    repeatInterval: ev.RepeatInterval,
    repeatWeekdays: ev.RepeatWeekdays,
    validRecurrenceStartEnd: true,
    validRepeatInterval: true,
    validRepeatWeekdays: true,
    transferToEmployeeId: null,
    transferFromScheduleEventId: ev.EventTransfer_ScheduleEventId_From,
    transferToScheduleEventId: ev.EventTransfer_ScheduleEventId_To
  };

  // If this is a Cover-Approved or Shift-Approved that was transferred from a
  // cover-request, and the person viewing it is an admin, then set the view of it to
  // read-only which will allow them to undo the cover before changing it.
  if ((editorValues.transferFromScheduleEventId !== null || editorValues.transferToScheduleEventId !== null) &&
      editorValues.eventStatus === EventStatus.Approved &&
      (editorValues.eventType === EventType.Shift || editorValues.eventType === EventType.Cover) &&
      isAdminForThisEvent(editorValues, auth)) {
    editorValues.isReadonly = true;
  }

  return editorValues;
}

// ------------------------------------------------------------------------------------------
function getLocationById(auth: AuthorizedItems, locId: number) {
  return auth.locations.filter((l) => l.id === locId)[0];
}

// ------------------------------------------------------------------------------------------
function getPositionById(auth: AuthorizedItems, posId: number) {
  return auth.locations.flatMap((l) =>
    l.departments.flatMap((d) => d.positions.filter((p) => p.id === posId))
  )[0];
}

// ------------------------------------------------------------------------------------------
function getValidStartEnd(startStr: string, endStr: string) {
  const start = parseISO(startStr);
  const end = parseISO(endStr);

  if (isEqual(start, end)) {
    return true;
  }

  return isAfter(end, start);
}

// ------------------------------------------------------------------------------------------
function isReadonly(auth: AuthorizedItems, ev: ScheduleEvent) {
  if (ev.ScheduleEventId <= 0) {
    return false;
  }

  if (auth.role === Role.Employee) {
    if (ev.EmployeeId !== auth.employeeId) {
      return true;
    }

    if (ev.EventStatusId === EventStatus.Request) {
      return (ev.EventTypeId === EventType.Cover);
    }

    return true;
  }

  if (auth.role === Role.BusinessAdmin || auth.role === Role.LocationAdmin) {
    return false;
  }

  return !authHelper.isAdminAuthorizedFor(
    auth,
    ev.LocationId,
    ev.DepartmentId,
    AdminAuthorization.CanEditSchedules);
}

// --------------------------------------------------------------------------------------
// Used to set the inital start and end time when creating a new event
function getDateWithTimeSpan(date: Date, ts: string): Date {
  const hours = th.getHoursFromTimeSpan(ts);
  const minutes = th.getMinutesFromTimeSpan(ts);
  return setMinutes(setHours(date, hours), minutes);
}

// ------------------------------------------------------------------------------------------------
// Generates EditorValues when creating a new event (clicking the plus button on Schedule page)
function createInitialEditorValuesForNewEvent(auth: AuthorizedItems, user: ApplicationUser): EditorValues {
  const loc = authHelper.getLocationFromLocationId(auth, user.defaultLocationId);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
  const now = new Date();
  const eventStart = th.convertTimeWithoutTimeZoneToLocalTime(
    (th.formatDateTimeAsMvcString(getDateWithTimeSpan(now, locSettings.NewEventDefaultStartTime))));
  const eventEnd = th.convertTimeWithoutTimeZoneToLocalTime(
    (th.formatDateTimeAsMvcString(getDateWithTimeSpan(now, locSettings.NewEventDefaultEndTime))));

  let lunchHourTime = null as null | string;
  let breakStartTime = null as null | string;
  if (locSettings.ShowBreak) {
    lunchHourTime = locSettings.DefaultBreakDuration;

    if (locSettings.EnableBreakStartTime && locSettings.DefaultBreakStartTime) {
      const startDate = parseISO(eventStart);
      let bst = subHours(subMinutes(startDate, getMinutes(startDate)), getHours(startDate));
      const tok = locSettings.DefaultBreakStartTime.split(":");
      bst = addHours(bst, tok[0]);
      bst = addMinutes(bst, tok[1]);
      breakStartTime = th.convertTimeWithoutTimeZoneToLocalTime(th.formatDateTimeAsMvcString(bst));
    }
  }

  return {
    ...INITIAL_EDITOR_VALUES,
    eventStart,
    eventEnd,
    employee: { id: auth.employeeId, name: auth.fullName},
    position: {
      location: loc,
      position: loc.departments[0].positions[0],
    },
    lunchHourTime,
    breakStartTime,
    scheduleEventId: 0,
  };
}

// ------------------------------------------------------------------------------------------------
function isClockoutValueValid(state: EditorValues, locSettings: LocationSettings,  val?: string) {
  if (!val) {
    return true;
  }

  // clockout values are not enabled
  if (
    !locSettings.ClockOutValueLabel ||
    locSettings.ClockOutValueLabel.length === 0
  ) {
    return false;
  }

  const regEx = /^\d*(\.\d{0,2})?$/;
  return regEx.test(val);
}

// ------------------------------------------------------------------------------------------------
function isAllValid(v: EditorValues): boolean {
  if (v.eventType === EventType.Absent && v.absentTimeMinutes === null) {
    return false;
  }

  return (
    v.validEventStartEnd &&
    v.validEmployee &&
    v.validTag &&
    v.validEventTypeStatus &&
    v.validClockoutValue &&
    v.validRecurrenceStartEnd &&
    v.validRepeatInterval &&
    v.validRepeatWeekdays
  );
}

// ------------------------------------------------------------------------------------------------
function getIsClockedIn(ev: ScheduleEvent) {
  const end = parseISO(ev.EventEnd);
  return end > new Date(2199, 1, 1);
}

// ------------------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------------------
export const editorValueReducer = (
  currentState: EditorValues,
  action: EditorReducerAction
): EditorValues => {
  switch (action.type) {
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "BEGIN-UPDATE":
      const { ev, auth } = action;
      return getEditorValuesFromScheduleEvent(auth, ev);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "CANCEL":
      return INITIAL_EDITOR_VALUES;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "POSITION-UPDATED":
      const { selectedPosition } = action;
      return {
        ...currentState,
        position: selectedPosition,
      };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "CREATE-EVENT":
      return createInitialEditorValuesForNewEvent(action.auth, action.user);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "SET-READONLY":
      return {
        ...currentState,
        isReadonly: action.isReadonly,
      };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "TYPESTATUS-UPDATED":
      const clockedInOnTypeStatusUpdate = currentState.isClockedIn &&
        action.eventType === EventType.Clock &&
        action.eventStatus === EventStatus.Approved;
      const newTypeStatusState = {
        ...currentState,
        eventType: action.eventType,
        eventStatus: action.eventStatus,
        absentId: action.absentId,
        isClockedIn: clockedInOnTypeStatusUpdate,
      };

      if (action.eventType !== EventType.Absent) {
        newTypeStatusState.absentTimeMinutes = null;
      }

      if (action.eventType === EventType.Clock && currentState.eventType === EventType.Shift) {
        newTypeStatusState.eventBreaks = [] as EventBreak[];
      }

      newTypeStatusState.valid = isAllValid(newTypeStatusState);
      return newTypeStatusState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "ABSENTTIME-UPDATED":
      const newAbsentTimeStatusState = {
        ...currentState,
        absentTimeMinutes: action.absentTimeMinutes
      }

      newAbsentTimeStatusState.valid = isAllValid(newAbsentTimeStatusState);
      return newAbsentTimeStatusState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "CLOCKOUTVALUE-UPDATED":
      const newClockoutValueState = {
        ...currentState,
        clockoutValue: action.clockoutValue,
        validClockoutValue: isClockoutValueValid(
          currentState,
          action.locSettings,
          action.clockoutValue
        ),
      };

      newClockoutValueState.valid = isAllValid(newClockoutValueState);
      return newClockoutValueState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "EMPLOYEE-UPDATED":
      const newState = {
        ...currentState,
        employee: action.employee,
        validEmployee: action.employee.id > 0,
      };

      newState.valid = isAllValid(newState);
      return newState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "START-UPDATED":
      const newStartState = {
        ...currentState,
        eventStart: action.eventStart,
        validEventStartEnd: getValidStartEnd(
          action.eventStart,
          currentState.eventEnd
        ),
      };

      newStartState.valid = isAllValid(newStartState);
      return newStartState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "END-UPDATED":
      const newEndState = {
        ...currentState,
        eventEnd: action.eventEnd,
        validEventStartEnd: getValidStartEnd(
          currentState.eventStart,
          action.eventEnd
        ),
      };

      newEndState.valid = isAllValid(newEndState);
      return newEndState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "EVENTBREAKS-UPDATED":
      return {
        ...currentState,
        eventBreaks: action.breaks,
      };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "LUNCHHOURTIME-UPDATED":
      return {
        ...currentState,
        lunchHourTime: action.lunchHourTime
      };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "BREAKSTARTTIME-UPDATED":
      return {
        ...currentState,
        breakStartTime: action.breakStartTime
      };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    case "TAG-UPDATED":
      const newTagState = {
        ...currentState,
        tag: action.tag,
        validTag: true,
      };

      newTagState.valid = isAllValid(newTagState);
      return newTagState;

    case "CONFIRM-UPDATED":
      const newConfirmState = {
        ...currentState,
        isConfirmed: action.isConfirmed,
      };

      newConfirmState.valid = isAllValid(newConfirmState);
      return newConfirmState;

    case "NOTES-UPDATED":
      return {
        ...currentState,
        notes: action.notes,
      };

    case "ISCLOCKEDIN-UPDATED":
      const newClockedInState = {
        ...currentState,
        isClockedIn: action.isClockedIn,
      };

      if (action.isClockedIn) {
        newClockedInState.eventEnd =
          th.convertTimeWithoutTimeZoneToLocalTime(th.formatDateTimeAsMvcString(new Date()));
      }

      newClockedInState.valid = isAllValid(newClockedInState);
      return newClockedInState;

    case "TRANSFERTO-EMPLOYEE-UPDATED":
      const newTransferToState = {
        ...currentState,
        transferToEmployeeId: action.transferToEmployeeId,
      }

      if (action.transferToEmployeeId !== null) {
        newTransferToState.eventStatus = EventStatus.Approved;
      }

      newTransferToState.valid = isAllValid(newTransferToState);
      return newTransferToState;

    case "REPEATING-UPDATED":
      const newRepeatState = {
        ...currentState,
        recurrenceStart: action.recurrenceStart,
        recurrenceEnd: (action.recurrenceStart !== undefined && action.recurrenceEnd === undefined)
          ? null
          : action.recurrenceEnd,
        recurrenceType: action.recurrenceType,
        repeatInterval: action.repeatInterval,
        repeatWeekdays: action.repeatWeekDays,
      };

      if (newRepeatState.recurrenceStart) {
        if (newRepeatState.recurrenceType === RecurrenceType.Weekly) {
          newRepeatState.validRepeatWeekdays =
            newRepeatState.repeatWeekdays !== null &&
            newRepeatState.repeatWeekdays !== undefined &&
            newRepeatState.repeatWeekdays.length > 0;
        } else {
          newRepeatState.repeatWeekdays = undefined;
          newRepeatState.validRepeatWeekdays = true;
        }

        if (newRepeatState.recurrenceEnd === undefined) {
          newRepeatState.validRecurrenceStartEnd = (newRepeatState.recurrenceStart === undefined);
        } else if (newRepeatState.recurrenceEnd === null) {
          newRepeatState.validRecurrenceStartEnd = true;
        } else {
          newRepeatState.validRecurrenceStartEnd = isBefore(
            parseISO(newRepeatState.recurrenceStart),
            parseISO(newRepeatState.recurrenceEnd)
          );
        }

        newRepeatState.validRepeatInterval =
          newRepeatState.repeatInterval !== null &&
          newRepeatState.repeatInterval !== undefined &&
          newRepeatState.repeatInterval > 0;
      }

      newRepeatState.valid = isAllValid(newRepeatState);
      return newRepeatState;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    default:
      console.error(
        "ERROR in editorValueReducer, could not find action for " + action.type
      );
      return currentState;
  }
};
