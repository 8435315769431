import React, { useEffect, useState } from "react";
import {
  IonCheckbox,
  IonContent,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonGrid,
  IonIcon,
} from "@ionic/react";
import "../../global-styles.scss";
import { EmployeeCommunication } from "../../orbital-interfaces/EmployeeCommunication";
import { mail, notifications, phonePortrait } from "ionicons/icons";

interface EmployeeSelectorModalProps {
  onCancel: () => void;
  onOk: (employees: EmployeeCommunication[]) => void;
  employeeList: EmployeeCommunication[];
  selectedEmployeeList: EmployeeCommunication[];
  channel: number;
}

const emailIcon = <IonIcon icon={mail} />;
const textIcon = <IonIcon icon={phonePortrait} />;
const pushIcon = <IonIcon icon={notifications} />;

// --------------------------------------------------------------------------------------
export function isEmployeeEnabled(
  emp: EmployeeCommunication,
  channel: number
): boolean {
  switch (channel) {
    case -1:
      return emp.hasEmail || emp.hasText || emp.hasPush;
    case 1:
      return emp.hasEmail;
    case 2:
      return emp.hasText;
    case 3:
      return emp.hasPush;
    default:
      return false;
  }
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EmployeeSelectorModal: React.FC<EmployeeSelectorModalProps> = ({
  onCancel,
  onOk,
  employeeList,
  selectedEmployeeList,
  channel,
}: EmployeeSelectorModalProps) => {
  const [list, setList] = useState([] as EmployeeCommunication[]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    const newList = employeeList.map((e) => {
      return {
        id: e.id,
        name: e.name,
        hasEmail: e.hasEmail,
        hasText: e.hasText,
        hasPush: e.hasPush,
        isChecked: selectedEmployeeList.some((s) => s.id === e.id),
      };
    });

    setList(newList);
  }, [employeeList, selectedEmployeeList]);

  // --------------------------------------------------------------------------------------
  function onOkClick() {
    onOk(list.filter((e) => e.isChecked));
  }

  // --------------------------------------------------------------------------------------
  function onEmployeeChecked(empId: number) {
    let checkCount = 0;
    const newList = list.map((e) => {
      if (!isEmployeeEnabled(e, channel)) {
        return { ...e, isChecked: false };
      }

      if (e.isChecked) {
        if (e.id === empId) {
          return { ...e, isChecked: false };
        } else {
          checkCount++;
          return { ...e, isChecked: isEmployeeEnabled(e, channel) };
        }
      } else {
        if (e.id === empId) {
          checkCount++;
          return { ...e, isChecked: isEmployeeEnabled(e, channel) };
        } else {
          return { ...e, isChecked: false };
        }
      }
    });

    const allCheckCount = employeeList.filter((e) =>
      isEmployeeEnabled(e, channel)
    ).length;

    setIsAllChecked(checkCount === allCheckCount);
    setList(newList);
  }

  // --------------------------------------------------------------------------------------
  function onAllClick() {
    const newValue = !isAllChecked;

    const newList = list.map((e) => {
      if (!isEmployeeEnabled(e, channel)) {
        return { ...e, isChecked: false };
      }

      return { ...e, isChecked: newValue };
    });

    setList(newList);
    setIsAllChecked(newValue);
  }

  return (
    <>
      <IonContent className="mini-modal">
        <IonGrid>
          <IonRow key={-1}>
            <IonCol size="2">
              <IonCheckbox
                mode="ios"
                checked={isAllChecked}
                onClick={onAllClick}
              />
            </IonCol>
            <IonCol className="label" size="6">
              Select All
            </IonCol>
            <IonCol size="1">{emailIcon}</IonCol>
            <IonCol size="1">{textIcon}</IonCol>
            <IonCol size="1">{pushIcon}</IonCol>
          </IonRow>
          {list.map((emp) => (
            <IonRow key={emp.id}>
              <IonCol size="2">
                <IonCheckbox
                  mode="ios"
                  checked={emp.isChecked}
                  disabled={!isEmployeeEnabled(emp, channel)}
                  onClick={() => {
                    onEmployeeChecked(emp.id);
                  }}
                />
              </IonCol>
              <IonCol className="label" size="6">
                {emp.name}
              </IonCol>
              <IonCol size="1">{emp.hasEmail && emailIcon}</IonCol>
              <IonCol size="1">{emp.hasText && textIcon}</IonCol>
              <IonCol size="1">{emp.hasText && pushIcon}</IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
      <IonFooter className="alert-button-group">
        <IonButton className="modal-alert-button" onClick={onCancel}>Cancel</IonButton>
        <IonButton className="modal-alert-button ok-button" onClick={onOkClick}>OK</IonButton>
      </IonFooter>
    </>
  );
};

export default EmployeeSelectorModal;
