import React, { useEffect, useRef } from "react";
import {
  IonButtons,
  IonButton,
  IonIcon,
  IonHeader,
  IonTitle,
  IonToolbar, IonFooter, IonRow, IonCol, IonGrid,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import "../../global-styles.scss";
import ClockPanel from "./ClockPanel";
import ClockTodayTab from "./ClockTodayTab";
import { useWatchPosition } from '@capacitor-community/geolocation-react';
import MapWrapper from './MapWrapper';
import {authHelper} from "../../util/authHelper";
import { AuthorizedItems } from "../../orbital-interfaces/AuthorizedItems";
import { TimeClockSettingsData } from "../../orbital-interfaces/TimeClockSettingsData";
import { GEOLOCATION_OPTIONS } from "../../appFunctions/geoLocationHelper";

interface TimeClockModalProps {
  onDismissModal: (toastMessage?: string) => void;
  isOpen: boolean;
  auth: AuthorizedItems;
  stationCode?: string;
  timeclockSettings: null | TimeClockSettingsData;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const TimeClockModal: React.FC<TimeClockModalProps> = ({
  onDismissModal,
  isOpen,
  auth,
  stationCode,
  timeclockSettings
}: TimeClockModalProps) => {
  const isMountedRef = useRef(null as boolean | null);
  const { startWatch, clearWatch, currentPosition } = useWatchPosition();

  // ------------------------------------------------------------------------------------
  function onClose(toastMessage?: string) {
    if (authHelper.requiresGeolocation(auth.locations)) {
      clearWatch();
    }

    onDismissModal(toastMessage);
  }

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    isMountedRef.current = true;
    if (!authHelper.requiresGeolocation(auth.locations)) {
      return;
    }

    if (isOpen) {
      startWatch(GEOLOCATION_OPTIONS);
    } else {
      clearWatch();
    }

    return () => {
      clearWatch();
      isMountedRef.current = false;
    }

    // eslint-disable-next-line
  }, [isOpen, auth]);

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onClose()}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>TimeClock: {auth.firstOrNickName} {auth.lastName}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <ClockTodayTab
        isOpen={isOpen}
        auth={auth}
        stationCode={stationCode}
      />
      <IonFooter>
        <IonGrid>
          {authHelper.requiresGeolocation(auth.locations) && (
            <IonRow>
              <IonCol size="12" className="map-button-padding">
                <MapWrapper
                  geoLocation={{
                    lat: currentPosition?.coords.latitude || 0,
                    lng: currentPosition?.coords.longitude || 0,
                    error: null
                  }}
                />
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol size="12" className="ion-no-margin">
              <ClockPanel
                isOpen={isOpen}
                onDismissModal={(toastMessage) => onClose(toastMessage)}
                  geoLocation={{
                    lat: currentPosition?.coords.latitude || 0,
                    lng: currentPosition?.coords.longitude || 0,
                    error: null
                  }}
                auth={auth}
                stationCode={stationCode}
                timeclockSettings={timeclockSettings}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  )
};

export default TimeClockModal;
