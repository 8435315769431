import React, {useContext, useEffect, useState} from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "../../global-styles.scss";
import { arrowBackOutline } from "ionicons/icons";
import ToggleListEx from "../ToggleListEx";
import { NotificationTypeModel } from "../../orbital-interfaces/NotificationTypeModel";
import {IdName} from "../../orbital-interfaces/IdName";
import {getMessageTypeIdNames} from "../../enums/MessageTypes";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import {employeesApi} from "../../api/employeesApi";

export interface MessageFilterSettings {
  msgTypeIds: number[];
  employeeId: number;
}

interface MessagesFilterModalProps {
  onDismissModal: (result: MessageFilterSettings) => void;
  currentFilterSettings: MessageFilterSettings;
}

export const defaultMessageFilterSettings = {
    msgTypeIds: getMessageTypeIdNames().map(mtIdName => { return mtIdName.id}),
    employeeId: -1
} as MessageFilterSettings;

// ---------------------------------------------------------------------------------
function filtersEqual(f1: MessageFilterSettings, f2: MessageFilterSettings) {
  if (f1.employeeId !== f2.employeeId) {
    return false;
  }

  for(let i = 0; i < f1.msgTypeIds.length; i++) {
    if (!f2.msgTypeIds.includes(f1.msgTypeIds[i])) {
      return false;
    }
  }

  for(let i = 0; i < f2.msgTypeIds.length; i++) {
    if (!f1.msgTypeIds.includes(f2.msgTypeIds[i])) {
      return false;
    }
  }

  return true;
}

// ---------------------------------------------------------------------------------
export function isFilterApplied(filter: MessageFilterSettings) {
  return !filtersEqual(filter, defaultMessageFilterSettings);
}

// ---------------------------------------------------------------------------------
//
// ---------------------------------------------------------------------------------
const MessagesFilterModal: React.FC<MessagesFilterModalProps> = ({
  onDismissModal,
  currentFilterSettings,
}: MessagesFilterModalProps) => {
  const { user } = useContext(ApplicationUserContext);
  const [employees, setEmployees] = useState([{ id: -1, name: "EVERYONE"}] as IdName[]);
  const [filterSettings, setFilterSettings] = useState(currentFilterSettings);

  useEffect(() => {
    getEmployeeList();

    // eslint-disable-next-line
  }, []);

  // --------------------------------------------------------------------------------------
  function getEmployeeList() {
    employeesApi.getEmployeesWhoSentMessagesToMe(user).then(results => {
      setEmployees(results);
    });
  }

  // --------------------------------------------------------------------------------------
  const onCloseModal = () => {
    onDismissModal(filterSettings);
  };

  // --------------------------------------------------------------------------------------
  const onCancel = () => {
    onDismissModal(defaultMessageFilterSettings);
  };

  // --------------------------------------------------------------------------------------
  const onResetAll = () => {
    setFilterSettings(defaultMessageFilterSettings);
  };

  // --------------------------------------------------------------------------------------
  const onMessageTypeChange = (newSelection: NotificationTypeModel[]) => {
    setFilterSettings({
      ...filterSettings,
      msgTypeIds: newSelection.map((mt) => mt.id),
    });
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onCancel}>
              <IonIcon icon={arrowBackOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onResetAll}>RESET</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent class="outer-content" >
        <IonItem mode="md">
          <IonLabel>From</IonLabel>
          <IonSelect
            mode="ios"
            interface="action-sheet"
            value={filterSettings.employeeId}
            onIonChange={(e) => {
              setFilterSettings({ ...filterSettings, employeeId: e.detail.value });
            }}
          >
            <IonSelectOption key={-1} value={-1}>
              EVERYONE
            </IonSelectOption>
            {employees.map((emp) => (
                <IonSelectOption key={emp.id} value={emp.id}>
                  {emp.name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        <IonItem mode="md" lines="none">
          <IonLabel>Message Types</IonLabel>
        </IonItem>
        {filterSettings.msgTypeIds && (
          <ToggleListEx
            list={getMessageTypeIdNames().map(mt => { return { id: mt.id, name: mt.name, description: ""}})}
            selected={getMessageTypeIdNames()
              .filter((mt) =>
                filterSettings.msgTypeIds.includes(mt.id)
              )
              .map((mt) => {
                return { id: mt.id, name: mt.name, description: "" };
              })}
            selectionChanged={onMessageTypeChange}
          />
        )}
      </IonContent>

      <IonFooter>
        <IonButton
          className="apply-button"
          expand="block"
          onClick={onCloseModal}
          disabled={
            filtersEqual(filterSettings, currentFilterSettings) ||
            filterSettings.msgTypeIds.length === 0
          }
        >
          APPLY FILTER
        </IonButton>
      </IonFooter>
    </>
  );
};

export default MessagesFilterModal;
