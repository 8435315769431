import React, {useEffect, useState } from "react";
import {Device} from '@capacitor/device';
import { PunchClockDeviceData } from './../orbital-interfaces/PunchClockDeviceData';
import env from '../appFunctions/environment';

interface DeviceIdDisplayProps {
  punchClockDeviceData: PunchClockDeviceData;
}


// ------------------------------------------------------------------------------------
//
// ------------------------------------------------------------------------------------
const DeviceIdDisplay: React.FC<DeviceIdDisplayProps> = ({ punchClockDeviceData}) => {
  const [ deviceId, setDeviceId ] = useState("");

  // -------------------------------------------------------------------------------
  useEffect(() => {
    if (!deviceId || deviceId.length === 0)
    {
      Device.getId().then(deviceId => {
        setDeviceId(deviceId.uuid);
      });
    }
  }, [deviceId, setDeviceId]);


  return (
    <div className="debug-info">
      Device: <b>{punchClockDeviceData.deviceName}</b>
      {" "}
      (Ver:{" "}

        {env.settings.revisionHash === "_REPLACE_COMMIT_HASH_"
            ? "N/A"
            : env.settings.appVersionString}
      )
    </div>
  );
}

export default DeviceIdDisplay;

