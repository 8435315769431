import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonRow,
  IonTitle,
  IonToolbar,
  IonFooter,
} from "@ionic/react";
import { RecurrenceUpdateType } from "../../enums";
import "../../global-styles.scss";

interface RecurrenceUpdateTypeSelectorProps {
  onSelect: (selection: RecurrenceUpdateType | null) => void;
  updateType: "NO" | "UPDATE" | "DELETE";
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const RecurrenceUpdateTypeSelector: React.FC<RecurrenceUpdateTypeSelectorProps> = ({
  onSelect,
  updateType,
}: RecurrenceUpdateTypeSelectorProps) => {
  if (updateType === "NO") {
    return <div />;
  }

  // ------------------------------------------------------------------------------------
  function onButtonClick(choice: RecurrenceUpdateType | null) {
    onSelect(choice);
  }

  const titleText =
    updateType === "UPDATE" ? "Update Repeating" : "Delete Repeating";

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonTitle>{titleText}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonButton
                expand="block"
                color="danger"
                className="apply-button"
                onClick={() =>
                  onButtonClick(RecurrenceUpdateType.UpdateSingleEvent)
                }
              >
                This event
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonButton
                expand="block"
                color="danger"
                className="apply-button"
                onClick={() =>
                  onButtonClick(RecurrenceUpdateType.UpdateEntireSeries)
                }
              >
                Entire series
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonButton
                expand="block"
                color="danger"
                className="apply-button"
                onClick={() =>
                  onButtonClick(RecurrenceUpdateType.UpdateSeriesForward)
                }
              >
                This event forward
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="block"
          className="cancel-button"
          onClick={() => onButtonClick(null)}
        >
          Cancel
        </IonButton>
      </IonFooter>
    </>
  );
};

export default RecurrenceUpdateTypeSelector;
