export enum ClockInResponseCode {
  ClockInOk = 0,
  UserNotAtSpecifiedLocation,
  UserRoleMayNotClockIn,
  UserIsAlreadyClockedIn,
  NoScheduleEventsAndClockInRequiresMatchingShift,
  NoPositionsAndClockInRequiresAnyShift,
  NoScheduleEventsAtPositionsAndClockInRequiresAnyShift,
  NoPositionsAndClockInRequiresNoShift,
  NoScheduleEventForGivenPositionAndClockInRequiresMatchingShift,
  NoPositionsToClockInAndRequiresAnyShift,
  UserAlreadyClockedIntoADifferentEvent,
  NoValidCookieFoundForClockByDevice,
  CannotClockInFromGivenIpAddress,
  BusinessNotEnabledForTimeClock,
  NoGeoCoordinatesPassedButRequiredForMobile,
  CouldNotDetermineGeoLocationRequiredToClockIn,
  UserNotAtRequiredGeoLocations,
  NoValidCookieOrIpAddressFound,
  CouldNotFindRequiredClockLocationForClockIn,
  CouldNotFindClockStationInfo,
  CouldNotFindBusinessClockStationId,
  CouldNotFindPositionForEmployee,
  NoPunchClockLocationsAvailableForUser,
  LocationSettingsForbidClockingInToShiftWithDifferentTag,
  LocationSettingsForbidClockingInWithTagAndNoEvent,
  LocationSettingsForbidSettingTagToNull,
}
