import { PushNotifications } from '@capacitor/push-notifications';
import { pushNotificationsApi } from '../api/pushNotificationsApi';
import { ApplicationUser } from '../context/ApplicationUserContext';

export interface PushNotificationsResult {
  enabled: boolean;
  token?: string;
  message: string;
}

// --------------------------------------------------------------------------------------
const addListeners = async (user :ApplicationUser) => {
  await PushNotifications.addListener('registration', token => {
    console.info('Registration token: ', token.value);

    pushNotificationsApi.subscribe(user, token.value).then(() => {
        console.log("PushNotificationController: token registered with Orbital Shift server");
    });
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
}

// --------------------------------------------------------------------------------------
const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

// --------------------------------------------------------------------------------------
export const registerPushNotifications = async(user: ApplicationUser): Promise<PushNotificationsResult> => {
  try {
    await addListeners(user);
    await registerNotifications();

    return {
      enabled: true,
      message: 'Push notifications enabled'
    };
  } catch (err: any) {
    return {
      enabled: false,
      message: err?.message
    };
  }
}

// import {
//   Plugins,
//   PushNotification,
//   PushNotificationToken,
//   PushNotificationActionPerformed,
// } from "@capacitor/core";
// import { log } from "./logger";
// import env from "../appFunctions/environment";
// import { ApplicationUser } from "../context/ApplicationUserContext";
// import { createBrowserHistory } from "history";
// import {pushNotificationsApi} from "../api/pushNotificationsApi";

// const { PushNotifications } = Plugins;


// CAPUPGRADE
// // --------------------------------------------------------------------------------------
// const register = (
//   user: ApplicationUser,
//   pushNotificationRegistrationUpdated: (result: PushNotificationsResult) => void
// ) => {
//   const history = createBrowserHistory({ forceRefresh: true });

//   if (!env.settings.hasCapacitor) {
//     // TODO: web push instead of capacitor
//     console.log("PushNotificationController: Capacitor not detected, exiting");
//     return;
//   }

//   console.log("PushNotificationController: calling requestPermission...");
//   PushNotifications.requestPermission().then(result => {
//     if (result.granted) {
//       console.log("PushNotificationController: requestPermisson returned 'granted'");
//       PushNotifications.register().then(() => {
//         console.log("PushNotificationController: register called and returned");
//       });
//     } else {
//       console.log("PushNotificationController: requestPermission WAS NOT GRANTED");
//     }
//   });

//   // On succcess, we should be able to receive notifications
//   PushNotifications.addListener(
//     "registration",
//     (token: PushNotificationToken) => {
//       console.log("PushNotificationController: registration, received token: " + token.value);
//       pushNotificationsApi.subscribe(user, token.value).then(() => {
//         console.log("PushNotificationController: token registered with Orbital Shift server");
//       });

//       pushNotificationRegistrationUpdated({
//         enabled: true,
//         token: token.value,
//         message: "Push registration success, token: " + token.value
//       });

//       log("pushNotifications_registered", {
//         token: token.value,
//         platform: env.settings.platform,
//       });
//     }
//   );

//   // Some issue with your setup and push will not work
//   PushNotifications.addListener("registrationError", (error: any) => {
//     console.log("PushNotificationController: registrationError, could not receive token");
//     pushNotificationRegistrationUpdated({
//       enabled: false,
//       token: undefined,
//       message: "Error on registration: " + JSON.stringify(error)
//     })
//     log("pushNotifications_registrationError", {
//       error,
//       platform: env.settings.platform,
//     });
//   });

//   // Show us the notification payload if the app is open on our device
//   PushNotifications.addListener(
//     "pushNotificationReceived",
//     (notification: PushNotification) => {
//       if (notification) {
//         /* Don't open the message for now.
//         if (notification.data && notification.data.MessageId) {
//           console.log(
//             "NOTIFICATION: " +
//               notification.title +
//               ": " +
//               notification.body +
//               " id:" +
//               notification.data.MessageId
//           );
//           history.push("/tabs/messages/" + notification.data.MessageId);
//           history.goForward();
//         } else {
//           console.log(
//             "NOTIFICATION: " +
//               notification.title +
//               ": " +
//               notification.body +
//               " (no id)"
//           );
//         }
//         */

//         log("pushNotifications_receivedWhenOpen", {
//           title: notification.title,
//           body: notification.body,
//           messageId: notification.data.MessageId ?? -123,
//         });
//       }
//     }
//   );

//   // Method called when tapping on a notification
//   PushNotifications.addListener(
//     "pushNotificationActionPerformed",
//     (notification: PushNotificationActionPerformed) => {
//       if (
//         notification.notification.data &&
//         notification.notification.data.MessageId
//       ) {
//         console.log(
//           "TAPPED ON NOTIFICATION: " +
//             notification.notification.title +
//             ": " +
//             notification.notification.body +
//             " id:" +
//             notification.notification.data.MessageId
//         );
//         history.push(
//           "/tabs/messages/" + notification.notification.data.MessageId
//         );
//         history.goForward();
//       } else {
//         console.log(
//           "TAPPED ON NOTIFICATION: " +
//             notification.notification.title +
//             ": " +
//             notification.notification.body +
//             " (no id)"
//         );
//       }

//       log("pushNotifications_actionPerformed", {
//         title: notification.notification.title,
//         body: notification.notification.body,
//         messageId: notification.notification.data.MessageId ?? -123,
//       });
//     }
//   );
// };

// export const pushNotificationsController = {
//   register,
// };
