/* eslint-disable */
import React, { useState, useEffect } from "react";
import db from "../data/dbStore"
// CAPUPGRADE
// import {pushNotificationsController, PushNotificationsResult} from "../util/pushNotificationsController";
import {PushNotificationsResult, registerPushNotifications} from "../util/pushNotificationsController";
import env from "../appFunctions/environment";
import {pushNotificationsApi} from "../api/pushNotificationsApi";
import {appLogApi} from "../api/appLogApi";
import {log} from "../util/logger";
import {LogLevel} from "../enums";

const initialDate = new Date(2000, 1, 1);

export interface ApplicationUser {
  isLoggedin: boolean;
  access_token?: string;
  username: string;
  darkMode: boolean;
  loading: boolean;
  fabLocation: "left" | "right";
  defaultLocationId: number;
  environment: string;
  isPushEnabled: boolean;
  pushToken?: string;
  loggedInDate: Date;
}

export interface IApplicationUserContext {
  user: ApplicationUser;
  logout: () => Promise<void>;
  userLoggedIn: (
    username: string,
    access_token: string,
    environment: string
  ) => Promise<ApplicationUser>;
  pushEnabled: (enabled: boolean, pushToken: string) => void;
  actionButtonPositionChanged: (loc: "left" | "right") => void;
  setDefaultLocation: (d: number) => void;
}

const INITIAL_APPLICATION_USER: ApplicationUser = {
  isLoggedin: false,
  access_token: undefined,
  username: "",
  darkMode: false,
  loading: true,
  fabLocation: "right",
  defaultLocationId: 0,
  environment: "prod",
  isPushEnabled: false,
  pushToken: undefined,
  loggedInDate: initialDate
};

const INITIAL_APPLICATION_USER_CONTEXT = {
  user: INITIAL_APPLICATION_USER,
  logout: async () => {},
  userLoggedIn: (u: string, at: string, e: string) => {
    return new Promise<ApplicationUser>(() => {
      return INITIAL_APPLICATION_USER;
    });
  },
  pushEnabled: (e: boolean, t?: string) => {},
  actionButtonPositionChanged: (loc: "left" | "right") => {},
  setDefaultLocation: (d: number) => {},
};

export const ApplicationUserContext = React.createContext<
  IApplicationUserContext
>(INITIAL_APPLICATION_USER_CONTEXT);

// ----------------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------------
export const ApplicationUserContextProvider = (props: any) => {
  const [userState, internalSetUserState] = useState({
    ...INITIAL_APPLICATION_USER,
  });

  // ----------------------------------------------------------------------------------------------
  useEffect(() => {
    let mounted = true;

    Promise.all([db.getUserState()]).then((response) => {
      if (mounted) {
        const newState = response[0];
        if (newState) {
          internalSetUserState({ ...newState, loading: false });
        } else {
          internalSetUserState({ ...userState, loading: false });
        }
      }
    });
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, []);

  // ----------------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      userState &&
      !userState.isPushEnabled &&
      userState.access_token &&
      userState.access_token.length > 0 &&
      env.settings.platform !== "other"  // "other" is web
    ) {

      const reg = async () => {
        const result = await registerPushNotifications(userState);
        pushNotificationUpdated(result);
      }

      reg();
    }

    // eslint-disable-next-line
  }, [userState, userState.isPushEnabled, userState.access_token]);

  // ----------------------------------------------------------------------------------------------
  function pushNotificationUpdated(result: PushNotificationsResult) {
    if (!result.enabled) {
      console.log("Push notifications not enabled");
      if  (userState.isPushEnabled) {
        internalSetUserState({ ...userState, isPushEnabled: false, pushToken: result.message });
      }
    } else {
      console.log("Push notifications enabled");
      internalSetUserState({ ...userState, isPushEnabled: result.enabled, pushToken: result.token });
    }
  }

  // ----------------------------------------------------------------------------------------------
  const pushEnabled = (enabled: boolean, pushToken?: string) => {
    console.log("pushEnabled called in ApplicationUserContext");
    internalSetUserState({
      ...userState,
      isPushEnabled: enabled,
      pushToken
    });
  };

  // ----------------------------------------------------------------------------------------------
  const actionButtonPositionChanged = async (newLocation: "left" | "right") => {
    if (newLocation === userState.fabLocation) {
      return;
    }

    const newState = {
      ...userState,
      fabLocation: newLocation
    };

    internalSetUserState(newState);
    await db.putUserState(newState);
  }

  // ----------------------------------------------------------------------------------------------
  const setDefaultLocation = async (newDefault: number) => {
    if (newDefault === userState.defaultLocationId) {
      return;
    }

    const newState = {
      ...userState,
      defaultLocationId: newDefault
    };

    internalSetUserState(newState);
    await db.putUserState(newState);
  }

  // ----------------------------------------------------------------------------------------------
  const userLoggedIn = async (
    username: string,
    access_token: string,
    environment: string
  ): Promise<ApplicationUser> => {
    const currentState = await db.getUserState();
    const newState = {
      username,
      access_token,
      environment,
      defaultLocationId: currentState ? currentState.defaultLocationId : INITIAL_APPLICATION_USER.defaultLocationId,
      fabLocation: currentState ? currentState.fabLocation : INITIAL_APPLICATION_USER.fabLocation,
      isLoggedin: true,
      darkMode: currentState
        ? currentState.darkMode
        : INITIAL_APPLICATION_USER.darkMode,
      loading: false,
      isPushEnabled: false,
      loggedInDate: new Date()
    };

    await db.putUserState(newState);
    internalSetUserState(newState);
    return newState;
  };

  // ----------------------------------------------------------------------------------------------
  const logout = async () => {
    try {
      if (userState.isLoggedin) {
        pushNotificationsApi.unsubscribe(userState, /* userState.pushToken */ "none").then((res) => {
          if (res.error) {
            log("unsubscribe_failed");
            appLogApi.writeLog(userState, LogLevel.Error, "push notification unsubscribe on logout failed");
          } else {
            log("unsubscribe_succeeded");
            appLogApi.writeLog(userState, LogLevel.Information, "push notification unsubscribe on logout succeeded");
          }
        });

      } else {
        await appLogApi.writeLog(userState, LogLevel.Information,
          "no unsubscribe called on logout, loggedin=" + userState.isLoggedin +
          ", pushEnabled=" + userState.isPushEnabled);
      }
    } catch(e) {
      appLogApi.writeLog(userState, LogLevel.Error, "exception on logout unsubscribe: " + e);
    }

    const newState = {
      ...userState,
      isLoggedin: false,
      access_token: undefined,
      latitude: undefined,
      longitude: undefined,
      isPushEnabled: false,
      requestedGeoPermission: false,
      refusedGeoPermission: false,
      fabLocation: INITIAL_APPLICATION_USER.fabLocation,
      defaultLocationId: INITIAL_APPLICATION_USER.defaultLocationId,
      loggedInDate: initialDate
    };
    internalSetUserState(newState);
    await db.putUserState(newState);
  };

  return (
    <ApplicationUserContext.Provider
      value={{
        user: userState,
        userLoggedIn,
        logout,
        pushEnabled,
        actionButtonPositionChanged,
        setDefaultLocation,
      }}
    >
      {props.children}
    </ApplicationUserContext.Provider>
  );
};
