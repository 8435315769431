import {IdName} from "../orbital-interfaces/IdName";

export enum EventType {
  Shift = 1,
  Unavailable = 2,
  Vacation = 3,
  Cover = 4,
  Clock = 6,
  Absent = 7,
}

export const eventTypeIdList = [
  { id: 1, name: "Shift"},
  { id: 2, name: "Unavailable"},
  { id: 3, name: "Vacation"},
  { id: 4, name: "Cover"},
  { id: 6, name: "Clock"},
  { id: 7, name: "Absent"}
] as IdName[];

export function getEventTypeIdName(eventType: EventType): IdName {
  const idName =  eventTypeIdList.find(et => et.id === eventType);
  if (idName === undefined) {
    throw new Error("getEventTypeIdList: Unknown event type: " + eventType);
  }

  return idName;
}

