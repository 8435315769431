import React, { useEffect, useReducer, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonFooter,
  IonHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonToolbar,
  IonModal,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import "../../global-styles.scss";
import { AuthorizedItems } from "../../orbital-interfaces/AuthorizedItems";
import EventTypeStatusEditor from "./editor/EventTypeStatusEditor";
import EventPositionEditor from "./editor/EventPositionEditor";
import EventEmployeeEditor from "./editor/EventEmployeeEditor";
import EventStartEndEditor from "./editor/EventStartEndEditor";
import EventBreakEditor from "./editor/EventBreakEditor";
import EventTagEditor from "./editor/EventTagEditor";
import EventConfirmEditor from "./editor/EventConfirmEditor";
import EventIsClockedInEditor from "./editor/EventIsClockedInEditor";
import { editorValueReducer, EditorValues } from "./EditEventModalState";
import { scheduleEventsApi } from "../../api/scheduleEventsApi";
import { requestsApi } from "../../api/requestsApi";
import { ApplicationUserContext } from "../../context/ApplicationUserContext";
import useDialogBox from "../../hooks/useDialogBox";
import { ScheduleEvent } from "../../orbital-interfaces/ScheduleEvent";
import EventNotesEditor from "./editor/EventNotesEditor";
import EventClockoutValueEditor from "./editor/EventClockoutValueEditor";
import { createBrowserHistory } from "history";
import RecurringEventSectionEx from "./editor/RecurringEventSectionEx";
import { RecurrenceUpdateType } from "../../enums";
import RecurrenceUpdateTypeSelector from "./RecurrenceUpdateTypeSelector";
import { useShowLoading } from "../../hooks/useShowLoading";
import { log } from "../../util/logger";
import { timeHelper as th } from "../../util/timeHelper";
import EventRequestEditorEx from "./editor/EventRequestEditorEx";
import EventTransferToEditor from "./editor/EventTransferToEditor";
import AbsentTimeEditor from "./editor/AbsentTimeEditor";
import useYesOrCancelDialogBox from "../../hooks/useYesOrCancelDialogBox";
import {
  dummyLocationSettings,
  LocationSettings,
} from "../../orbital-interfaces/LocationSettings";
import { authHelper } from "../../util/authHelper";
import LegacyBreakEditor from "./editor/LegacyBreakEditor";

interface ScheduleFilterModalProps {
  scheduleEventId: number | null;
  auth: AuthorizedItems;
  onDismissModal: (toastMessage?: string) => void;
  onCancelModal: () => void;
  isOpen: boolean;
  initialEvent: EditorValues;
}

// --------------------------------------------------------------------------------------
function fixDatesForIPhone(se: ScheduleEvent): void {
  se.EventStart = th.convertTimeWithoutTimeZoneToLocalTime(se.EventStart);
  se.EventEnd = th.convertTimeWithoutTimeZoneToLocalTime(se.EventEnd);
  if (se.BreakStartTime !== null) {
    se.BreakStartTime = th.convertTimeWithoutTimeZoneToLocalTime(
      se.BreakStartTime
    );
  }

  if (se.EventBreaks) {
    for (let i = 0; i < se.EventBreaks.length; i++) {
      se.EventBreaks[i].BreakStart = th.convertTimeWithoutTimeZoneToLocalTime(
        se.EventBreaks[i].BreakStart
      );

      if (se.EventBreaks[i].BreakEnd) {
        th.convertTimeWithoutTimeZoneToLocalTime(se.EventBreaks[i].BreakEnd);
      }
    }
  }
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EditEventModal: React.FC<ScheduleFilterModalProps> = ({
  auth,
  scheduleEventId,
  onDismissModal,
  onCancelModal,
  isOpen,
  initialEvent,
}) => {
  const loading = useShowLoading("Please wait...");
  const dlg = useDialogBox();
  const yesCancelDlg = useYesOrCancelDialogBox();
  const { user } = React.useContext(ApplicationUserContext);
  const [employeeEditorShowLoading, setEmployeeEditorShowLoading] = useState(
    false
  );
  const [getEventUpdateShowLoading, setGetEventUpdateShowLoading] = useState(
    false
  );
  const [editorValues, updateEditorValues] = useReducer(
    editorValueReducer,
    initialEvent
  );
  const [locSettings, setLocSettings] = useState({
    ...dummyLocationSettings,
  } as LocationSettings);

  const [scheduleEvent, setScheduleEvent] = useState(
    null as ScheduleEvent | null
  );

  const [recurrenceUpdateType, setRecurrenceUpdateType] = useState(
    null as RecurrenceUpdateType | null
  );

  const [
    showRecurrenceUpdateTypeSelector,
    setShowRecurrenceUpdateTypeSelector,
  ] = useState("NO" as "NO" | "UPDATE" | "DELETE");

  const [buttonClicked, setButtonClicked] = useState(
    "NONE" as "NONE" | "CREATEUPDATE" | "DELETE"
  );

  const isEditing = scheduleEventId !== null;
  let title = "New Event";
  let buttonText = "CREATE";

  if (isEditing) {
    title = "Edit Event";
    buttonText = "UPDATE";
  }

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!auth || !auth.locations || auth.locations.length === 0) {
      return;
    }

    if (scheduleEventId) {
      onGetEventUpdateShowLoading(true);
      log("edit_event_get_scheduleevent", {
        user: user.username,
        scheduleEventId: scheduleEventId,
      });
      scheduleEventsApi
        .getScheduleEvent(user, scheduleEventId)
        .then((scheduleEvent) => {
          if (!scheduleEvent) {
            dlg.showAlert(
              "No Event",
              "The event may have been removed or you no longer have access",
              onCancel
            );
            return;
          }

          fixDatesForIPhone(scheduleEvent);

          setScheduleEvent(scheduleEvent);
          setLocSettings(
            authHelper.getLocSettingsForLocationId(
              auth,
              scheduleEvent.LocationId
            )
          );
          updateEditorValues({
            type: "BEGIN-UPDATE",
            ev: scheduleEvent,
            auth,
          });
          onGetEventUpdateShowLoading(false);
        });
    } else {
      if (scheduleEvent !== null) {
        setScheduleEvent(null);
      }

      setLocSettings(
        authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId)
      );
      updateEditorValues({ type: "CREATE-EVENT", auth, user });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleEventId, user, isOpen, auth]);

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (recurrenceUpdateType !== null && buttonClicked !== "NONE") {
      if (buttonClicked === "CREATEUPDATE") {
        createOrUpdate();
      } else {
        deleteEvent(
          editorValues.recurrenceId !== null &&
            editorValues.recurrenceId !== undefined
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrenceUpdateType, buttonClicked]);

  // --------------------------------------------------------------------------------------
  function createOrUpdate() {
    const apiCall =
      editorValues.scheduleEventId === 0
        ? scheduleEventsApi.createScheduleEvent
        : scheduleEventsApi.updateScheduleEvent;

    const logEvent =
      editorValues.scheduleEventId === 0
        ? "edit_event_create"
        : "edit_event_update";
    const successMessage =
      editorValues.scheduleEventId === 0 ? "Event Created" : "Event Updated";

    apiCall(user, editorValues, recurrenceUpdateType).then((result) => {
      if (result.error) {
        dlg.showAlert("Cannot update", result.message, () => {});
      } else {
        log(logEvent, {
          user: user.username,
          scheduleEventId: scheduleEventId,
        });

        if (
          editorValues.transferToEmployeeId !== null &&
          editorValues.scheduleEventId > 0
        ) {
          requestsApi
            .transferCoverToEmployee(
              user,
              editorValues.transferToEmployeeId,
              editorValues.scheduleEventId
            )
            .then((res) => {
              if (res.error) {
                dlg.showAlert("Cannot transfer", result.message, () => {});
              } else {
                onDismissModal("Transferred");
              }
            });
        } else {
          onDismissModal(successMessage);
        }
      }
    });
  }

  // --------------------------------------------------------------------------------------
  function onConfirmButtonClicked() {
    // Since the update has not updated editor values, we're essentially toggling
    // the confirmed setting.  Update with what it is currently *not* set to.
    const confirmed = !editorValues.isConfirmed;
    scheduleEventsApi
      .updateConfirmedScheduleEvent(
        user,
        editorValues.scheduleEventId,
        confirmed
      )
      .then((res) => {
        if (res.error) {
          dlg.showAlert(
            "Could not " + confirmed ? "confirm" : "unconfirm",
            res.message,
            () => {}
          );
        } else {
          onDismissModal(confirmed ? "Confirmed" : "Unconfirmed");
        }
      });
  }

  // --------------------------------------------------------------------------------------
  function deleteEvent(skipPrompt: boolean) {
    const goDelete = () => {
      scheduleEventsApi
        .deleteScheduleEvent(
          user,
          editorValues.scheduleEventId,
          recurrenceUpdateType
        )
        .then((result) => {
          if (result.error) {
            dlg.showAlert("Cannot delete", result.message, () => {
              onDismissModal("Could not delete event");
            });
          } else {
            log("edit_event_delete", {
              user: user.username,
              scheduleEventId: scheduleEventId,
            });
            onDismissModal("Deleted Event");
          }
        });
    };

    if (skipPrompt) {
      goDelete();
    } else {
      yesCancelDlg.showDialog(
        "Delete event?",
        () => {
          goDelete();
        },
        () => {}
      );
    }
  }

  // --------------------------------------------------------------------------------------
  function onCreateOrUpdateClick() {
    setButtonClicked("CREATEUPDATE");
    if (editorValues.recurrenceId) {
      if (recurrenceUpdateType !== null) {
        createOrUpdate();
      } else {
        setShowRecurrenceUpdateTypeSelector("UPDATE");
      }
    } else {
      createOrUpdate();
    }
  }

  // --------------------------------------------------------------------------------------
  function onRecurrenceUpdateTypeSelect(
    selection: RecurrenceUpdateType | null
  ) {
    setShowRecurrenceUpdateTypeSelector("NO");
    setRecurrenceUpdateType(selection);
    if (selection === null) {
      setButtonClicked("NONE");
    }
  }

  // --------------------------------------------------------------------------------------
  function onDeleteClick() {
    setButtonClicked("DELETE");
    if (editorValues.recurrenceId) {
      if (recurrenceUpdateType !== null) {
        deleteEvent(true);
      } else {
        setShowRecurrenceUpdateTypeSelector("DELETE");
      }
    } else {
      deleteEvent(
        editorValues.recurrenceId !== null &&
          editorValues.recurrenceId !== undefined
      );
    }
  }

  // --------------------------------------------------------------------------------------
  function onGetEventUpdateShowLoading(newVal: boolean): void {
    setGetEventUpdateShowLoading(newVal);

    if (loading.showLoading && (newVal || employeeEditorShowLoading)) {
      return;
    }

    if (!newVal && !employeeEditorShowLoading) {
      loading.setShowLoading(false);
      return;
    }

    if (!loading.showLoading && (newVal || employeeEditorShowLoading)) {
      loading.setShowLoading(true);
      return;
    }
  }

  // --------------------------------------------------------------------------------------
  function onEventEditorUpdateShowLoading(newVal: boolean): void {
    if (!isOpen) {
      return;
    }

    setEmployeeEditorShowLoading(newVal);

    if (loading.showLoading && (newVal || getEventUpdateShowLoading)) {
      return;
    }

    if (!newVal && !getEventUpdateShowLoading) {
      loading.setShowLoading(false);
      return;
    }

    if (!loading.showLoading && (newVal || getEventUpdateShowLoading)) {
      loading.setShowLoading(true);
      return;
    }
  }

  // --------------------------------------------------------------------------------------
  function onCancel() {
    updateEditorValues({ type: "CANCEL" });
    const history = createBrowserHistory();
    history.push("/tabs/schedule");
    onCancelModal();
  }

  if (!isOpen || !auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
        {dlg.alertBoxJsx}
      </>
    );
  } else {
    return (
      <>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <IonIcon icon={arrowBackOutline} mode="md" slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent class="outer-content">
          <IonGrid>
            <EventTypeStatusEditor
              editorValues={editorValues}
              update={updateEditorValues}
              auth={auth}
            />

            <AbsentTimeEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <IonRow>
              <EventConfirmEditor
                auth={auth}
                editorValues={editorValues}
                update={updateEditorValues}
                onEmployeeUpdated={onConfirmButtonClicked}
              />
              <EventIsClockedInEditor
                auth={auth}
                editorValues={editorValues}
                update={updateEditorValues}
              />
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <div
                  style={{ width: "100%", borderBottom: "1px solid #ddd" }}
                />
              </IonCol>
            </IonRow>

            <EventPositionEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <EventEmployeeEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
              updateShowLoading={onEventEditorUpdateShowLoading}
            />

            <EventStartEndEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <RecurringEventSectionEx
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <IonRow>
              <IonCol size="12">
                <div
                  style={{ width: "100%", borderBottom: "1px solid #ddd" }}
                />
              </IonCol>
            </IonRow>

            {locSettings.LocationId > 0 &&
              locSettings.EnableEnhancedBreakManagement && (
                <EventBreakEditor
                  auth={auth}
                  editorValues={editorValues}
                  update={updateEditorValues}
                />
              )}

            {locSettings.LocationId > 0 &&
              !locSettings.EnableEnhancedBreakManagement && (
                <LegacyBreakEditor
                  auth={auth}
                  editorValues={editorValues}
                  update={updateEditorValues}
                />
              )}

            <EventTagEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <EventTransferToEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <EventClockoutValueEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />

            <IonRow>
              <IonCol size="12">
                <div
                  style={{ width: "100%", borderBottom: "1px solid #ddd" }}
                />
              </IonCol>
            </IonRow>

            <EventNotesEditor
              auth={auth}
              editorValues={editorValues}
              update={updateEditorValues}
            />
          </IonGrid>

          {dlg.alertBoxJsx}
          {yesCancelDlg.dialogJsx}
          {loading.showLoadingJsx}

          <IonModal
            mode="md"
            isOpen={showRecurrenceUpdateTypeSelector !== "NO"}
            onDidDismiss={() => {
              onRecurrenceUpdateTypeSelect(null);
            }}
            cssClass="mini-modal recurrence-update-modal"
          >
            <RecurrenceUpdateTypeSelector
              onSelect={onRecurrenceUpdateTypeSelect}
              updateType={showRecurrenceUpdateTypeSelector}
            />
          </IonModal>
        </IonContent>

        <IonFooter>
          {editorValues.isReadonly && (
            <EventRequestEditorEx
              auth={auth}
              update={updateEditorValues}
              cancelEditModal={(message) => onDismissModal(message)}
              editorValues={editorValues}
              messageType={null}
            />
          )}
          <IonButton
            className="apply-button"
            expand="block"
            onClick={onCreateOrUpdateClick}
            disabled={!editorValues.valid}
            hidden={editorValues.isReadonly}
          >
            {buttonText}
          </IonButton>
          <IonButton
            className="delete-button"
            expand="block"
            onClick={onDeleteClick}
            hidden={
              editorValues.isReadonly || editorValues.scheduleEventId <= 0
            }
          >
            DELETE
          </IonButton>
        </IonFooter>
      </>
    );
  }
};

export default EditEventModal;
