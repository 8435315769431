import { IonSelect, IonSelectOption } from "@ionic/react";
import React, {useEffect, useState} from "react";
import {IdName} from "../../orbital-interfaces/IdName";

interface OptionSelectorProps {
  options: IdName[];
  initialOption: number | undefined;
  initialDisplay?: string;
  onOptionUpdated: (id: number) => void;
  isDisabled: boolean;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const OptionSelector: React.FC<OptionSelectorProps> = ({
  options,
  initialOption,
  onOptionUpdated,
  isDisabled,
  initialDisplay
}: OptionSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState(initialOption);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (initialOption === undefined && options.length > 0) {
      setSelectedOption(options[0].id);
    } else if (initialOption !== selectedOption) {
      setSelectedOption(initialOption);
    }

    // eslint-disable-next-line
  }, [initialOption, options.length])

  // ------------------------------------------------------------------------------------
  const onSelectionChanged = (newOptId: number | null | undefined) => {
    if (newOptId === null || newOptId === undefined || newOptId === selectedOption) {
      return;
    }

    setSelectedOption(newOptId);
    onOptionUpdated(newOptId);
  };

  if (options.length === 0) {
    return (
      <IonSelect
        mode="ios"
        className="select"
        interface="action-sheet"
        value={selectedOption}
        disabled={true}
      >
        {initialDisplay !== undefined && (
          <IonSelectOption key={selectedOption} value={selectedOption}>
            {initialDisplay}
          </IonSelectOption>
        )}
      </IonSelect>
    );
  }

  return (
    <IonSelect
      mode="ios"
      className="select"
      interface="action-sheet"
      disabled={isDisabled}
      value={selectedOption}
      onIonChange={(e) => {onSelectionChanged(e.detail.value)}}
    >
      {options.map((opt) => (
        <IonSelectOption key={opt.id} value={opt.id}>
          {opt.name}
        </IonSelectOption>
      ))}
    </IonSelect>

  );
};

export default OptionSelector;
