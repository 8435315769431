import React, {useState, useEffect} from "react";
import { IonLabel, IonRow, IonCol } from "@ionic/react";
import { AuthorizedItems, AuthorizedLocation } from "../../../orbital-interfaces/AuthorizedItems";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";
import {authHelper} from "../../../util/authHelper";
import { IdName } from "../../../orbital-interfaces/IdName";
import OptionSelector from "../../EventsPageComponents/OptionSelector";

interface EventPositionEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
function createPositionList(loc: AuthorizedLocation[]): IdName[]  {
  const isMultiLoc = loc.length > 1;
  return loc.flatMap((l) =>
    l.departments.flatMap((d) =>
      d.positions.map((p) => {
        const isMultiDep = l.departments.length > 1;
        let name = "";
        if (isMultiLoc) {
          name += (l.abbr ? l.abbr : l.name) + "/";
        }

        if (isMultiDep) {
          name += (d.abbr ? d.abbr : d.name) + "/";
        }

        name += p.name;
        return { id: p.id, name: name };
      })
    )
  ).sort((a,b) => (a.name > b.name)
    ? 1
    : (b.name > a.name)
      ? -1 : 0);
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventPositionEditor: React.FC<EventPositionEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventPositionEditorProps) => {
  const [positionList, setPositionList] = useState([] as IdName[]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    if (auth && auth.locations && auth.locations.length > 0) {
      setPositionList(createPositionList(auth.locations));
    }

    // eslint-disable-next-line
  }, [auth.locations.length, editorValues.scheduleEventId,
            editorValues.position.position.id, editorValues.employee.id]);

  // ------------------------------------------------------------------------------------
  function updatePosition(posId: number) {
    if (posId === editorValues.position.position.id) {
      return;
    }

    const selected = authHelper.getSelectedPosition(auth.locations, posId);
    update({ type: "POSITION-UPDATED", selectedPosition: selected });
  }

  return (
    <IonRow>
      <IonCol size="4">
        <IonLabel className="label">Position</IonLabel>
      </IonCol>
      <IonCol size="8">
        <OptionSelector
          options={positionList}
          initialOption={editorValues.position.position.id}
          onOptionUpdated={(e) => {updatePosition(e)}}
          isDisabled={
            editorValues.isReadonly ||
            editorValues.isConfirmed ||
            editorValues.transferToEmployeeId !== null
          }
        />
      </IonCol>
    </IonRow>
  );
};

export default EventPositionEditor;
