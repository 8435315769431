import {ApplicationUser} from "../context/ApplicationUserContext";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";

// --------------------------------------------------------------------------------------
export function appReady(user: ApplicationUser, auth: AuthorizedItems) {
    return user &&
        !user.loading &&
        user.isLoggedin &&
        auth !== null &&
        auth !== undefined &&
        !auth.loading &&
        auth!.locations !== undefined &&
        auth!.locations !== null &&
        auth!.locations.length > 0;
}
