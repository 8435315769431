import React, {forwardRef, Ref, useImperativeHandle} from "react";
import EventCard from "./EventCard";
import DailyNoteCard from "./DailyNoteCard";
import { AuthorizedLocation } from "../../orbital-interfaces/AuthorizedItems";
import "../../global-styles.scss";
import { EventInformation } from "../../orbital-interfaces/TEC";
import { DailyNotes } from "../../orbital-interfaces/DailyNotes";
// import AddDailyNoteButton from "./AddDailyNoteButton";
import NoEventsToShow from "./NoEventsToShow";

interface EventListSectionProps {
  locations: AuthorizedLocation[];
  events: EventInformation[];
  notes: DailyNotes[];
  header: string;
  showEmployeeNames: boolean;

  showTimeColumn: boolean;
  onEdit: (ev: EventInformation) => void;
  dailyNotesAddButtonDate: Date | null;
  isTimeClockPage: boolean;
  onForceRefresh: (toastMessage: string) => void;
}

export interface EventListSectionRef {
  scrollIntoView: () => void;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
// eslint-disable-next-line react/display-name
const EventListSection = forwardRef((props: EventListSectionProps, ref: Ref<EventListSectionRef>) => {
  const {
    locations,
    header,
    events,
    notes,
    showEmployeeNames,
    showTimeColumn,
    onEdit,
    isTimeClockPage,
    // dailyNotesAddButtonDate,
    onForceRefresh,
  } = props;

  const headerRef = React.createRef<HTMLDivElement>();

  const multiLocation =
    locations.filter((l) =>
      l.departments.filter((d) => d.positions.filter((p) => p.hasPosition))
    ).length > 1;

  // --------------------------------------------------------------------------
  useImperativeHandle(ref, () => ({

    scrollIntoView() {
      if (headerRef.current !== null) {
        headerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        });

        setTimeout(() => {
          if (headerRef.current !== null) {
            headerRef.current!.focus();
          }
        }, 1000);
      }
    }
  }));

  return (
    <>
      <div tabIndex={0} ref={headerRef}  className="event-list-header">
        {header}

      </div>
      {notes.length === 0 && events.length === 0 && (
        <NoEventsToShow message="No Events" />
      )}
      <div>
        {notes.map((note) => (
          <DailyNoteCard
            onForceRefresh={onForceRefresh}
            key={note.DailyNoteId}
            note={note}
            location={locations.filter((l) => l.id === note.LocationId)[0]}
          />
        ))}
      </div>
      <div>
        {events.map((ev) => (
          <EventCard
            onEdit={onEdit}
            key={ev.id}
            event={ev}
            location={locations.filter((l) => l.id === ev.locId)[0]}
            multiLocation={multiLocation}
            showEmployeeName={showEmployeeNames}
            showTimeColumn={showTimeColumn}
            isTimeClockPage={isTimeClockPage}
          />
        ))}
      </div>
    </>
  );
});

export default EventListSection;
