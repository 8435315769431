import {EventGroup, EventType, EventStatus, } from "../enums";
import {ScheduleEvent} from "../orbital-interfaces/ScheduleEvent";
import {isAfter} from "date-fns";
import {EventInformation} from "../orbital-interfaces/TEC";
import {LocationSettings} from "../orbital-interfaces/LocationSettings";
import {timeHelper as th} from "./timeHelper";

const onClockEventEnd = new Date(9000, 1, 1);

// ---------------------------------------------------------------------------------
function isClockedIn(ev: ScheduleEvent) {
  return (
    ev.EventTypeId === EventType.Clock &&
    ev.EventStatusId === EventStatus.Approved &&
    isAfter(ev.EventEnd, onClockEventEnd)
  );
}


/*
 * Code below in this file was based on ScheduleEventHelper.cs in OrbitalShift.BLL.dll
 */

// --------------------------------------------------------------------------------------
// Returns true if the event type is a type that is associated to a position (e.g.,
// not an unavailable)
function isPositionSpecificEvent(eventType: EventType) {
  return (
    eventType === EventType.Shift ||
    eventType === EventType.Cover ||
    eventType === EventType.Clock ||
    eventType === EventType.Absent
  );
}

// ---------------------------------------------------------------------------------
function isShiftTypeEvent(
  eventType: EventType,
  eventStatus: EventStatus
): boolean {
  return (
    (eventType === EventType.Shift && eventStatus === EventStatus.Approved) ||
    (eventType === EventType.Cover && eventStatus === EventStatus.Request) ||
    (eventType === EventType.Cover && eventStatus === EventStatus.RequestDenied)
  );
}

// ---------------------------------------------------------------------------------
function isAbsentExpenseEvent(
  eventType: EventType,
  eventStatus: EventStatus
): boolean {
  return eventType === EventType.Absent && eventStatus === EventStatus.Approved;
}

// ---------------------------------------------------------------------------------
function isTimeClockEvent(
  eventType: EventType,
  eventStatus: EventStatus
): boolean {
  return (
    isShiftTypeEvent(eventType, eventStatus) ||
    (eventType === EventType.Clock && eventStatus === EventStatus.Approved)
  );
}

// ---------------------------------------------------------------------------------
function isDontWorkEvent(eventType: EventType, eventStatus: EventStatus): boolean {
  return (
    (eventType === EventType.Unavailable && eventStatus === EventStatus.Approved) ||
    (eventType === EventType.Vacation && eventStatus === EventStatus.Approved) ||
    (eventType === EventType.Absent && eventStatus === EventStatus.Approved) ||
    (eventType === EventType.Cover && eventStatus === EventStatus.Approved)
  );
}

// ---------------------------------------------------------------------------------
function eventMatchesFilter(
  eventTypeFilter: EventType | EventGroup,
  eventStatusFilter: EventStatus | EventGroup,
  eventType: EventType,
  eventStatus: EventStatus
): boolean {
  if (eventTypeFilter === EventGroup.DontWork && eventStatusFilter === EventGroup.DontWork) {
    return isDontWorkEvent(eventType, eventStatus);
  }


  if (eventTypeFilter > 0 && eventStatusFilter > 0) {
    if (eventTypeFilter === eventType && eventStatusFilter === eventStatus) {
      return true;
    }
  } else if (eventTypeFilter > 0 && eventStatusFilter === EventGroup.All) {
    return eventTypeFilter === eventType;
  } else if (eventTypeFilter === EventGroup.All && eventStatusFilter > 0) {
    return eventStatusFilter === eventStatus;
  } else if (
    eventTypeFilter === EventGroup.Scheduled &&
    eventStatusFilter === EventGroup.Scheduled
  ) {
    return (
      isShiftTypeEvent(eventType, eventStatus) ||
      isAbsentExpenseEvent(eventType, eventStatus)
    );
  } else if (
    eventTypeFilter === EventGroup.ClockAndSched &&
    eventStatusFilter === EventGroup.ClockAndSched
  ) {
    return (
      isTimeClockEvent(eventType, eventStatus) ||
      isAbsentExpenseEvent(eventType, eventStatus)
    );
  } else {
    return true;
  }

  return false;
}

// ---------------------------------------------------------------------------------
function showFlagForExtendedClock(ev: EventInformation, locSettings: LocationSettings | null) {
  if (locSettings === null) {
    return false;
  }

  if (ev.eventTypeId !== EventType.Clock || ev.isClockedIn) {
    return false;
  }

  return isExtendedClock(ev, locSettings);
}

// ---------------------------------------------------------------------------------
function isExtendedClock(ev: EventInformation, locSettings: LocationSettings | null) {
  if (locSettings === null) {
    return false;
  }

  const clockTime = th.getDurationFromTimeSpan(ev.data?.TotalValue.Time.ActualTime);
  const forgotToClockOutTime = th.getDurationFromTimeSpan(locSettings.ForgotToClockOutTime)
  return clockTime > forgotToClockOutTime;
}

export const scheduleEventHelper = {
  eventMatchesFilter,
  isAbsentExpenseEvent,
  isClockedIn,
  isDontWorkEvent,
  isExtendedClock,
  isPositionSpecificEvent,
  isShiftTypeEvent,
  isTimeClockEvent,
  showFlagForExtendedClock,
}
