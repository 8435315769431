import React, { useRef, useEffect, useState } from "react";
import "../../global-styles.scss";
import { ApplicationUserContext } from "../../context/ApplicationUserContext";
import {timeClockApi} from "../../api/timeClockApi";
import { ClockInGeoLocations } from "../../orbital-interfaces/ClockInGeoLocations";
import { IonButton, IonIcon } from "@ionic/react";
import { arrowUpCircle, arrowDownCircle } from "ionicons/icons";
import {GeoLocationResults} from "../../appFunctions/geoLocationHelper";

// -----------------------------------------------------------------------------------------------
function calculateNearestLocationMessage(
  lat: number | undefined,
  lng: number | undefined,
  locations: ClockInGeoLocations[]
): string {
  if (!lat || !lng || locations.length === 0) {
    return "";
  }

  if (
    !google ||
    !google.maps ||
    !google.maps.geometry ||
    !google.maps.geometry.spherical ||
    !google.maps.geometry.spherical.computeDistanceBetween
  ) {
    return "";
  }

  const userPos = new google.maps.LatLng(lat, lng);
  const MAX_DISTANCE = 999999999;
  let closestLocation = null as ClockInGeoLocations | null;
  let minDistance = MAX_DISTANCE;
  for (let i = 0; i < locations.length; i++) {
    const curLoc = locations[i];
    if (!curLoc.lat || !curLoc.lng) {
      continue;
    }
    const locPos = new google.maps.LatLng(curLoc.lat, curLoc.lng);
    const distance =
      google.maps.geometry.spherical.computeDistanceBetween(userPos, locPos) *
        3.28084 -
      curLoc.proximity;
    if (distance < minDistance) {
      minDistance = distance;
      closestLocation = curLoc;
    }
  }

  if (minDistance === MAX_DISTANCE) {
    return "";
  }

  if (minDistance < 0) {
    return "At " + closestLocation!.locationName;
  }

  const miles = minDistance * 0.000189394;
  if (miles > 0.1) {
    return `${Math.round((miles + Number.EPSILON) * 10) / 10} mi to ${
      closestLocation!.locationName
    }`;
  }

  return `${Math.round(minDistance)} ft to ${closestLocation!.locationName}`;
}

// -----------------------------------------------------------------------------------------------
function validPos(geoLocation: GeoLocationResults | null) {
  if (geoLocation === null) {
    return false;
  }

  return !(geoLocation.lat === undefined || geoLocation.lng === undefined);
}

interface MapComponentProps {
  geoLocation: GeoLocationResults;
}

// -----------------------------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------------------------
const Map: React.FC<MapComponentProps> = ({geoLocation}: MapComponentProps) => {
  const mapEle = useRef<HTMLDivElement | null>(null);
  const map = useRef<google.maps.Map>();
  const userCircle = useRef<google.maps.Circle>();
  const { user } = React.useContext(ApplicationUserContext);
  const [message, setMessage] = useState("");
  const [geoLocations, setGeoLocations] = useState([] as ClockInGeoLocations[]);
  const [showMap, setShowMap] = useState(false);

  // -----------------------------------------------------------------------------------------------
  function onShowMapButtonClick() {
    const newShowMap = !showMap;

    if (geoLocation !== null && geoLocation.lat !== undefined && geoLocation.lng !== undefined) {
      setShowMap(newShowMap);
      setMessage(
        calculateNearestLocationMessage(
          geoLocation.lat,
          geoLocation.lng,
          geoLocations
        )
      );
    }
  }

  // -----------------------------------------------------------------------------------------------
  useEffect(() => {
    if (mapEle.current === null || !validPos(geoLocation)) {
      return;
    }

    if (map.current !== undefined) {
      if (map.current && validPos(geoLocation)) {
        const userPos = new google.maps.LatLng(geoLocation.lat!, geoLocation.lng!);
        map.current.setCenter(userPos);
        if (userCircle.current) {
          userCircle.current.setMap(null);
        }

        userCircle.current = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.25,
          radius: 6.5,
          center: userPos,
          map: map.current,
        });
      }

      setMessage(
        calculateNearestLocationMessage(
          geoLocation.lat,
          geoLocation.lng,
          geoLocations
        )
      );

      return;
    }

    map.current = new google.maps.Map(mapEle.current, {
      center: {
        lat: geoLocation.lat!,
        lng: geoLocation.lng!,
      },
      zoom: 16,
      disableDefaultUI: true,
      fullscreenControl: false,
    });

    addMarkers();

    google.maps.event.addListenerOnce(map.current, "idle", () => {
      if (mapEle.current) {
        mapEle.current.classList.add("show-map");
      }
    });

    function addMarkers() {
      if (validPos(geoLocation)) {
        userCircle.current = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.25,
          radius: 6.5,
          center: { lat: geoLocation.lat!, lng: geoLocation.lng! },
          map: map.current,
        });
      }

      timeClockApi.getGeoLocations(user).then((clockLocations) => {
        setGeoLocations(clockLocations);
        clockLocations.forEach((markerData) => {
          const infoWindow = new google.maps.InfoWindow({
            content: `<h5>${markerData.addr}</h5>`,
          });

          if (markerData.lat && markerData.lng) {
            const position = new google.maps.LatLng(
              markerData!.lat,
              markerData!.lng
            );

            const marker = new google.maps.Marker({
              position,
              map: map.current!,
              title: markerData.addr,
            });

            new google.maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.5,
              strokeWeight: 1,
              fillColor: "#DD0000",
              fillOpacity: 0.2,
              radius: markerData.proximity * 0.3048, // feet to meters
              center: position,
              map: map.current,
            });
            marker.addListener("click", () => {
              infoWindow.open(map.current!, marker);
            });
          }
        });

        if (validPos(geoLocation))
        {
          setMessage(
            calculateNearestLocationMessage(
              geoLocation.lat!,
              geoLocation.lng!,
              clockLocations
            )
          );
        }
      });
    }

    // eslint-disable-next-line
  }, [geoLocation.lat, geoLocation.lng, google, google.maps]);


  return (
    <>
      <IonButton className="map-button" onClick={onShowMapButtonClick}
                 hidden={geoLocations.length === 0}
      >
        {message}
        <IonIcon
          id="show-map-button"
          icon={showMap ? arrowDownCircle : arrowUpCircle}
        />
      </IonButton>
      <div id="map-container" hidden={!showMap}>
        <div ref={mapEle} className="map-canvas" />
      </div>
    </>
  );
};

export default Map;
