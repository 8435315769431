import { ApplicationUser } from "../context/ApplicationUserContext";
import { AuthorizedItems } from "../orbital-interfaces/AuthorizedItems";
import { openDB, DBSchema, IDBPDatabase } from "idb";

const DB_NAME = "osKeyStore";
const DB_VERSION = 1;

interface OSKeyStore extends DBSchema {
  userStore: {
    key: "user";
    value: ApplicationUser;
  };
  authItems: {
    key: "authItems";
    value: AuthorizedItems;
  };
}

// -----------------------------------------------------------------------------------------
const getKeyStore = (): Promise<IDBPDatabase<OSKeyStore>> => {
  return openDB<OSKeyStore>(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore("userStore");
      db.createObjectStore("authItems");
    },
  });
};

// -----------------------------------------------------------------------------------------
const putUserState = async (state: ApplicationUser) => {
  const db = await getKeyStore();
  await db.put("userStore", state, "user");
};

// -----------------------------------------------------------------------------------------
const getUserState = async (): Promise<ApplicationUser | undefined> => {
  const db = await getKeyStore();
  return await db.get("userStore", "user");
};

// -----------------------------------------------------------------------------------------
const getAccessToken = async (): Promise<string | undefined> => {
  const db = await getKeyStore();
  return await db.get("userStore", "user").then((state) => {
    return state ? state.access_token : undefined;
  });
};

// -----------------------------------------------------------------------------------------
const putAuthItems = async (auth: AuthorizedItems) => {
  const db = await getKeyStore();
  await db.put("authItems", auth, "authItems");
};

const getAuthItems = async () => {
  const db = await getKeyStore();
  return await db.get("authItems", "authItems");
};

const dbStore = {
  getKeyStore,
  putUserState,
  getUserState,
  getAccessToken,
  putAuthItems,
  getAuthItems,
};

export default dbStore;
