import React, { useContext, useState } from "react";
import {IonCol, IonLabel, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import "../../global-styles.scss";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import {authHelper, PositionEx } from "../../util/authHelper";
import {
  AuthorizedItems,
  AuthorizedLocation,
  AuthorizedPosition,
} from "../../orbital-interfaces/AuthorizedItems";
import {Role} from "../../enums";

interface PositionSelectorProps {
  currentPosition: PositionEx;
  onPositionUpdate: (pos: PositionEx) => void;
}

// --------------------------------------------------------------------------------------
function isAdminAtLocation(
  auth: AuthorizedItems,
  location: AuthorizedLocation
) {
  if (auth.role === Role.LocationAdmin || auth.role === Role.BusinessAdmin) {
    return true;
  }

  if (auth.role === Role.Employee) {
    return false;
  }

  return location.departments.find((d) => d.isDeptAdmin) !== undefined;
}

// --------------------------------------------------------------------------------------
function setPositionForLocation(
  auth: AuthorizedItems,
  locationId: number
): PositionEx {
  const location = authHelper.getLocationFromLocationId(auth, locationId);
  if (location === null) {
    throw new Error("Can't find location");
  }

  return {
    location,
    department: null,
    position: null,
  };
}

// --------------------------------------------------------------------------------------
function setPositionForDepartment(
  auth: AuthorizedItems,
  pos: PositionEx,
  departmentId: number | null
): PositionEx {
  if (departmentId === null) {
    return setPositionForLocation(auth, pos.location.id);
  }

  const department = pos.location.departments.filter(
    (d) => d.id === departmentId
  )[0];
  return {
    location: pos.location,
    department,
    position: null,
  };
}

// --------------------------------------------------------------------------------------
function setPositionForPosition(
  auth: AuthorizedItems,
  pos: PositionEx,
  positionId: number | null
): PositionEx {
  if (positionId === null) {
    return {
      location: pos.location,
      department: pos.department,
      position: null,
    };
  }

  const position = pos.department!.positions.filter(
    (p) => p.id === positionId
  )[0];
  return {
    location: pos.location,
    department: pos.department,
    position,
  };
}

// --------------------------------------------------------------------------------------
function getPositionListForSelection(sel: PositionEx): AuthorizedPosition[] {
  if (sel.department !== null) {
    return sel.department.positions;
  }

  return sel.location.departments.flatMap((d) => d.positions);
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const PositionSelector: React.FC<PositionSelectorProps> = ({
  currentPosition,
  onPositionUpdate,
}) => {
  const { auth } = useContext(AuthorizedItemsContext);
  const [selectedPosition, setSelectedPosition] = useState(currentPosition);

  // --------------------------------------------------------------------------------------
  const updateLocation = (locId: number | undefined | null) => {
    if (!locId) {
      return;
    }

    const newPos = setPositionForLocation(auth, locId);
    setSelectedPosition(newPos);
    onPositionUpdate(newPos);
  };

  // --------------------------------------------------------------------------------------
  const updateDepartment = (depId: number | undefined | null) => {
    if (depId === undefined) {
      return;
    }

    const newPos = setPositionForDepartment(auth, selectedPosition, depId);
    setSelectedPosition(newPos);
    onPositionUpdate(newPos);
  };

  // --------------------------------------------------------------------------------------
  const updatePosition = (posId: number | undefined | null) => {
    if (posId === undefined) {
      return;
    }

    const newPos = setPositionForPosition(auth, selectedPosition, posId);
    setSelectedPosition(newPos);
    onPositionUpdate(newPos);
  };

  if (!auth || !auth.locations || auth.locations.length === 0) {
    return (
      <>
        <div>Loading</div>
      </>
    );
  } else {
    return (
      <>
        <IonRow>
          <IonCol size="4">
            <IonLabel className="label">Location</IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonSelect
              mode="ios"
              className="select"
              interface="action-sheet"
              value={selectedPosition.location.id}
              onIonChange={(e) => updateLocation(e.detail.value)}
            >
              {auth.locations
                .filter((l) => isAdminAtLocation(auth, l))
                .map((loc) => (
                  <IonSelectOption key={loc.id} value={loc.id}>
                    {loc.name}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="4">
            <IonLabel className="label">Department</IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonSelect
              mode="ios"
              className="select"
              interface="action-sheet"
              value={
                selectedPosition.department === null
                  ? null
                  : selectedPosition.department.id
              }
              onIonChange={(e) => updateDepartment(e.detail.value)}
            >
              <IonSelectOption key={-1} value={null}>
                All Departments
              </IonSelectOption>
              {selectedPosition.location.departments &&
              selectedPosition.location.departments
                .filter((dep) => authHelper.isDeptAdmin(auth, dep.id))
                .map((dep) => (
                  <IonSelectOption key={dep.id} value={dep.id}>
                    {dep.name}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="4">
            <IonLabel className="label">Position</IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonSelect
              mode="ios"
              className="select"
              interface="action-sheet"
              value={
                selectedPosition.position === null
                  ? null
                  : selectedPosition.position.id
              }
              onIonChange={(e) => updatePosition(e.detail.value)}
            >
              <IonSelectOption key={-1} value={null}>
                All Positions
              </IonSelectOption>
              {getPositionListForSelection(selectedPosition).map((pos) => (
                <IonSelectOption key={pos.id} value={pos.id}>
                  {selectedPosition.department === null
                    ? authHelper.getDepartmentFromPositionId(auth, pos.id)!.abbr +
                    "/" +
                    pos.name
                    : pos.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonCol>
        </IonRow>
      </>
    );
  }
};

export default PositionSelector;
