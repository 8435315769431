import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
// import "./useOrbitalDialogBox.scss";
import "../global-styles.scss";

export interface DialogOptions {
  value: number;
  text: string;
  color: string;
}

function useOrbitalDialogBox() {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    header: "",
    message: "",
    options: [] as DialogOptions[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDismiss: (selection: number | null) => {},
  });

  // --------------------------------------------------------------------------------------
  const showDialog = (
    header: string,
    message: string,
    dialogOptions: DialogOptions[],
    onDismiss: (selection: number | null) => void
  ) => {
    setDialogData({
      header,
      message,
      options: dialogOptions,
      onDismiss,
    });

    setIsOpen(true);
  };

  // --------------------------------------------------------------------------------------
  const onButtonClick = (selection: number | null) => {
    setIsOpen(false);
    dialogData.onDismiss(selection);
  };

  const dialogBoxJsx = (
    <IonModal
      mode="md"
      isOpen={isOpen}
      cssClass="mini-modal orbital-dialog-modal"
      onDidDismiss={() => onButtonClick(null)}
    >
      <IonContent className="modal-content">
        <IonHeader mode="ios">
          <IonToolbar>
            <IonTitle>{dialogData.header}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          {dialogData.options.map((opt) => (
            <IonRow key={opt.value}>
              <IonCol size="12">
                <IonButton
                  expand="block"
                  color={opt.color}
                  className="apply-button"
                  onClick={() => onButtonClick(opt.value)}
                >
                  {opt.text}
                </IonButton>
              </IonCol>
            </IonRow>
          ))}
          <IonRow>
            <IonCol size="12">
              <IonButton
                expand="block"
                className="cancel-button"
                onClick={() => onButtonClick(null)}
              >
                Cancel
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );

  return {
    showDialog,
    dialogBoxJsx,
  };
}

export default useOrbitalDialogBox;
