import { IonAlert } from "@ionic/react";
import React, { useState } from "react";

function useYesOrCancelDialogBox() {
  const [isOpen, setIsOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    message: "",
    onCancel: () => {},
    onYes: () => {},
  });

  const showDialog = (
    message: string,
    onYes: () => void,
    onCancel: () => void
  ) => {
    setAlertData({
      message,
      onYes,
      onCancel,
    });

    setIsOpen(true);
  };

  const dialogJsx = (
    <IonAlert
      mode="ios"
      isOpen={isOpen}
      onDidDismiss={() => {
        setIsOpen(false);
      }}
      message={alertData.message}
      buttons={[
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: alertData.onCancel,
        },

        {
          text: "Yes",
          handler: alertData.onYes,
        },
      ]}
    />
  );

  return {
    showDialog,
    dialogJsx,
  };
}

export default useYesOrCancelDialogBox;
