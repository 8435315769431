/* eslint camelcase: 0 */
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";
import { ApplicationUser } from "../context/ApplicationUserContext";
import env from "../appFunctions/environment";
import apiHelper from "./orbitalApiHelper";
import {ApiErrorResult} from "../orbital-interfaces/ApiErrorResult";
import {log} from "../util/logger";
import {Device} from '@capacitor/device';

interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  error?: string;
  error_description?: string;
  [".issued"]: string;
  [".expires"]: string;
}

// ----------------------------------------------------------------------------------------------
async function authenticateUser(
  username: string,
  password: string,
  environment: string
) {
  log("api_user_authenticateUser");
  console.log(
    `Authenticating against ${env.settings.env.item(environment).name} (${
      env.settings.env.item(environment).url
    })`
  );
  const url = env.settings.env.item(environment).url + "token";
  const version = env.settings.appVersionString;
  const userInterface = env.settings.userInterface;
  const body = `grant_type=password&scope=${userInterface} ${version}&username=${username}&password=${password}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
    });

    const json = (await response.json()) as AccessTokenResponse;
    if (json.error || json.error_description) {
      return {
        loggedIn: false,
        error: json.error_description,
        access_token: "",
      };
    }

    return { loggedIn: true, access_token: json.access_token };
  } catch (err) {

    let errorMsg = "Error attempting to log in";
    if (err && err instanceof Error && err.toString().indexOf("Failed to fetch") > -1) {
      errorMsg = "Orbital Shift could not be contacted. Check your internet service or try again later.";
    }

    console.error("Error returned from authentication request: " + err);
    return {
      loggedIn: false,
      err,
      error: errorMsg,
      access_token: "",
    };
  }
}

// ----------------------------------------------------------------------------------------------
async function resetPassword(userName: string, email: string, environment: string) {
  log("api_user_resetPassword");
  const url = env.settings.env.item(environment).url + "api/v2/reset-password";
  const data = { userName, email };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        AppVersion: env.settings.appVersionString
      }
    })

    return (await response.json()) as ApiErrorResult;
  } catch (err) {
    return { error: true, message: err instanceof Error ? err.message : ""} as ApiErrorResult;
  }
}

// ----------------------------------------------------------------------------------------------
async function getAuthorizedItems(user: ApplicationUser) {
  log("api_user_getAuthorizedItems");

  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;
  const data = {
    osVersion: deviceInfo.osVersion,
    model: deviceInfo.model,
    manufacturer: deviceInfo.manufacturer,
    uuid: uuid
  };

  return await apiHelper.post(
    user,
    "api/v3/home/get-auth",
    data
  ) as AuthorizedItems | ApiErrorResult;
}

// ----------------------------------------------------------------------------------------------
async function logout(user: ApplicationUser) {
  log("api_user_logout");

  if (!user || !user.isLoggedin) {
    return;
  }

  return (await apiHelper.post(
      user,
      "api/v3/home/logout",
  ));
}

export const userApi = {
  authenticateUser,
  getAuthorizedItems,
  logout,
  resetPassword,
};
