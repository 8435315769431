import { ApplicationUser } from "../context/ApplicationUserContext";
import env from "../appFunctions/environment";
import { log } from "../util/logger";
import {Device} from '@capacitor/device';

// ----------------------------------------------------------------------------------------------
// function createTrace(
//     curEnv: EnvironmentType,
//     baseUrl: string,
//     user: ApplicationUser): firebase.performance.Trace {
//   const perf = firebase.performance();
//   const trace = perf.trace("api_" + curEnv.key);

//   try {
//     trace.putAttribute("baseUrl", baseUrl);
//     trace.putAttribute("version", env.settings.appVersionString);
//     trace.putAttribute("userName", (user) ? user.username : "");
//     trace.putAttribute("platform", env.settings.platform);
//   } catch(e) {
//     console.warn("error thrown trying to add trace attributes: " + e)
//   }

//   console.log("API: " + baseUrl);

//   return trace;
// }

// ----------------------------------------------------------------------------------------------
const post = async (user: ApplicationUser, baseUrl: string, data?: any) => {
  const curEnv = env.getCurrentEnvironment(user);
  const url = curEnv.url + baseUrl;
  //const trace = createTrace(curEnv, baseUrl, user);
  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;

  try {
    //trace.start();
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
        AppVersion: env.settings.appVersionString,
        UserInterface:  env.settings.userInterface,
        OsVersion: deviceInfo.osVersion,
        DeviceModel: deviceInfo.model,
        Uuid: uuid,
        RevisionHash: env.settings.revisionHash,
      },
    });

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const results = await response.json();
      log("api_succeeded", {
        method: "POST",
        baseUrl,
        username: user.username,
      });
      //trace.stop();
      return results;
    }

    log("api_succeeded", { method: "POST", baseUrl, username: user.username });
  } catch (err) {
    //trace.stop();
    log("api_err", {
      method: "POST",
      baseUrl,
      username: user?.username,
      ...data,
      appVersion: env.settings.appVersionString,
    });
    console.error("Error calling post against url, " + baseUrl + ": " + err);
    throw err;
  }
};

// ----------------------------------------------------------------------------------------------
const putData = async (user: ApplicationUser, baseUrl: string, data: any) => {
  const curEnv = env.getCurrentEnvironment(user);
  const url = curEnv.url + baseUrl;
  //const trace = createTrace(curEnv, baseUrl, user);
  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;

  try {
    //trace.start();
    const dataString = JSON.stringify(data);

    const response = await fetch(url, {
      method: "PUT",
      body: dataString,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
        AppVersion: env.settings.appVersionString,
        Platform: env.settings.platform,
        UserInterface: env.settings.userInterface,

        OsVersion: deviceInfo.osVersion,
        DeviceModel: deviceInfo.model,
        DeviceManufacturer: deviceInfo.manufacturer,
        Uuid: uuid,
        Revision: env.settings.revisionHash,
      },
    });

    const results = await response.json();
    //trace.stop();
    log("api_succeeded", { method: "PUT", baseUrl, username: user.username });
    return results;
  } catch (err) {
    //trace.stop();
    log("api_err", {
      method: "PUT",
      baseUrl,
      username: user?.username,
      ...data,
      appVersion: env.settings.appVersionString,
    });
    console.error("Error calling putData against url, " + baseUrl + ": " + err);
    throw err;
  }
};

// ----------------------------------------------------------------------------------------------
const get = async (user: ApplicationUser, baseUrl: string, data?: any) => {
  const curEnv = env.getCurrentEnvironment(user);
  const url = curEnv.url + baseUrl;
  //const trace = createTrace(curEnv, baseUrl, user);
  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;

  try {
    //trace.start();
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
        AppVersion: env.settings.appVersionString,
        Platform: env.settings.platform,
        UserInterface: env.settings.userInterface,
        OsVersion: deviceInfo.osVersion,
        DeviceModel: deviceInfo.model,
        DeviceManufacturer: deviceInfo.manufacturer,
        Uuid: uuid,
        Revision: env.settings.revisionHash,
      },
      body: null as null | string,
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    const response = await fetch(url, options);
    const results = await response.json();
    //trace.stop();
    log("api_succeeded", { method: "GET", baseUrl, username: user.username });
    return results;
  } catch (err) {
    log("api_err", {
      method: "GET",
      baseUrl,
      username: user?.username,
      appVersion: env.settings.appVersionString,
    });
    console.error("Error calling get against url, " + baseUrl + ": " + err);
    throw err;
  }
};

// ----------------------------------------------------------------------------------------------
const put = async (user: ApplicationUser, baseUrl: string, data?: any) => {
  const curEnv = env.getCurrentEnvironment(user);
  const url = curEnv.url + baseUrl;
  //const trace = createTrace(curEnv, baseUrl, user);
  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.access_token}`,
      AppVersion: env.settings.appVersionString,
      Platform: env.settings.platform,
      UserInterface: env.settings.userInterface,
      OsVersion: deviceInfo.osVersion,
      DeviceModel: deviceInfo.model,
      DeviceManufacturer: deviceInfo.manufacturer,
      Uuid: uuid,
      Revision: env.settings.revisionHash,
    },
  } as any;

  if (data) {
    options.body = JSON.stringify(data!);
  }

  try {
    //trace.start();
    const response = await fetch(url, options);
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const results = await response.json();
      //trace.stop();
      log("api_succeeded", { method: "PUT", baseUrl, username: user.username });
      return results;
    }

    //trace.stop();
    log("api_succeeded", { method: "PUT", baseUrl, username: user.username });
  } catch (err) {
    //trace.stop();
    log("api_err", {
      method: "PUT",
      baseUrl,
      username: user?.username,
      ...data,
      appVersion: env.settings.appVersionString,
    });
    console.error("Error calling put against url, " + baseUrl + ": " + err);
    throw err;
  }
};

// ----------------------------------------------------------------------------------------------
const del = async (user: ApplicationUser, baseUrl: string, data?: any) => {
  const curEnv = env.getCurrentEnvironment(user);
  const url = curEnv.url + baseUrl;
  //const trace = createTrace(curEnv, baseUrl, user);
  const deviceInfo = await Device.getInfo();
  const uuid = (await Device.getId()).uuid;

  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.access_token}`,
      AppVersion: env.settings.appVersionString,
      Platform: env.settings.platform,
      UserInterface: env.settings.userInterface,
      OsVersion: deviceInfo.osVersion,
      DeviceModel: deviceInfo.model,
      DeviceManufacturer: deviceInfo.manufacturer,
      Uuid: uuid,
      Revision: env.settings.revisionHash,
    },
  } as any;

  if (data) {
    options.body = JSON.stringify(data!);
  }

  try {
    //trace.start();
    const response = await fetch(url, options);
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      //trace.stop();
      log("api_succeeded", {
        method: "DELETE",
        baseUrl,
        username: user.username,
      });
      return await response.json();
    }

    //trace.stop();
    log("api_succeeded", {
      method: "DELETE",
      baseUrl,
      username: user.username,
    });
    return;
  } catch (err) {
    //trace.stop();
    log("api_err", {
      method: "DELETE",
      baseUrl,
      username: user?.username,
      ...data,
      appVersion: env.settings.appVersionString,
    });
    console.error("Error calling delete against url, " + baseUrl + ": " + err);
    throw err;
  }
};

const orbitalApiHelper = {
  get,
  post,
  put,
  putData,
  del,
};

export default orbitalApiHelper;
