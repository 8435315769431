export enum ClockOutResponseCode {
  ClockOutOk = 0,
  UserNotAtSpecifiedLocation,
  UserRoleMayNotClockIn,
  UserNotClockedIn,
  NoOpenTimeClockEventForUserAtLocation,
  BreakStartComesBeforeStartOfClockedInTime,
  BreakStartComesAfterClockedOutTime,
  BreakTimeIsGreaterThanShiftTimeAfterBreakStart,
  BreakTimeIsGreaterThanEntireShiftTime,
  BusinessNotEnabledForTimeClock,
  NoValidCookieFoundForClockByDevice,
  CannotClockOutFromGivenIpAddress,
  NoGeoCoordinatesPassedButRequiredForMobile,
  CouldNotDetermineGeoLocationRequiredToClockOut,
  UserNotAtRequiredGeoLocations,
  NoValidCookieOrIpAddressFound,
  CouldNotFindRequiredClockLocationForClockOut,
  CouldNotFindClockStationInfo,
  CouldNotFindBusinessClockStationId,
  CouldNotFindPositionForEmployee,
  NoPunchClockLocationsAvailableForUser,
  ErrorUpdateEventBreaks,
}
