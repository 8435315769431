import React, { useState, useEffect } from "react";
import {IonLabel, IonRow, IonCol} from "@ionic/react";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";
import { IdName } from "../../../orbital-interfaces/IdName";
import OptionSelector from "../../EventsPageComponents/OptionSelector";

interface EventTagEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// Value / selection for the no tag
const noTagValue = {
  id: -1,
  name: "None"
} as IdName;

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventTagEditor: React.FC<EventTagEditorProps> = ({
  auth,
  editorValues,
  update,
}: EventTagEditorProps) => {
  const [selectedTagId, setSelectedTagId] = useState(noTagValue.id);
  const [tagList, setTagList] = useState([] as IdName[]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId === -1) {
      return;
    }

    if (auth && auth.locations && auth.locations.length > 0) {
      const tags = auth.tags
        .filter((t) =>
          t.positionIds.includes(editorValues.position.position.id)
        )
        .map((t) => {
          return { id: t.id, name: t.name };
        });


      const tagIds = tags.map((t) => t.id);
      tags.push(noTagValue);
      setTagList(tags);

      if (!tagIds.includes(selectedTagId)) {
        if (
          editorValues.tag &&
          editorValues.tag.id &&
          tagIds.includes(editorValues.tag.id)
        ) {
          setSelectedTagId(editorValues.tag.id);
        } else {
          setSelectedTagId(noTagValue.id);
        }
      }
    }

    // eslint-disable-next-line
  }, [auth, editorValues.position, editorValues.scheduleEventId]);

  // --------------------------------------------------------------------------------------
  function onUpdateTag(tagId: number) {
    setSelectedTagId(tagId);

    if (tagId === editorValues.tag.id) {
      return;
    }

    if (tagId === noTagValue.id) {
      update({ type: "TAG-UPDATED", tag: noTagValue });
    } else {
      const tag = tagList.filter((t) => t.id === tagId)[0];
      update({ type: "TAG-UPDATED", tag });
    }
  }

  return (
    <IonRow>
      <IonCol size="4">
        <IonLabel className="label">~Tag</IonLabel>
      </IonCol>
      <IonCol size="8">
        <OptionSelector
          options={tagList}
          initialOption={selectedTagId}
          onOptionUpdated={(e) => onUpdateTag(e)}
          isDisabled={editorValues.isReadonly || editorValues.isConfirmed}
        />
      </IonCol>
    </IonRow>
  );
};

export default EventTagEditor;
