import {Column, ColumnType, ReportResults, TimeRange} from "./ReportResults";
import {ColDef, GridOptions} from "ag-grid-community";
import {orbitalTimeHelper} from "../../util/orbitalTimeHelper";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";
import {OrbitalTime} from "../../orbital-interfaces/OrbitalTime";
import {format, parseISO} from "date-fns";

// ----------------------------------------------------------------------------
function setupOrbitalTimeColumn(colDef: ColDef, repCol: Column, locSettings: LocationSettings) {
  colDef.cellRenderer = (params) => {
    const columnName = params.column.getColDef().field;    
    const otVal = params.data[columnName] as OrbitalTime;
  
    const val = orbitalTimeHelper.getString(otVal, locSettings);
    if (val !== undefined) {
      return val;
    }

    return "?";
  }

  colDef.valueGetter = (params) => {
    if (!params || !params.data) {
      return 0.0;
    }

    try {
      const columnName = params.column.getColDef().field;
      if (columnName === undefined) {
        return 0.0;
      }

      const otVal = params.data[columnName] as OrbitalTime;
      if (!otVal || !otVal.ActualHours) {
        return 0.0;
      }

      const val = otVal.ActualHours;
      if (val !== undefined) {
        return val;
      }
    } catch(e) {
      console.log(e);
    }

    return 0.0;
  }


  colDef.comparator = (t1: OrbitalTime, t2: OrbitalTime) => {
    if (t1.ActualHours > t2.ActualHours) {
      return 1;
    } else if (t1.ActualHours < t2.ActualHours) {
      return -1;
    }

    return 0;
  }

  colDef.editable = false;
}

// ----------------------------------------------------------------------------
function setupDateColumn(colDef: ColDef, repCol: Column) {
  colDef.cellRenderer = (params) => {
    const columnName = params.column.colDef.field;
    const dateStr = params.data[columnName];
    if (!dateStr) {
      return "";
    }

    if (repCol.key && dateStr === "Total") {
      return dateStr;
    }

    const date = parseISO(dateStr);
    return (repCol.format)
      ? format(date, repCol.format).toUpperCase()
      : format(date, "EEE M/d").toUpperCase();
  }

  colDef.comparator = (d1: string, d2: string) => {
    if (d1 === d2) {
      return 0;
    }
    return (d1 > d2) ? 1 : -1;
  }

  colDef.editable = false;
}

// ----------------------------------------------------------------------------
function setupTimeRangeColumn(colDef: ColDef) {
  colDef.cellRenderer = (params) => {
    const columnName = params.column.colDef.field;
    const timeRange = params.data[columnName] as TimeRange;
    return timeRange
      ? timeRange.Display
      : "";
  }

  colDef.editable = false;
}

// ----------------------------------------------------------------------------
function generateColumnDefs(data: ReportResults, locSettings: LocationSettings): ColDef[] {
  return data.def.columns.map(repCol => {
    const colDef = {
      headerName: repCol.name,
      field: repCol.name,
      type: 'rightAligned',
      pinned: repCol.pinned,
      sortable: true,
    } as ColDef;

    if (repCol.type === ColumnType.OrbitalTime) {
      setupOrbitalTimeColumn(colDef, repCol, locSettings);
    } else if (repCol.type === ColumnType.Date) {
      setupDateColumn(colDef, repCol);
    } else if (repCol.type === ColumnType.TimeRange) {
      setupTimeRangeColumn(colDef);
    }

    if (repCol.hidden) {
      colDef.hide = true;
    }

    if (repCol.hideByDefault || repCol.hidden) {
      colDef.hide = true;
      colDef.suppressColumnsToolPanel = true;
    }

    let hasData = false;
    data.records.map(repRow => {
      const field = repRow.fields.find(f => f.name === repCol.name);
      if (field !== undefined) {
        if (repCol.type === ColumnType.OrbitalTime) {
          const val = field.value as OrbitalTime;
          if (val.ActualHours > 0.0) {
            hasData = true;
          }
        } else {
          hasData = true;
        }
      }

      return 0;
    });

    if (!hasData) {
      colDef.hide = true;
    }

    colDef.editable = false;
    colDef.sortable = true;

    return colDef;
  });
}

// ----------------------------------------------------------------------------
function generateRowData(data: ReportResults): any[] {
  const rows = [] as any[];
  let rowNum = 0;
  data.records.map(repRow => {
    const row = {} as any;
    repRow.fields.map(f => {
      row[f.name] = f.value;
      return row;
    });

    row._rowNum = rowNum++;
    rows.push(row);
    return row;
  });

  return rows;
}

// ----------------------------------------------------------------------------
function generateTotalData(data:ReportResults) {
  const totalRow = {} as any;
  data.def.columns.map(repCol => {
    if (repCol.summable && repCol.type === ColumnType.OrbitalTime) {
      const sum = orbitalTimeHelper.add(data.records.map(rec => {
        const field = rec.fields.find(f => f.name === repCol.name);
        return (field === undefined) ? orbitalTimeHelper.getZero() : field.value as OrbitalTime;
      }));

      totalRow[repCol.name] = sum;
    } else if (repCol.key) {
      totalRow[repCol.name] = "Total";
    } else {
      totalRow[repCol.name] = "";
    }

    return totalRow[repCol.name];
  });

  totalRow._rowNum = 0;

  return [totalRow];
}

// ----------------------------------------------------------------------------
export function generateGridState(data: ReportResults, locSettings: LocationSettings): GridOptions[] {
  const columnDefs = generateColumnDefs(data, locSettings);

  const topOptions = {
    alignedGrids: [],
    suppressHorizontalScroll: true,
    autoSizePadding: 0,
    headerHeight: 32,
    rowHeight: 32,
    rowSelection: 'single',
    rowMultiSelectWithClick: false,

    sideBar: 'columns',
    defaultColDef: {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    },
    columnDefs,
    rowData: generateRowData(data),
  } as GridOptions;

  const bottomOptions = {
    alignedGrids: [],
    suppressHorizontalScroll: true,
    headerHeight: 0,
    rowHeight: 32,
    defaultColDef: {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    },
    columnDefs,
    rowData: generateTotalData(data)
  } as GridOptions;

  topOptions.alignedGrids!.push(bottomOptions);
  bottomOptions.alignedGrids!.push(topOptions);

  return [
    topOptions,
    bottomOptions
  ]
}
