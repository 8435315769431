export enum LogLevel {
  Verbose,

  Debug,

  Information,

  Warning,

  Error,

  Fatal
}
