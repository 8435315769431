import React, {useContext} from "react";
import { Message } from "../../orbital-interfaces/Message";
import { timeHelper as th } from "../../util/timeHelper";
import "../../global-styles.scss";
import {IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding} from "@ionic/react";
import {AuthorizedItemsContext} from "../../context/AuthorizedItemsContext";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import {authHelper} from "../../util/authHelper";
import {bookmark, bookmarkOutline, trash} from "ionicons/icons";
import {messageApi} from "../../api/messageApi";
import {MessagesReducerAction} from "../../pages/MessagesPage";

interface MessageItemProps {
  message: Message;
  onMessageClick: () => void;
  updateMessages: React.Dispatch<MessagesReducerAction>;
  showToast: (msg: string) => void;
}

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
const MessageItem: React.FC<MessageItemProps> = ({
  message,
  onMessageClick,
  updateMessages,
  showToast,
}: MessageItemProps) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
  if (!message) {
    return <div />;
  }

  const fontWeightForSubject = message.isViewed ? "normal" : "bold";

  // --------------------------------------------------------------------------------------
  function closeSlider() {
    const slidingElement = document.getElementById("ion-item-sliding-" + message.messageId) as HTMLIonItemSlidingElement;
    slidingElement.closeOpened();
  }

  // --------------------------------------------------------------------------------------
  function onPinnedClick() {
    messageApi.setIsPinned(user, message.messageId, !message.isPinned).then(() => {
      updateMessages({
        type: "UPDATE-PINNED",
        msgId: message!.messageId,
        isPinned: !message.isPinned,
      });

      closeSlider();
    });
  }

  // --------------------------------------------------------------------------------------
  function onDeleteClick() {
    messageApi.deleteMessage(user, message!.messageId).then(() => {
      updateMessages({ type: "DELETE", msgId: message!.messageId });
      closeSlider();
      showToast("Message deleted");
    });
  }

  return (
    <IonItemSliding id={"ion-item-sliding-" + message.messageId}>
      {message.isViewed && (
        <IonItemOptions side="end">
          <IonItemOption
            color="danger"
            onClick={onDeleteClick}>
            <IonIcon slot="icon-only" icon={trash} />
          </IonItemOption>
        </IonItemOptions>
      )}
      <IonItem>
        <button className="message" onClick={onMessageClick}>
          <div className="top-line">
            <span className="subject" style={{ fontWeight: fontWeightForSubject }}>
              {message.isPinned && (
                <IonIcon icon={bookmark} style={{ color: "gray" }} />
              )}
              {message.subject}
            </span>
            <span className="time">
              {th.messageDateString(message.deliveryDateTime, locSettings.TimeZoneOffset)}{" "}
            </span>
          </div>
          <div className="bottom-line">{message.previewLine}</div>
        </button>
      </IonItem>

      <IonItemOptions side="start">
        <IonItemOption
          color="secondary"
          onClick={() => onPinnedClick()}
        >
          <IonIcon
            slot="icon-only"
            icon={message.isPinned ? bookmark : bookmarkOutline} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default MessageItem;
