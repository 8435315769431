import React, { useState, useEffect, useReducer, useRef } from "react";
import {
  IonModal,
  IonHeader,
  IonButton,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  useIonViewWillEnter,
  useIonViewWillLeave, IonRefresher, IonRefresherContent,
} from "@ionic/react";
import "../global-styles.scss";
import { funnel } from "ionicons/icons";
import { Message } from "../orbital-interfaces/Message";
import MessageItem from "../components/MessagesPageComponents/MessageItem";
import MessagesFilterModal, {
  MessageFilterSettings,
  defaultMessageFilterSettings,
  isFilterApplied,
} from "../components/MessagesPageComponents/MessagesFilterModal";
import { arrayHelper } from "../util/arrayHelper";
import { messageApi } from "../api/messageApi";
import { ApplicationUserContext } from "../context/ApplicationUserContext";
import ViewMessagetModal from "../components/MessagesPageComponents/ViewMessageModal";
import useSimpleToast from "../hooks/useSimpleToast";
import { RouteComponentProps } from "react-router";
import { AuthorizedItemsContext } from "../context/AuthorizedItemsContext";
import { MessagesContext } from "../context/MessagesContext";
import FabButton from "../components/FabButton";
import { getConstants } from "../appFunctions/environment";
import { authHelper } from "../util/authHelper";
import { MessageCardSkeleton } from "../components/EventsPageComponents/CardSkeleton";
import {useInterval} from '../hooks/useInterval';


const initialMessages: Message[] = [];

export type MessagesReducerAction =
  | { type: "LOADED-FROM-API"; data: Message[] }
  | { type: "CLEAR" }
  | { type: "UPDATE-PINNED"; msgId: number; isPinned: boolean }
  | { type: "UPDATE-VIEWED"; msgId: number; isViewed: boolean }
  | { type: "DELETE"; msgId: number };

// ------------------------------------------------------------------------------------------------
export const messagesReducer = (
  currentState: Message[],
  action: MessagesReducerAction
): Message[] => {
  switch (action.type) {
    case "LOADED-FROM-API":
      return action.data;
    case "CLEAR":
      return [] as Message[];
    case "UPDATE-PINNED":
      return currentState.map((m) =>
        m.messageId === action.msgId ? { ...m, isPinned: action.isPinned } : m
      );
    case "UPDATE-VIEWED":
      return currentState.map((m) => {
        return m.messageId === action.msgId
          ? { ...m, isViewed: action.isViewed }
          : m;
      });
    case "DELETE":
      return currentState.filter((m) => m.messageId !== action.msgId);
  }
};

interface MessagesPageProps
  extends RouteComponentProps<{
    messageId?: string;
  }> {}

// eslint-disable-next-line react/prop-types
const MessagesPage: React.FC<MessagesPageProps> = ({
  match,
  history,
}: MessagesPageProps) => {
  const { user } = React.useContext(ApplicationUserContext);
  const { auth, refreshAuth, authLoading } = React.useContext(
    AuthorizedItemsContext
  );
  const { messagesViewed, refreshCount } = React.useContext(MessagesContext);
  const fabButtonRef = useRef(null as any | null); // Should be HTMLIonFabElement but  ¯\_(ツ)_/¯
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);


  // ------------------------------------------------------------------------------------
  useIonViewDidEnter(() => {
    messagesViewed();
  }, [messagesViewed]);

  // ------------------------------------------------------------------------------------
  useIonViewDidLeave(() => {
    fabButtonRef.current!.close();
    messagesViewed();
  }, [messagesViewed]);

  // ------------------------------------------------------------------------------------
  useIonViewWillEnter(() => {
    setIsShowing(true);
  });

  // ------------------------------------------------------------------------------------
  useIonViewWillLeave(() => {
    setIsShowing(false);
  });

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (
      !auth ||
      !auth.locations ||
      (auth.locations.length === 0 &&
        user.isLoggedin &&
        !user.loading &&
        !authLoading &&
        isShowing)
    ) {
      refreshAuth(false, user, needsLogout);
    }

    // eslint-disable-next-line
  }, [auth]);


  // ------------------------------------------------------------------------------------
  function doRefresh() {
    if (nextPage !== 1) {
      setNextPage(1);
    }

    if (disableInfiniteScroll) {
      setDisableInfiniteScroll(false);
    }

    refreshMessages(1);
  }

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [messages, updateMessages] = useReducer(messagesReducer, []);
  const [filteredMessages, setFilteredMessages] = useState(initialMessages);
  const [filterSettings, setFilterSettings] = useState(
    defaultMessageFilterSettings
  );
  const [viewMessage, setViewMessage] = useState(null as Message | null);
  const [nextPage, setNextPage] = useState(1);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );
  const toast = useSimpleToast();
  const [isShowing, setIsShowing] = useState(false);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    if (authHelper.isAuthItemsValid(auth)) {
      return refreshMessages(1);
    }

    // eslint-disable-next-line
  }, [auth.locations.length]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    filterMessages(filterSettings, messages);

    // eslint-disable-next-line
  }, [messages]);

  // ------------------------------------------------------------------------------------
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (match.params.messageId && viewMessage === null) {
      messageApi
        // eslint-disable-next-line react/prop-types
        .getUserMessage(user, parseInt(match.params.messageId))
        .then((msg) => {
          if (!msg) {
            toast.showToast("The requested message has been removed");
            // eslint-disable-next-line react/prop-types
            history.push("/tabs/messages/");
          }

          setViewMessage(msg);
        });
    }

    // eslint-disable-next-line
  }, [match.params.messageId]);

  // ------------------------------------------------------------------------------------
  const refreshMessages = (pageNumber: number) => {
    let isSubscribed = true;

    if (messages.length === 0 && !isLoadingMessages) {
      setIsLoadingMessages(true);
    }

    messageApi.getUserMessagesByPage(user, pageNumber).then((msgs) => {
      if (!isSubscribed) {
        return;
      }

      if (pageNumber === 1) {
        updateMessages({ type: "LOADED-FROM-API", data: msgs });
      } else {
        updateMessages({
          type: "LOADED-FROM-API",
          data: [...messages, ...msgs],
        });
      }

      filterMessages(filterSettings, msgs);
      if (
        msgs.length < getConstants(user).messagesReturnedPerPage &&
        disableInfiniteScroll === false
      ) {
        setDisableInfiniteScroll(true);
      }

      setIsLoadingMessages(false);

      ionRefresherRef.current!.complete();
    });

    return () => {
      isSubscribed = false;
    };
  };

  // ------------------------------------------------------------------------------------
  useInterval(
    () => {
      refreshCount();
    },
    isShowing,
    getConstants(user).millisecondsToRefreshMessageCountOnMessagePage,
    [isShowing, messages, nextPage]
  );


  // ----------------------------------------------------------------------------------------------
  function needsLogout() {
    history.push("/login");
    history.goForward();
    return;
  }

  // ------------------------------------------------------------------------------------
  async function fetchMoreMessages(e: CustomEvent<void>) {
    await refreshMessages(nextPage + 1);
    setNextPage(nextPage + 1);
    (e.target as HTMLIonInfiniteScrollElement).complete();
  }

  // ------------------------------------------------------------------------------------
  const filterClose = (updatedFilterSettings: MessageFilterSettings) => {
    setFilterSettings(updatedFilterSettings);
    filterMessages(updatedFilterSettings, messages);
    setShowFilterModal(false);
  };

  // ------------------------------------------------------------------------------------
  const filterMessages = (f: MessageFilterSettings, msgs: Message[]) => {
    if (!f || !f.msgTypeIds) {
      setFilteredMessages(msgs);
      return;
    }

    if (f.employeeId !== -1) {
      if (f.msgTypeIds.length > 0) {
        setFilteredMessages(
          msgs.filter(
            (m) =>
              m.fromEmployeeId === f.employeeId &&
              arrayHelper.contains(f.msgTypeIds, m.messageTypeId)
          )
        );
      } else {
        setFilteredMessages(
          msgs.filter((m) => m.fromEmployeeId === f.employeeId)
        );
      }
    } else if (f.msgTypeIds.length > 0) {
      setFilteredMessages(
        msgs.filter((m) => arrayHelper.contains(f.msgTypeIds, m.messageTypeId))
      );
    } else {
      setFilteredMessages(msgs);
    }
  };

  // ------------------------------------------------------------------------------------
  const onCloseMessageModal = (msg: string | null) => {
    setViewMessage(null);
    if (msg) {
      toast.showToast(msg);
    }
  };

  // ------------------------------------------------------------------------------------
  const onFabModalClosed = (
    updateMessages: boolean,
    updateEventsOrNotes: boolean,
    toastMessage?: string
  ) => {
    if (toastMessage) {
      toast.showToast(toastMessage);
    }

    if (updateMessages) {
      setTimeout(() => {
        //refreshMessages(1);
      }, 4000);
    }

    if (updateEventsOrNotes) {
    }
  };

  // ------------------------------------------------------------------------------------
  const showFilter = () => {
    setShowFilterModal(true);
  };

  return (
    <IonPage id="messages-page">
      <IonHeader className="ion-no-border">
        <IonToolbar mode="md">
          <IonButtons slot="start">
            <IonMenuButton className="ion-menu-button" />
          </IonButtons>
          <IonTitle slot="start">Messages</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={showFilter}>
              <IonIcon
                icon={funnel}
                mode="ios"
                slot="icon-only"
                className={
                  isFilterApplied(filterSettings)
                    ? "filter-applied"
                    : "filter-not-applied"
                }
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="outer-content">
        <IonRefresher
          slot="fixed"
          onIonRefresh={doRefresh}
          ref={ionRefresherRef}
        >
          <IonRefresherContent
            pullingIcon="arrow-dropdown"
            pullingText="Pull down to refresh"
            refreshingText="Refreshing Messages..."
          />
        </IonRefresher>

        {isLoadingMessages && <MessageCardSkeleton count={5} />}

        {!isLoadingMessages && filteredMessages.length === 0 && (
          <div className="no-messagesOrEvents-text">No Messages</div>
        )}

        <IonList className="message-list" lines="none">
          {!isLoadingMessages &&
            filteredMessages.length > 0 &&
            filteredMessages
              .filter((m) => m.isPinned)
              .map((msg) => (
                <MessageItem
                  key={msg.messageId}
                  updateMessages={updateMessages}
                  message={msg}
                  onMessageClick={() => setViewMessage(msg)}
                  showToast={(msg) => {
                    toast.showToast(msg);
                  }}
                />
              ))}
        </IonList>

        <IonList className="message-list" lines="none">
          {!isLoadingMessages &&
            filteredMessages.length > 0 &&
            filteredMessages
              .filter((m) => !m.isPinned)
              .map((msg) => (
                <MessageItem
                  key={msg.messageId}
                  message={msg}
                  updateMessages={updateMessages}
                  onMessageClick={() => setViewMessage(msg)}
                  showToast={(msg) => {
                    toast.showToast(msg);
                  }}
                />
              ))}
        </IonList>
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={fetchMoreMessages}
        >
          <IonInfiniteScrollContent loadingText="Retrieving older messages..." />
        </IonInfiniteScroll>
      </IonContent>

      <FabButton fabRef={fabButtonRef} onFabModalClosed={onFabModalClosed} />

      <IonModal mode="md" isOpen={showFilterModal}>
        <MessagesFilterModal
          currentFilterSettings={filterSettings}
          onDismissModal={filterClose}
        />
      </IonModal>

      <IonModal 
        mode="md" 
        isOpen={viewMessage !== null}
        onDidDismiss={() => onCloseMessageModal(null)}                          
      >
        <ViewMessagetModal
          message={viewMessage}
          updateMessages={updateMessages}
          onCloseModal={onCloseMessageModal}
        />
      </IonModal>

      {toast.simpleToastJsx}
    </IonPage>
  );
};

export default MessagesPage;
