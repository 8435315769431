import React from "react";
import { IonLabel, IonRow, IonCol, IonDatetime } from "@ionic/react";
import "../../../global-styles.scss";
import "./EventStartEndEditor";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { parseISO } from "date-fns";
import { EditorValues, EditorReducerAction } from "../EditEventModalState";

interface EventStartEndEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventStartEndEditor: React.FC<EventStartEndEditorProps> = ({
  editorValues,
  update,
}: EventStartEndEditorProps) => {
  const onStartChange = (newEventStart?: string | null) => {
    if (!newEventStart) {
      return;
    }

    if (parseISO(newEventStart) === parseISO(editorValues.eventStart)) {
      return;
    }

    update({
      type: "START-UPDATED",
      eventStart: newEventStart,
    });
  };

  // ------------------------------------------------------------------------------------
  function onEndChange(newEventEnd?: string | null) {
    if (!newEventEnd) {
      return;
    }

    if (parseISO(newEventEnd) === parseISO(editorValues.eventEnd)) {
      return;
    }

    update({
      type: "END-UPDATED",
      eventEnd: newEventEnd,
    });
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">Start</IonLabel>
        </IonCol>
        <IonCol size="8">
          <IonDatetime
            mode="ios"
            className="select"
            displayFormat="h:mm A, M/D DDD"
            disabled={editorValues.isReadonly || editorValues.isConfirmed}
            pickerFormat="h:mm A MMM D YY"
            minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
            max="2040-01-01"
            value={editorValues.eventStart}
            onIonChange={(e) => {
              onStartChange(e.detail.value);
            }}
          />
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">End</IonLabel>
        </IonCol>
        <IonCol size="8">
          <IonDatetime
            mode="ios"
            className="select"
            displayFormat="h:mm A, M/D DDD"
            disabled={editorValues.isReadonly || editorValues.isClockedIn || editorValues.isConfirmed}
            pickerFormat="h:mm A MMM D YY"
            minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
            max="2040-01-01"
            value={editorValues.eventEnd}
            onIonChange={(e) => {
              onEndChange(e.detail.value);
            }}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default EventStartEndEditor;
