import React, {useContext, useEffect, useState} from "react";
import {IonCol, IonLabel, IonRow, IonSelect, IonSelectOption,} from "@ionic/react";
import "../../../global-styles.scss";
import {AuthorizedItems} from "../../../orbital-interfaces/AuthorizedItems";
import {EditorReducerAction, EditorValues} from "../EditEventModalState";
import {CoverListItem, requestsApi} from "../../../api/requestsApi";
import {ApplicationUserContext} from "../../../context/ApplicationUserContext";
import {EventStatus, EventType, Role} from "../../../enums";
import useCancelablePromise from "../../../hooks/useCancelablePromise";

interface EventTransferToEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const EventTransferToEditor: React.FC<EventTransferToEditorProps> = ({
 auth,
 editorValues,
 update,
}: EventTransferToEditorProps) => {
  const {user} = useContext(ApplicationUserContext);
  const [coverList, setCoverList] = useState([] as CoverListItem[]);
  const makeCancelable = useCancelablePromise();

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (editorValues.scheduleEventId <= 0 || !user.isLoggedin) {
      return;
    }

    makeCancelable(requestsApi.getCoverRequestListItems(
      user,
      editorValues.scheduleEventId,
      false)).then(res => {
       setCoverList(res);

    });

    // eslint-disable-next-line
  }, [editorValues.scheduleEventId, user]);

  // --------------------------------------------------------------------------------------
  function onTransferToChange(selected: number | null | undefined) {
    if (selected !== undefined && selected !== editorValues.transferToEmployeeId) {
      update({type: "TRANSFERTO-EMPLOYEE-UPDATED", transferToEmployeeId: selected});
    }
  }

  if (
    (editorValues.eventType !== EventType.Cover || editorValues.eventStatus !== EventStatus.Request)
    &&
    (editorValues.transferToEmployeeId === null || auth.role === Role.Employee)
  ) {
    return <></>;
  }

  return (
    <IonRow>
      <IonCol size="4">
        <IonLabel className="label">Transfer To</IonLabel>
      </IonCol>
      <IonCol size="8">
        <IonSelect
          mode="ios"
          className="select"
          placeholder="None"
          interface="action-sheet"
          disabled={editorValues.isReadonly || editorValues.isConfirmed}
          value={editorValues.transferToEmployeeId}
          onIonChange={(e) => {
            onTransferToChange(e.detail.value);
          }}
        >
          {coverList.map((cov) => (
            <IonSelectOption
              key={cov.value}
              value={cov.value === 0 ? null : cov.value}
              disabled={cov.disabled}
            >
              {cov.text}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonCol>
    </IonRow>
  );
};

export default EventTransferToEditor;
