import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonHeader,
  IonTitle,
  IonLabel,
  IonToolbar,
  IonTextarea,
  IonFooter,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import "../../global-styles.scss";
import { arrowBackOutline } from "ionicons/icons";
import { AuthorizedItemsContext } from "../../context/AuthorizedItemsContext";
import EmployeesSelector from "./EmployeesSelector";
import { EmployeeCommunication } from "../../orbital-interfaces/EmployeeCommunication";
import { messageApi } from "../../api/messageApi";
import { useShowLoading } from "../../hooks/useShowLoading";
import { ApplicationUserContext } from "../../context/ApplicationUserContext";
import useDialogBox from "../../hooks/useDialogBox";
import { log } from "../../util/logger";

interface CreateMessageModalProps {
  onCloseModal: (toastMessage: string) => void;
  isOpen: boolean;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const CreateMessageModal: React.FC<CreateMessageModalProps> = ({
  onCloseModal,
  isOpen,
}) => {
  const { user } = useContext(ApplicationUserContext);
  const { auth } = useContext(AuthorizedItemsContext);
  const [messageText, setMessageText] = useState("");
  const [employeeIds, setEmployeeIds] = useState([] as number[]);
  const [locationId, setLocationId] = useState(0);
  const [channel, setChannel] = useState(0);
  const sendingMessage = useShowLoading("Sending");
  const dialog = useDialogBox();

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    log("createMsg_openDlg");
  }, []);

  // --------------------------------------------------------------------------------------
  const onSendClick = () => {
    sendingMessage.setShowLoading(true);
    messageApi
      .sendMessage(user, locationId, channel, employeeIds, messageText)
      .then((res) => {
        sendingMessage.setShowLoading(false);
        if (res.error) {
          dialog.showAlert(
            "Could not send",
            "Could not send message. " + res.message,
            () => {}
          );
        } else {
          log("createMsg_sent");
          if (employeeIds.length === 1) {
            onCloseModal("Message sent");
          } else {
            onCloseModal("Messages sent");
          }
        }
      });
  };

  // --------------------------------------------------------------------------------------
  const onMessageTextUpdate = (newText: string | undefined | null) => {
    if (newText !== null && newText !== undefined && newText !== messageText) {
      setMessageText(newText);
    }
  };

  // --------------------------------------------------------------------------------------
  const onEmployeeListUpdate = (
    newEmpList: EmployeeCommunication[],
    newLocId: number,
    newChannel: number
  ) => {
    setEmployeeIds(
      newEmpList.map((e) => {
        return e.id;
      })
    );

    if (newLocId !== locationId) {
      setLocationId(newLocId);
    }

    if (newChannel !== channel) {
      setChannel(newChannel);
    }
  };

  if (!isOpen || !auth || !auth.locations || auth.locations.length === 0) {
    return <div>Loading</div>;
  } else {
    return (
      <>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  log("createMessage_cancelDlg");
                  onCloseModal("");
                }}
              >
                <IonIcon icon={arrowBackOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>New Message</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="outer-content">
          <EmployeesSelector onUpdate={onEmployeeListUpdate} />
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div
                  style={{ width: "100%", borderBottom: "1px solid #ddd" }}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="4">
                <IonLabel className="label">Message</IonLabel>
              </IonCol>
              <IonCol size="8"></IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonTextarea
                  className="textarea"
                  mode="md"
                  value={messageText}
                  // placeholder={"Type here"}
                  spellcheck={true}
                  wrap="hard"
                  autoGrow={true}
                  debounce={250}
                  rows={8}
                  onIonChange={(e) => {
                    onMessageTextUpdate(e.detail.value);
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          {sendingMessage.showLoadingJsx}
        </IonContent>
        <IonFooter>
          <IonButton
            className="apply-button"
            expand="block"
            onClick={onSendClick}
            disabled={
              employeeIds.length === 0 ||
              messageText.length === 0 ||
              sendingMessage.showLoading
            }
          >
            Send
          </IonButton>
        </IonFooter>
      </>
    );
  }
};

export default CreateMessageModal;
