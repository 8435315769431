import React, {useContext, useEffect, useState} from "react";
import {
  IonRow,
  IonCol,
  IonLabel,
  IonInput,
} from "@ionic/react";
import "../global-styles.scss";
import {ApplicationUserContext} from "../context/ApplicationUserContext";
import {AuthorizedItemsContext} from "../context/AuthorizedItemsContext";
import {authHelper} from "../util/authHelper";
import {employeesApi, PayrollBanksForEmployee} from "../api/employeesApi";
import {LocationSettings} from "../orbital-interfaces/LocationSettings";
import {timeHelper} from "../util/timeHelper";

interface EmployeePayrollBanksProps {
  employeeId: number | null;
}

// -------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------
const EmployeePayrollBanks: React.FC<EmployeePayrollBanksProps> = ({
 employeeId,
}: EmployeePayrollBanksProps) => {
  const {user} = useContext(ApplicationUserContext);
  const {auth} = useContext(AuthorizedItemsContext);
  const [showBanks, setShowBanks] = useState(false);
  const [payrollBanks, setPayrollBanks] = useState([] as PayrollBanksForEmployee[]);
  const [locSettings, setLocSettings] = useState(null as null | LocationSettings);

  // -------------------------------------------------------------------------------------------
  useEffect(() => {
    if (employeeId !== null && user.isLoggedin && !user.loading && authHelper.isAuthItemsValid(auth)) {
      const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
      setLocSettings(locSettings);
      if (locSettings.EnablePTO) {
        employeesApi.getPayrollBanksForEmployee(user, employeeId).then(res => {
          if (res.length > 0) {
            setPayrollBanks(res);
            setShowBanks(true);
          }
        });
      }
    }

    // eslint-disable-next-line
  }, [user.isLoggedin, auth.locations?.length]);

  if (!showBanks) {
    return <></>;
  }

  return (
    <>
      <IonRow>
        <IonCol size="12">
          <IonLabel className="label">Payroll Banks</IonLabel>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="12">
          <div style={{ width: "100%", borderBottom: "1px solid #ddd" }} />
        </IonCol>
      </IonRow>

      {payrollBanks.map(pb => (
        <IonRow key={pb.payrollBankId}>
          <IonCol size="4">
            <IonLabel className="label"> {pb.payrollBankName} </IonLabel>
          </IonCol>
          <IonCol size="8">
            <IonInput
              type="text"
              className="input-textbox"
              disabled={true}
              value={timeHelper.getDesktopDurationStringFromTimeSpan(pb.bankedTime, locSettings!)}
            />
          </IonCol>
        </IonRow>
      ))}

    </>
  );
};

export default EmployeePayrollBanks;
