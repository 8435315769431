export enum AdminAuthorization {
  None = 0,
  CanViewWages = 1,
  CanEditPublishedTo = 2,
  CanEditTemplates = 4,
  CanEditSchedules = 8,
  CanEditEmployeeClockEvents = 16,
  CanEditOwnClockEvents = 32,
  CanEditUserAccounts = 64,
}
