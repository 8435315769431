import React  from "react";
import { IonButton } from "@ionic/react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { GeoLocationResults } from "../../appFunctions/geoLocationHelper";
import Map from './Map';

interface MapComponentProps {
  geoLocation: GeoLocationResults;
}

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
const MapWrapper: React.FC<MapComponentProps> = ({geoLocation}: MapComponentProps) => {
  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <Loading />;
      case Status.FAILURE:
        return <Error />;
      case Status.SUCCESS:
        return <Map geoLocation={geoLocation} />;
    }
  };

  return (
    <Wrapper
      apiKey={"AIzaSyCegwXwbr-NBIwZX85MAWpaMx9nQgDRGMg"}
      render={render}
    />
  );
};

const Loading: React.FC = () => {
  return (
      <IonButton className="map-button" disabled
      >
        ...
      </IonButton>
  );
}

const Error: React.FC = () => {
  return (
      <IonButton className="map-button" disabled
      >
        Map Unavailable
      </IonButton>
  );
}

export default MapWrapper;
