import {ApplicationUser} from "../context/ApplicationUserContext";
import {AuthorizedItems} from "../orbital-interfaces/AuthorizedItems";
import {getSubscriptionTypeName} from "../enums";
import env from "../appFunctions/environment";
import { Capacitor } from '@capacitor/core';
import {
  FirebaseAnalytics,
  FirebaseInitOptions
} from "@capacitor-community/firebase-analytics";

let firebaseInitialized = false;

// ------------------------------------------------------------------------------------------------
export async function clearAnalytics() {
  if (Capacitor.getPlatform() !== 'web') {
    await FirebaseAnalytics.reset();
  }

  firebaseInitialized = false;
}

// ------------------------------------------------------------------------------------------------
export async function initialize(firebaseConfig: FirebaseInitOptions) {
  try
  {
    await FirebaseAnalytics.initializeFirebase(firebaseConfig);
    await FirebaseAnalytics.setCollectionEnabled({enabled: true});
    firebaseInitialized = true;
  } catch (error) {
    console.log("firebase init error: ", error);
  }
}

// ------------------------------------------------------------------------------------------------
export function log(eventName: string, eventParams?: any) {
  if (!firebaseInitialized)  {
    return;
  }

  FirebaseAnalytics.logEvent({
    name: eventName,
    params: eventParams
  });
}

// ------------------------------------------------------------------------------------------------
export function setFirebaseUserProperties(
  user: ApplicationUser,
  auth: AuthorizedItems) {

  if (user.isLoggedin){
    FirebaseAnalytics.setUserId({userId: user.username});
    FirebaseAnalytics.setUserProperty({name: "userRole", value: auth.role});
    FirebaseAnalytics.setUserProperty({name: "subscriptionType", value: getSubscriptionTypeName(auth.subscriptionType)});
    FirebaseAnalytics.setUserProperty({name: "businessName", value: auth.businessName});
    FirebaseAnalytics.setUserProperty({name: "userName", value: user.username});
    FirebaseAnalytics.setUserProperty({name: "version", value: env.settings.appVersionString});
    FirebaseAnalytics.setUserProperty({name: "environment", value: env.settings.env.item(user.environment).name});
    FirebaseAnalytics.setUserProperty({name: "appType", value: env.settings.appType});
    FirebaseAnalytics.setUserProperty({name: "userInterface", value: env.settings.userInterface});
  }
}
