import React, { useState } from "react";

export interface IEventsState {
  eventsRefreshDate: Date;
}

export interface IEventsContext extends IEventsState {
  setEventsRefreshed: () => void;
}

const INITIAL_EVENTS_CONTEXT: IEventsContext = {
  eventsRefreshDate: new Date(2010, 0, 1),
  setEventsRefreshed: () => {}
};

export const EventsContext = React.createContext<IEventsContext>(
  INITIAL_EVENTS_CONTEXT
);

// --------------------------------------------------------------------------------------
//
// --------------------------------------------------------------------------------------
export const EventsContextProvider = (props: any) => {
  const [eventsState, internalSetEventsState] = useState<IEventsState>({
    eventsRefreshDate: new Date(2010, 0, 1)
  });

  // --------------------------------------------------------------------------------------
  function setEventsRefreshed() {
    internalSetEventsState({
      ...eventsState,
      eventsRefreshDate: new Date(),
    });
  }

  return (
    <EventsContext.Provider
      value={{
        eventsRefreshDate: eventsState.eventsRefreshDate,
        setEventsRefreshed,
      }}
    >
      {props.children}
    </EventsContext.Provider>
  );
};
