import React, {useContext, useEffect, useState} from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../ag-grid-custom-styles.scss';
import {Definition, ReportResults, Row, RowDetailType} from "./ReportResults";
import {AuthorizedItemsContext} from "../../context/AuthorizedItemsContext";
import {authHelper} from "../../util/authHelper";
import {IonModal} from "@ionic/react";
import {useAgGrid} from "../../hooks/useAgGrid";
import {generateGridState} from "./generateGridState";
import EmployeeDetailModal from "../EmployeeDetailModal";
import EditEventModal from "../EditEvent/EditEventModal";
import {INITIAL_EDITOR_VALUES} from "../EditEvent/EditEventModalState";
import useSimpleToast from "../../hooks/useSimpleToast";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";

interface ReportGridProps {
  onRefreshData: () => void;
  reportData: ReportResults;
  isShowing: boolean;
}

// ----------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------
const ReportGrid: React.FC<ReportGridProps> = ({ onRefreshData, reportData, isShowing }: ReportGridProps) => {
  const {user} = useContext(ApplicationUserContext);
  const {auth} = useContext(AuthorizedItemsContext);
  const agTop = useAgGrid();
  const agBottom = useAgGrid();
  const toast = useSimpleToast();
  const locSettings = authHelper.getLocSettingsForLocationId(auth, user.defaultLocationId);
  const [topGridOptions, bottomGridOptions] = generateGridState(reportData, locSettings);

  const [showEmployeeDetailModal, setShowEmployeeDetailModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null as number | null);

  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedScheduleEventId, setSelectedScheduleEventId] = useState(null as number | null);

  // --------------------------------------------------------------------------------
  useEffect(() => {
    if (isShowing && reportData && agTop.columnApi !== null && agBottom.columnApi !== null) {
      agTop.columnApi.autoSizeAllColumns();
      agTop.gridApi!.sizeColumnsToFit();
      agBottom.columnApi.autoSizeAllColumns();
      agBottom.gridApi!.sizeColumnsToFit();
    }
  }, [reportData, agTop, agBottom, isShowing]);

  // --------------------------------------------------------------------------------
  function onRowSelectionChanged(reportRow: Row, reportDef: Definition) {

    if (reportDef.rowDetailType === RowDetailType.Employee) {
      const empIdField = reportRow.fields.find(f => f.name === "_employeeId");
      if (empIdField !== undefined) {
        setSelectedEmployeeId(empIdField.value as number);
        setShowEmployeeDetailModal(true);
      }
    } else if (reportDef.rowDetailType === RowDetailType.ScheduleEvent) {
      const eventIdField = reportRow.fields.find(f => f.name === "_scheduleEventId");
      if (eventIdField !== undefined) {
        setSelectedScheduleEventId(eventIdField.value as number);
        setShowEventModal(true)
      }
    }
  }

  return (
    <>
      <div className="ag-theme-alpine" /* style={{display: 'flex', flexDirection: 'column', height: '100%'}} */ >
        <div className="data-display-container" /* style={{flex: '1 1 auto'}} */ >
          <AgGridReact
            gridOptions={topGridOptions}
            columnDefs={topGridOptions.columnDefs}
            rowData={topGridOptions.rowData}
            onGridReady={(p) => {
              p.columnApi.autoSizeAllColumns();
              p.api.sizeColumnsToFit();
              agTop.onGridReady(p);
            }}

            onSelectionChanged={() => {
              if (!agTop.gridApi) {
                return;
              }
              const gridRow = agTop.gridApi!.getSelectedRows()[0];
              const reportRow = reportData.records[gridRow._rowNum];
              onRowSelectionChanged(reportRow, reportData.def);
            }}
          />
        </div>

        <div className="data-footer-container" >
          <div className="data-footer-container-inner">
            <AgGridReact
              gridOptions={bottomGridOptions}
              columnDefs={bottomGridOptions.columnDefs}
              rowData={bottomGridOptions.rowData}
              onGridReady={(p) => {
                p.columnApi.autoSizeAllColumns();
                p.api.sizeColumnsToFit();
                agBottom.onGridReady(p);
              }}
            />
          </div>
        </div>
      </div>

      <IonModal mode="md" isOpen={showEmployeeDetailModal}>
        <EmployeeDetailModal
          onDismissModal={() => setShowEmployeeDetailModal(false)}
          isOpen={showEmployeeDetailModal}
          employeeId={selectedEmployeeId}
        />
      </IonModal>

      <IonModal
        mode="md"
        isOpen={showEventModal}
        onDidDismiss={() => setShowEventModal(false)}
      >
        <EditEventModal
          isOpen={showEventModal}
          auth={auth}
          onCancelModal={() => setShowEventModal(false)}
          onDismissModal={(toastMessage) => {
            if (toastMessage) {
              toast.showToast(toastMessage);
              onRefreshData();
            }
            setShowEventModal(false);
          }}
          scheduleEventId={selectedScheduleEventId}
          initialEvent={{...INITIAL_EDITOR_VALUES}}
        />
      </IonModal>
      {toast.simpleToastJsx}
    </>
  );
};

export default ReportGrid;

