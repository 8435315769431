import React, {useContext, useEffect, useRef, useState} from "react";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  BreakType,
  ClockInRequirement,
  EndBreakResponseCode,
  StartBreakResponseCode,
  UnscheduledBreakOptions,
} from "../../enums";
import "../../global-styles.scss";
import {LocationSettings} from "../../orbital-interfaces/LocationSettings";
import {TimeClockSettingsData} from "../../orbital-interfaces/TimeClockSettingsData";
import {cafe, time} from "ionicons/icons";
import BreakSelectorModal, {BreakItem} from "./BreakSelectorModal";
import {arrayHelper} from "../../util/arrayHelper";
import {timeClockApi} from "../../api/timeClockApi";
import {format, parseISO} from "date-fns";
import {ApplicationUserContext} from "../../context/ApplicationUserContext";
import ClockoutModal from "./ClockoutModal";
import SwitchPositionOrTagButton from "./SwitchPositionOrTagButton";
import {useInterval} from "../../hooks/useInterval";
import useSimpleToast from "../../hooks/useSimpleToast";
import {getConstants} from "../../appFunctions/environment";
import {EventsContext} from "../../context/EventsContext";
import {GeoLocationResults} from "../../appFunctions/geoLocationHelper";
import {authHelper} from "../../util/authHelper";
import { AuthorizedItems } from "../../orbital-interfaces/AuthorizedItems";
import { punchClockApi } from "../../api/punchClockApi";
import { isApiError } from "../../orbital-interfaces/ApiErrorResult";

interface ClockPanelProps {
  isOpen: boolean;
  onDismissModal: (toastMessage?: string) => void;
  geoLocation: GeoLocationResults;
  auth: AuthorizedItems;
  stationCode?: string;
  timeclockSettings: null | TimeClockSettingsData;
}

interface List {
  id: number;
  name: string;
}

// --------------------------------------------------------------------------------------
function getBreakSetting(
  tcData: TimeClockSettingsData,
  locSettings: LocationSettings): "END BREAK" | "START BREAK" | "NONE" {

  if (!locSettings.EnableEnhancedBreakManagement) {
    return "NONE";
  }

  if (tcData.UserClockedIn && tcData.CurrentEventBreak) {
    return "END BREAK";
  }

  if (locSettings.UnscheduledBreakOptions !== UnscheduledBreakOptions.None) {
    return "START BREAK";
  }

  if (tcData.ScheduleEventsForClockIn === null ||
      tcData.ScheduleEventsForClockIn.length === 0) {
    return "NONE";
  }

  const ev = tcData.ScheduleEventsForClockIn[0];
  if (ev.EventBreaks === null ||
      ev.EventBreaks.length === 0) {
    return "NONE";
  }

  return "START BREAK";
}

// -------------------------------------------------------------------------------------------------------
//
// -------------------------------------------------------------------------------------------------------
const ClockPanel: React.FC<ClockPanelProps> = ({
  isOpen,
  onDismissModal,
  geoLocation,
  auth,
  stationCode,
  timeclockSettings
}: ClockPanelProps) => {
  const { user } = React.useContext(ApplicationUserContext);
  const [posList, setPosList] = useState([] as List[]);
  const [selectedPosId, setSelectedPosId] = useState(0);
  const positionRef = useRef(0);
  const [tagSwitchingEnabled, setTagSwitchingEnabled] = useState(false);
  const [tagList, setTagList] = useState([] as List[]);
  const [selectedTagId, setSelectedTagId] = useState(null as number | null);
  const tagRef = useRef(null as number | null);
  const [breakSetting, setBreakSetting] = useState<
    "START BREAK" | "END BREAK" | "NONE"
  >("START BREAK");
  const [locSettings, setLocSettings] = useState({} as LocationSettings);
  const [showBreakSelectorModal, setShowBreakSelectorModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Please wait...");
  const [showAlertData, setShowAlertData] = useState({
    show: false,
    header: "Alert",
    message: "",
  });
  const [tcData, setTCData] = useState(timeclockSettings);
  const [showClockoutModal, setShowClockoutModal] = useState(false);
  const toast = useSimpleToast();
  const eventsContext = useContext(EventsContext);
  const isMountedRef = useRef(null as null | boolean);

  // ------------------------------------------------------------------------------------
  useEffect(function useEffectOne() {
    isMountedRef.current = true;
    if (isOpen && timeclockSettings === null) {
      updateTimeClockData(geoLocation.lat, geoLocation.lng);
    }

    return () => {isMountedRef.current = false};

    // eslint-disable-next-line
  }, [isOpen, eventsContext.eventsRefreshDate, geoLocation.lat, geoLocation.lng, timeclockSettings]);


  // ------------------------------------------------------------------------------------
  useEffect(function useEffectTwo() {
    isMountedRef.current = true;
    if (!tcData || !authHelper.isAuthItemsValid(auth)) {
      return;
    }

    if (tcData.ShowTimeClock) {
      const uniqueLocationIds = arrayHelper.unique(
        tcData.PositionsToClockInto.map((p) => p.LocationId)
      );
      const uniqueDeptIds = arrayHelper.unique(
        tcData.PositionsToClockInto.map((p) => p.DepartmentId)
      );
      let plist: List[];
      if (uniqueLocationIds.length > 1) {
        plist = tcData.PositionsToClockInto.map((p) => {
          return {
            id: p.PositionId,
            name: p.PositionNameWithLocAndDeptAbbreviation,
          };
        });
      } else if (uniqueDeptIds.length > 1) {
        plist = tcData.PositionsToClockInto.map((p) => {
          return { id: p.PositionId, name: p.PositionNameWithDeptAbbreviation };
        });
      } else {
        plist = tcData.PositionsToClockInto.map((p) => {
          return { id: p.PositionId, name: p.PositionName };
        });
      }

      let curPosId = selectedPosId;
      if (curPosId === 0) {
        if (tcData.UserClockedIn) {
          curPosId = tcData.ClockedInScheduleEvent!.PositionId;
        } else if (
          tcData.ScheduleEventsForClockIn &&
          tcData.ScheduleEventsForClockIn.length > 0
        ) {
          curPosId = tcData.ScheduleEventsForClockIn[0].PositionId;
        } else if (plist.length > 0) {
          curPosId = plist[0].id;
        } else {
          curPosId = 0;
        }
      }

      let curLocId = tcData.UserClockedIn
        ? tcData.ClockedInScheduleEvent!.LocationId
        : curPosId !== 0
          ? auth.locations.filter((l) =>
              l.departments.flatMap((d) =>
                d.positions.flatMap((p) => p.id === curPosId)
              )
            )[0].id
          : user.defaultLocationId;

      if (curLocId === undefined || curLocId === 0) {
        curLocId = auth.locations[0].id;
      }

      if (curPosId !== selectedPosId && curPosId !== 0) {
        setSelectedPosId(curPosId);
        setTimeout(() => {
          setSelectedPosId(curPosId);
        }, 1500);
      }

      let newLocSettings: LocationSettings;

      if (curPosId !== 0) {
        const locationId = authHelper.getLocationIdFromPositionid(auth, curPosId);
        newLocSettings = authHelper.getLocSettingsForLocationId(auth, locationId);
      } else {
        newLocSettings = auth.locations.filter((l) => l.id === curLocId)[0].settings;
      }

      setLocSettings(newLocSettings);
      setBreakSetting(getBreakSetting(tcData, newLocSettings));
      setPosList(plist);

      setTags(curPosId);
    }

    return () => { isMountedRef.current = false;}
    // eslint-disable-next-line
  }, [auth, tcData, geoLocation.lat, geoLocation.lng]);

  // ------------------------------------------------------------------------------------
  useInterval(
    () => {
      if (isMountedRef.current) {
        updateTimeClockData(geoLocation.lat, geoLocation.lng);
      }
    },
    isOpen,
    getConstants(user).refreshTimeclockSettingsTimer,
    [geoLocation.lat, geoLocation.lng]
  );

  // ------------------------------------------------------------------------------------
  const updateTimeClockData = (
    curLat?: number | undefined,
    curLng?: number | undefined
  ) => {
    if (stationCode) {
      punchClockApi.refreshStationCode(user, stationCode, curLat, curLng).then((results) => {
        if (!isApiError(results) && isMountedRef.current) {
          setTCData(results.timeclockData);
        }
      })
    } else {
      timeClockApi.getTimeClockSettings(user, curLat, curLng).then((results) => {
        if (isMountedRef.current) {
          setTCData(results);
        }
      });  
    }
  };

  // ------------------------------------------------------------------------------------
  const setTags = (currentPositionId: number): number => {
    if (!tcData) {
      return -1;
    }

    const curLocId = tcData.UserClockedIn
      ? tcData.ClockedInScheduleEvent!.LocationId
      : auth.locations.filter((l) =>
          l.departments.flatMap((d) =>
            d.positions.flatMap((p) => p.id === currentPositionId)
          )
        )[0].id;
    const locSettings = auth.locations.filter((l) => l.id === curLocId)[0]
      .settings;

    if (
      locSettings.ClockInWithDifferentTag &&
      tcData.TagsPerPosition[currentPositionId] &&
      tcData.TagsPerPosition[currentPositionId].length > 1
    ) {
      setTagSwitchingEnabled(true);
      const tagList = tcData.TagsPerPosition[currentPositionId].map((t) => {
        return { id: t.TagId, name: t.TagName };
      });
      tagList.unshift({ id: -1, name: "NONE" });
      setTagList(tagList);
    } else {
      setTagSwitchingEnabled(false);
      return -1;
    }

    let tagId = -1;
    if (tcData.UserClockedIn) {
      if (tcData.ClockedInScheduleEvent!.TagId) {
        tagId = tcData.ClockedInScheduleEvent!.TagId;
      } else if (tcData.ClockedInScheduleEvent!.TagId === null) {
        tagId = -1;
      } else {
        tagId = -1;
      }
    } else if (
      tcData.TagsPerPosition[currentPositionId] &&
      tcData.TagsPerPosition[currentPositionId].length > 0
    ) {
      if (
        tcData.ScheduleEventsForClockIn &&
        tcData.ScheduleEventsForClockIn[0] &&
        tcData.ScheduleEventsForClockIn[0].TagId
      ) {
        tagId = tcData.ScheduleEventsForClockIn[0].TagId;
      } else {
        tagId = -1;
      }
    }

    setSelectedTagId(tagId);
    return tagId;
  };

  // ------------------------------------------------------------------------------------
  const onPositionChange = (newVal: number) => {
    if (newVal !== selectedPosId && newVal !== positionRef.current) {
      setSelectedPosId(newVal);
      setTags(newVal);
    }
  };

  // ------------------------------------------------------------------------------------
  const onTagChange = (newVal: number) => {
    if (newVal !== selectedTagId && newVal !== tagRef.current) {
      setSelectedTagId(newVal);
    }
  };

  // ------------------------------------------------------------------------------------
  const showAlert = (header: string, message: string) => {
    setShowAlertData({ show: true, header, message });
  };

  // ------------------------------------------------------------------------------------
  const onBreakSelectorModalClose = (brk: BreakItem) => {
    if (!tcData) {
      return;
    }

    setShowBreakSelectorModal(false);
    if (brk.key === "Cancel") {
      return;
    }

    setLoadingMessage("Starting Break...");
    setShowLoading(true);

    if (stationCode) {
      punchClockApi
      .startBreak(
        user,
        stationCode,
        tcData.ClockedInScheduleEvent!,
        tcData.ScheduleEventsForClockIn[0],
        brk.breakType,
        geoLocation.lat,
        geoLocation.lng,
        brk.breakType === BreakType.Scheduled ? brk.key : undefined
      )
      .then((response) => {
        setShowLoading(false);
        if (
          response.StartBreakResponseCode === StartBreakResponseCode.Success
        ) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Break started at " +
            format(parseISO(response.BreakStart), "h:mmaaa"));
        } else {
          showAlert(
            "Could not start break",
            `Unable to start break, ${response.Message}`
          );
        }
      });
    } else {
      timeClockApi
      .startBreak(
        user,
        tcData.ClockedInScheduleEvent!,
        tcData.ScheduleEventsForClockIn[0],
        brk.breakType,
        geoLocation.lat,
        geoLocation.lng,
        brk.breakType === BreakType.Scheduled ? brk.key : undefined
      )
      .then((response) => {
        setShowLoading(false);
        if (
          response.StartBreakResponseCode === StartBreakResponseCode.Success
        ) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Break started at " +
            format(parseISO(response.BreakStart), "h:mmaaa"));
        } else {
          showAlert(
            "Could not start break",
            `Unable to start break, ${response.Message}`
          );
        }
      });
    }
  };

  // ------------------------------------------------------------------------------------
  const clockout = (clockOutValue?: number) => {
    if (showClockoutModal) {
      setShowClockoutModal(false);
    }

    setLoadingMessage("Clocking Out...");
    setShowLoading(true);

    if (stationCode) {
      punchClockApi.clockOut(
        user,
        stationCode,
        geoLocation.lat,
        geoLocation.lng,
        clockOutValue)
        .then((response) => {
        setShowLoading(false);
        if (response.ClockOutResponseCode === 0) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Clocked out at " +
            format(parseISO(response.ClockOutEvent.EventEnd), "h:mmaaa"));
        } else {
          showAlert(
            "Could not Clock Out",
            `Unable to Clock out, ${response.Message}`
          );
        }
      });  
    } else {
      timeClockApi.clockOut(
        user,
        geoLocation.lat,
        geoLocation.lng,
        clockOutValue)
        .then((response) => {
        setShowLoading(false);
        if (response.ClockOutResponseCode === 0) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Clocked out at " +
            format(parseISO(response.ClockOutEvent.EventEnd), "h:mmaaa"));
        } else {
          showAlert(
            "Could not Clock Out",
            `Unable to Clock out, ${response.Message}`
          );
        }
      });  
    }
  };

  // ------------------------------------------------------------------------------------
  const onClockOutClick = () => {
    if (locSettings.ClockOutValueLabel) {
      setShowClockoutModal(true);
    } else {
      clockout();
    }
  };

  // ------------------------------------------------------------------------------------
  const onClockInClick = () => {
    setLoadingMessage("Clocking in...");
    setShowLoading(true);

    if (stationCode) {
      punchClockApi
      .clockIn(user, stationCode, selectedPosId, selectedTagId, geoLocation.lat, geoLocation.lng)
      .then((response) => {
        setShowLoading(false);
        if (response.ClockInResponseCode === 0) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Clocked in at " +
            format(parseISO(response.ClockInEvent.EventStart), "h:mmaaa"));
        } else {
          showAlert(
            "Could not Clock In",
            `Unable to Clock in, ${response.Message}`
          );
        }
      });
    } else {
      timeClockApi
      .clockIn(user, selectedPosId, selectedTagId, geoLocation.lat, geoLocation.lng)
      .then((response) => {
        setShowLoading(false);
        if (response.ClockInResponseCode === 0) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Clocked in at " +
            format(parseISO(response.ClockInEvent.EventStart), "h:mmaaa"));
        } else {
          showAlert(
            "Could not Clock In",
            `Unable to Clock in, ${response.Message}`
          );
        }
      });
    }
  };

  // ------------------------------------------------------------------------------------
  const onBreakButtonClick = () => {
    if (breakSetting === "NONE" || !tcData) {
      return;
    }

    if (breakSetting === "END BREAK") {
      setLoadingMessage("Ending Break...");
      setShowLoading(true);

      if (stationCode) {
        punchClockApi
        .endBreak(
          user,
          stationCode,
          tcData.ClockedInScheduleEvent!,
          tcData.ScheduleEventsForClockIn.length > 0
            ? tcData.ScheduleEventsForClockIn[0]
            : null,
          geoLocation.lat,
          geoLocation.lng
        )
        .then((response) => {
          setShowLoading(false);
          if (response.EndBreakResponseCode === EndBreakResponseCode.Success) {
            eventsContext.setEventsRefreshed();
            onDismissModal(`Ended break at ${format(parseISO(response.BreakEnd), "h:mmaaa")}`);
          } else {
            showAlert("Did not come off break", response.Message);
          }
        });
      } else {
        timeClockApi
        .endBreak(
          user,
          tcData.ClockedInScheduleEvent!,
          tcData.ScheduleEventsForClockIn.length > 0
            ? tcData.ScheduleEventsForClockIn[0]
            : null,
          geoLocation.lat,
          geoLocation.lng
        )
        .then((response) => {
          setShowLoading(false);
          if (response.EndBreakResponseCode === EndBreakResponseCode.Success) {
            eventsContext.setEventsRefreshed();
            onDismissModal(`Ended break at ${format(parseISO(response.BreakEnd), "h:mmaaa")}`);
          } else {
            showAlert("Did not come off break", response.Message);
          }
        });
      }

      return;
    }

    setShowBreakSelectorModal(true);
  };

  // ------------------------------------------------------------------------------------
  const onSwitchClick = () => {
    setLoadingMessage("Switching...");
    setShowLoading(true);

    if (stationCode) {
      punchClockApi
      .switchPositionOrTag(user, stationCode, selectedPosId, selectedTagId, geoLocation.lat, geoLocation.lng)
      .then((response) => {
        setShowLoading(false);
        if (response.AllowedToSwitchPositions || response.AllowedToSwitchTags) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Switched");
        } else {
          showAlert(
            "Could not switch",
            `${response.ClockInMessage} ${response.ClockOutMessage}`
          );
        }
      });
    } else {
      timeClockApi
      .switchPositionOrTag(user, selectedPosId, selectedTagId, geoLocation.lat, geoLocation.lng)
      .then((response) => {
        setShowLoading(false);
        if (response.AllowedToSwitchPositions || response.AllowedToSwitchTags) {
          eventsContext.setEventsRefreshed();
          onDismissModal("Switched");
        } else {
          showAlert(
            "Could not switch",
            `${response.ClockInMessage} ${response.ClockOutMessage}`
          );
        }
      });
    }
  };

  if (!auth || !tcData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <IonGrid className="clock-controls">
        {toast.simpleToastJsx}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          // spinner="dots"
          message={loadingMessage}
        />
        {tcData.PositionsToClockInto && tcData.PositionsToClockInto.length > 1 && (
          <IonRow>
            <IonCol size="3">
              <IonLabel>Position</IonLabel>
            </IonCol>
            <IonCol size="9">
              <IonSelect
                className="select"
                mode="ios"
                interface="action-sheet"
                onIonChange={(e) => onPositionChange(e.detail.value)}
                disabled={
                  !(
                    ((tcData.UserClockedIn && tcData.UserMayClockOut) ||
                      (!tcData.UserClockedIn && tcData.UserMayClockIn)) &&
                    locSettings.ClockInRequirement !==
                      ClockInRequirement.MatchingShift
                  )
                }
                value={selectedPosId}
              >
                {posList.map((pos) => (
                  <IonSelectOption key={pos.id} value={pos.id}>
                    {pos.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
        )}
        {tagSwitchingEnabled && (
          <IonRow>
            <IonCol size="3">
              <IonLabel>~Tag</IonLabel>
            </IonCol>
            <IonCol size="9">
              <IonSelect
                className="select"
                mode="ios"
                interface="action-sheet"
                onIonChange={(e) => onTagChange(e.detail.value)}
                disabled={
                  !(
                    ((tcData.UserClockedIn && tcData.UserMayClockOut) ||
                      (!tcData.UserClockedIn && tcData.UserMayClockIn))
                  )
                }
                value={selectedTagId}
              >
                {tagList.map((t) => (
                  <IonSelectOption key={t.id} value={t.id}>
                    {t.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
        )}
        {((tcData.PositionsToClockInto && tcData.PositionsToClockInto.length > 1) || tagSwitchingEnabled) && (
          <IonRow>
            <IonCol size="12" className="ion-no-margin">
              <SwitchPositionOrTagButton
                tcData={tcData}
                locSettings={locSettings}
                selectedPosId={selectedPosId}
                selectedTagId={selectedTagId}
                onSwitch={onSwitchClick}
              />
            </IonCol>
          </IonRow>
        )}
        {!tcData.UserClockedIn && (
          <IonRow>
            <IonCol size="12" className="ion-no-margin">
              <IonButton
                className="clockin-button"
                onClick={onClockInClick}
                disabled={!tcData.UserMayClockIn}
              >
                <IonIcon mode="md" icon={time} /> &nbsp;CLOCK IN
              </IonButton>
            </IonCol>
          </IonRow>
        )}

        {tcData.UserClockedIn && (
          <IonRow>
            <IonCol className="clockout-button-container">
              <IonButton
                className="clockout-button"
                disabled={!tcData.UserMayClockOut}
                expand="block"
                onClick={onClockOutClick}
              >
                <IonIcon mode="md" icon={time} /> &nbsp;CLOCK OUT
              </IonButton>

              {breakSetting !== "NONE" && (
                <IonButton
                  onClick={onBreakButtonClick}
                  expand="block"
                  className="break-button"
                  disabled={!tcData.UserMayClockOut}
                >
                  <IonIcon icon={cafe} /> &nbsp;
                  {breakSetting}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>

      <IonModal
        mode="md"
        isOpen={showBreakSelectorModal}
        cssClass="mini-modal break-selector-modal"
      >
        <BreakSelectorModal
          auth={auth}
          tcData={tcData}
          onDismissModal={(selection) => onBreakSelectorModalClose(selection)}
        />
      </IonModal>

      <IonModal
        mode="md"
        cssClass="mini-modal clockout-modal"
        isOpen={showClockoutModal}
        onDidDismiss={() => {
          setShowClockoutModal(false);
        }}
      >
        <ClockoutModal
          onCancel={() => {
            setShowClockoutModal(false);
          }}
          onOk={clockout}
          locSettings={locSettings}
        />
      </IonModal>

      <IonAlert
        isOpen={showAlertData.show}
        onDidDismiss={() => setShowAlertData({ ...showAlertData, show: false })}
        header={showAlertData.header}
        message={showAlertData.message}
        buttons={["OK"]}
      />
    </>
  );
};

export default ClockPanel;
