import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonCol,
  IonLabel,
  IonRow,
} from "@ionic/react";
import "../../../global-styles.scss";
import "./EventEmployeeEditor";
import { AuthorizedItems } from "../../../orbital-interfaces/AuthorizedItems";
import { Role } from "../../../enums";
import { employeesApi } from "../../../api/employeesApi";
import { ApplicationUserContext } from "../../../context/ApplicationUserContext";
import { parseISO } from "date-fns";
import { EditorReducerAction, EditorValues } from "../EditEventModalState";
import { IdName } from "../../../orbital-interfaces/IdName";
import { log } from "../../../util/logger";
import OptionSelector from "../../EventsPageComponents/OptionSelector";
import useCancelablePromise from "../../../hooks/useCancelablePromise";

interface EventEmployeeEditorProps {
  auth: AuthorizedItems;
  editorValues: EditorValues;
  update: React.Dispatch<EditorReducerAction>;
  updateShowLoading: (newVal: boolean) => void;
}

// --------------------------------------------------------------------------------------
const EventEmployeeEditor: React.FC<EventEmployeeEditorProps> = ({
  auth,
  editorValues,
  update,
  updateShowLoading,
}: EventEmployeeEditorProps) => {
  const [employeeList, setEmployeeList] = useState([] as IdName[]);
  const { user } = useContext(ApplicationUserContext);
  const empIdRef = useRef(null as number | null);
  const makeCancelable = useCancelablePromise();

  // --------------------------------------------------------------------------------------
  useEffect(() => {
    if (user && user.isLoggedin && editorValues.scheduleEventId !== -1) {
      log("edit_event_get_employeeList", { user: user.username });
      updateShowLoading(true);
      getEmployees(editorValues);
    }
    // eslint-disable-next-line
  }, [
    user,
    editorValues.position.position.id,
    editorValues.scheduleEventId,
    editorValues.employee.id,
  ]);

  // --------------------------------------------------------------------------------------
  function onChange(employeeId: number) {
    if (employeeId === -5 || employeeId === editorValues.employee.id) {
      return;
    }

    if (employeeId === empIdRef.current) {
      return;
    }

    empIdRef.current = employeeId;
    const emp = employeeList.filter((e) => e.id === employeeId)[0];
    if (employeeId === -1) {
      update({
        type: "EMPLOYEE-UPDATED",
        employee: { id: -1, name: "SELECT EMPLOYEE" },
      });
    } else {
      update({
        type: "EMPLOYEE-UPDATED",
        employee: { id: employeeId, name: emp.name },
      });
    }
  }

  // --------------------------------------------------------------------------------------
  function getEmployees(ev: EditorValues) {
    const justEmployee = [{ id: auth.employeeId, name: auth.fullName }];

    if (auth.role === Role.Employee) {
      setEmployeeList(justEmployee);
      updateShowLoading(false);
      return;
    }

    makeCancelable(employeesApi
      .getEmployeesByLocationIdPositionIdAndActiveDateRange(
        user,
        ev.position.location.id,
        ev.position.position.id,
        parseISO(ev.eventStart),
        parseISO(ev.eventEnd),
        ev.position.location.settings.EmployeeSort
      ))
      .then((employees) => {
        const empIds = employees.map((e) => e.id);
        const selectEmployeeValue = { id: -1, name: "SELECT EMPLOYEE"} as IdName
        employees.push(selectEmployeeValue);
        setEmployeeList(employees);

        if (!empIds.includes(ev.employee.id)) {
          update({
            type: "EMPLOYEE-UPDATED",
            employee: selectEmployeeValue,
          });
        }

        updateShowLoading(false);
    });
  }

  return (
    <>
      <IonRow>
        <IonCol size="4">
          <IonLabel className="label">Employee</IonLabel>
        </IonCol>
        <IonCol size="8">
          <OptionSelector
            options={employeeList}
            initialOption={editorValues.employee.id}
            initialDisplay={editorValues.employee.name}
            onOptionUpdated={(e) => onChange(e)}
            isDisabled={
              editorValues.isReadonly ||
              auth.role === Role.Employee ||
              editorValues.isConfirmed ||
              editorValues.transferToEmployeeId !== null
            }
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default EventEmployeeEditor;
