import {OrbitalTime} from "../../orbital-interfaces/OrbitalTime";

export enum ColumnType {
  String = 1,
  Date,
  DateTime,
  OrbitalTime,
  TimeSpan,
  Decimal,
  Number,
  TimeRange
}

export enum ColumnAlignment {
  Default = 0,
  Left,
  Center,
  Right
}

export enum RowDetailType {
  None = 0,
  Employee = 1,
  ScheduleEvent = 2
}

export interface TimeRange {
  Start: string;
  End: string;
  OrbitalTime: OrbitalTime;
  Display: string
}

export interface Column {
  name: string;
  type: ColumnType;
  format: string;
  pinned: boolean;
  hidden: boolean;
  hideInDetail: boolean;
  hideByDefault: boolean;
  key: boolean;
  groupable: boolean;
  groupByDefault: boolean;
  summable: boolean;
  countable: boolean;
  alignment: ColumnAlignment;
}

export interface Definition {
  title: string;
  columns: Column[];
  showTotalRow: boolean;
  rowDetailType: RowDetailType;
}

export interface Field {
  name: string;
  value: any;
  red: boolean;
  yellow: boolean;
  bold: boolean;
  italic: boolean;
  appendString?: string;
}

export interface Row {
  highlighted: boolean;
  fields: Field[];
}

export interface ReportResults {
  def: Definition;
  records: Row[];
}
